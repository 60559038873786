import { create } from 'zustand'

// trigger a refresh of the account tracker data
// update the trackerRefreshIndex to trigger a refresh of the account tracker data after the 1st mount
type refreshTrackerDataStoreType = {
    trackerRefreshIndex: number;
    refreshTrackerData: () => void;
};

export const refreshTrackerDataStore = create<refreshTrackerDataStoreType>( ( set ) => ({
  trackerRefreshIndex: 0,
  refreshTrackerData: () => set( ( state ) => ({
    trackerRefreshIndex: state.trackerRefreshIndex + 1
  }) )
}) )