import { gql } from '@apollo/client'

export const productsFragment = gql`
  fragment Products on Products {
    items {
      id
      name
      sku
      homepage_most_popular
      small_image {
        label
        url
      }
      image {
        label
        url
      }
    }
  }
`
