import { gql } from '@apollo/client'

import { customerFragment } from 'graphql/fragments'

export const customerQuery = gql`
  ${customerFragment}
  query customer {
    customer {
      ...Customer
    }
  }
`
