import { useLocation } from 'react-router-dom'
import { RequestPasswordResetForm, AuthPageWrapper } from 'components/auth'

const RequestPasswordResetScreen = () => {
  const { state } = useLocation()

  return (
    <AuthPageWrapper>
      <div className="mt-8 md:mx-16">
        <h3 className="sm:text-2xl md:text-4xl font-bold">{`Forgot Your Password`}</h3>
      </div>

      <div className="my-8">
        <p className="text-xs font-light">{`Please enter your email address below to receive a password reset link.`}</p>
        <RequestPasswordResetForm email={state?.email ?? ``} />
      </div>
    </AuthPageWrapper>
  )
}

export default RequestPasswordResetScreen
