import React from 'react'
import TrackerStepIcon from '../../TrackerStepIcon'
import { calendarWhite } from 'resources/images'
import StepContentRenderer from '../../StepContentRenderer'
import { convertIsoDateToDateString } from 'components/accountTracker/utils/utils'

type AppointmentScheduleStepProps = {
    stepStatus: boolean;
    isCurrentStep?: boolean;
    isoApptDate: string;
}

function AppointmentScheduleStep({ stepStatus, isCurrentStep, isoApptDate } : AppointmentScheduleStepProps ) : JSX.Element {
  const getAppointmentDate = convertIsoDateToDateString( isoApptDate )

  const appointmentScheduleCopy = {
    notCompletedStepHeading: `Schedule an Appointment with your MD`,
    currentStepDetails: `Your insurance requires follow-up notes from your doctor. Once you have scheduled this appointment, let us 
      know by emailing papcompliance@aeroflowinc.com`,
    stepToolTip: `Part 2 of passing compliance per insurance guidelines requires follow-up notes from your doctor that indicate you 
      are using and benefiting from your machine. `,
    completedStepHeading: `Appointment Scheduled`,
    completedStepDetails: getAppointmentDate.date ? `Appointment with your MD is scheduled for ${getAppointmentDate.date}.` : ``
  }

  return (
    <div className="flex flex-wrap">
      <TrackerStepIcon
        imageSrc={calendarWhite}
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
      />
      <StepContentRenderer
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
        stepCopy={appointmentScheduleCopy}
      />
    </div>
  )
}

export default AppointmentScheduleStep