import { ReactElement, useState } from 'react'
import { SfAccordionItem } from '@storefront-ui/react'
import { Transition } from 'react-transition-group'
import './Accordion.css'

export type AccordionProps = {
  id: string;
  header: ReactElement
  openHeader?: ReactElement // if you need to dynamically render the header based on if the accordion is open
  body: ReactElement
}

export default function Accordion({id, header, openHeader, body}: AccordionProps ) {
  const [ isTransitioning, setTransitioning ] = useState( false )
  const [ opened, setOpened ] = useState<string[]>( [] )

  const isOpen = ( id: string ) => opened.includes( id )
  const summary = openHeader ? ( isOpen( id ) ? openHeader : header ) : header

  const handleToggle = ( id: string ) => ( open: boolean ) => {
    setTransitioning( true )
    setOpened( ( current ) => open ? [ ...current, id ] : current.filter( ( item ) => item !== id ) )
  }

  const handleStopTransition = () => {
    setTransitioning( false )
  }


  return (
    <>
      <SfAccordionItem
        key={id}
        summary={summary}
        onToggle={handleToggle( id )}
        open={isTransitioning || isOpen( id )}
        className={`accordion-item ${isOpen( id ) ? `is-open` : `is-closed`}`}
      >
        <Transition
          in={isOpen( id )}
          timeout={300}
          onEntered={handleStopTransition}
          onExited={handleStopTransition}
          mountOnEnter
          unmountOnExit
        >
          {( state ) => (
            <div
              className={`grid transition-[grid-template-rows] duration-300 grid-rows-[0fr] 
                ${state === `entering` || state === `entered` ? `!grid-rows-[1fr]` : ``} 
                ${state === `exiting` ? `grid-rows-[0fr]` : ``}`}
            >
              <div className="overflow-hidden">
                {body}
              </div>
            </div>
          )}
        </Transition>
      </SfAccordionItem>
    </>
  )
}