import { useEffect } from 'react'
import { fetchSnlData } from '../fetch'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import { reportToSentry } from 'utils/reportToSentry'
import { useBaseEquipmentStore } from '../State/baseEquipmentStore'

export const useGetMachineData = () => {
  const { machineData, isLoading, errorMessage, setMachineData, setIsLoading, setErrorMessage } = useBaseEquipmentStore()

  const displayErrorMessage = `Oops! Looks like something went wrong, please try again or call us at ${AF_SUPPORT_PHONE_NUMBER} for help.`

  useEffect( () => {
    const fetchData = async () => {
      if ( machineData.length > 0 ) return

      setIsLoading( true )
      try {
        const controller = new AbortController()

        const res = await fetchSnlData( `/machine-data`, controller.signal )

        if ( !res ) {
          setErrorMessage( displayErrorMessage )
          reportToSentry(
            `Failed to load machine data`,
            {
              route: `/machine-data`
            }
          )
        } else {
          setMachineData( res.response )
        }
      } catch ( error: any ) {
        if ( error.name !== `AbortError` ) {
          setErrorMessage( displayErrorMessage )
          reportToSentry( error )
        }
      } finally {
        setIsLoading( false )
      }
    }

    fetchData()
  }, [] )

  return {
    machineData,
    isLoading,
    errorMessage
  }
}