import { useNavigate } from "react-router-dom"
import { Tooltip as ReactTooltip } from "react-tooltip"
import { icoCaretLeft } from "resources/images"
import { LOGIN_PATH } from "routes"

export default function AuthBorderWrap({backButton = false, title, className, children, onClick} : {backButton?: boolean, title?: string, className?: string, children?: React.ReactNode, onClick?: () => void}) : JSX.Element {
  const navigate = useNavigate()

  return (
    <div className="mx-auto max-w-lg mb-20 mt-5">
      <div className={`flex flex-col border-4 border-lightGray sm:p-4 md:p-10 sm:mx-2 md:mx-auto ${className}`} onClick={onClick}>
        <h3 className="mb-4 flex items-center">
          {backButton && (
            <div
              className="self-center text-small mr-3 cursor-pointer"
              id="go-back-tooltip"
              data-tooltip-content="Go Back"
            >
              <ReactTooltip anchorSelect="#go-back-tooltip" className="text-sm" />
              <img
                src={icoCaretLeft}
                onClick={() => { navigate( `${LOGIN_PATH}` ) }}
                alt="Go Back"
                width="10"
                height="10"
              />
            </div>
          )}
          {title ? title : ``}
        </h3>
        {children}
      </div>
    </div>
  )
}