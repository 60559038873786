import { AuthBorderWrap } from 'components/auth'
import OneTimeLoginForm from 'components/auth/OneTimeLoginForm'
import React from 'react'

function OneTimePassword() : JSX.Element {
  return (
    <AuthBorderWrap title={`One Time Login`} backButton>
      <OneTimeLoginForm />
    </AuthBorderWrap>
  )
}

export default OneTimePassword