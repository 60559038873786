import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RESUPPLY_CLAIMS_PATH } from '../routes'
import { useClaimedState, useResupplySetting } from '../hooks'
import { MedicareChecklistType, ResupplyOption } from '../types'
import { QuantityQualityOptionGroup } from './'
import { PagePropsAbstract } from 'components/pageNavigator'
import LoadingSpinner from 'components/LoadingSpinner'
import { submitMedicareChecklist } from './utils'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import { reportToSentry } from 'utils/reportToSentry'
import Header from '../Claims/Header'


type MedicareChecklistProps = PagePropsAbstract
export default function MedicareChecklist({...pageProps} : MedicareChecklistProps ) : JSX.Element {

  const [ userDeclined, setUserDeclined ] = useState<boolean>( false )
  const [ loading, setLoading ] = useState<boolean>( false )
  const [ error, setError ] = useState<string>( `` )

  const { patientId } = useResupplySetting()[0]
  const [ claimedItems, setClaimedItems ] = useClaimedState()
  const navigate = useNavigate()

  useEffect( () => {
    if ( !claimedItems?.length ) {
      navigate( RESUPPLY_CLAIMS_PATH, {
        replace: true
      })
    }
  }, [] )

  const handleChecklistChange = ( hcpc_id: number, questionType: `quantity` | `quality`, value: `yes` | `no` ) => {
    setUserDeclined( false )

    // Get medicareChecklist from saved claimedItems,
    let medicareChecklist = getMedicareChecklist( hcpc_id )
    if ( !medicareChecklist ) { // If checklist doesn't exist we create a new one
      medicareChecklist = {
        hcpc_id: hcpc_id,
        reason: {
          "qualityQuestionResponse": questionType === `quality` ? ( value === `yes` ) : false,
          "quantityQuestionResponse": questionType === `quantity` ? ( value === `yes` ) : false
        }
      }
    } else { // If medicareChecklist exists, we want to immutably overwrite the quantity | quality value
      const reason = Object.assign({}, medicareChecklist.reason )
      const questionResponseType = ( questionType === `quality` ) ? `qualityQuestionResponse` : `quantityQuestionResponse`
      reason[questionResponseType] = ( value === `yes` )
      medicareChecklist = {
        ...medicareChecklist,
        reason: {
          ...reason
        }
      }
    }
    setMedicareCheckList( hcpc_id, medicareChecklist )
  }

  /**
   * Will get medicareChecklist from claimedItems, if it doesn't exist will return a new empty checklist object
   */
  const getMedicareChecklist = ( hcpc_id : number ) : MedicareChecklistType | undefined => {
    const item = claimedItems.find( ( item ) => {
      return item.hcpc_id == hcpc_id
    })

    return item?.medicareChecklist
  }

  /**
   * Appends a medicareCheckList Object onto claimedItems' item at the hcpc_id provided
  */
  const setMedicareCheckList = ( hcpc_id : number, checkList : MedicareChecklistType ) => {
    const _claimed = [ ...claimedItems ]
    const activeIndex = _claimed.findIndex( item => { return item.hcpc_id == hcpc_id })
    Object.assign( _claimed[ activeIndex ]?.medicareChecklist ?? {}, checkList )
    setClaimedItems( _claimed )
  }

  const hasAcceptedAll = () => {
    const accepted = claimedItems.filter( ( item ) => {
      return (
        item.medicareChecklist?.reason?.qualityQuestionResponse &&
        item.medicareChecklist?.reason?.quantityQuestionResponse
      )
    })

    return accepted.length === claimedItems.length
  }

  const handleNext = async ( event : React.MouseEvent<HTMLButtonElement> ) => {
    event.preventDefault()
    const userAcceptedAll = hasAcceptedAll()
    setUserDeclined( !userAcceptedAll )
    if ( !patientId ) return setError( `Oops... We encountered an error submitting your medicare response. Please try again or reach out to customer service at ${AF_SUPPORT_PHONE_NUMBER} if the issue persists.` )
    if ( userAcceptedAll ) {
      setError( `` )
      setLoading( true )

      const medicareSubmissionResponse = await submitMedicareChecklist({
        checklists: claimedItems.filter( ( option: ResupplyOption ) => { return option.checklist_required }).map( ( option: ResupplyOption ) => {
          return {
            pat_num: patientId,
            medicare_checklist_hcpc: option.medicareChecklist?.hcpc_id?.toString(),
            quality_question_response: option.medicareChecklist?.reason.qualityQuestionResponse,
            quantity_question_response: option.medicareChecklist?.reason.quantityQuestionResponse
          }
        })
      })
        .catch( ( error : Error ) => {
          setLoading( false )
          reportToSentry( new Error( `Resupply: Exception submitting medicare checklist`, {
            cause: error
          }), {
            patientId,
            claimedItems: JSON.stringify( claimedItems )
          })

          return setError( `Oops... We encountered an error submitting your medicare response. Please try again or reach out to customer service at ${AF_SUPPORT_PHONE_NUMBER} if the issue persists.` )

        })

      setLoading( false )

      if ( medicareSubmissionResponse?.meta?.status !== `OK` || medicareSubmissionResponse?.errors?.length ) {
        reportToSentry( new Error( `Resupply: Exception submitting medicare checklist` ), {
          patientId,
          claimedItems: JSON.stringify( claimedItems ),
          medicareSubmissionResponse: JSON.stringify( medicareSubmissionResponse )
        })

        return setError( `Oops... We encountered an error submitting your medicare response. Please try again or reach out to customer service at ${AF_SUPPORT_PHONE_NUMBER} if the issue persists.` )
      }

      pageProps.nextPage()
    }
  }

  if ( loading ) return (
    <div className="my-24">
      <LoadingSpinner
        messageStyling="font-light text-lg text-center my-5"
        message="Please hold tight while submit your medicare responses..."
      />
    </div>
  )

  return (
    <div className="max-w-5xl mx-auto my-8 px-4">
      <div className="mb-6 text-center">
        <Header isMedicare />
      </div>
      <div data-testid="resupply-claim-checklist-container">
        {
          claimedItems.filter( ( option: ResupplyOption ) => { return option.checklist_required }).map( ( option: ResupplyOption ) => {
            return <QuantityQualityOptionGroup key={option.hcpc_id} option={option} onChange={handleChecklistChange} />
          })
        }
        {
          userDeclined && (
            <p className="text-error text-center max-w-sm mx-auto font-light text-lg">{`Your answers indicate you are not in need of one or more of these items. None of these items will be shipped...`}</p>
          )
        }
        {
          error &&
          <p className="text-error font-light text-lg px-5 max-w-md text-center mx-auto my-6">{error}</p>
        }
        <div className="max-w-xs w-full mx-auto mt-10 mb-5 flex flex-col items-center gap-4">
          <button className="btn btn-secondary" onClick={handleNext} disabled={userDeclined || loading}>{`Next`}</button>
          <button className="btn btn-secondary" onClick={pageProps.prevPage} disabled={userDeclined || loading}>{`Back`}</button>
        </div>
      </div>
    </div>
  )
}