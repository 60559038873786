import { useEffect } from 'react'
import { fetchSnlData } from '../fetch'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import { reportToSentry } from 'utils/reportToSentry'
import { useBaseEquipmentStore } from '../State/baseEquipmentStore'

export const useGetMaskData = () => {
  const { maskData, isLoading, errorMessage, setMaskData, setIsLoading, setErrorMessage } = useBaseEquipmentStore()

  const displayErrorMessage = `Oops! Looks like something went wrong, please try again or call us at ${AF_SUPPORT_PHONE_NUMBER} for help.`

  useEffect( () => {
    const fetchData = async () => {
      if ( maskData.length > 0 ) return

      setIsLoading( true )
      try {
        const controller = new AbortController()

        const res = await fetchSnlData( `/mask-data`, controller.signal )

        if ( !res ) {
          setErrorMessage( displayErrorMessage )
          reportToSentry(
            `Failed to load mask data`,
            {
              route: `/mask-data`
            }
          )
        } else {
          setMaskData( res.response )
        }
      } catch ( error ) {
        if ( error.name !== `AbortError` ) {
          reportToSentry( error )
          setErrorMessage( displayErrorMessage )
        }
      } finally {
        setIsLoading( false )
      }
    }

    fetchData()
  }, [] )

  return {
    maskData,
    isLoading,
    errorMessage
  }
}