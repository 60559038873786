import React from 'react'
import ContentLoader from 'react-content-loader'

function QualifyPlugContentLoader() {
  return (
    <div className="w-9/12 mx-auto sm:my-6 md:my-2 sm:p-0 md:p-4 max-w-full flex flex-col">
      <ContentLoader className="mt-5" viewBox="0 0 200 60">
        <rect
          x="2" y="30" rx="15"
          ry="15" width="190" height="25"
        />
        <rect
          x="22" y="1" rx="10"
          ry="10" width="150" height="15"
        />
      </ContentLoader>
      <div className="my-10">
        <ContentLoader viewBox="0 0 100 100" >
          <rect
            x="0" y="0" rx="5"
            ry="5" width="98" height="98"
          />
        </ContentLoader>
      </div>
      <div className="mb-10">
        <ContentLoader viewBox="0 0 200 60">
          <rect
            x="2" y="1" rx="15"
            ry="15" width="190" height="25"
          />
          <rect
            x="22" y="40" rx="10"
            ry="10" width="150" height="15"
          />
        </ContentLoader>
      </div>
    </div>
  )
}

export default QualifyPlugContentLoader