import React from 'react'

const OfflineLeadSubmitThankYou = () : JSX.Element => {
  const handleStartOver = () => {
    sessionStorage.clear()
  }
  return (
    <div className="container mx-auto max-w-4xl pb-8 min-h-50vh mt-6">
      <div className="text-center">
        <h1 className="mb-3 sm:text-3xl md:text-5xl">{`Thank You!`}</h1>
        <p className="mb-12">
          {`We have received your information and will work to verify your insurance coverage. Please be on the lookout for communication via email or phone for next steps.`}
        </p>
        <button className="btn-primary text-center sm:max-w-72 md:max-w-[420px]" onClick={handleStartOver} data-testid="start-over">{`Start over`}</button>
      </div>
    </div>
  )
}

export default OfflineLeadSubmitThankYou
