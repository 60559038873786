import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'components/modal'
import { icoXAob } from 'resources/images'
import LoadingSpinner from 'components/LoadingSpinner'

function PayLaterModal({ openModal, submittingAob, setOpenModal, handleConfirm }) {

  const handleRedirect = async () => {
    await handleConfirm()
    setOpenModal( false )
  }

  return (
    <Modal
      isOpen={openModal}
      onClose={() => { setOpenModal( false ) }}
      defaultCloseButton={false}
      initialClose
    >
      <img
        src={icoXAob}
        alt="ico-x"
        className="absolute right-1 top-3 px-3 hover:opacity-80 cursor-pointer object-contain"
        width={40}
        onClick={() => { return setOpenModal( false ) }}
      />
      <p className="text-xl font-bold text-center py-3">{`Pay Later`}</p>
      <p className="text-lg w-9/12 text-center font-light mx-auto">
        {`Aeroflow Sleep offers you the option to order your supply items now and receive them according to the ship date and prescription requirements outlined in the previous screens. After your insurance has processed the claims you will receive a bill in the mail or via email.`}
      </p>
      <div className="my-5 max-w-xs mx-auto">
        <button className="btn-secondary" disabled={submittingAob} onClick={handleRedirect}>{submittingAob ? <LoadingSpinner height="2.5rem" width="2.5rem" rings={3} /> : `Confirm`}</button>
      </div>
    </Modal>
  )
}

PayLaterModal.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  handleConfirm: PropTypes.func,
  submittingAob: PropTypes.bool
}

export default PayLaterModal