import React from 'react'
import PropTypes from 'prop-types'

import { icoArrow } from 'resources/images'


// component expects displayNumber like `(888) 555-1212`
export const PhoneLink = ({displayNumber, textClassName}) => {

  const digitNumber = displayNumber.replace( [ ` `, `-`, `(`, `)` ], `` )

  return (
    <a
      className="a"
      href={`tel:+1${digitNumber}`}
    >
      <span className={textClassName}>{displayNumber}</span>
    </a>
  )
}

PhoneLink.propTypes = {
  displayNumber: PropTypes.string.isRequired,
  textClassName: PropTypes.string
}

export const EmailLink = ({email, textClassName}) => {

  return (
    <a
      className="a"
      href={`mailto:${email}`}
    >
      <span className={textClassName}>{email}</span>
    </a>
  )
}

EmailLink.propTypes = {
  email: PropTypes.string.isRequired,
  textClassName: PropTypes.string
}

export const LinkArrow = () => {
  return (
    <img
      src={icoArrow} alt="Link Arrow"
      width="16px"
      height="11.8px"
    />
  )
}

export const ALink = ({children, ...linkProps}) => {
  return (
    <a
      className="a"
      {...linkProps}
    >
      <div className={`flex flex-row gap-2`}>
        {children}
        <LinkArrow />
      </div>
    </a>
  )
}

ALink.propTypes = {
  children: PropTypes.node
}
