import { gql } from '@apollo/client'

export const snowdogMenusQuery = gql`
  query snowdogMenusQuery {
    snowdogMenus(identifiers: "Main_sleep") {
      items {
        creation_time
        css_class
        identifier
        menu_id
        nodes {
          items {
            additional_data
            classes
            creation_time
            level
            menu_id
            node_id
            node_template
            parent_id
            position
            submenu_template
            title
            type
            update_time
            ... on SnowdogMenuCustomUrlNode {
            url: content
            }
          }
        }
        title
        update_time
      }
    }
  }
`
