import React from 'react'
import { Builder } from '@builder.io/react'

type ComponentProps = { title: string; description?: string; }

export const PageHeadingBuilderComponent = ( props: ComponentProps ) => {
  return (
    <div className="mb-6">
      <h4 className="text-deepSleepBlue mb-2">{props.title}</h4>
      { props.description &&
      <p className="text-sm">
        {props.description}
      </p>
      }
    </div>
  )
}

Builder.registerComponent( PageHeadingBuilderComponent, {
  name: `PageHeadingBuilderComponent`,
  inputs: [{
    name: `title`,
    type: `text`
  }, {
    name: `description`,
    type: `text`
  }]
})

export default PageHeadingBuilderComponent
