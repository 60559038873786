import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/isoWeek'
import { getAuthHeader } from 'utils/auth/helpers'
import { addMagentoEnvAuthHeaders } from 'utils/magentoEnvAuthHeaders'

import { TIME_ZONES } from './constants'

dayjs.extend( weekday )

export const addZeroIfNeeded = day => { return ( day < 10 ? `0${day}` : day ) }

export function getCurrentTimeZone() {
  return (
    TIME_ZONES.find(
      ({ area }) => { return area === Intl.DateTimeFormat().resolvedOptions().timeZone }
    ) ?? TIME_ZONES[3] // EST
  )
}

// from date string to date obj
export function getMDY( date, format = `YYYY-MM-DD` ) {
  if ( !dayjs( date, format ).isValid() ) return {}
  const dateObj = format ? dayjs( date, format ) : dayjs( date )

  return {
    month: dateObj.get( `month` ),
    date: dateObj.get( `date` ),
    year: dateObj.get( `year` ),
    weekday: dateObj.isoWeekday()
  }
}

export const normalizeDateForSubmission = date => {
  const tempDate = new Date( date )
  const year = tempDate.getUTCFullYear()
  const month = tempDate.getUTCMonth() + 1
  const day = tempDate.getUTCDate()

  return `${year}-${month}-${day}`
}

export const formatPhoneNumber = value => {
  if ( !value ) return value

  const phoneNumber = value.replace( /[^\d]/g, `` )

  if ( phoneNumber.length < 4 ) return phoneNumber

  if ( phoneNumber.length < 7 ) {
    return `${phoneNumber.slice( 0, 3 )}-${phoneNumber.slice( 3 )}`
  }

  return `${phoneNumber.slice( 0, 3 )}-${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice( 6, 10 )}`
}

export const getActiveMonthToPaginatedMonthKey = ( initialMonth ) => {

  let newObj = {}

  // allowed pages
  const newArray = [ 1, 2, 3, 4, 5, 6 ]

  newArray.map( ( n, i ) => {

    newObj[( initialMonth + i ).toString()] = n

  })

  return newObj
}

export const getHash = () => {
  const params = new URLSearchParams( window.location.search )
  const hash = params.get( `sgh` ) || params.get( `txt` )

  return hash
}

export async function fetchPatientTimezone( signal ) {
  const authHeader = getAuthHeader()

  const response = await fetch( `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/time-zone`, {
    headers: {
      'Authorization': authHeader,
      ...addMagentoEnvAuthHeaders()
    },
    signal
  })

  return await response.json()
}

export async function fetchAppointmentsByPage(
  {
    patient_id
  },
  tz,
  patient_hash,
  signal,
  page,
  source = window.location.hostname,
  setup_class = `Telemed`
) {

  const queryParams = new URLSearchParams({
    patient_id,
    patient_hash,
    tz,
    setup_class,
    source,
    ...( page !== undefined ? {
      page
    } : {})
  })

  const response = await fetch( `${process.env.REACT_APP_CLINICAL_SCHEDULING_URL}/get-appointment-timeslots?${queryParams}`, {
    signal
  })

  return await response.json()

}

export async function submitAppointment({
  patient_id,
  first_name: fname,
  last_name: lname,
  phone,
  state,
  street,
  city,
  zip_code: zip
}, email, tz, invoice_number, patient_hash, appointment ) {



  const appointmentBody = JSON.stringify({
    invoice_number,
    patient_hash,
    patient_id,
    event_type: true,
    fname,
    lname,
    street,
    city,
    state,
    zip,
    phone,
    email,
    ...( !appointment ? {
      inperson_request: true
    } : {
      staff_id: appointment.staff_id,
      service_id: appointment.service_id,
      start_time: appointment.start_time,
      setup_class: appointment.setup_class,
      inperson_request: false,
      tz
    })
  })
  const response = await fetch( `${process.env.REACT_APP_CLINICAL_SCHEDULING_URL}/post-appointment`, {
    method: `POST`,
    headers: {
      "Content-Type": `application-json`
    },
    body: appointmentBody
  })

  return await response.json()
}