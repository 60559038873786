import React from 'react'
import PropTypes from 'prop-types'
import { useSpring, animated } from 'react-spring'

function IdentifierTooltip({identifier, display}) {

  const tooltipStyling = useSpring({
    opacity: display ? 1 : 0
  })

  return (
    <animated.div
      style={tooltipStyling}
      className="flex justify-end items-center absolute text-white pointer-events-none z-50"
    >
      <span className="text-sm text-white bg-sleepBlue p-2 rounded-lg">
        {identifier}
      </span>
    </animated.div>
  )
}

IdentifierTooltip.propTypes = {
  identifier: PropTypes.string,
  display: PropTypes.bool,
  setDisplay: PropTypes.func
}
export default IdentifierTooltip
