export const SCHEDULE_PAGE_PATH = `/scheduling`
export const SCHEDULE_THANK_YOU_PATH = `/scheduling/thank-you`

export const onlineAppointmentAgreementCopy = `Schedule your online telehealth appointment with one of our licensed clinicians via Zoom. Your equipment will ship upon scheduling this appointment. Your new PAP machine should arrive at your home prior to your telehealth appointment so that you can spend time with Aeroflow's licensed clinician learning about your new PAP equipment and sleep apnea treatment.`
export const inPersonAppointmentCopy = `I would prefer my setup with an Aeroflow Sleep clinician to be done in-person, or I have additional questions about this process and would like to speak to an Aeroflow Sleep Specialist.`
export const canContactCopy = `I authorize Aeroflow Healthcare to contact me by text, phone and email. By checking this box I agree to `
export const thankYouInPerson = `To schedule your in-person appointment with an Aeroflow Sleep Clinician as quickly as possible, please call 866-650-7508. Otherwise, please expect to receive a call from Aeroflow Sleep to schedule your appointment in the next 3 - 5 business days. Your equipment will be shipped upon the scheduling of this appointment.`
export const thankYouVirtual = `You will receive an email confirming the date and time of your appointment. Your equipment is also now being prepared to be shipped.`

export const STATES = [
  {
    value: ``,
    name: `-State-`
  },
  {
    value: `AL`,
    name: `Alabama`
  },
  {
    value: `AK`,
    name: `Alaska`
  },
  {
    value: `AZ`,
    name: `Arizona`
  },
  {
    value: `AR`,
    name: `Arkansas`
  },
  {
    value: `CA`,
    name: `California`
  },
  {
    value: `CO`,
    name: `Colorado`
  },
  {
    value: `CT`,
    name: `Connecticut`
  },
  {
    value: `DE`,
    name: `Delaware`
  },
  {
    value: `DC`,
    name: `District Of Columbia`
  },
  {
    value: `FL`,
    name: `Florida`
  },
  {
    value: `GA`,
    name: `Georgia`
  },
  {
    value: `HI`,
    name: `Hawaii`
  },
  {
    value: `ID`,
    name: `Idaho`
  },
  {
    value: `IL`,
    name: `Illinois`
  },
  {
    value: `IN`,
    name: `Indiana`
  },
  {
    value: `IA`,
    name: `Iowa`
  },
  {
    value: `KS`,
    name: `Kansas`
  },
  {
    value: `KY`,
    name: `Kentucky`
  },
  {
    value: `LA`,
    name: `Louisiana`
  },
  {
    value: `ME`,
    name: `Maine`
  },
  {
    value: `MD`,
    name: `Maryland`
  },
  {
    value: `MA`,
    name: `Massachusetts`
  },
  {
    value: `MI`,
    name: `Michigan`
  },
  {
    value: `MN`,
    name: `Minnesota`
  },
  {
    value: `MS`,
    name: `Mississippi`
  },
  {
    value: `MO`,
    name: `Missouri`
  },
  {
    value: `MT`,
    name: `Montana`
  },
  {
    value: `NE`,
    name: `Nebraska`
  },
  {
    value: `NV`,
    name: `Nevada`
  },
  {
    value: `NH`,
    name: `New Hampshire`
  },
  {
    value: `NJ`,
    name: `New Jersey`
  },
  {
    value: `NM`,
    name: `New Mexico`
  },
  {
    value: `NY`,
    name: `New York`
  },
  {
    value: `NC`,
    name: `North Carolina`
  },
  {
    value: `ND`,
    name: `North Dakota`
  },
  {
    value: `OH`,
    name: `Ohio`
  },
  {
    value: `OK`,
    name: `Oklahoma`
  },
  {
    value: `OR`,
    name: `Oregon`
  },
  {
    value: `PA`,
    name: `Pennsylvania`
  },
  {
    value: `RI`,
    name: `Rhode Island`
  },
  {
    value: `SC`,
    name: `South Carolina`
  },
  {
    value: `SD`,
    name: `South Dakota`
  },
  {
    value: `TN`,
    name: `Tennessee`
  },
  {
    value: `TX`,
    name: `Texas`
  },
  {
    value: `UT`,
    name: `Utah`
  },
  {
    value: `VT`,
    name: `Vermont`
  },
  {
    value: `VA`,
    name: `Virginia`
  },
  {
    value: `WA`,
    name: `Washington`
  },
  {
    value: `WV`,
    name: `West Virginia`
  },
  {
    value: `WI`,
    name: `Wisconsin`
  },
  {
    value: `WY`,
    name: `Wyoming`
  }
]

export const TIME_ZONES = [
  {
    name: `US/Pacific`,
    label: `PDT`,
    area: `America/Los_Angeles`
  },
  {
    name: `US/Mountain`,
    label: `MDT`,
    area: `America/Denver`
  },
  {
    name: `US/Central`,
    label: `CDT`,
    area: `America/Chicago`
  },
  {
    name: `US/Eastern`,
    label: `EST`,
    area: `America/New_York`
  }
]

export const DAYS = [
  ``,
  `Monday`,
  `Tuesday`,
  `Wednesday`,
  `Thursday`,
  `Friday`,
  `Saturday`,
  `Sunday`
]

export const MONTHS = [
  `January`,
  `February`,
  `March`,
  `April`,
  `May`,
  `June`,
  `July`,
  `August`,
  `September`,
  `October`,
  `November`,
  `December`
]