import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useQuery } from '@apollo/client'
import { cmsBlocksQuery } from 'graphql/queries'
import { reportToSentry } from '../../../utils/reportToSentry'

function SearchDoctorHeading({ headerStyle, doctorSearchHeader } : { textContent?: string, headerStyle?: string, doctorSearchHeader?: string }) : JSX.Element {
  const { data } = useQuery( cmsBlocksQuery, {
    skip: Boolean( doctorSearchHeader ),
    onError: ( error ) => {
      reportToSentry( `Doctor Block: Error getting doctor search text content`, {
        error: error?.message,
        jsonError: JSON.stringify( error )
      })
    },
    variables: {
      identifiers: `react-doctor-block-text`
    }
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const transform = ( node: any ) => {
    if ( node?.name === `style` ) return null
    if ( node?.name === `p` ) node.attribs.class= `font-light`
    if ( node?.name === `h2` && headerStyle ) node.attribs.class = headerStyle
  }

  if ( doctorSearchHeader ) {
    return (
      <>
        <h1 className={`${headerStyle ?? `text-3xl font-light`} text-center mb-3`}>{`Search for your Doctor`}</h1>
        <p className="font-light text-center mb-10">{doctorSearchHeader}</p>
      </>
    )
  }

  if ( data?.cmsBlocks?.items?.length ) return (
    <>
      {
        ReactHtmlParser( data.cmsBlocks.items[0]?.content || ``, {
          transform
        })
      }
    </>
  )

  return (
    <>
      <h1 className={`${headerStyle ?? `text-3xl font-light`} text-center mb-3`}>{`Search for your Doctor`}</h1>
      <p className="font-light text-center mb-10">{`Please provide your doctor's information. We will send a request to your doctor to approve your healthcare supplies.`}</p>
    </>
  )
}

export default SearchDoctorHeading