import React from 'react'
import { useMediaQuery } from 'react-responsive'
import HomePageHeroSkeleton from './skeletonComponents/HomePageHeroSkeleton'
import ReviewWidgetSkeleton from './skeletonComponents/ReviewWidgetSkeleton'
import TwoColGridSkeleton from './skeletonComponents/TwoColGridSkeleton'
import ContentCardGridSkeleton from './skeletonComponents/ContentCardGridSkeleton'
import VideoBlockSkeleton from './skeletonComponents/VideoBlockSkeleton'


const HomePageSkeleton = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: 976px)`
  })

  return (
    <>
      <HomePageHeroSkeleton isMobile={isMobile} />
      <ReviewWidgetSkeleton isMobile={isMobile} />
      <TwoColGridSkeleton isMobile={isMobile} />
      <ContentCardGridSkeleton />
      <TwoColGridSkeleton isMobile={isMobile} />
      <VideoBlockSkeleton />
      <TwoColGridSkeleton isMobile={isMobile} />
    </>
  )
}

export default HomePageSkeleton