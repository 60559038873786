import PropTypes from 'prop-types'
import { PasswordInput, TextInput, Validators } from 'components/forms/components'
import { regexErrors } from 'components/forms/constants'

export const PwdChange = ( props ) => {

  const {
    handleChange,
    formValues,
    formInputErrors,
    handleInputError,
    setUsernamePwdInputErrors,
    displayError,
    setDisplayError,
    inputRefs,
    disableUsername,
    disableAll
  } = props


  const addError = ( name, msg ) => {
    return handleInputError( name, msg )
  }

  const handleBlur = () => {
    setDisplayError( true )
  }

  const removeErrorByKey = ( name ) => {
    const newErrorObject = Object.assign({}, formInputErrors )
    delete newErrorObject[name]
    setUsernamePwdInputErrors( newErrorObject )
  }

  const onHandleChange = ({target: {name, value}}) => {
    handleChange({
      [name]: value
    })
    setDisplayError( false )
    // Clear target error and recheck
    removeErrorByKey( name )
    if ( name === `password` ) {
      if ( !Validators.password( value ) ) {
        addError( `password`, regexErrors.password )

        return inputRefs.passwordInputRef.current.focus()
      }
    } else if ( name === `passwordConfirmation` ) {
      if ( formValues.password !== value ) {
        addError( `passwordConfirmation`, regexErrors.passwordConfirmation )

        return inputRefs.passwordConfirmationInputRef.current.focus()
      }
    } else {
      if ( !Validators.email( value ) ) {
        addError( `email`, regexErrors.email )

        return inputRefs.emailInputRef.current.focus()
      }
    }
  }

  return (
    <div>
      {disableUsername ?
        <div className="text-center mb-2">
          <p className="input-label">{`Email`}</p>
          <p>
            {formValues.email}
          </p>
        </div>
        :
        <TextInput
          id="email"
          name="email"
          type="text"
          label="Email"
          value={formValues.email}
          onBlur={handleBlur}
          onChange={onHandleChange}
          disabled={disableUsername || disableAll}
          autoComplete="username"
          errorMessage={displayError ? formInputErrors.email : ``}
          reference={inputRefs.emailInputRef}
          required
        />
      }
      <PasswordInput
        id="password"
        name="password"
        label="Password"
        value={formValues.password}
        onChange={onHandleChange}
        onBlur={handleBlur}
        errorMessage={displayError ? formInputErrors.password : ``}
        autoComplete="new-password"
        required
        reference={inputRefs.passwordInputRef}
        disabled={disableAll}
      />
      <PasswordInput
        id="passwordConfirmation"
        name="passwordConfirmation"
        label="Confirm Password"
        onChange={onHandleChange}
        onBlur={handleBlur}
        value={formValues.passwordConfirmation}
        errorMessage={displayError ? formInputErrors.passwordConfirmation : ``}
        autoComplete="new-password"
        required
        reference={inputRefs.passwordConfirmationInputRef}
        disabled={disableAll}
      />
    </div>
  )
}

PwdChange.propTypes = {
  handleChange: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  handleInputError: PropTypes.func.isRequired,
  formInputErrors: PropTypes.object.isRequired,
  setUsernamePwdInputErrors: PropTypes.func.isRequired,
  displayError: PropTypes.bool.isRequired,
  setDisplayError: PropTypes.func.isRequired,
  disableUsername: PropTypes.bool,
  disableAll: PropTypes.bool,
  inputRefs: PropTypes.shape({
    emailInputRef: PropTypes.oneOfType( [
      PropTypes.func,
      PropTypes.shape({
        current: PropTypes.instanceOf( HTMLInputElement )
      })
    ] ),
    passwordInputRef: PropTypes.oneOfType( [
      PropTypes.func,
      PropTypes.shape({
        current: PropTypes.instanceOf( HTMLInputElement )
      })
    ] ),
    passwordConfirmationInputRef: PropTypes.oneOfType( [
      PropTypes.func,
      PropTypes.shape({
        current: PropTypes.instanceOf( HTMLInputElement )
      })
    ] )
  })
}