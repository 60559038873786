import React from 'react'
import { TextInput, Validators, Formatters } from 'components/forms/components'
import { ResponsiveSectionBox } from 'components/layoutUi'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import { ContactInfo, useContactInfoState } from 'modules/resupply2'
import postDemographics from 'utils/auth/demographics'
import { reportToSentry } from 'utils/reportToSentry'
import { getAuthHeader } from 'utils/auth/helpers'

export default function ContactForm({ onSave, onCancel } : {onSave: () => void, onCancel: () => void}) : JSX.Element {
  const [ contactInformation, setContactInformation ] = useContactInfoState()

  const phoneNumberRef = React.useRef<HTMLInputElement>( null )

  const [ preEditState, setPreEditState ] = React.useState<ContactInfo>() // Allows us to revert the state onCancel
  const [ showEmailInfoBox, setShowEmailInfoBox ] = React.useState( false )

  const handleChange = ( event : React.ChangeEvent<HTMLInputElement> ) => {
    setShowEmailInfoBox( true )
    setContactInformation({
      ...contactInformation,
      [event.target.name]: event.target.value
    })
  }

  React.useEffect( () => {
    setPreEditState( contactInformation )
  }, [] )

  const handleSubmit = ( event : React.MouseEvent<HTMLButtonElement> ) => {
    event.preventDefault()

    if ( !Validators.phone( contactInformation.phone ) ) return phoneNumberRef?.current?.focus()

    // send update patient demographics with new phone value
    postDemographics( getAuthHeader(), {
      phone: contactInformation.phone
    }).catch( error => {
      reportToSentry( new Error( `resupply 2.0 - failed to update patient demographics - phone`, {
        cause: error
      }) )
    })

    setPreEditState( contactInformation )
    onSave && onSave( )
  }

  const handleCancel = () => {
    if ( preEditState ) setContactInformation( preEditState )
    onCancel && onCancel()
  }

  return (
    <form className="w-full">
      <div className="flex flex-col items-center w-full" >
        <div className="w-full md:w-3/4">
          <TextInput
            label="Phone Number"
            name="phone"
            value={contactInformation.phone}
            onChange={handleChange}
            formatter={{
              function: Formatters.phone
            }}
            validator={{
              function: Validators.phone,
              failureMessage: `Enter a valid phone number`
            }}
            reference={phoneNumberRef}
            onBlur={undefined}
            id={undefined}
          />
        </div>

        { showEmailInfoBox &&
          <ResponsiveSectionBox customClassNames="my-2">
            <p>{`To update your email address, please give us a call at ${AF_SUPPORT_PHONE_NUMBER}`}</p>
          </ResponsiveSectionBox>
        }

        <div className="flex flex-wrap w-80 mt-3 gap-3">
          <button className="btn btn-primary" onClick={handleSubmit}>{`✎ Save Phone Number`}</button>
          <button className="btn btn-danger-secondary" onClick={handleCancel}>{`✗ Cancel`}</button>
        </div>

      </div>
    </form>
  )
}
