import React from 'react'
import PropTypes from 'prop-types'

export const Button = ({ children, className, ...otherProps }) => {
  // Refer to index.css for differing button styles
  let styledClassName = ( className ?? `btn-primary` )

  return (
    <button
      className={styledClassName}
      {...otherProps}
    >{children ?? ``}</button>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}
