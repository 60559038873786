import React from 'react'

function ProcessPaymentButton({paymentError, ...buttonProps} : { paymentError: string }) : JSX.Element {

  return (
    <div className="max-w-xs w-full mx-auto mt-10 mb-36">
      <button className="btn-secondary"{...buttonProps} >{`Submit Payment`}</button>
      {
        paymentError !== `` &&
        <p className="text-error font-light text-lg" id="payment_error">{paymentError}</p>
      }
    </div>
  )
}

export default ProcessPaymentButton