import { useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { SelectInput } from 'components/forms/components'
import { accountNavOptions } from 'apollo'
import { useAccountStore } from '../state'

function AccountHeader() : JSX.Element | null {

  const { customer } = useAccountStore()
  const [ navSection, setNavSection ] = useState<string>( `` )

  const navOptions = useReactiveVar( accountNavOptions )

  if ( customer.error || !customer.data ) return (
    <div className="flex flex-col items-center justify-center">
      <p className="text-lg">{`Account Dashboard`}</p>
    </div>
  )

  return (
    <div className="flex flex-col items-center justify-center">
      <p className="capitalize text-lg text-deepSleepBlue">{`${customer?.data?.firstname?.toLowerCase()} ${customer?.data?.lastname?.toLowerCase()}'s`}</p>
      <p className="text-lg">{`Account Dashboard`}</p>
      { customer?.data?.patient_id && <p>{`Patient # ${customer.data.patient_id}`}</p>}
      <div className="flex gap-4 mt-7 items-center justify-center lg:invisible lg:absolute">
        <p>{`Navigate To`}</p>
        <SelectInput
          aria-label="navigate-to"
          name="account-nav"
          className="rounded-xl border-lightGray border px-2 py-1 text-sm shadow-lg text-graphite focus:outline-none"
          value={navSection}
          onChange={( e: React.ChangeEvent<HTMLSelectElement> ) => {
            e.preventDefault()
            setNavSection( e.target.value )
            document.getElementById( e.target.value )?.scrollIntoView({
              behavior: `smooth`
            })
          }}
        >
          <option value="" hidden disabled>{`Choose Section`}</option>
          {
            navOptions?.map( ( option: { label: string, id: string }) => {
              return <option key={option.id} value={option.id}>{option.label}</option>
            })
          }
        </SelectInput>
      </div>
    </div>
  )
}

export default AccountHeader