import React from 'react'
import { Builder } from '@builder.io/react'
import { ImageHoverCardTypes } from './types'
import { Container } from '../../Container'
import { ImageHoverCard } from './components/ImageHoverCard'

export const ImageHoverCardGrid = ( props: { imageHoverCards: ImageHoverCardTypes[] }) : JSX.Element => {

  return (
    <>
      {props?.imageHoverCards && (
        <div className="bg-[#F3F5F7] sm:px-4 md:px-6">
          <Container maxWidth={1280}>
            <div className="flex sm:flex-wrap lg:flex-nowrap sm:py-5 md:py-12 sm:gap-y-2.5 md:gap-y-0 justify-between">
              {props.imageHoverCards.map( ( card ) => {
                return (
                  <ImageHoverCard {...card} key={card.title} />
                )
              })}
            </div>
          </Container>
        </div>
      )}
    </>
  )
}

Builder.registerComponent( ImageHoverCardGrid, {
  name: `ImageHoverCardGrid`,
  inputs: [
    {
      name: `imageHoverCards`,
      type: `list`,
      subFields: [
        {
          name: `image`,
          type: `file`,
          allowedFileTypes: [ `jpeg`, `jpg`, `png`, `svg`, `webp` ],
          required: true,
          helperText: `Upload .jpeg, .jpg, .png, .svg, or .webp`
        },
        {
          name: `title`,
          type: `text`,
          required: true
        },
        {
          name: `url`,
          type: `url`,
          required: true
        },
        {
          name: `openInNewTab`,
          type: `boolean`,
          defaultValue: false
        }
      ]
    }
  ]
})

export default ImageHoverCardGrid