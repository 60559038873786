import { getProductPricing } from 'utils/patientInfoApi'
import { reportToSentry } from 'utils/reportToSentry'

type DataLayerEvent = {
  [key: string]: string;
};

declare global {
  interface Window {
    dataLayer: DataLayerEvent[];
  }
}

/**
 *
 * @param { string } event - this is an event you want to track in GA
 * @param { DataLayerEvent } eventData - An object containing the custom data to push with the event.
 */

export const trackAnalyticEvent = ( event: string, eventData?: DataLayerEvent ) : void => {
  if ( window.location.origin === `https://aeroflowsleep.com` ) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      'event': event,
      ...eventData
    })
  }
}

export const trackUserLoginEvent = ( ) : void => {
  getProductPricing()
    .then( ( data ) => {
      trackAnalyticEvent( `customer_login` )

      if ( data?.data?.patient?.patient_id ) trackAnalyticEvent( `customer_reload`, {
        patient_id: data.data.patient.patient_id.toString()
      })
    })
    .catch( ( error ) => {
      reportToSentry( new Error( `error getting patient id for analytic event`, {
        cause: JSON.stringify( error ?? `` )
      }) )
    })
}