

/* eslint-disable no-console */

/**
 *
 * @param {str} string
 * @returns string
 */

const removeInitialZeroChar = ( str ) => {

  if ( typeof str === `string` ){
    return str.replace( /^0+/, `` )
  }

  // if not a string we just pass back argv
  return str
}
/**
 * Helper funciton that formats Date string to mm/dd/yyyy
 * @param {String} date to be formated into a easier to read date of mm/dd/yyyy
 * @returns String - formatted date
 */
export function formatDate( date ) {

  // eslint-disable-next-line no-console
  // @NOTE this function accepts 2 data types which requires a bifibrucation to handle spot on
  // if eligible is immediately a new js Date constructed object is passed to the function
  // if eligible is the future we get a string `2022-01-09` would be the example formatting
  // we match for regex and just work at the string level for that ^

  const expectedUnserializedFormat = /\d{4}[-]\d{2}[-]\d{2}/

  if ( typeof date === `string` && date.match( expectedUnserializedFormat ) ) {

    const subStrings = date.substring( 0, 10 ).split( `-` )

    const [ year, month, dateDD ] = subStrings
    const _month = removeInitialZeroChar( month )
    const _date = removeInitialZeroChar( dateDD )

    return `${_month}/${_date}/${year}`

  }

  const d = new Date( date )

  let f_date = ``
  if ( d ) f_date = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`

  return f_date
}

export function isDateInPast( date ) {

  const today = new Date()
  const d = new Date( date )

  return ( today > d )
}