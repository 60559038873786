import React, { useState, useEffect } from 'react'
// import { Button } from 'components/Button'
import { caratUpGraphite } from 'resources/images'

const ScrollToTop = ( ) => {
  const [ show, setShow ] = useState( false )

  useEffect( () => {
    document.addEventListener( `scroll`, handleShow )

    return () => {
      document.removeEventListener( `scroll`, handleShow )
    }
  }, [] )

  const handleShow = () => {
    const isNearBottom = ( ( document.body.scrollHeight - window.pageYOffset ) < 1000 )
    setShow( window.pageYOffset > 1600 || isNearBottom )
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: `smooth`
    })
  }

  // don't show when at the top of the page or in an iframe
  if ( !show || window.location !== window?.parent?.location ) return null

  return (
    <div className="md:hidden sm:block sm:fixed print:hidden bottom-16 right-5 z-10">
      <div onClick={scrollToTop} className="shadow-xl rounded-full h-10 w-10 bg-ctaYellow flex items-center justify-center">
        <img src={caratUpGraphite} height="22" width="22" />
      </div>
    </div>
  )
}

export default ScrollToTop

export const scrollToBelowLogo = () => {
  window.scrollTo({
    top: 100,
    behavior: `smooth`
  }, 2 )
}