import React from 'react'
import { Builder } from '@builder.io/react'
import HomepageReviewWidget from '../../homepageReviewWidget/HomePageReviewWidget'

type ReviewCarouselProps = {
  reviewHeading?: string
}

export const ReviewCarousel = ( props: ReviewCarouselProps ) : JSX.Element => {
  return (
    <HomepageReviewWidget reviewHeading={props?.reviewHeading} />
  )
}

Builder.registerComponent( ReviewCarousel, {
  name: `ReviewCarousel`,
  inputs: [
    {
      name: `reviewHeading`,
      type: `text`
    }
  ]
})


export default ReviewCarousel