import { gql } from '@apollo/client'

// @NOTE we are using this mutation for logged in users to create a cart
export const customerCartMutation = gql`
  query customerCartMutation {
    customerCart {
      id
      items{
        id
        quantity
        product{
          sku
          name
        }
      }
    }
  }
`