import React from 'react'
import ContentLoader from 'react-content-loader'

function AsSeenInBlockLoader() {

  return (
    <div className="flex flex-col w-full items-center">
      <ContentLoader viewBox="0 0 100 10">
        <rect
          x="35" y="5" rx="1"
          ry="1" width="30" height="1"
        />
        <rect
          x="35" y="7" rx="1"
          ry="1" width="20" height="1"
        />
      </ContentLoader>
      <ContentLoader viewBox="0 0 100 10">
        <rect
          rx="2" ry="2" width="18"
          height="4" x="1" y="1"
        />
        <rect
          rx="2" ry="2" width="18"
          height="4" x="21" y="1"
        />
        <rect
          rx="2" ry="2" width="18"
          height="4" x="41" y="1"
        />
        <rect
          rx="2" ry="2" width="18"
          height="4" x="61" y="1"
        />
        <rect
          rx="2" ry="2" width="18"
          height="4" x="81" y="1"
        />
      </ContentLoader>
    </div>
  )
}

export default AsSeenInBlockLoader