// These cache items provide very little value for debugging from Sentry and are very large.
export const filterCache = cacheData => {
  const typesToFilter = [ `BundleProduct`, `SimpleProduct`, `XsearchProduct`, `ConfigurableProduct`, `Menu`, `Region`, `Country`, `paymentProfile` ]

  return Object.keys( cacheData ).reduce( ( modifiedCache, key ) => {
    const cacheItem = cacheData[key]

    if ( typesToFilter.includes( cacheItem.__typename ) || key === `ROOT_QUERY` ) return modifiedCache

    return {
      ...modifiedCache,
      [key]: cacheItem
    }
  }, {})
}
