import { useEffect, useState } from 'react'
import { PhoneInput, usePhoneInputState, useValidInputs } from './input'
import { ErrorField, TextInput } from 'components/forms/components'
import LoadingSpinner from 'components/LoadingSpinner'
import { reportToSentry } from 'utils/reportToSentry'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import { preLoginLocationVar } from 'apollo'
import { getRedirectable } from 'modules/auth/redirectable'
import { useNavigate } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { otpLogin, sendOTP } from './utils'
import { MY_ACCOUNT_PATH } from 'routes'
import { useTokenStore } from 'stores/tokenStore'

function OneTimeLoginForm() : JSX.Element {

  const [ error, setError ] = useState<string>( `` )
  const [ loginError, setLoginError ] = useState<string>( `` )
  const [ loading, setLoading ] = useState<boolean>( false )
  const [ loginLoading, setLoginLoading ] = useState<boolean>( false )
  const [ otpInput, setOtpInput ] = useState<string>( `` )
  const [ otpSuccess, setOtpSuccess ] = useState<string>( `` )

  const { isValidPhone } = useValidInputs()
  const phone:string = usePhoneInputState()[0]

  const navigate = useNavigate()
  const locationState = useReactiveVar( preLoginLocationVar )
  const { setCustomerToken } = useTokenStore()

  const defaultOTPSendError = `Oops... we encountered an error attempting to send your one time password. Please try again or reach out to customer service at ${AF_SUPPORT_PHONE_NUMBER} if the issue persists.`
  const defaultOTPLoginError = `The one time password is incorrect, you do not have an account created for this site, or we encountered an error on our side. Please try again or reach out to customer service at ${AF_SUPPORT_PHONE_NUMBER} if the issue persists.`


  useEffect( () => {
    setOtpSuccess( `` )
  }, [ phone ] )

  const handleSend = async () => {
    setError( `` )
    setLoading( true )
    const otpResponseMessage = await sendOTP( phone )
      .catch( ( error: Error ) => {
        reportToSentry( new Error( `OTP Login: Error sending OTP to patient`, {
          cause: error
        }), {
          phone
        })
        setLoading( false )

        return setError( defaultOTPSendError )
      })
    setLoading( false )
    const otpResponse = JSON.parse( otpResponseMessage )
    if ( !otpResponse || otpResponse?.success !== `true` ) {
      reportToSentry( new Error( `OTP Login: Error sending OTP to patient` ), {
        phone,
        response: otpResponseMessage
      })

      return setError( defaultOTPSendError )
    }
    setOtpSuccess( `A one-time password has been sent to your phone. Please check your phone and enter the password below to login.` )
  }

  const submitOTP = async () => {
    setLoginLoading( true )
    const otpLoginMessage = await otpLogin( phone, otpInput )
      .catch( ( error: Error ) => {
        reportToSentry( new Error( `OTP Login: Error logging in patient with OTP`, {
          cause: error
        }), {
          phone,
          otpInput
        })
        setLoginLoading( false )

        return setLoginError( defaultOTPLoginError )
      })

    setLoginLoading( false )
    const otpLoginResponse = JSON.parse( otpLoginMessage )
    if ( !otpLoginResponse || otpLoginResponse?.success !== `true` || !otpLoginResponse?.token ) {
      reportToSentry( new Error( `OTP Login: Error logging in patient with OTP` ), {
        phone,
        otpInput,
        response: otpLoginMessage
      })

      return setLoginError( defaultOTPLoginError )
    }

    handleLoginSuccess( otpLoginResponse.token )
  }

  const handleLoginSuccess = ( token: string ) => {
    setCustomerToken( token )

    const { from } = locationState || {
      from: {
        pathname: MY_ACCOUNT_PATH,
        search: window.location.search
      }
    }
    const redirectable = getRedirectable( from )

    return navigate( redirectable?.redirectLocation ?? MY_ACCOUNT_PATH, {
      state: locationState,
      replace: true
    })
  }

  return (
    <div className="form p-5 flex flex-col">
      <div className="mb-5">
        <PhoneInput required={false} />
      </div>
      <button
        className="mt-2 mb-8 btn-primary mx-auto"
        disabled={!isValidPhone || loading}
        onClick={handleSend}
      >
        { loading ?
          <LoadingSpinner width={`2rem`} height={`2rem`} rings={5} />
          :
          otpSuccess ? `Resend` : `Send`
        }
      </button>
      {otpSuccess && <p className="text-success my-3 text-center">{otpSuccess}</p>}
      {error && <div className="text-center"><ErrorField message={error} /></div>}
      <div className="my-5">
        <TextInput
          name="otpInput"
          label="One Time Password"
          value={otpInput}
          disabled={!isValidPhone || !otpSuccess}
          onChange={( e: React.ChangeEvent<HTMLInputElement> ) => {
            setLoginError( `` )
            setOtpInput( e.currentTarget.value )
          }}
        />
      </div>
      <button
        className="mx-auto mt-2 mb-8 btn-primary"
        disabled={!otpInput?.length || loginLoading || !isValidPhone || !otpSuccess}
        onClick={submitOTP}
      >
        { loginLoading ?
          <LoadingSpinner width={`2rem`} height={`2rem`} rings={5} />
          :
          `Login`
        }
      </button>
      {loginError && <div className="text-center"><ErrorField message={loginError} /></div>}
    </div>
  )
}

export default OneTimeLoginForm