import { gql } from '@apollo/client'

import { customerFragment, customerAddressFragment } from 'graphql/fragments'

export const customerAddressesQuery = gql`
${customerFragment}
  ${customerAddressFragment}
  query CustomerAddressesQuery {
      customer {
        ...Customer
       addresses {
          ...CustomerAddress
        }
      }
  }
`
