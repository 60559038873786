import { CheckboxInput } from 'components/forms/components'
import React from 'react'

export type TermsCheckboxProps = {
  checked: boolean;
  setChecked?: React.Dispatch<React.SetStateAction<boolean>>;
  onChange?: ( _e: React.ChangeEvent ) => void;
}

function SMSChecker( props: TermsCheckboxProps ) {
  function handleCheck( e: React.ChangeEvent ) {
    props.onChange && props.onChange( e )
    props.setChecked && props.setChecked( !props.checked )
  }

  return (
    <div
      className="my-2 p-3 text-center flex cursor-pointer"
      // toggle checkbox state when clicking anywhere in the terms checkbox container
      onClick={() => handleCheck({
        target: {
          checked: !props.checked
        }
      } as unknown as React.ChangeEvent )} // bypass type check and treat as React.ChangeEvent
    >
      <CheckboxInput
        name="sms-opt-in"
        aria-label="sms-checkbox"
        className="cursor-pointer mr-2 focus:outline-none rounded-full font-bold text-sm w-7 h-7"
        labelClassName="cursor-pointer"
        onChange={handleCheck}
        value={props.checked}
        data-testid="sms-opt-in"
      />
      <span className="max-w-md text-center mx-auto sm:text-xs md:text-sm">
        {`By checking this box, I provide my consent for Aeroflow Health or subsidiaries to send me recurring text messages and calls, including those containing marketing, and sent via an automatic dialer or prerecorded voice, at the number provided. I understand I can reply "STOP" to stop text messages at any time and that text messages and data rates may apply.`}
      </span>
    </div>
  )
}

export default SMSChecker