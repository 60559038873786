import React from 'react'
import ContentLoader from 'react-content-loader'

function FaqBlogContentLoader() {
  return (
    <div className="flex sm:flex-col md:flex-row justify-evenly w-full max-w-full">
      <ContentLoader viewBox="0 0 100 70">
        <rect
          x="4" width="90" height="50"
          rx="3" ry="3"
        />
        <rect
          x="4" y="52" rx="2"
          ry="2" width="86" height="2"
        />
        <rect
          x="4" y="56" rx="2"
          ry="2" width="80" height="2"
        />
        <rect
          width="40" height="8"
          y="62" rx="4" ry="4"
          x="27"
        />
      </ContentLoader>
      <ContentLoader viewBox="0 0 100 70">
        <rect
          x="4" width="90" height="50"
          rx="3" ry="3"
        />
        <rect
          x="4" y="52" rx="2"
          ry="2" width="86" height="2"
        />
        <rect
          x="4" y="56" rx="2"
          ry="2" width="80" height="2"
        />
        <rect
          width="40" height="8"
          y="62" rx="4" ry="4"
          x="27"
        />
      </ContentLoader>
    </div>
  )
}

export default FaqBlogContentLoader