import React from 'react'
import { qualifyThankYouCopy, referAFriendThankYouCopy, defaultThankYouCopy } from 'constants/copy'
import { useReactiveVar } from '@apollo/client'
import { qualifyFormSubmitSuccess, referAFriendFormSubmitSuccess } from 'apollo'

const ThankYou = () => {
  const [ copy, setCopy ] = React.useState( defaultThankYouCopy )
  const qualifyFormWasSubmitted = useReactiveVar( qualifyFormSubmitSuccess )
  const referAFriendFormWasSubmitted = useReactiveVar( referAFriendFormSubmitSuccess )


  React.useEffect( () => {
    if ( qualifyFormWasSubmitted ) setCopy( qualifyThankYouCopy )
    else if ( referAFriendFormWasSubmitted ) setCopy( referAFriendThankYouCopy ) // refer a friend disabled 03-14-22, may be re-enabled

  })

  return (
    <div className="pb-8 min-h-50vh bg-white">
      <h2 className="m-6 text-center">
        {copy?.header}
      </h2>
      <p className="mx-auto mt-6 mb-8 px-2 text-center max-w-lg">
        {copy?.subheader}
      </p>
      <img
        src={copy?.thanksImg} height={305} width={240}
        className="mx-auto w-max" alt="thanks for becoming a user graphic of human face behind notepad"
      />

    </div>
  )
}

export default ThankYou