import { useState } from 'react'
import { ContactForm, ContactCard } from "./"

export default function ContactBlock({initialReadOnly = false, onComplete, onEdit} : {initialReadOnly : boolean, onComplete : () => void, onEdit: () => void}) : JSX.Element {
  const [ isEditingContactInfo, setIsEditingContactInfo ] = useState( initialReadOnly )

  const handleContactClose = () => {
    setIsEditingContactInfo( false )
    onComplete()
  }

  const handleContactOpen = () => {
    setIsEditingContactInfo( true )
    onEdit()
  }

  return (
    <div className="rounded-2xl bg-lightGray max-w-md mx-auto my-4 p-3">
      <p className="font-bold text-center">{`Contact: `}</p>
      {isEditingContactInfo ? (
        <ContactForm onSave={handleContactClose} onCancel={handleContactClose} />
      ) : (
        <ContactCard onEdit={handleContactOpen} />
      )}
    </div>
  )
}