import React from 'react'
import { useParams } from 'react-router-dom'
import { Page as CMSPage } from 'components/cms'

const ContentPage = () => {
  const { contentPage } = useParams()

  return <CMSPage urlKey={contentPage} />
}

export default ContentPage

