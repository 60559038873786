import { useEffect, useState } from 'react'
import { HeroDiv, PromoFooter, PromoLoader } from './components'
import { useQuery } from '@apollo/client'
import ReactHtmlParser from 'react-html-parser'
import { cmsPageQuery } from 'graphql/queries'
import { getFormDataValue, mapStateNameToAbbreviation, promoContentTransform, validateAndCheckForNull } from './utils'
import { useNavigate } from 'react-router-dom'
import { promoHeroImg } from 'resources/images'
import HeroContentType from './HeroContentType'
import { reportToSentry } from 'utils/reportToSentry'
import { leadQuery } from '../../graphql/queries/lead'
import {
  DATE_OF_BIRTH,
  EMAIL,
  FIRST_NAME,
  FULL_NAME,
  LAST_NAME,
  PHONE_NUMBER,
  POST_CODE,
  STATE,
  ZIP_CODE
} from '../../graphql/models/Lead'
import { useQualifyFieldsStore } from '../../stores/qualifyFieldsStore'
import { Formatters } from '../../components/forms/components'

function SleepPromo() : JSX.Element | null {
  const { setFormFields } = useQualifyFieldsStore()

  // grab the fbld_id param from the url to fetch lead data for qualify form
  const currentQueryParams = new URLSearchParams( window.location.search )
  const fbldId = currentQueryParams.get( `fbld_id` )
  useQuery( leadQuery, {
    variables: {
      leadId: fbldId
    },
    skip: !fbldId,
    onCompleted: ( data ) => {
      // store the lead data in the qualify fields store
      setFormFields({
        username: getFormDataValue( data, EMAIL ) ?? ``,
        password: ``,
        passwordConfirmation: ``,
        dobMonth: validateAndCheckForNull( `dobMonth`, getFormDataValue( data, DATE_OF_BIRTH )?.slice( 0, 2 ) ),
        dobDay: validateAndCheckForNull( `dobDay`, getFormDataValue( data, DATE_OF_BIRTH )?.slice( 3, 5 ) ),
        dobYear: validateAndCheckForNull( `dobYear`, getFormDataValue( data, DATE_OF_BIRTH )?.slice( 6, 10 ) ),
        firstName: getFormDataValue( data, FIRST_NAME ) ?? getFormDataValue( data, FULL_NAME )?.split( ` ` )[0] ?? ``,
        lastName: getFormDataValue( data, LAST_NAME ) ?? getFormDataValue( data, FULL_NAME )?.split( ` ` )[1] ?? ``,
        phoneNumber: Formatters.phone( getFormDataValue( data, PHONE_NUMBER )?.replace( `+1`, `` ) ) ?? ``,
        state: mapStateNameToAbbreviation( getFormDataValue( data, STATE ) ) ?? ``,
        zipCode: getFormDataValue( data, ZIP_CODE ) ?? getFormDataValue( data, POST_CODE ) ?? ``,
        insuranceType: ``,
        memberID: ``,
        secondaryInsuranceType: ``,
        secondaryMemberID: ``,
        hasSleepApnea: ``,
        hasReceivedCpapThroughInsurance: ``,
        howDidYouHearAboutUs: ``,
        terms: false,
        smsOptIn: false
      })
    },
    onError: ( error ) => {
      reportToSentry( `Error fetching facebook lead for ${fbldId}`, {
        error
      })
    }
  })

  const navigate = useNavigate()
  const [ heroSrc, setHeroSrc ] = useState<string>( promoHeroImg )
  const [ heroContent, setHeroContent ] = useState<HeroContentType>({
    heroHeading: null,
    qualifyHeading: null
  })

  const { data, loading} = useQuery( cmsPageQuery, {
    errorPolicy: `all`,
    fetchPolicy: `cache-and-network`,
    variables: {
      identifier: `${window.location.pathname.replace( `/sleep-promo/`, `` )}`
    },
    onError: ( error ) => {
      reportToSentry( `cmsPagesQuery Error -> SleepPromo.tsx`, {
        cause: error
      })
    }
  })

  const handleSetHeroContent = ( key: string, value: string ) => {
    if ( !heroContent[key] ) {
      setHeroContent( Object.assign({}, heroContent, {
        [key]: value
      }) )
    }
  }

  useEffect( () => {
    if ( !loading && !data?.cmsPage?.content ) {
      return navigate( `/promo-not-found`, {
        replace: true
      })
    }
  }, [ data ] )

  if ( data?.cmsPage?.content ) {
    return (
      <div className="w-full flex flex-col items-center">
        <HeroDiv heroSrc={heroSrc} heroContent={heroContent} />
        {
          !loading && data?.cmsPage?.content ?
            <>
              {
                ReactHtmlParser( data.cmsPage.content, {
                  transform: ( node ) => { return promoContentTransform( node, heroSrc, setHeroSrc, handleSetHeroContent ) }
                })
              }
            </>
            :
            <PromoLoader />
        }
        <PromoFooter />
      </div>
    )
  }

  return null
}

export default SleepPromo