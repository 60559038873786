import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import useEnteredViewport from './useEnteredViewport'
import { ShakeDiv } from './ShakeDiv'
import { Tooltip } from 'react-tooltip'
import { uniqueId } from 'lodash'
import { icoXAob } from 'resources/images'

function CustomTooltip({tooltipContent, children, id, place, offset }) {

  const [ blockShake, setBlockShake ] = useState( false )
  const [ isOpen, setIsOpen ] = useState( false )

  const iconRef = useRef( null )
  const tooltipRef = useRef( null )
  const wasExecuted = useRef( false )

  const enteredViewport = useEnteredViewport( iconRef )

  useEffect( () => {
    document.removeEventListener( `click`, handleClickOutside )
    if ( isOpen ) document.addEventListener( `click`, handleClickOutside )

    return () => {
      document.removeEventListener( `click`, handleClickOutside )
    }
  }, [ isOpen ] )

  const handleClickOutside = ( e ) => {
    if ( !wasExecuted.current ) return wasExecuted.current = true
    if ( !tooltipRef?.current?.contains( e.target ) ) handleClose()
  }

  const handleClose = () => {
    wasExecuted.current = false
    setIsOpen( false )
  }

  const tooltipId = id ?? uniqueId()

  return (
    <>
      <ShakeDiv
        data-tooltip-id={`${tooltipId}-tooltip`}
        className={`${( enteredViewport && !blockShake ) && `shake`} min-w-fit`}
        ref={iconRef}
        onAnimationEnd={() => { setBlockShake( true ) }}
        onClick={() => { setIsOpen( true ) }}
      >
        {
          React.cloneElement( children ?? <></> )
        }
      </ShakeDiv>
      <Tooltip
        id={`${tooltipId}-tooltip`}
        style={{
          width: `90%`,
          backgroundColor: `white`,
          opacity: 1,
          borderRadius: `5px`,
          border: `1px solid #E5E5E5`,
          boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.15)`,
          pointerEvents: `auto`,
          zIndex: 1000
        }}
        place={place ?? `top`}
        openOnClick
        noArrow
        offset={offset}
        isOpen={isOpen}
      >
        <div className="bg-white opacity-100 relative pt-5">
          <div className="absolute top-1 right-1 cursor-pointer">
            <img
              src={icoXAob}
              width={18}
              height={18}
              onClick={handleClose}
            />
          </div>
          {tooltipContent}
        </div>
      </Tooltip>
    </>
  )
}

CustomTooltip.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType( [
    PropTypes.arrayOf( PropTypes.node ),
    PropTypes.node
  ] ),
  tooltipContent: PropTypes.oneOfType( [
    PropTypes.arrayOf( PropTypes.node ),
    PropTypes.node
  ] ),
  place: PropTypes.string,
  offset: PropTypes.object
}

export default CustomTooltip