import styled from 'styled-components'
import tailwindColors from 'config/tailwindColors'
import { icoFormCheck } from 'resources/images'

export const CheckBoxDiv = styled.div`
  display: flex;
  justify-content: center;
  [type=checkbox] {
    width: 1.1rem;
    height: 1.1rem;
    color: ${props => { return props?.checkboxBackground ?? tailwindColors.deepSleepBlue }};
    -webkit-appearance: none;
    background: none;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background-color: white;
    transition: background 300ms;
    cursor: pointer;
  }
  [type=checkbox]::before {
    content: "";
    color: transparent;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    border: 0;
    background-color: transparent;
    background-size: contain;
    box-shadow: inset 0 0 1px 1px lightgray;
  }

  [type=checkbox]:checked {
    background-color: ${props => { return props?.checkboxBackground ?? tailwindColors.deepSleepBlue }};
  }

  [type=checkbox]:checked::before {
    box-shadow: none;
    background-image: url(${icoFormCheck});
    background-repeat: no-repeat;
    background-size: 11px;
    background-position: center;
  }
`

export default CheckBoxDiv