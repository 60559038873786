import { GQLSnowdogMenu, GQLSnowdogResponse, TMenu, TNode } from './types'

/**
 * Takes snowdog menu data and formats it for use in the front-end megaMenu
 * @param {*} data Object from GraphQL api call that is formated as data.snowdogmenu.nodes<array>
 */
export const parseSnowdogMenu = ( data : GQLSnowdogResponse ) => {
  if ( !data?.snowdogMenus?.items?.length ) return []
  const menu : GQLSnowdogMenu = data.snowdogMenus.items[0]
  if ( !data || !data?.snowdogMenus?.items || !menu ) {
    throw new Error( `SnowdogFilter Error: Undefined or malformed data passed` )
  }

  const mItems : TMenu = [] // Formated array for react to render into a megaMenu!
  const nodeLookup : Record<string, number> = {} //  Object map that allows me to lookup parent nodes easy
  const submenuLookup : Record<string, {smIndex: number, mIndex: number}> = {} // Object map that allows to lookup parent submenu and it's parent
  const sdMenuNodes : TNode[] = menu.nodes.items
  sdMenuNodes.forEach( ( node : TNode ) => {
    // check for null nodes
    if ( !node ) return

    const { node_id, title, parent_id, url } = node
    if ( !parent_id ) { // means that item is of type TMenuItem
      mItems.push({
        url,
        title,
        submenus: [] // initialize with an empty subarray for now
      })
      nodeLookup[node_id] = ( mItems.length - 1 )

      return // Done with MenuItem
    }
    const parentIndex = nodeLookup[parent_id]
    if ( !parentIndex ) {
      // Means we are at a subMenuItem
      const { smIndex, mIndex } = submenuLookup[parent_id]
      mItems[mIndex].submenus[smIndex].submenuItems.push({
        title,
        url
      })

      return // Done with subMenuItem
    }
    // At this point, we are left with a subMenu
    // Save the indexes for easy access later in the loop
    submenuLookup[node_id] = {
      smIndex: mItems[parentIndex].submenus.length,
      mIndex: parentIndex
    }
    mItems[parentIndex].submenus.push({
      title,
      url,
      submenuItems: []
    })
  })

  return mItems
}
