import { number, string, func, bool, oneOfType, shape } from 'prop-types'

// TypeScript types are easier to read imo
// type Payment = {
//   monthly_max: number,
//   number_of_payments: number,
//   payment_for: PatientID,
//   single_payment: number,
//   recurring_payment: number
// }
//
// type PatientID = string
// This is used in multiple locations, reason for the export
export const Payment = shape({
  monthly_max: number,
  number_of_payments: number,
  payment_for: string, // this is a patientID
  single_payment: number,
  recurring_payment: number
})

export const PaymentMethodProps = {
  payMethod: shape({
    cardNumber: string,
    zip: oneOfType( [ string, number ] ),
    expMonth: oneOfType( [ string, number ] ),
    expYear: oneOfType( [ string, number ] ),
    ccv: oneOfType( [ string, number ] ),
    issuer: string // mastercard, visa, etc
  }),
  setPayMethod: func,
  removePayMethod: func,
  selected: bool,
  setSelected: func,
  handleCance: func
}


export type IPaymentMethod = CardPaymentMethod | BankPaymentMethod

export interface CardPaymentMethod {
  cardholderFirstName: string;
  cardholderLastName: string;
  cardNumber: string;
  expMonth: string;
  expYear: string;
  ccv: string;
  zip: string;
  state: string;
  city: string;
  address: string;
}

export interface BankPaymentMethod {
  routingNumber: string;
  accountNumber: string;
  nameOnAccount: string;
  accountType: 'checking' | 'savings';
}

export function identifyPaymentMethodType( payMethod : IPaymentMethod ) {
  const cardPayment = payMethod as CardPaymentMethod
  const bankPayment = payMethod as BankPaymentMethod
  if ( cardPayment?.cardNumber ) return `card`
  if ( bankPayment?.accountNumber ) return `ach`

  return null
}

// Some TypeScript Types for the submitPayment function
// export type singlePaymentDetails = {
//   amount : number,
//   orderDescription: string
// }

// export type recurringPaymentDetails = {
//   name: string,
//   amount: number,
//   start_date: string,
//   intervalLength: number,
//   intervalUnit: `days` | `month`,
//   totalOccurances: number,
//   initialPayment: boolean
// }
