import React from 'react'
import PropTypes from 'prop-types'

const ErrorField = ({message}) => {
  return <p className="text-error my-3">{message}</p>
}
ErrorField.propTypes = {
  message: PropTypes.string.isRequired
}

export default ErrorField
