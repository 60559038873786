import { cpapMaskImg, cpapMachineImg, cpapAccessoriesImg, thanksImg } from 'resources/images'

export const homePageCopy = {
  header: `Join Aeroflow Sleep`,
  subheader: `Our innovative service helps sleep apnea patients sleep well and live better by doing all the hard work for you. CPAP supplies through insurance has never been easier.`,
  qualifyFormTitle: `Qualify Through Insurance`,
  qualifyFormDisplay: `In 3 easy steps!`,
  qualifyFormDescription: `From CPAP machines, masks, tubing and filters, discover the sleep apnea essentials covered by your insurance.`
}

export const sleepProductsThroughInsuranceData = {
  header: `Aeroflow SLEEP is the #1 source for`,
  subheader: `Sleep Apnea Products through Insurance`,
  slideData: [
    {
      id:1,
      identifier: `react-products-machine`,
      imgSrc: cpapMachineImg,
      title: `Machines`,
      copy: `Explore high quality CPAP machines from trusted manufacturers.`,
      pathname: `cpap-machines`
    },
    {
      id:2,
      imgSrc: cpapMaskImg,
      identifier: `react-products-mask`,
      title: `Masks`,
      copy: `Find CPAP masks that fit for restful sleep.`,
      pathname: `cpap-masks`
    },
    {
      id:3,
      identifier: `react-products-accessories`,
      imgSrc: cpapAccessoriesImg,
      title: `Accessories`,
      copy: `Choose from cushions, headgear, tubing and more.`,
      pathname: `cpap-accessories`
    }
  ]
}

export const qualifyStandaloneCopy = {
  header: `Qualify Through Insurance`,
  subheader: `Aeroflow Sleep is the one-stop solution for all your sleep apnea needs. From connecting with your doctor, contacting your insurance, assigning you a Sleep Specialist who helps match you with the perfect CPAP, and replacing your CPAP supplies, we will assist you with every single step.`,
  stepsSubheader: `GET YOUR SLEEP ESSENTIALS`,
  stepsHeader: `How it works:`,
  postStepHeader: `congrats!`,
  postStepSubheader: `Finally, a sleep apnea partner you can count on.`,
  terms: `I authorize Aeroflow Healthcare to contact me by email, phone or SMS. Aeroflow will not share or distribute this information.`
}

export const qualifyThankYouCopy = {
  header: `Thank You!`,
  subheader: `We’re hard at work verifying your eligibility. Please be on the lookout for an email and/or text message from us. If you have not received any communication from us within 5 - 7 business days, please check your spam/junk folder in your email or contact us at AeroflowPAP.Supplies@aeroflowinc.com.`
}

// refer a friend disabled 03-14-22, may be re-enabled
export const referAFriendThankYouCopy = {
  header: `Thank You!`,
  subheader: `Thank you for telling your friends about Aeroflow Sleep! Keep an eye on your inbox. We'll notify this month's winner via email.`
}

export const resupplyOrderThankYouCopy = {
  header: `Thank You!`,
  subheader: `Your resupply order has been processed successfully!`
}

export const defaultThankYouCopy = {
  header: `Thank You.`,
  subheader: ``,
  thanksImg: thanksImg
}