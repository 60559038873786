import React from 'react'
import { qualifyStandaloneCopy as copy } from 'constants/copy'

const DefaultHeading = () => {
  return (
    <div>
      <h3 className="mb-6 text-center">
        {copy.header}
      </h3>

      <p className="mx-0 mb-9 text-center">
        {copy.subheader}
      </p>
    </div>
  )
}

export default DefaultHeading