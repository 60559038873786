import React from 'react'
import Appointment from 'types/appointment'
import { reportToSentry } from 'utils/reportToSentry'
import { AppointmentButton } from '../styled'

type AppointmentButtonsProps = {
  times: Appointment[],
  appointment: Appointment | null,
  dayFromCal: Date | null,
  setAppointment: React.Dispatch<React.SetStateAction<Appointment | null>>
  appointments: {
    [key: string]: Array<Appointment>;
  } | null;
}

function AppointmentButtons({ times, appointment, dayFromCal, setAppointment, appointments } : AppointmentButtonsProps ) : JSX.Element {

  const formatTime = ( time: string ) => {
    try {
      const formattedDateString = new Date( time.substring( 0, 16 ) ).toLocaleString( `en-US`, {
        hour: `numeric`,
        minute: `numeric`,
        hour12: true
      })

      return formattedDateString
    } catch {
      reportToSentry( `CST: Invalid time type given`, {
        time
      })

      return ``
    }
  }

  return (
    <div>
      {
        times.length > 0 &&
          <>
            <div className="mb-5 m-auto flex flex-wrap justify-center gap-5 max-w-5xl">
              {
                times.map( time => {
                  return (
                    <AppointmentButton
                      onClick={() => { return setAppointment( time ) }}
                      key={time.start_time}
                      name={time.start_time === appointment?.start_time ? `active` : `non-active`}
                    >
                      <p className="font-bold text-sm md:text-base">{formatTime( time.start_time )}</p>
                      <p className="text-sm md:text-base">{`${time.duration_minutes} min${time.duration_minutes > 1 ? `s` : ``}`}</p>
                    </AppointmentButton>
                  )
                })
              }
            </div>
          </>
      }
      {
        times && times.length === 0 && dayFromCal && appointments &&
        <p className="mt-5 mb-10 text-error">{`There are no appointments with open spots for this date. Please select another day...`}</p>
      }
    </div>
  )
}

export default AppointmentButtons