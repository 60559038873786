import { useReactiveVar } from "@apollo/client"
import { ReactiveVar } from "./types"

export default function buildCustomState<T>( rVar : ReactiveVar<T> ) : [T, ( ( _newVar : T ) => void ) ] {
  const get = useReactiveVar( rVar )
  const set = ( newVar : T ) => {
    rVar( newVar )
  }

  return [ get, set ]
}