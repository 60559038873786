import React from 'react'
import ActiveStepIndicator from './ActiveStepIndicator'
import { checkmarkWhite } from 'resources/images'

type TrackerStepIconProps = {
    imageSrc: string;
    stepStatus: boolean;
    isCurrentStep?: boolean;
    stepLinkUrl?: string;
    openLinkInNewTab?: boolean;
    onStepAction?: () => void;
 }

function TrackerStepIcon({ imageSrc, stepStatus, isCurrentStep, stepLinkUrl, openLinkInNewTab, onStepAction } : TrackerStepIconProps ) : JSX.Element {
  const baseClasses = `flex items-center justify-center rounded-full`
  const outLineClasses = `outline outline-1 outline-[#F1F1F1] outline-offset-2`
  const activeStepClass= `${baseClasses} w-[75px] h-[75px]  bg-sleepBlue ${stepLinkUrl || onStepAction ? `cursor-pointer` : ``}`
  const completeStepClass= `${baseClasses} ${outLineClasses} w-12 h-12 bg-[#51A255]`
  const upcomingStepClass= `${baseClasses} ${outLineClasses} w-12 h-12 bg-[#F1F1F1]`

  // make the icon clickable -- navigate to the stepLinkUrl or fire the action for the active step
  const handleStepAction = () => {
    if ( !isCurrentStep ) return

    if ( onStepAction ) onStepAction()
    if ( stepLinkUrl ) window.open( stepLinkUrl, openLinkInNewTab ? `_blank` : `_self` )
  }

  return (
    <div className={`flex flex-col relative ${!isCurrentStep ? `pl-[14px]` : ``}`}>
      {isCurrentStep && <ActiveStepIndicator />}
      <div
        className={stepStatus ? completeStepClass : isCurrentStep ? activeStepClass : upcomingStepClass}
        onClick={handleStepAction}
      >
        <img src={stepStatus ? checkmarkWhite : imageSrc} className={isCurrentStep ? `max-w-[38px]` : `max-w-[30px]`} />
      </div>
      {/* dotted vertical line under tracker icon */}
      <div className="step-separator flex justify-center flex-grow after:content-[''] after:border-[#D9D9D9] after:border-r-2 after:border-dotted text-center py-1"></div>
    </div>
  )
}

export default TrackerStepIcon