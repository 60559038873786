import React from 'react'
import InsuranceAdvantage from '../InsuranceAdvantage/InsuranceAdvantage'
import { sleepBgVertical } from 'resources/images'
import { EditSelection } from '../EditSelection/EditSelection'
import { INSURANCE_ADVANTAGE_COPY } from '../InsuranceAdvantage/constants'

type VerticalSNLStatusContainerProps = {
  children: React.ReactNode
  activeStep: number
}

const VerticalSNLStatusContainer = ({ children, activeStep }: VerticalSNLStatusContainerProps ) => {
  const getInsAdvantageDescription = () : string => {
    return activeStep < 4 ? INSURANCE_ADVANTAGE_COPY.maskDescription : INSURANCE_ADVANTAGE_COPY.machineDescription
  }

  return (
    <div className="py-[188px] px-[30px] bg-cover bg-center bg-no-repeat shrink-1 w-[30%] md:min-w-[375px] md:max-w-[460px]" style={{ backgroundImage: `url(${sleepBgVertical})` }}>
      <h2 className="text-white font-inter text-4xl font-semibold mb-9">{`Your Sleep Apnea Profile`}</h2>
      <div className="mb-[22px]">
        {children}
      </div>
      <div className="flex flex-col justify-center">
        <EditSelection activeStep={activeStep} />
        <InsuranceAdvantage description={getInsAdvantageDescription()} />
      </div>
    </div>
  )
}

export default VerticalSNLStatusContainer
