import React from 'react'
import CheckBoxDiv from './CheckBoxDiv'


type OptionSelectionProps = {
  checked: boolean;
  handleToggle(): void;
  checkboxBackground?: string;
  containerStyling?: string;
  header?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: React.ReactElement<any>;
  detail?: string;
}

function OptionSelection({
  checked,
  handleToggle,
  containerStyling,
  header,
  detail,
  children,
  checkboxBackground
}: OptionSelectionProps ) : JSX.Element {

  return (
    <div
      className={`rounded-lg mx-4 p-4 ${containerStyling ?? ` bg-lightGray max-w-xl mb-5`} ${header === undefined && `flex items-start gap-1` } ${detail && `cursor-pointer`}`}
      onClick={detail ? handleToggle : () => { return }}
    >
      <div className={`flex gap-2 items-center mb-1 ${header === undefined && `mt-1`}`}>
        <CheckBoxDiv
          {
            ...{
              checkboxBackground: checkboxBackground
            }
          }
        >
          <input type="checkbox" checked={checked} onChange={handleToggle} />
        </CheckBoxDiv>
        { header !== null && <p className="font-bold text-base md:text-lg" onClick={handleToggle}>{header}</p> }
      </div>
      {
        detail ?
          <span className="font-light text-sm md:text-base" onClick={handleToggle}>{detail}</span>
          :
          React.cloneElement( children ?? <></> )
      }
    </div>
  )
}

export default OptionSelection