import React from 'react'
import { YouTubeFacade } from 'components/video'
import PropTypes from 'prop-types'

function DefaultCMS({type, containerStyling = ``, failSafe, customTag, customProps, styling}) {
  const CMSTag = customTag

  if ( type === `video` ) {
    return (
      <div className={containerStyling}>
        <YouTubeFacade
          imgSrc={failSafe?.image}
          videoID={failSafe?.videoID}
          {...customProps}
        />
      </div>
    )
  }

  if ( type === `text` ) {
    return (
      <div className={containerStyling}>
        <CMSTag className={styling} {...customProps}>
          {
            failSafe
          }
        </CMSTag>
      </div>

    )
  }

  return (
    <CMSTag className={containerStyling}>
      <img
        src={failSafe}
        className={styling}
        {...customProps}
      />
    </CMSTag>
  )
}

DefaultCMS.propTypes = {
  customTag: PropTypes.string,
  styling: PropTypes.string,
  failSafe: PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] ),
  type: PropTypes.string,
  customProps: PropTypes.object,
  containerStyling: PropTypes.string
}

export default DefaultCMS
