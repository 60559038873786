import FormDetailsFetch from 'modules/qualifyForm/api/formDetailsFetch'
import * as React from 'react'
import { reportToSentry } from 'utils/reportToSentry'
import { HdyhauReturn } from './useHdyhau.d'

export default function useHdyhau() : HdyhauReturn {
  const [ loading, setLoading ] = React.useState<boolean>( false )
  const [ error, setError ] = React.useState<boolean>( false )
  const [ hdyhauData, setHdyhauData ] = React.useState<string[]>( [] )

  React.useEffect( () => {
    const referral_url = process.env.REACT_APP_ENVIRONMENT === `local` ? `https://react-sleep.aeroflow.ninja/` : window.location.origin
    FormDetailsFetch( referral_url ).then( ( data ) => {
      if ( data?.meta?.status === `OK` ) {
        setLoading( false )

        setHdyhauData( data.data.how_did_you_hear_about_us_options )
      }
    })
      .catch( error => {
        setLoading( false )
        setError( true )

        reportToSentry( new Error( `there was an error getting the hdyhau data` ), {
          cause: error
        })
      })
  }, [] )

  return {
    hdyhauData,
    hdyhauLoading: loading,
    hdyhauError: error
  }
}