import { useEffect, useState } from 'react'
import { reportToSentry } from 'utils/reportToSentry'
import { useDoctorStore } from './state'
import { getInternalZip } from '../utils/utils'
import { getToken } from 'stores/tokenStore'
import { useAddressStore } from 'components/addressBlock'
import { useAccountStore } from '../../../modules/accountV2/state'
import { useQualifyFieldsStore } from 'stores/qualifyFieldsStore'

function useRetrieveZipCode(): boolean {
  const [ loading, setLoading ] = useState<boolean>( Boolean( getToken() ) )
  const { doctorInput, setDoctorInput } = useDoctorStore()
  const { address } = useAddressStore()
  const { customer } = useAccountStore()
  const { formFields } = useQualifyFieldsStore()

  useEffect( () => {
    if ( address?.zipCode ) {
      // prefill with latest address if we have it
      setDoctorInput(
        Object.assign({}, doctorInput, {
          zipCode: address.zipCode
        })
      )
    } else if ( formFields?.zipCode ) {
      // prefill from qualify form if we have it
      setDoctorInput(
        Object.assign({}, doctorInput, {
          zipCode: formFields.zipCode
        })
      )
    } else if ( customer?.data?.date_of_birth && customer?.data?.email ) {
      // @NOTE I can confirm we do need to keep the below code in place as of 12-14-2023 for dr block in sleep new leads
      // in that case the patient address is not yet in magento so using the deprecated login needs to happen
      // it allows us to pick up the zip code they entered in the qualify form if they are coming direct from qualify form.
      getInternalZip( customer.data.email, customer.data.date_of_birth )
        .then( zipData => {
          if ( zipData?.meta?.status === `OK` ){
            setDoctorInput(
              Object.assign({}, doctorInput, {
                zipCode: zipData.data.zip_code
              })
            )
          } else {
            reportToSentry(
              new Error(
                `Doctor Block: Error getting patient zip from internal`
              ),
              {
                customer: JSON.stringify( customer.data ),
                response: JSON.stringify( zipData )
              }
            )
          }
          setLoading( false )
        })
        .catch( error => {
          setLoading( false )
          reportToSentry(
            new Error(
              `Doctor Block: Error getting patient zip from internal`,
              {
                cause: error
              }
            ),
            {
              customer: JSON.stringify( customer.data )
            }
          )
        })
    }
    setLoading( false )
  }, [ customer ] )

  return loading
}

export default useRetrieveZipCode
