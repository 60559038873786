import { gql } from '@apollo/client'

export const activeOrdersQuery = gql`
  query activeOrdersQuery{
  customerOrders{
    items{
      id
      order_number
      created_at
      status
      tracking_numbers
    }
  }
}
`