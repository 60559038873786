
import React, { useEffect, useState } from 'react'
import { useTransition, animated as a, useSpringRef } from '@react-spring/web'
import { SpringValue } from 'react-spring'

type ContentSliderProps = {
  content: React.ReactNode[],
  interval?: number,
  containerStyle?: string,
  aDivStyle?: string,
  triggerHeightChange: () => void;
}

function ContentSlider({
  content,
  interval = 8,
  containerStyle = `relative overflow-hidden p-10 w-full`,
  aDivStyle = ``,
  triggerHeightChange
}: ContentSliderProps ) {

  const [ contentIndex, setContentIndex ] = useState( 0 )

  const animate = () => { return setContentIndex( ( contentIndex + 1 ) % content?.length ) }

  const transApi = useSpringRef()
  const transitions = useTransition( contentIndex, {
    ref: transApi,
    order: [ `leave`, `enter`, `update` ],
    from: {
      overflow: `hidden`,
      transform: `translate3d(100%,0,0)`
    },
    enter: {
      overflow: `hidden`,
      transform: `translate3d(0%,0,0)`,
      height: `fit-content`,
      zIndex: 1000
    },
    leave: {
      overflow: `hidden`,
      position: `absolute`,
      height: `fit-content`,
      transform: `translate3d(-110%,0,0)`,
      zIndex: 0
    },
    onChange: triggerHeightChange
  })

  useEffect( () => {
    transApi.start()

    // rotate messages every (interval) seconds
    const messageRotationInterval = setInterval( () => {
      animate()
    }, interval * 1000 )

    return () => {
      clearInterval( messageRotationInterval )
    }
  }, [ contentIndex ] )

  return (
    <div className={containerStyle}>
      {
        transitions( ( style: {[key: string]: SpringValue }, i: number ) => {
          return (
            <a.div style={style} className={aDivStyle}>
              {
                content[i]
              }
            </a.div>
          )
        })
      }
    </div>
  )
}

export default ContentSlider