import { useState, useEffect } from "react"
import AuthBorderWrap from "./AuthBorderWrap"
import { DateOfBirthInput, PhoneInput, useDateInputState, usePhoneInputState, useValidInputs } from "./input"
import { useNavigate, useLocation } from "react-router-dom"
import { useMutation } from "@apollo/client"
import { getRedirectable } from "modules/auth/redirectable"
import LoadingSpinner from "components/LoadingSpinner"
import { reportToSentry } from "utils/reportToSentry"
import { generateInstantLoginToken } from "graphql/mutations/instantLogin"
import EmailSelectionForm from "./EmailSelectionForm"
import { AF_SUPPORT_PHONE_NUMBER } from "constants/phoneNumbers"
import { MY_ACCOUNT_PATH } from "routes"
import { useTokenStore } from "stores/tokenStore"

export default function InstantLoginForm() {
  const { isValidPhone, isValidDate } = useValidInputs()
  const dateOfBirth = useDateInputState()[0]
  const phone = usePhoneInputState()[0]

  const [ errorMessage, setErrorMessage ] = useState( `` )
  const [ loading, setLoading ] = useState( false )
  const [ logInAttempts, setLogInAttempts ] = useState( 0 )

  const [ showEmailSelectForm, setShowEmailSelectForm ] = useState( false )
  const [ emailSelection, setEmailSelection ] = useState<Array<string>>( [] )

  const navigate = useNavigate()
  const location = useLocation()
  const search = new URLSearchParams( window.location.search )
  const sgh = search.get( `sgh` ) ?? search.get( `txt` )

  const { customerToken, setCustomerToken } = useTokenStore()

  useEffect( () => {
    // If user is already logged in and get here somehow, redirect
    customerToken && navigate( location?.state?.from ?? MY_ACCOUNT_PATH )

    // this code is to auto login stakeholders so they can remotely troubleshoot patient issues
    if ( search?.get( `sct` ) ) {
      setCustomerToken( search?.get( `sct` ) ?? `` )
      navigate( MY_ACCOUNT_PATH )
    }
  })

  const [ login ] = useMutation( generateInstantLoginToken, {
    fetchPolicy: `no-cache`, // Prevent caching user login creds
    onCompleted: ({ generateInstantLoginToken }) => {
      setLoading( false )
      const instantLoginResponseObject = generateInstantLoginToken[0]
      const { token, emails } = instantLoginResponseObject
      if ( token?.length ) return handleSuccessfulLogin( token )
      if ( emails?.length ) {
        setShowEmailSelectForm( true )

        return setEmailSelection( emails )
      }

      setLogInAttempts( logInAttempts + 1 )
      setErrorMessage( `There was an error logging in.` )
    },
    onError: ( data ) => {

      const { graphQLErrors: errors } = data

      setLogInAttempts( logInAttempts + 1 )

      // we want to display something different if there is an error of a certain message
      const signInAuthError = errors?.find( error => {
        return error.message === `The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.`
      })

      // This is a general error that comes from internal. This is generally because of a test patient store issue. (ie. patient from mom and baby)
      const internalError = errors?.find( error => {
        return error.message === `Internal server error.` || error.message === `An error occurred, please try again later.`
      })

      if ( signInAuthError ) {
        setErrorMessage( `The account sign-in was incorrect or your account is disabled temporarily, please give us a call at ${AF_SUPPORT_PHONE_NUMBER}` )
      } else if ( internalError ) {
        setErrorMessage( `We had trouble processing your request. If this issue persists, please give us a call at ${AF_SUPPORT_PHONE_NUMBER}` )
        reportToSentry( new Error( `Internal Server Error return from generateInstantLoginToken on InstantLoginForm`, {
          cause: data
        }) )
      } else {
        setErrorMessage( errors.map( errorObject => { return errorObject.message }).join( ` ` )
          .concat( ` If this issue persists, please give us a call at ${AF_SUPPORT_PHONE_NUMBER}` ) )
        reportToSentry( new Error( `unknown error on generateInstantLoginToken mutation on InstantLoginForm.ts`, {
          cause: data
        }) )
      }
      setLoading( false )
    }
  })

  function handleSuccessfulLogin( magentoToken : string ) {

    setCustomerToken( magentoToken )

    const locationState = location?.state || {
      from: {
        pathname: MY_ACCOUNT_PATH,
        search: window.location.search
      }
    }

    const redirectable = getRedirectable( locationState.from )

    return navigate( redirectable?.redirectLocation ?? MY_ACCOUNT_PATH, {
      state: locationState,
      replace: true
    })
  }

  function handleFormSubmit( e : React.FormEvent, userSelectedEmail? : string ) {
    e.preventDefault()
    setLoading( true )
    login({
      variables: {
        telephone: phone.replace( ` `, `-` ).replace( /[()]/g, `` ),
        date_of_birth: `${dateOfBirth.dobMonth}/${dateOfBirth.dobDay}/${dateOfBirth.dobYear}`,
        sgh,
        ...( userSelectedEmail && {
          email: userSelectedEmail
        })
      }
    })
  }

  const tooManyAttempts = logInAttempts >= 3
  const tooManyAttemptsError = `It Appears That You Are Having Trouble Logging In? Please give us a call at ${AF_SUPPORT_PHONE_NUMBER}`

  if ( showEmailSelectForm ) return <EmailSelectionForm emailSelection={emailSelection} onEmailSubmit={handleFormSubmit} shouldLoad={loading} />

  return (
    <AuthBorderWrap title={`Log In`}>
      <p className="mb-4 p-sm">{`Log in by entering the patient date of birth and the phone number associated with the Aeroflow Sleep account.`}</p>
      <form onSubmit={handleFormSubmit}>
        <DateOfBirthInput />
        <PhoneInput required />
        <p className="my-2 text-error">{tooManyAttempts ? tooManyAttemptsError : errorMessage}</p>
        <button className="btn btn-primary" type="submit" disabled={!isValidPhone || !isValidDate || loading || tooManyAttempts}>
          {loading ? <LoadingSpinner width={`40px`} height={`40px`} /> : `Log In to your Aeroflow Sleep Account`}
        </button>
      </form>
    </AuthBorderWrap>
  )
}