import { reportToSentry } from "utils/reportToSentry"

export function formatDate( dateToFormat: string ) {
  const dateArray = dateToFormat?.split( `-` )
  try {
    const newDate = `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`

    return newDate

  } catch ( error ) {
    return reportToSentry( new Error( `There was an error getting the dates` ), {
      date: dateToFormat
    })
  }
}