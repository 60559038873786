import React from 'react'
import { Builder } from '@builder.io/react'

type ComponentProps = { heading?: string; description?: string; }

export const PageSectionHeadingBuilderComponent = ( props: ComponentProps ) => {
  return (
    <div className="mb-6 pb-4">
      {props.heading &&
      <div className="mb-2">
        <h4 className="text-deepSleepBlue font-light text-3xl sm:text-center lg:text-left">{props.heading}</h4>
      </div>
      }
      { props.description &&
         <div className="mb-6">
           <p>{props.description}</p>
         </div>
      }
    </div>
  )
}

Builder.registerComponent( PageSectionHeadingBuilderComponent, {
  name: `PageSectionHeadingBuilderComponent`,
  inputs: [{
    name: `heading`,
    type: `text`
  }, {
    name: `description`,
    type: `text`
  }]
})

export default PageSectionHeadingBuilderComponent
