import { addMagentoEnvAuthHeaders } from './magentoEnvAuthHeaders'
import { getAuthHeader } from './auth/helpers'

export const getProductPricing = async ( bustCache = false ) => {
  if ( !getAuthHeader() ) return

  if ( sessionStorage.getItem( `product-pricing` ) && !bustCache ) {
    return JSON.parse( sessionStorage.getItem( `product-pricing` ) || `` )
  }
  const response = await fetch( `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/product-pricing`, {
    method: `GET`,
    headers: {
      ...addMagentoEnvAuthHeaders(),
      "Authorization": getAuthHeader()
    }
  })

  const data = await response.json()

  if ( data?.meta.status === `OK` ) {
    sessionStorage.setItem( `product-pricing`, JSON.stringify( data ) )
    return data
  }
  throw new Error( response?.statusText ?? `Wrongly formatted response returned` )
}

export const getPatientAddress = async () => {
  const response = await getProductPricing( true ) // We want any error to bubble up which is why there is no .catch
  const { address } = response.data.patient

  return address
}