import styled from 'styled-components'

import tailwindColors from 'config/tailwindColors'

export const StyledCMSPageDiv = styled.div`
  h1, h2, h3, h4, h5 {
    font-family: Open Sans, serif;
  }

  img.max-w-none {
    max-width: none;
  }

  h1 {
    font-size: 3rem;
    font-weight: 300;
    color: ${tailwindColors.deepSleepBlue};
  }

  h2 {
    font-size: 2.25rem;
    font-weight: 300;
    color: ${tailwindColors.deepSleepBlue};
  }

  h3 {
    font-size: 1.75rem;
    font-weight: 600;
    color: ${tailwindColors.deepSleepBlue};
  }
  
  h4 {
    font-size: 1.375rem;
    font-weight: 600;
    color: ${tailwindColors.deepSleepBlue};
  }

  h5 {
    font-size: 1.125rem;
    color: ${tailwindColors.black};
  }

  p {
    margin-bottom: 1rem;
  }

  blockquote {
    margin: 0 0 20px 40px;
    font-style: italic;
  }

  figure {
    text-align: center;

    img {
      margin: 0 auto;
    }
  }

  ul {
    list-style-type: disc;
    padding-inline-start: 2.5rem;
    margin-bottom: 2.5rem;

    li {
      display: list-item;
    }
  }

  ol {
    list-style-type: decimal;
    padding-inline-start: 2.5rem;
  }

  a {
    color: #88456e;

    &:hover {
      color: #515151;
      text-decoration: underline;
    }
  }

  @media(min-width: 769px){
    .pagebuilder-mobile-only {
      display: none;
    }
  }
  @media(max-width: 768px){
    .pagebuilder-mobile-hidden {
      display: none;
    }
  }
`
