import { useLocation, matchPath } from "react-router-dom"
import { PAYMENT_PORTAL_PATH } from "modules/aobPayments/constants"

export const whichPathsMatch = ( paths : Array<string> ) => {
  const location = useLocation()
  const token = ( new URLSearchParams( location.search ) ).get( `afmcatoken` )

  const matches = paths.map( ( path ) => {
    // If the token is present we want to display normal header, so we need to exclude this path from matching
    if ( path === PAYMENT_PORTAL_PATH && token ) return null

    const pathMatch = matchPath({
      path: path
    }, location.pathname )

    return pathMatch ? path : null
  }).filter( s => { return s }) // this filter removes the null values from the map

  return matches as Array<string> // returns an array of matching paths
}