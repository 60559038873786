import { ResupplyOption } from '../types'
import { RadioInput } from 'components/forms/components'


type QQOptionGroupProps = {
  option : ResupplyOption,
  onChange: ( _hcpc_id: number, _questionType : `quantity` | `quality`, _value: `yes` | `no` ) => void
}

export default function QuantityQualityOptionGroup({option, onChange} : QQOptionGroupProps ) : JSX.Element {

  const optionWrapperClassName = `text-center p-4 my-4 max-w-xl mx-auto`
  const radioWrapperClassName = `flex flex-row gap-4 w-16 min-w-max mx-auto my-2`

  const buildDescription = ( questionType : string, description : string ) : string => {
    return questionType === `quality` ?
      `Is your current ${description} in need of replacement for reasons relating to hygienic safety, damage, loss, or a decline in machine performance?` :
      `Is your supply of ${description} at or approaching zero?`
  }

  return (
    <>
      {
        ( [ `quality`, `quantity` ] as Array<`quality` | `quantity`> ).map( ( questionType ) => {
          return (
            <div key={`${option.hcpc_id}-${questionType}`} className={optionWrapperClassName}>
              <p className="text-lg font-light">{buildDescription( questionType, option.description )}</p>
              <div className={radioWrapperClassName}>
                <RadioInput
                  className="cursor-pointer"
                  label="Yes"
                  labelClassName="text-lg font-light"
                  value="yes"
                  name={`${option.hcpc_id}-${questionType}-RadioGroup`}
                  onChange={() => { onChange( option.hcpc_id, questionType, `yes` ) }}
                  defaultChecked={Boolean( option?.medicareChecklist?.reason?.qualityQuestionResponse )}
                />
                <RadioInput
                  className="cursor-pointer"
                  label="No"
                  labelClassName="text-lg font-light"
                  value="no"
                  name={`${option.hcpc_id}-${questionType}-RadioGroup`}
                  onChange={() => { onChange( option.hcpc_id, questionType, `no` ) }}
                />
              </div>
            </div>
          )
        })
      }
    </>
  )
}