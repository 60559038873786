import React from 'react'

interface ActionLinkProps {
  handleClick: () => void;
  text: string;
  className?: string;
}

const ActionLink = ({ handleClick, text, className }: ActionLinkProps ) => {
  return (
    <>
      {text &&
      <div className={`text-[#0057A8] underline cursor-pointer sm:pt-12 lg:pt-36 sm:text-center lg:text-left ${className}`} onClick={handleClick}>
        {text}
      </div>}
    </>
  )
}

export default ActionLink