import React from 'react'

function PromoFooter() : JSX.Element {
  return (
    <div className="flex flex-col py-10 bg-deepSleepBlue w-full items-center px-5">
      <p className="text-white mb-5 text-center">{`Contact us at 800-480-5491 if you have any questions about getting PAP supplies through insurance.`}</p>
      <a
        className="max-w-xs text-center m-auto rounded-full bg-white py-1 px-14 cursor-pointer hover:bg-secondaryBlue-tint2"
        href={`mailto: info@aeroflowsleep.com`}
      >
        <span className="text-center m-auto text-deepSleepBlue font-light text-xl text-deepSleepBlue">
          {`Email Us!`}
        </span>
      </a>
    </div>
  )
}

export default PromoFooter