import React from 'react'
import { transformWidget } from './utils'
import { useQuery } from '@apollo/client'
import { cmsBlocksQuery } from 'graphql/queries/cmsBlocks'
import ReactHtmlParser from 'react-html-parser'
import QualifyPlugBlockBackup from './QualifyPlugBlockBackup'
import QualifyPlugContentLoader from './QualifyPlugContentLoader'
import CMSWidgetTooltipWrapper from 'components/cmsWidgetTooltipWrapper'

export const QualifyPlugBlock = () => {

  const { data, loading, error } = useQuery( cmsBlocksQuery, {
    errorPolicy: `ignore`,
    variables: {
      identifiers: `react-qualify-plug-widget`
    }
  })

  if ( loading ) return <QualifyPlugContentLoader />

  if ( error || !data?.cmsBlocks?.items?.length || !data?.cmsBlocks?.items[0]?.content ) return <QualifyPlugBlockBackup />

  return (
    <CMSWidgetTooltipWrapper
      className="sm:mx-0 sm:my-6 md:m-2 sm:p-0 md:p-4 max-w-full"
      identifier={`react-qualify-plug-widget`}
    >
      <>
        {
          ReactHtmlParser( data.cmsBlocks.items[0].content, {
            transform: transformWidget
          })
        }
      </>
    </CMSWidgetTooltipWrapper>
  )
}

export default QualifyPlugBlock
