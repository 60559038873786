import { matchPath } from 'react-router-dom'
import { RESUPPLY_CLAIMS_PATH, RESUPPLY_PORTAL_PATH } from 'modules/resupply2'
import { PAYMENT_PORTAL_PATH, SUMMARY_PAGE_PATH } from 'modules/aobPayments/constants'
import { SNL_BASE_PATH } from 'modules/sleepNewLeads/constants'

export interface IRedirectable {
  pathMatch: string,
  conditionalMatch?: boolean,
  redirectLocation: string
}

export type FromLocation = {
  pathname: string,
  search: string
}

export const isRedirectable = ( from : FromLocation ) : boolean => {
  const match = getRedirectable( from )

  return Boolean( match )
}

export const getRedirectable = ( from : FromLocation ) : IRedirectable | undefined => {

  const currentQueryParams = new URLSearchParams( window.location.search )
  const originQueryParam = currentQueryParams?.get( `origin` )

  const redirectables : Array<IRedirectable> = [{
    pathMatch: RESUPPLY_PORTAL_PATH, // A REGEX/STRING pathname to compare against the current pathname
    conditionalMatch: originQueryParam === `resupply`, // An optional bool to evaluate, will return a match if true
    redirectLocation: RESUPPLY_CLAIMS_PATH // The route that will be used for the redirect if one of the above evaluates to true
  }, {
    pathMatch: SNL_BASE_PATH,
    redirectLocation: SNL_BASE_PATH
  }, {
    pathMatch: PAYMENT_PORTAL_PATH,
    redirectLocation: `${SUMMARY_PAGE_PATH}${from.search}`
  }]

  const match = redirectables.find( ( redirectable ) => {
    return matchPath({
      path: redirectable.pathMatch
    }, from.pathname ) || redirectable.conditionalMatch
  })

  return match
}