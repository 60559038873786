import React from 'react'
import ProductCarousel from './productCarousel/ProductCarousel'
import Select from 'react-select'
import { themeConfig, getStylesConfig } from 'components/react-select'
import { useMediaQuery } from 'react-responsive'

interface MaskQuickViewProps {
  maskData: {
    mask: string;
    maskSizes: {
      size: string;
      dme_id: number;
    }[];
    maskImages: {
      items: string[]
    };
  };
  selectedSize: string | null;
  setSelectedSize: ( _size: string ) => void;
  onMaskChange: ( _selectedSize: string | null ) => void;
}

const MaskQuickView: React.FC<MaskQuickViewProps> = ({ maskData, selectedSize, setSelectedSize, onMaskChange }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: 976px)`
  })

  return (
    <div className="flex sm:flex-col lg:flex-row sm:items-start lg:items-center">
      <ProductCarousel productImages={maskData.maskImages || []} />
      <div className="sm:w-full lg:w-1/2 sm:mt-4 sm:pl-0 lg:pl-10 flex flex-col sm:max-w-none lg:max-w-[588px]">
        {!isMobile &&
          <h2 className="text-[#4D4C4D] text-[42px] font-inter font-semibold leading-[47px] pb-7">{maskData?.mask}</h2>
        }
        <label htmlFor="size" className="text-[#4D4C4D] text-base">
          {`Mask Size`}
          <span className="text-[#FF0000]">{`*`}</span>
        </label>
        <div className="sm:max-w-none lg:max-w-[421px]">
          <Select
            name="size"
            placeholder="Size Option"
            value={selectedSize && {
              value: selectedSize,
              label: maskData.maskSizes.find( sizeInfo => sizeInfo.dme_id.toString() === selectedSize )?.size
            }}
            options={maskData?.maskSizes?.map( ( sizeInfo ) => ({
              value: sizeInfo.dme_id.toString(),
              label: sizeInfo.size
            }) )}
            onChange={( e ) => setSelectedSize( e.value )}
            required
            theme={themeConfig}
            styles={getStylesConfig( false )}
          />
          <div className="flex items-center sm:justify-center lg:justify-start mt-7">
            <button
              className="btn-primary text-xl"
              disabled={!selectedSize}
              onClick={() => onMaskChange( selectedSize )}
            >
              {`Add To Order`}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MaskQuickView