import React from 'react'
import { useMutation } from '@apollo/client'
import { reportToSentry } from 'utils/reportToSentry'
import { requestPasswordResetEmailMutation } from 'graphql/mutations'
import { TextInput, ErrorField } from 'components/forms/components'
import LoadingSpinner from 'components/LoadingSpinner'

const RequestPasswordResetForm = ({ email }) => {
  const [ formFields, setFormFields ] = React.useState({
    email: email ?? ``
  })
  const [ errorMessage, setErrorMessage ] = React.useState( `` )
  const [ requestResetPasswordEmail, { data, loading }] = useMutation( requestPasswordResetEmailMutation, {
    variables: formFields,
    errorPolicy: `all`,
    onError: ( ( error ) => {
      reportToSentry( new Error ( `RequestPasswordResetForm -> requestPasswordResetEmail`, {
        cause: error
      }) )
    })
  })

  const handleSubmit = async event => {
    event.preventDefault()
    const result = await requestResetPasswordEmail()

    if ( result?.errors ) {
      setErrorMessage( result.errors.map( errorObject => { return errorObject.message }).join( ` ` ) )
    }

    if ( result?.data && !result.data.requestPasswordResetEmail ) {
      setErrorMessage( `Either we couldn't find an account with that email, or that email is invalid` )
    }
  }

  const handleChange = event => {
    setFormFields({
      ...formFields,
      [event.currentTarget.name]: event.currentTarget.value
    })
  }

  if ( loading ) {
    return (
      <div className="my-8">
        <LoadingSpinner />
      </div>
    )
  }

  if ( data && data.requestPasswordResetEmail ) return <p className="my-3">{`You will receive an email with instructions to reset your password shortly`}</p>

  return (
    <form onSubmit={handleSubmit} className="my-5 flex flex-col">
      <TextInput
        name="email"
        label="Email"
        type="email"
        value={formFields.email}
        onChange={handleChange}
        required
      />
      <br></br>
      <button className="btn btn-primary mx-auto" type="submit">
        {`Reset My Password`}
      </button>

      {errorMessage && <ErrorField message={errorMessage} />}

      <p className="text-xs text-error my-2 text-center py-2">{`* Required Fields`}</p>
    </form>
  )
}

export default RequestPasswordResetForm
