export const xSearchProductsOperation = `
  xsearchProducts(search: $searchTerm) {
    code
    items {
      id
      url_key
      name
      sku
      small_image {
        label
        url
      }
      image {
        label
        url
      }
    }
    total_count
  }
`

export const xSearchPopularSearchesOperation = `
  xsearchPopularSearches(search: $searchTerm) {
    code
    items {
      name
      num_results
      url
    }
    total_count
  }
`

export const xSearchRecentSearchesOperation = `
  xsearchRecentSearches {
    code
    items {
      name
      num_results
      url
    }
    total_count
  }
`

export const xSearchBlogsOperation = `
  xsearchBlogs(search: $searchTerm) {
    code
    items {
      name
      title
      description
      url
    }
    total_count
    }
`
