import Cookies from 'js-cookie'

export function getCookies() {
  const cookieReferrals = {
    utm_campaign: Cookies.get( `utm_campaign` ),
    fbclid: Cookies.get( `fbclid` ),
    gclid: Cookies.get( `gclid` ),
    msclkid: Cookies.get( `msclkid` ),
    fbp: Cookies.get( `_fbp` ),
    ttclid: Cookies.get( `ttclid` )
  }

  const { length } = Object.values({
    ...cookieReferrals,
    fbp: `` // Remove fbp from the object as it's not normally packaged with our cookie logic
  }).filter( ( cookie ) => { return Boolean( cookie ) })

  return {
    length,
    ...cookieReferrals
  }
}

export function setCookies() {
  const currentQueryParams = new URLSearchParams( window.location.search )

  const utmValue = currentQueryParams.get( `utm_campaign` )
  const fbookValue = currentQueryParams.get( `fbclid` )
  const googleValue = currentQueryParams.get( `gclid` )
  const msValue = currentQueryParams.get( `msclkid` )
  const ttclid = currentQueryParams.get( `ttclid` )

  if ( utmValue !== null ) {
    Cookies.set( `utm_campaign`, utmValue )
  } else if ( fbookValue !== null ) {
    Cookies.set( `fbclid`, fbookValue )
  } else if ( googleValue !== null ) {
    Cookies.set( `gclid`, googleValue )
  }

  // set msValue if present, no hierarchy
  if ( msValue !== null ) {
    Cookies.set( `msclkid`, msValue )
  }

  if ( ttclid !== null ) {
    Cookies.set( `ttclid`, ttclid )
  }
}