import { gql } from "@apollo/client"

export const setShippingMethodsOnCartMutation = gql`
  mutation setShippingMethodsOnCartMutation($input : SetShippingMethodsOnCartInput!) {
    setShippingMethodsOnCart(input : $input) {
      cart {
        shipping_addresses {
          selected_shipping_method {
            carrier_code
            carrier_title
            method_code
            method_title
          }
        }
      }
    }
  }
`