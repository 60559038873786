import { CheckboxInput, ErrorField, TextInput, Validators } from 'components/forms/components'
import LoadingSpinner from 'components/LoadingSpinner'
import { formatDate } from 'components/tables/util'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { reportToSentry } from 'utils/reportToSentry'
import {getAbnInfo, submitAbn} from './fetch'
import { abnInformationType, abnResponse, optionsType } from './types/types'

export default function DigitalAbn() {

  const [ abnInformation, setAbnInformation ] = React.useState<abnInformationType[]>( [] )
  const [ optionChecked, setOptionChecked ] = React.useState<optionsType>({
    option: ``
  })
  const [ signedName, setSignedName ] = React.useState<string>( `` )
  const [ error, setError ] = React.useState<string>( `` )
  const [ isLoading, setIsLoading ] = React.useState<boolean>( false )

  const searchParams = new URLSearchParams( window.location.search )
  const hash = searchParams.get( `sgh` )
  const navigate = useNavigate()

  const getInitialSignedDate = () => {
    const d = new Date
    const year = d.getFullYear()
    const day = d.getDate()
    const month = d.getMonth() + 1

    return `${year}-${month}-${day}`
  }

  const optionOneLabel = `OPTION 1.  I want the D. ${abnInformation[0]?.D} listed above. You may ask to be paid now, but I also want Medicare billed for an official decision on payment, which is sent to me on a Medicare Summary Notice (MSN). I understand that if Medicare doesn’t pay, I am responsible for payment, but I can appeal to Medicare by following the directions on the MSN. If Medicare does pay, you will refund any payments I made to you, less co-pays or deductibles.`
  const optionTwoLabel = `OPTION 2.  I want the D. ${abnInformation[0]?.D} listed above, but do not bill Medicare. You may ask to be paid now as I am responsible for payment. I cannot appeal if Medicare is not billed.`
  const optionThreeLabel = `OPTION 3.  I don’t want the D. ${abnInformation[0]?.D} listed above. I understand with this choice I am not responsible for payment, and I cannot appeal to see if Medicare would pay.`

  React.useEffect( () => {
    if ( !hash ) return navigate( `/` )

    getAbnInfo( hash ).then( ( res: abnResponse ) => {
      if ( res?.meta?.status === `OK` && res?.data ) {
        return setAbnInformation( res.data )
      }

      return navigate( `/` )
    })
      .catch( error => {
        reportToSentry( new Error( `there was an error getting abn info`, {
          cause: error
        }) )

        return navigate( `/` )
      })
  }, [] )

  const handleCheckboxChange = ( e: { target: { name: string } }) => {
    if ( error.length > 0 ) setError( `` )
    if ( e.target.name === optionChecked.option ) {
      return setOptionChecked({
        option: ``
      })
    } else {
      return setOptionChecked({
        option:  e.target.name
      })
    }
  }

  const handleOnChange = ( e : React.ChangeEvent<HTMLInputElement> ) => {
    const { value } = e.target
    setSignedName( value.trimStart() )
  }

  const handleBlur = () => {
    if ( error.length > 0 ) setError( `` )
  }

  const handleSubmit = () => {


    if ( optionChecked.option === `` ) {

      return setError( `Must choose a option` )
    }

    if ( signedName.length === 0 ) {

      return setError( `Please sign` )
    }

    if ( !Validators.signature( signedName ) ) return setError( `Enter a valid signature. Alphabet characters, spaces, dashes and apostrophes are allowed. Must be more than 4 letters` )

    setIsLoading( true )
    const submitBody = {
      patient_hash: hash,
      sign_date: getInitialSignedDate(),
      signed_name: signedName,
      signature: new Date().toISOString(),
      option_chosen: optionChecked.option
    }

    submitAbn( submitBody ).then( res => {
      if ( res.meta.status === `OK` ) {

        return navigate( `/thank-you` )
      } else {
        return reportToSentry( new Error( `The response for the digital abn was not ok.` ), {
          cause: res
        })
      }
    })
      .catch( error => {
        return reportToSentry( new Error( `There was an error submitting the digital ABN.` ), {
          cause: error
        })
      })
  }

  if ( abnInformation.length === 0 ) return <LoadingSpinner />

  return (
    <div className="my-8 p-4 md:max-w-5xl md:ml-[230px] md:pr-4 lg:ml-[360px]">
      <div className="flex items-end justify-around">
        <div>
          <p className="font-bold">{`A. Notifier: ${abnInformation[0]?.A}`}</p>
          <p className="font-bold">{`B. Patient Name: ${abnInformation[0]?.B}`}</p>
        </div>
        <div>
          <p className="font-bold ">{`C. Identification Number: ${abnInformation[0]?.C}`}</p>
        </div>
      </div>
      <hr className="border-2 border-black my-2" />
      <h1 className="text-3xl font-bold text-center mb-2">{`Advance Beneficiary Notice of Non-coverage (ABN)`}</h1>
      <p>
        <span className="font-extrabold">{`NOTE:  `}</span>
        {`If Medicare doesn’t pay for `}
        <span className="font-extrabold underline">{`D.${abnInformation[0]?.D}`}</span>
        {` below, you may have to pay.`}
      </p>
      <p>
        {`Medicare does not pay for everything, even some care that you or your health care provider have good reason to think you need.  We expect Medicare may not pay for the `}
        <span className="font-extrabold underline">{`D.${abnInformation[0]?.D}`}</span>
        {` below.`}
      </p>
      <table className="border border-black mx-auto my-4">
        <tr>
          <td className="border border-black py-4 px-2 bg-gray-200 font-bold">{`D.`}</td>
          <td className="border border-black py-4 px-2 bg-gray-200 font-bold">{`E. Reason Medicare might not pay`}</td>
          <td className="border border-black py-4 px-2 bg-gray-200 font-bold">{`F. Estimated Cost`}</td>
        </tr>
        <tr>
          <td className="border border-black py-4 px-2">{`${abnInformation[0]?.D}`}</td>
          <td className="border border-black py-4 px-2">{`${abnInformation[0]?.E}`}</td>
          <td className="border border-black py-4 px-2">{`${abnInformation[0]?.F}`}</td>
        </tr>
      </table>
      <p className="text-lg font-bold">{`WHAT YOU NEED TO DO NOW:`}</p>
      <ul className="list-disc ml-4">
        <li>{`Read this notice, so you can make an informed decision about your care.`}</li>
        <li>{`Ask us any questions that you may have after you finish reading.`}</li>
        <li>{`Choose an option below about whether to receive the `}
          <span className="font-extrabold underline">{`D.${abnInformation[0]?.D}`}</span>
          {` listed above.`}</li>
        <span>{`Note: If you choose Option 1 or 2, we may help you to use any other insurance that you might have, but Medicare cannot require us to do this.`}</span>
      </ul>
      <p className="border-black rounded-xl bg-yellow-300 py-4 px-2 shadow-lg md:absolute md:left-4 md:mt-16 border md:w-48 lg:left-40">{`Select an option by clicking in the box beside the option you would like to choose.`}</p>
      <table className={`border ${error === `Must choose a option` ? `border-error border-4` : `border-black`} mx-auto my-4`}>
        <tr>
          <td className="border border-black py-4 px-2 bg-gray-200 font-bold">{`G. Options: Check only one box.  We cannot choose a box for you.`}</td>
        </tr>
        <tr>
          <td className="py-4 px-2">
            <CheckboxInput
              name={`1`} value={optionChecked.option === `1`} onChange={handleCheckboxChange}
              label={optionOneLabel} id={`1`} reference={undefined}
              labelClassName={`font-normal`}
            />
            <CheckboxInput
              name={`2`} value={optionChecked.option === `2`} onChange={handleCheckboxChange}
              label={optionTwoLabel} id={`2`} reference={undefined}
              labelClassName={`font-normal`}
            />
            <CheckboxInput
              name={`3`} value={optionChecked.option === `3`} onChange={handleCheckboxChange}
              label={optionThreeLabel} id={`3`} reference={undefined}
              labelClassName={`font-normal`}
            />
          </td>
        </tr>
      </table>
      <h2 className="text-2xl mb-4 font-bold">{`H. Additional Information:`}</h2>
      {
        abnInformation[0]?.H &&
        <div>
          <p>{`${abnInformation[0]?.H}`}</p>
        </div>
      }
      <p>{`This notice gives our opinion, not an official Medicare decision. If you have other questions on this notice or Medicare billing, call 1-800-MEDICARE (1-800-633-4227/TTY: 1-877-486-2048). Signing below means that you have received and understand this notice. You also receive a copy.`}</p>
      <p className="border border-black rounded-xl bg-yellow-300 py-4 px-2 mt-8 shadow-lg md:absolute md:left-4 md:-mt-2 md:w-48 lg:left-40">{`Add your signature by typing your full name into the box.`}</p>
      <div className={`flex justify-around border ${error === `Please sign` ? `border-error border-4` : `border-black`} mt-2`}>
        <div className="w-7/12">
          <TextInput
            name={`signed_name`} value={signedName} label={`I. Signature:`}
            validator={{
              function: Validators.signature,
              failureMessage: `Enter a valid signature. Alphabet characters, spaces, dashes and apostrophes are allowed. Must be more than 4 letters`
            }}
            reference={undefined} onChange={handleOnChange}
            onBlur={handleBlur} formatter={undefined} id={undefined}
          />
        </div>
        <div className="w-1/3">
          <p className="font-bold">{`J. Date:`}</p>
          <p>{formatDate( Date() )}</p>
        </div>
      </div>
      <p className="font-bold my-2">{`CMS does not discriminate in its programs and activities. To request this publication in an alternative format, please call: 1-800-MEDICARE or email: AltFormatRequest@cms.hhs.gov`}</p>
      <p className="text-sm">{`According to the Paperwork Reduction Act of 1995, no persons are required to respond to a collection of information unless it displays a valid OMB control number. The valid OMB control number for this information collection is 0938-0566. The time required to complete this information collection is estimated to average 7 minutes per response, including the time to review instructions, search existing data resources, gather the data needed, and complete and review the information collection. If you have comments concerning the accuracy of the time estimate or suggestions for improving this form, please write to: CMS, 7500 Security Boulevard, Attn: PRA Reports Clearance Officer, Baltimore, Maryland 21244-1850.`}</p>
      <hr className="border-2 border-black my-2" />
      <div className="flex justify-around">
        <div>
          <p>{`Form CMS-R-131 (Exp. 06/30/2023) `}</p>
        </div>
        <div>
          <p>{`Form Approved OMB No. 0938-0566`}</p>
        </div>
      </div>
      <div className="text-center mx-auto w-1/3 mb-4">
        {
          error &&
        <ErrorField message={error} />
        }
        <button
          type="button" onClick={handleSubmit} className="btn-primary"
          disabled={isLoading}
        >{`Submit`}</button>
      </div>
    </div>
  )
}
