import React from 'react'
import { YouTubeFacade } from 'components/video'
import PropTypes from 'prop-types'
import { reportToSentry } from 'utils/reportToSentry'


function CMSVideo({identifier, failSafe, customProps, srcItem, containerStyling=``, setDisplayTooltip, displayTooltips}) {

  const [ splitSearch, setSplitSearch ] = React.useState( false )
  const splitKey = `<div data-content-type="html" data-appearance="default" data-element="main">`
  const videoContentArray = srcItem.split( splitKey )
  videoContentArray.shift()

  React.useEffect( () => {
    if ( srcItem.includes( splitKey ) ) {
      setSplitSearch( true )
    } else {
      reportToSentry( new Error( `CMS Video Error: Magento CMS Pre-populated Tags Have Changed` ), {
        srcItem
      })
    }
  }, [] )

  const copyToClipboard = () => {
    if ( displayTooltips ) {
      navigator.clipboard.writeText( identifier )
    }
  }

  return (
    <div
      className={containerStyling}
      onMouseEnter={() => { return setDisplayTooltip( true ) }}
      onMouseLeave={() => { return setDisplayTooltip( false ) }}
      onClick={copyToClipboard}
    >
      <YouTubeFacade
        imgSrc={splitSearch ? videoContentArray[0].replace( /(<([^>]+)>)/gi, `` ).replace( process.env.REACT_APP_MAGENTO_2_BASE_URL, process.env.REACT_APP_CMS_IMAGE_URL ) : failSafe?.image}
        videoID={splitSearch ? videoContentArray[1].replace( /(<([^>]+)>)/gi, `` ) : failSafe?.videoID}
        {...customProps}
      />
    </div>
  )
}

CMSVideo.propTypes = {
  identifier: PropTypes.string,
  styling: PropTypes.string,
  customProps: PropTypes.object,
  containerStyling: PropTypes.string,
  srcItem: PropTypes.string,
  setDisplayTooltip: PropTypes.func,
  displayTooltips: PropTypes.bool,
  failSafe: PropTypes.shape({
    image: PropTypes.string,
    videoID: PropTypes.string
  })
}

export default CMSVideo
