import { Modal } from 'components/modal'
import React from 'react'
import HealthTapModalForm from './HealthTapModalForm'

type HealthTapModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess?: () => void;
  patientId: number | null;
}

export default function HealthTapModal({ open, setOpen, onSuccess, patientId } : HealthTapModalProps ) : JSX.Element {
  return (
    <Modal
      isOpen={open}
      onClose={() => { setOpen( false ) }}
      initialClose
      defaultCloseButton={false}
      closeOnFocusChange={false}
    >
      <HealthTapModalForm onSuccess={onSuccess} patientId={patientId} />
    </Modal>
  )
}