import React from 'react'
import { TeamMember } from '../types'

type ContentProps = {
   salesTeam?: TeamMember[];
}

const TerritoryTeamSubList = ( props: ContentProps ) => {
  return (

    <>
      {
        props?.salesTeam?.length && props.salesTeam.map( ( salesTeamMember, index ) => {
          return (
            <div
              className="flex sm:flex-col lg:flex-row sm:items-center lg:items-start gap-3 sm:mb-12 lg:mb-16"
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              <div className="flex sm:justify-center lg:justify-start">
                <img
                  className="object-contain max-w-none max-h-none"
                  src={salesTeamMember.img}
                  alt={salesTeamMember.name}
                  height="215"
                  width="215"
                />
              </div>

              <div>
                <p className="sm:text-center lg:text-left"><b className="text-lg">{salesTeamMember.name}</b></p>
                <p className="mb-2 sm:text-center lg:text-left font-light text-xl">{salesTeamMember.role}</p>
                <p className="mb-2">
                  {salesTeamMember.desc}
                  {salesTeamMember.email &&
                  <a className="a" href={`mailto:${salesTeamMember.email}`}>
                    {salesTeamMember.email}
                  </a>
                  }
                </p>
              </div>

            </div>
          )
        })
      }
    </>

  )
}

export default TerritoryTeamSubList