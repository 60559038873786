import { create } from 'zustand'

type Size = {
  size: string
  dme_id: number
}

type Mask = {
  mask: string
  includes_headgear: boolean
  sizesAvailable: Size[]
}

type Brand = {
  brand: string
  masksAvailable: Mask[]
}

type SleepMaskCategory = {
  sleep_mask_category: string
  brandsAvailable: Brand[]
}

type MachineData = {
  brand: string;
  machineNames: { name: string }[];
}

type BaseEquipmentState = {
  maskData: SleepMaskCategory[]
  machineData: MachineData[]
  isLoading: boolean
  errorMessage: string | null
  setMaskData: ( _data: SleepMaskCategory[] ) => void
  setMachineData: ( _data: MachineData[] ) => void
  setIsLoading: ( _loading: boolean ) => void
  setErrorMessage: ( _message: string | null ) => void
}

export const useBaseEquipmentStore = create<BaseEquipmentState>( ( set ) => ({
  maskData: [],
  machineData: [],
  isLoading: false,
  errorMessage: null,
  setMaskData: ( data ) => set({
    maskData: data
  }),
  setMachineData: ( data ) => set({
    machineData: data
  }),
  setIsLoading: ( loading ) => set({
    isLoading: loading
  }),
  setErrorMessage: ( message ) => set({
    errorMessage: message
  })
}) )