import React from 'react'
import TrackerStepIcon from '../../TrackerStepIcon'
import { cpapMachineWhite } from 'resources/images'
import { EligibleForNewMachineCopy } from '../constants'
import StepContentRenderer from '../../StepContentRenderer'

type EligibleForNewMachineStepProps = {
    stepStatus: boolean;
    isCurrentStep?: boolean;
 }

function EligibleForNewMachineStep({ stepStatus, isCurrentStep } : EligibleForNewMachineStepProps ) : JSX.Element {
  return (
    <div className="flex">
      <TrackerStepIcon imageSrc={cpapMachineWhite} stepStatus={stepStatus} isCurrentStep={isCurrentStep} />
      <StepContentRenderer
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
        stepCopy={EligibleForNewMachineCopy}
      />
    </div>
  )
}

export default EligibleForNewMachineStep