import React from 'react'
import { Builder } from '@builder.io/react'
import { ImageBlock } from './components/ImageBlock'
import { AsSeenInBlockTypes } from './types'

export const AsSeenInBlock = ({ headerText, images }: AsSeenInBlockTypes ): JSX.Element => {
  return (
    <>
      {images && (
        <div className="bg-[#e1e5ea66] sm:pt-5 md:pt-12 sm:pb-12 md:pb-[68px] px-4 mx-auto">
          <div className="lg:w-10/12 xl:w-8/12 mx-auto">
            {headerText && (
              <h2 className="mx-auto text-center font-source-serif-4 text-aeroflowNavy sm:text-xl md:text-5xl sm:pb-8 md:pb-[50px]">
                {headerText}
              </h2>
            )}
            <div className="flex sm:flex-wrap md:flex-nowrap items-center justify-center sm:gap-10 md:gap-20">
              {images.map( ( img ) => (
                <ImageBlock key={img.title} {...img} />
              )
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

Builder.registerComponent( AsSeenInBlock, {
  name: `AsSeenInBlock`,
  inputs: [
    {
      name: `headerText`,
      type: `text`,
      defaultValue: `As Seen In`
    },
    {
      name: `images`,
      type: `list`,
      subFields: [
        {
          name: `imgSrc`,
          type: `file`,
          allowedFileTypes: [ `jpeg`, `jpg`, `png`, `svg`, `webp` ]
        },
        {
          name: `hoverImgSrc`,
          type: `file`,
          allowedFileTypes: [ `jpeg`, `jpg`, `png`, `svg`, `webp` ]
        },
        {
          name: `title`,
          type: `text`
        },
        {
          name: `href`,
          type: `url`
        }
      ]
    }
  ]
})

export default AsSeenInBlock