import React from 'react'
import { Tile } from 'components/tile'
import StepQueueRenderer from '../StepQueueRenderer'
import { ReferralReceivedStep, OnlineMaskFitStep, InsurancePriorAuthStep, InsuranceInformationStep, ActionNeededStep, OrderShippedStep, ClinicalEduApptStep } from './steps'
import LoadingSpinner from 'components/LoadingSpinner'
import OrderCanceledStep from '../sharedSteps/OrderCanceledStep'

type NewCpapType = {
    referral_received: boolean;
    online_mask_fit_needed: boolean;
    online_mask_fit_url?: string;
    documentation_on_file: boolean;
    pa_needed: boolean;
    pa_on_file: boolean;
    information_and_insurance_verified: boolean;
    action_needed: boolean;
    order_shipped: boolean;
    tracking_number?: string;
    tracking_link?: string;
    education_appt_status: string;
    education_appt_date?: string;
    ship_date?: string;
};

type NewCpapTrackerProps = {
  newCpapTrackerDetails: NewCpapType;
}

function NewCpapTracker({ newCpapTrackerDetails }: NewCpapTrackerProps ): JSX.Element {
  const hasShipped = Boolean( newCpapTrackerDetails?.order_shipped || newCpapTrackerDetails?.tracking_number || newCpapTrackerDetails?.tracking_link )
  const isOrderCanceled = newCpapTrackerDetails?.tracking_link === `Order Cancelled`

  return (
    <Tile headerContent="New CPAP Machine & Supplies" id="new-cpap-tracker" className="account-tracker">
      {
        !newCpapTrackerDetails ? <LoadingSpinner /> :
          ( isOrderCanceled ? <OrderCanceledStep /> :
            <StepQueueRenderer dynamicStepStatus>
              <ReferralReceivedStep stepStatus={newCpapTrackerDetails?.referral_received} />
              <InsuranceInformationStep stepStatus={newCpapTrackerDetails?.information_and_insurance_verified} />
              {newCpapTrackerDetails?.pa_needed &&
              /* if prior auth is not needed(false), do not to show this step. If order is shipped, show this step as complete */
              <InsurancePriorAuthStep stepStatus={hasShipped || newCpapTrackerDetails?.pa_on_file} />
              }
              <OnlineMaskFitStep stepStatus={!newCpapTrackerDetails?.online_mask_fit_needed} sovasageUrl={newCpapTrackerDetails?.online_mask_fit_url || ``} />
              <ActionNeededStep stepStatus={!newCpapTrackerDetails?.action_needed} />
              <OrderShippedStep
                stepStatus={hasShipped}
                trackingNumber={newCpapTrackerDetails?.tracking_number || ``}
                trackingLink={newCpapTrackerDetails?.tracking_link || ``}
                shipDate={newCpapTrackerDetails?.ship_date || ``}
              />
              <ClinicalEduApptStep
                stepStatus={newCpapTrackerDetails?.education_appt_status.toLocaleLowerCase() === `complete`}
                apptStatus={newCpapTrackerDetails?.education_appt_status.toLocaleLowerCase()}
                apptDate={newCpapTrackerDetails?.education_appt_date}
              />
            </StepQueueRenderer>
          )
      }
    </Tile>
  )
}

export default NewCpapTracker