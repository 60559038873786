import { getAuthHeader } from "utils/auth/helpers"
import { addMagentoEnvAuthHeaders } from "utils/magentoEnvAuthHeaders"

export const getPatientRouting = async () => {
  const data = await fetch( `${process.env.REACT_APP_SNL_URL}/patient-routing`, {
    headers: {
      "Content-Type": `application/json`,
      ...addMagentoEnvAuthHeaders(),
      'Authorization': getAuthHeader()
    }
  })

  return await data.json()
}