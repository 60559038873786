import { useState, useEffect } from 'react'
import { reportToSentry } from 'utils/reportToSentry'
import RoutingResponse from 'types/routingResponse'
import { getPatientRouting } from 'components/account/utils'

export default function usePatientRouting() : { data: RoutingResponse | null, loading: boolean, error: string } {

  const [ data, setData ] = useState<RoutingResponse | null>( null )
  const [ loading, setLoading ] = useState<boolean>( true )
  const [ error, setError ] = useState<string>( `` )

  useEffect( () => {
    getPatientRouting()
      .then( ( data ) => {
        setLoading( false )
        if ( data?.meta?.status === `OK` && !data?.errors?.length ) return setData( data.data )

        reportToSentry( new Error( `My Account: Error getting patient routing` ), {
          response: JSON.stringify( data )
        })

        return setError( `We encountered an issue attempting to get your resupply options and AOB invoices...` )
      })
      .catch( error => {
        setLoading( false )
        reportToSentry( new Error( `My Account: Error getting patient routing`, {
          cause: error
        }) )

        return setError( `We encountered an issue attempting to get your resupply options and AOB invoices...` )
      })
  }, [] )

  return {
    data,
    loading,
    error
  }
}