export const referralReceivedCopy = {
  notCompletedStepHeading: `Referral Needed`,
  completedStepHeading: `Referral Received`,
  completedStepDetails: `Your referral has been received!`
}

export const prescriptionNeededCopy = {
  notCompletedStepHeading: `Obtain Documentation from MD`,
  currentStepDetails: `Our representatives are working to obtain all medical documentation that is required by your insurance provider.`,
  stepToolTip: `Your insurance provider requires that we obtain certain medical documentation before we can ship out your order.`,
  completedStepHeading: `Documentation from MD Received`
}

export const insurancePriorAuthCopy = {
  notCompletedStepHeading: `Prior Authorization Needed`,
  currentStepDetails: `Your insurance provider requires prior authorization before we can ship your order. Our representatives are working to obtain this. Please reference your email for important updates.`,
  stepToolTip: `Your insurance provider requires prior authorization before we can ship out your order.`,
  completedStepHeading: `Prior Authorization Obtained`
}

export const insuranceInformationCopy = {
  notCompletedStepHeading: `Insurance Information Not Verified`,
  currentStepDetails: `We're having an issue verifying your insurance information. Make sure your name and member ID matches your insurance card. If recently updated in our system, please allow up to 24 hours for this update to reflect.`,
  stepLinkText: `View/change your insurance information`,
  stepLinkUrl: `#my-account-insurance`,
  stepToolTip: `We must verify that your insurance information is correct and that we are in network with your provider before we can move forward.`,
  completedStepHeading: `Insurance Information Verified`
}

export const clinicalEduApptCopy = {
  stepHeaderIncomplete: `Clinical Education Appointment Required`,
  stepHeaderComplete: `Clinical Education Appointment Completed`,
  stepToolTip: `It is important to attend your clincial setup appointment as this leads to a higher success rate for new patients who are starting sleep therapy for the first time.`,
  apptIsScheduledDetails: `Attend your clincial setup appointment where you will learn how to use your new equipment from one of our licensed clinicians.`,
  apptNoShowDetails: `It looks like we missed you! Please refer to your email which contains a link to reschedule your appointment or call us at the number below.
    This appointment is important and will provide you with instructions on how to properly use your new PAP equipment.`,
  apptCancelledDetails: `Your clinical setup appointment has been cancelled. Please call us at the number below to reschedule your setup appointment with one of our licensed clinicians.
    This appointment is important and will provide you with instructions on how to properly use your new PAP equipment.`
}