import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

type HealthTapStore = {
    healthtapSelected: boolean | null,
    setHealthtapSelected: ( value: boolean ) => void
}

export const useHealthTapStore = create<HealthTapStore>()(
  persist(
    ( set ) => ({
      healthtapSelected: null, // prevent radio button from being checked on initial render
      setHealthtapSelected: ( value: boolean ) => set({
        healthtapSelected: value
      })
    }),
    {
      name: `healthTap-store`,
      storage: createJSONStorage( () => sessionStorage )
    }
  )
)