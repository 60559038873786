import React from 'react'

type PhoneNumberProps = {
  containerClassName?: string
  linkClassName?: string
}

const PhoneNumber = ({ containerClassName, linkClassName } : PhoneNumberProps ) => {
  return (
    <div className={containerClassName ?? `flex sm:hidden lg-wide:block`}>
      <a
        className={linkClassName ?? `mr-3 sm:text-xs lg:text-base whitespace-nowrap`}
        href="tel:+18004805491" type="tel"
      >
        {`1 (800) 480-5491`}
      </a>
    </div>
  )
}

export default PhoneNumber