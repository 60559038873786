import React from 'react'
import PropTypes from 'prop-types'
import { blogLadyImg, faqManImg } from 'resources/images'
import { Button } from 'components/Button'

export const BlockWrapper = ({ imgSrc, title, description, callToActionLink, calltoActionValue = `LEARN MORE`}) => {

  return (
    <div className="grid grid-cols-1 grid-rows-2 p-1 mx-8 mt-12 justify-items-center items-center">
      <img
        src={imgSrc}
        alt={`${title} image`}
        width={966}
        loading="lazy"
        height={483}
      />
      <div className="text-center mt-1">
        <h3>{title}</h3>
        <p className="my-6 h-28 md:w-96">{description}</p>
        <div className="w-48 mx-auto">
          <Button
            className="btn-primary"
            onClick={() => {
              return window.location.href = callToActionLink
            }}
          >
            {calltoActionValue}
          </Button>
        </div>
      </div>
    </div>
  )
}

BlockWrapper.propTypes = {
  imgSrc: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  callToActionLink: PropTypes.string,
  calltoActionValue: PropTypes.string
}


export const FaqBlogBlockBackup = () => {
  return (
    <div className="flex sm:flex-col md:flex-row justify-evenly w-full max-w-full">
      <BlockWrapper
        imgSrc={blogLadyImg}
        title={`Aeroflow Blog`}
        description={`Aeroflow Sleep wants you to be informed about sleep apnea. Stay up-to-date on the latest sleep news and information, with blog content written by our very own team of professionals!`}
        callToActionLink={`${process.env.REACT_APP_MAGENTO_2_BASE_URL}/blog`}
        calltoActionValue={`Stay Informed`}
      />
      <BlockWrapper
        imgSrc={faqManImg}
        title={`Frequently Asked Questions`}
        description={`Remember, there are no stupid questions. Please feel free to browse these Frequently Asked Questions sent in by sleep apnea patients just like you.`}
        callToActionLink={`${process.env.REACT_APP_MAGENTO_2_BASE_URL}/frequently-asked-questions`}
        calltoActionValue={`Browse FAQs`}
      />
    </div>
  )
}

export default FaqBlogBlockBackup