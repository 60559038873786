import { formatDate } from './utils'

type ProductProps = {
  productName: string, // This may end up actually being the description
  imgSrc?: string,
  productQty: number,
  shipDate?: string,
  nextOrderDate?: string,
  eligibleDate?: string,
  prescriptionNeeded?: boolean
}

export default function CommerceDetailBlock({productName, imgSrc, productQty, shipDate, nextOrderDate, eligibleDate, prescriptionNeeded}: ProductProps ) : JSX.Element {

  return (
    <div className="flex min-w-xs max-w-3xl">
      {imgSrc && ( <div className="mr-4 min-w-max">
        <img
          src={imgSrc} alt={productName} width={70}
        />
      </div>
      )}
      <div>
        <p>{productName}</p>
        <p className="font-light text-sm">{`${nextOrderDate ? `Next Order Date: ${formatDate( nextOrderDate )}` : ``}`}</p>
        <p className="font-light text-sm">{`${shipDate ? `Ships: ${formatDate( shipDate )}` : ``}`}</p>
        <p className="font-light text-sm">{`Quantity: ${productQty}`}</p>
        <p className="font-light text-sm">{`${eligibleDate ? `Eligible to ship on: ${formatDate( eligibleDate )}` : ``}`}</p>
        <p className="font-light text-sm">{`${prescriptionNeeded ? `& when prescription received` : ``}`}</p>
      </div>
    </div>
  )
}