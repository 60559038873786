import PricingEstimateBlockWrapper from '../PricingEstimateBlockWrapper'
import PropTypes from 'prop-types'
import { icoCaretDownAob, icoInfoAob } from 'resources/images'
import TotalCost from './TotalCost'
import { capitalize } from 'lodash'
import { useReactiveVar } from '@apollo/client'
import { resupplyAOBData } from 'apollo'
import { useGetAobSegmentedCopy } from 'modules/aobPayments/hooks/useGetAobSegmentedCopy'

function PaymentSummary({ invoice }) {

  const nameFromResupply = useReactiveVar( resupplyAOBData )?.firstName
  const name = invoice?.first_name ? `${capitalize( invoice.first_name )}, ` : nameFromResupply ? `${capitalize( nameFromResupply )}, ` : ``

  const segmentedPaymentSummaryCopy = useGetAobSegmentedCopy()

  return (
    <PricingEstimateBlockWrapper>
      <div className="flex flex-col items-center justify-center gap-4 mb-2 mt-10">
        <h1 className="sm:text-3xl md:text-[42px] sm:leading-9 lg:leading-[47px] font-inter tracking-wide font-semibold w-10/12 md:w-8/12 text-center mt-16 text-[#0057A8]">{`${name}Here is your Price Estimate`}</h1>
        <p className="sm:text-sm md:text-lg w-9/12 text-center font-light">
          {`Click the down arrow`}
          <img
            src={icoCaretDownAob}
            alt="ico-caret-down"
            className="inline px-2"
            width={35}
          />
          {`or the information icon`}
          <img
            src={icoInfoAob}
            alt="ico-info"
            className="inline px-2"
            width={35}
          />
          {`next to any item for more information`}
          {segmentedPaymentSummaryCopy?.PriceEstimateDetails && (
            <segmentedPaymentSummaryCopy.PriceEstimateDetails />
          )}
        </p>
      </div>
      <TotalCost invoice={invoice} />
    </PricingEstimateBlockWrapper>
  )
}

PaymentSummary.propTypes = {
  invoice: PropTypes.object
}

export default PaymentSummary