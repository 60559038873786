import * as React from 'react'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'

/**
 * Renders children elements as Pages that can be clicked through with next and prev
 * This is an alternative to having the component controller handled by the main parent, and
 * passing a 'display: hidden' based on a string state on each element in the page.
 * I feel like this pattern may come up in the future (eg. An alternative to the qualify-form page controller)
 * */
const PageNavigator = ( props ) => {

  const [ currentPageIndex, setCurrentPageIndex ] = React.useState( 0 )

  const navigate = useNavigate()
  const location = useLocation()

  React.useEffect( () => {
    // Ensures that the route in the url will correspond to the correct page
    const initialIndex = props.pageNames.findIndex( ( pageName ) => { return pageName === location.pathname })
    setCurrentPageIndex( initialIndex === -1 ? 0 : initialIndex )
    if ( initialIndex === -1 ) {
      navigate( `${props.pageNames[0]}${location.search}` )
    }
  }, [ location, props.pageNames ] )

  React.useEffect( () => {
    props.onChange && props.onChange( currentPageIndex )
  }, [ currentPageIndex ] )

  function _onNext() {
    const nextIndex = currentPageIndex + 1
    const isLast = ( React.Children.count( props.children ) === nextIndex )
    if ( isLast ) return
    setCurrentPageIndex( nextIndex )
    navigate( `${props.pageNames[nextIndex]}${location.search}` )
  }

  function _onPrev() {
    if ( currentPageIndex === 0 ) return
    const prevIndex = currentPageIndex - 1
    setCurrentPageIndex( prevIndex )
    navigate( `${props.pageNames[ prevIndex ]}${location.search}` )
  }

  function _onSet( pageName ) {
    const pageIndex = props.pageNames.findIndex( ( page ) => { return page === pageName })
    if ( pageIndex != -1 ) {
      setCurrentPageIndex( pageIndex )
    }
    navigate( `${props.pageNames[ pageIndex ]}${location.search}` )
  }

  const child = React.Children.toArray( props.children )[currentPageIndex]

  // render each child as a unique page
  return (
    <div className={props.pageClassName ?? ``}>
      {React.cloneElement( child, { // These props will get passed down to every child element
        nextPage: _onNext, // Allows for child to go to nextPage
        prevPage: _onPrev, // Allows for child to go to prevPage
        setPage: _onSet, // Allows for a redirect to a specific pageName
        ...props.childSharedProps,
        ...child.props
      })}
    </div>
  )
}

PageNavigator.propTypes = {
  children: PropTypes.node,
  pageNames: PropTypes.arrayOf( PropTypes.string ).isRequired, // names for the pages, will use ReactRouter to dynamically update the routes
  pageClassName: PropTypes.string,
  childSharedProps: PropTypes.object, // global props to be passed down to all children
  onChange: PropTypes.func // callback that will fire when the route changes
}

export default PageNavigator