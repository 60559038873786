import React from 'react'
import PropTypes from 'prop-types'
import { SleepLinearGradient } from 'components/sleepLinearGradient'

export const ColWrapper = ({children}) => {
  return (
    <div className="pt-8">
      {
        children
      }
    </div>
  )
}

ColWrapper.propTypes = {
  children: PropTypes.node
}

export const ColHeader = ({ children }) => {
  return (
    <>
      <p className="lg:text-2xl pb-4 font-medium leading-8">
        {children}
      </p>
      <div className="pb-4">
        <SleepLinearGradient height={1} />
      </div>
    </>
  )
}

ColHeader.propTypes = {
  children: PropTypes.node
}


