import React, { useState } from 'react'
import AnimatedAlertBar from './AnimatedAlertBar'
import { getToken, useTokenStore } from 'stores/tokenStore'

const AlertBar = () => {
  const [ display, setDisplay ] = useState( Boolean( getToken() ) )

  const { customerToken } = useTokenStore()

  // do not display if users are not logged in
  if ( !customerToken ) return null

  return (
    <div className="relative overflow-hidden">
      {
        display &&
        <AnimatedAlertBar setDisplay={setDisplay} />
      }
    </div>
  )
}

export default AlertBar
