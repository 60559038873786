import { ProductResponse } from 'components/account/YourProducts/ProductType'
import { formatDate } from 'components/resupply2'

function Product({ item, interval, eligible = true } : { item: ProductResponse, interval: number | null | undefined, eligible?: boolean }) {
  return (
    <div className="flex gap-5 px-1">
      <div className="flex flex-col items-center justify-center">
        <div className="p-2 bg-white rounded-t border-t border-x border-hardGray h-[138px] w-[138px] relative flex">
          {
            eligible ?
              <div className="rounded-full sleep-gradient-lightBlueToLight absolute top-[4px] left-[4px] text-center text-white text-sm w-[46px] h-[46px] flex items-center justify-center">
                <span className="leading-none font-semibold block m-auto">{`Due Now`}</span>
              </div>
              :
              <div className="rounded bg-deepSleepBlue absolute top-[6px] left-[5px] text-center text-white text-xs w-[78px] h-[30px] flex items-center justify-center">
                <span className="leading-none font-semibold block m-auto">{`Available ${formatDate( item.eligible )}`}</span>
              </div>
          }

          <img
            height={130}
            width={130}
            src={item?.image?.url}
            alt={item?.image?.label}
            className="block m-auto"
          />
        </div>
        <div className="bg-deepSleepBlue rounded-b w-[138px]">
          <p className="text-white font-light px-2 text-xs text-center py-1.5 leading-[14.52px]">{`Ordered ${formatDate( item.last_ordered )}`}</p>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-lg">{item.description}</p>
        { interval && interval > 0 && <p className="font-light text-sm">{`Eligible through insurance every ${interval} months.`}</p> }
      </div>
    </div>
  )
}

export default Product