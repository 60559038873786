import React, { useState } from 'react'
import { Formatters, TextInput } from 'components/forms/components'
import { useDoctorStore } from '../hooks/state'
import ZipDisplay from './ZipDisplay'
import ManualDoctorForm from './ManualDoctorForm'
import useDoctorSearch from '../hooks/useDoctorSearch'
import DoctorResults from './DoctorResults'
import AnimatedHeightDiv from 'components/animatedHeightDiv/AnimatedHeightDiv'
import DoctorSearchActionBlock from './DoctorSearchActionBlock'
import DoctorInfo from '../types/DoctorInfo'
import SearchDoctorHeading from './SearchDoctorHeading'
import { getProductPricing } from '../../../utils/patientInfoApi'

type DoctorSearchProps = {
  setSearchActive: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess?: ( _doctor: DoctorInfo ) => void
  forceUpdate: boolean;
  headerStyle?: string;
  containerStyle?: string;
  isMyAccount?: boolean;
  doctorSearchStyle?: string;
  onHandleBack?: () => void;
  scrollIntoView: boolean;
  doctorSearchHeader?: string;
}

function DoctorSearch({ setSearchActive, onSuccess, forceUpdate, headerStyle, containerStyle, isMyAccount = false, onHandleBack, doctorSearchStyle, scrollIntoView, doctorSearchHeader } : DoctorSearchProps ) {
  const { doctor, setDoctor, doctorInput, setDoctorInput } = useDoctorStore()
  const [ zipDisplay, setZipDisplay ] = useState<boolean>( !doctorInput.zipCode )
  const [ manualDisplay, setManualDisplay ] = useState<boolean>( false )
  const [ submitLoading, setSubmitLoading ] = useState<boolean>( false )
  const { searchResults, searchLoading, searchError } = useDoctorSearch( doctorInput.name )

  // if zip code is not set, display zip code input
  if ( !doctorInput.zipCode && !zipDisplay ) setZipDisplay( true )

  const handleBack = () => {
    if ( onHandleBack ) onHandleBack()

    setDoctorInput( Object.assign({}, doctorInput, {
      name: ``
    }) )
    setSearchActive( false )
  }

  // smooth scroll to top polyfill for safari/ios
  const smoothScrollToTopPollyfill = () => {
    const scrollStep = -window.scrollY / ( 500 / 15 ) // 500 sets the duration of the scroll
    const scrollInterval = setInterval( () => {
      if ( window.scrollY !== 0 ) {
        window.scrollBy( 0, scrollStep )
      } else {
        clearInterval( scrollInterval )
      }
    }, 15 )
  }

  const handleSuccess = ( newDoctor: DoctorInfo ) => {
    setDoctor( newDoctor )
    // reset product pricing cache
    getProductPricing( true )
    // reset doctor name input
    setDoctorInput( Object.assign({}, doctorInput, {
      name: ``
    }) )
    if ( onSuccess ) onSuccess( newDoctor )
    handleBack()
    if ( !isMyAccount && scrollIntoView ) {
      smoothScrollToTopPollyfill()
    }
  }

  if ( manualDisplay ) return (
    <ManualDoctorForm
      setManualDisplay={setManualDisplay}
      handleSuccess={handleSuccess}
      currentDoctor={doctor}
      isMyAccount={isMyAccount}
    />
  )

  return (
    <div className={`flex flex-col w-full max-w-md mx-auto text-center ${doctorSearchStyle}`}>
      { !isMyAccount && <SearchDoctorHeading headerStyle={headerStyle} doctorSearchHeader={doctorSearchHeader} /> }
      {
        doctor &&
        <>
          <h1 className={`text-2xl font-light text-center mb-1`}>{`Current Doctor`}</h1>
          <div className="flex flex-col items-center mb-5">
            <p className="text-xl capitalize mb-2">{`${doctor?.firstName?.toLowerCase()} ${doctor?.lastName?.toLowerCase()}`}</p>
            <p className="text-lg font-light">{Formatters.phone( doctor?.phone )}</p>
          </div>
        </>
      }
      <div className={containerStyle}>
        <AnimatedHeightDiv
          display
          heightDependencies={[ zipDisplay, searchResults, doctor, submitLoading ]}
        >
          <>
            {
              !zipDisplay &&
            <TextInput
              name="name"
              label="Enter Your Doctor's Name"
              value={doctorInput.name}
              disabled={zipDisplay}
              onChange={( e: React.ChangeEvent<HTMLInputElement> ) => {
                setDoctorInput( Object.assign({}, doctorInput, {
                  [e.target.name]: e.target.value
                }) )
              }}
            />
            }
            <ZipDisplay
              zipDisplay={zipDisplay}
              setZipDisplay={setZipDisplay}
            />
            <DoctorResults
              zipDisplay={zipDisplay}
              searchError={searchError}
              searchLoading={searchLoading}
              searchResults={searchResults}
              handleSuccess={handleSuccess}
              submitLoading={submitLoading}
              setSubmitLoading={setSubmitLoading}
            />
            <DoctorSearchActionBlock
              forceUpdate={forceUpdate}
              searchResults={searchResults}
              searchError={searchError}
              zipDisplay={zipDisplay}
              setManualDisplay={setManualDisplay}
              handleBack={handleBack}
              isMyAccount={isMyAccount}
            />
          </>
        </AnimatedHeightDiv>
      </div>

    </div>
  )
}

export default DoctorSearch