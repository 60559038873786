import React from 'react'
import ThreeStepsBlock from './ThreeStepsBlock'
import ImageStackBlock from './ImageStackBlock'
import CallToActionButton from '../CallToActionButton'
import CMSBlock from 'components/cmsBlock'
import QuestionsMessaging from 'components/questionsMessaging/QuestionsMessaging'
import { useTokenStore } from 'stores/tokenStore'

export const BlockOne = () => {

  const { customerToken } = useTokenStore()

  return (
    <div className="flex flex-row md:mx-20 sm:mx-2">

      {/* left col */}
      <div className="flex-2 flex items-center justify-center flex-col">
        <ThreeStepsBlock />
        {
          !customerToken ?
            <CallToActionButton
              to={`/qualify-through-insurance`}
              className="btn-primary"
            >
              <CMSBlock
                type="text"
                customTag="span"
                failSafe="Check Your Eligibility"
                identifier="react-block-one-button-text"
              />
            </CallToActionButton>
            :
            <QuestionsMessaging />
        }
      </div>
      <div className="sm:hidden md:block lg:flex-3 md:flex-2">

        <ImageStackBlock />
      </div>
      {/* right col */}

    </div>
  )
}

export default BlockOne
