import { useEffect, useState } from 'react'

export default function useOnScreen( ref ) {

  const [ enteredViewport, setEnteredViewport ] = useState( false )

  const viewportObserver = new IntersectionObserver(
    ( [ entry ] ) => {
      if ( entry.isIntersecting ) return setEnteredViewport( true )
    }
  )

  useEffect( () => {
    viewportObserver.observe( ref.current )

    return () => { viewportObserver.disconnect() }
  })

  return enteredViewport
}