import React, { useEffect, useState } from 'react'
import { builder, BuilderComponent } from '@builder.io/react'
import { reportToSentry } from 'utils/reportToSentry'
import HomePageSkeleton from '../../components/reactSkeleton/HomePageSkeleton'
import LegacyHomepage from './LegacyHomePage'

builder.init( `25b1a215ae9f430989a220ee58457f96` )

export const HomeScreen = () => {
  // useMedchatWidget( `dvKo2qy0JEq1fVRJ0YZ2Rg` )

  const [ homepage, setHomepage ] = useState( null )
  const [ isLoading, setIsLoading ] = useState( true )

  useEffect( () => {
    builder
      .get( `homepage` )
      .toPromise()
      .then( ( homepageData ) => {
        setHomepage( homepageData )
        setIsLoading( false )
      })
      .catch( ( error ) => {
        console.error( `Error fetching homepage data from builder.io:`, error )
        reportToSentry( new Error( `Failed to fetch homepage data from builder.io`, {
          cause: error
        }) )
        setIsLoading( false )
      })
  }, [] )

  if ( isLoading ) return <HomePageSkeleton />

  if ( homepage ) return <BuilderComponent model="homepage" content={homepage} />

  // fallback to legacy homepage
  if ( !isLoading && !homepage ) return <LegacyHomepage />
}

export default HomeScreen
