import React from 'react'
import PhoneNumber from '../headerBarV2/PhoneNumber'

function QuestionsMessaging({ mobileResponsive = false } : { mobileResponsive? : boolean }) {
  return (
    <div className={`px-4 text-center max-w-xs ${ mobileResponsive ? `sm:text-xs lg:text-base sm:hidden md:inline` : `inline` }`}>
      {`If you have a question about your account, call `}
      <PhoneNumber containerClassName="inline" linkClassName={`${ mobileResponsive && `sm:text-xs lg:text-base` } whitespace-nowrap`} />
    </div>
  )
}

export default QuestionsMessaging