const messages = {
  data: [
    {
      message: `Click here to claim your fresh CPAP supplies.`,
      color: `deepSleepBlue`,
      textColor: `white`,
      linkTo: `/resupply/claim`
    }
  ]
}

export default messages