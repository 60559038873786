import { addMagentoEnvAuthHeaders } from 'utils/magentoEnvAuthHeaders'

export type updateDoctorBody = {
  doctorFirstName: string;
  doctorLastName: string;
  doctorPhone: string;
  doctorNPI?: number;
  storeName?: string;
  profitCenterPK?: string;
}

export async function updateDoctor( body : updateDoctorBody, authorization : string ) {

  const awsResponse = await fetch( `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/doctor`, {
    method: `POST`,
    body: JSON.stringify( body ),
    headers: {
      ...addMagentoEnvAuthHeaders(),
      'Authorization': authorization
    }
  })

  return await awsResponse.json()
}

export const searchDoctorFetch = async ( location: string, name: string, distance: number ) => {
  const searchParams = new URLSearchParams({
    location,
    fullname: name,
    distance: distance.toString(),
    profit_center_pk: `5`
  })
  const response = await fetch( `${process.env.REACT_APP_DOCTOR_LOOKUP_V4_URL}/search?${searchParams.toString()}`, {
    headers: {
      "Content-Type": `application/json`
    }
  })

  return await response.json()
}

export const getInternalZip = async ( email: string, dob: string ) => {

  const response = await fetch( `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/deprecated-login`, {
    method: `POST`,
    headers: {
      'Content-Type': `application/json`
    },
    body: JSON.stringify({
      'dob': dob,
      'email': email,
      'profit_center_pk': `5`
    })
  })

  return await response.json()
}