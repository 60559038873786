import React from 'react'
import TrackerStepIcon from '../../TrackerStepIcon'
import { usageComplianceCopy } from '../constants'
import { personWithMaskWhite } from 'resources/images'
import StepContentRenderer from '../../StepContentRenderer'

type UsageComplianceStepProps = {
    stepStatus: boolean;
    isCurrentStep?: boolean;
 }

function UsageComplianceStep({ stepStatus, isCurrentStep } : UsageComplianceStepProps ) : JSX.Element {
  return (
    <div className="flex">
      <TrackerStepIcon
        imageSrc={personWithMaskWhite}
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
        stepLinkUrl={usageComplianceCopy.stepLinkUrl || ``}
        openLinkInNewTab={usageComplianceCopy.openLinkInNewTab}
      />
      <StepContentRenderer
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
        stepCopy={usageComplianceCopy}
      />
    </div>
  )
}

export default UsageComplianceStep