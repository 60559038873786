import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { revokeCustomerTokenMutation } from 'graphql/mutations'

import LoadingSpinner from 'components/LoadingSpinner'
import { useTokenStore } from 'stores/tokenStore'

const Logout = () => {

  const [ revokeCustomerToken, { client }] = useMutation( revokeCustomerTokenMutation, {
    errorPolicy: `ignore`
  })
  const navigate = useNavigate()

  const { clearCustomerToken } = useTokenStore()

  useEffect( () => {

    revokeCustomerToken()

    if ( client ) {
      sessionStorage.clear()
      client.clearStore()
      clearCustomerToken()
      navigate( `/` )
    }
  }, [ client ] )

  return (
    <div className="flex flex-col justify-center m-auto">
      <LoadingSpinner />
      <p className="text-lg text-center py-4">{`Logging out...`}</p>
    </div>
  )
}

export default Logout
