import { useAddressStore } from '../state'
import { startCase } from 'lodash'
import { CheckboxInput } from 'components/forms/components'
import { useAddressStyleStore } from '../style'

export type ViewAddressProps = {
  addressType?: `shipping` | `billing` | ``;
  hideAddressConfirm: boolean;
}

export default function ViewAddress({ addressType = ``, hideAddressConfirm} : ViewAddressProps ) : JSX.Element {

  const { address, setCurrentView } = useAddressStore()
  const [ isAddressComplete, setIsAddressComplete ] = useAddressStore( state => [ state.isAddressComplete, state.setIsAddressComplete ] )
  const { styleOverrides } = useAddressStyleStore()
  const { view : styles, ctaStyle } = styleOverrides

  const startEdit = () => {
    setIsAddressComplete( false )
    setCurrentView( `edit` )
  }

  const handleCheckboxChange = () => {
    setIsAddressComplete( !isAddressComplete )
  }

  const address1 = address?.street?.toLowerCase() ?? ``
  const address2 = address?.street2?.length ? `, ${address.street2.toLowerCase()}` : ``

  return (
    <>
      <p className={styles.textStyle}>{`${address?.firstName?.toLowerCase() ?? ``} ${address?.lastName?.toLowerCase() ?? ``}`}</p>
      <p className={styles.textStyle}>{`${address1}${address2}`}</p>
      <p className={styles.textStyle}>{`${address?.city?.toLowerCase() ?? ``}, ${address?.state ?? ``} ${address?.zipCode}`}</p>
      <div className="mt-3">
        <p className={ctaStyle} onClick={startEdit}>{`Edit ${startCase( addressType )} Address`}</p>
      </div>
      {hideAddressConfirm ? null : (
        <CheckboxInput
          name="current_shipping"
          wrapperClassName={styles.inputWrapperClassName}
          className={styles.inputClassName}
          labelClassName={styles.labelClassName}
          value={isAddressComplete}
          onChange={handleCheckboxChange}
          id="addressInput"
          reference={undefined}
          label={`I confirm that this is my current shipping address.`}
        />
      )}
    </>
  )
}