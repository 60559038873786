import { Tile } from '../../../components/tile'
import React from 'react'

export interface ErrorProps {
  title: string
  id: string
  fillInTheBlank: string
}

function Error({title, id, fillInTheBlank}: ErrorProps ): JSX.Element | null {
  return ( <Tile headerContent={title} id={id}>
    <p className="text-error">
      {`There was an error retrieving your ${fillInTheBlank} details...`}
    </p>
  </Tile> )
}

export default Error