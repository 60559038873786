import React from 'react'
import PropTypes from 'prop-types'

function CMSText({identifier, customTag, styling, customProps, textItem, containerStyling=``, setDisplayTooltip, displayTooltips}) {
  const CMSTag = customTag

  const copyToClipboard = () => {
    if ( displayTooltips ) {
      navigator.clipboard.writeText( identifier )
    }
  }

  return (
    <div
      className={`${containerStyling} ${displayTooltips && `cursor-pointer`}`}
      onMouseEnter={() => { return setDisplayTooltip( true ) }}
      onMouseLeave={() => { return setDisplayTooltip( false ) }}
      onClick={copyToClipboard}
    >
      <CMSTag className={styling} {...customProps}>
        {textItem.replace( /(<([^>]+)>)/gi, `` )}
      </CMSTag>
    </div>
  )
}

CMSText.propTypes = {
  identifier: PropTypes.string,
  styling: PropTypes.string,
  customProps: PropTypes.object,
  textItem: PropTypes.string,
  customTag: PropTypes.string,
  containerStyling: PropTypes.string,
  setDisplayTooltip: PropTypes.func,
  displayTooltips: PropTypes.bool
}

export default CMSText
