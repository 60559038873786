import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import CustomSkeletonTheme from 'components/reactSkeleton/SkeletonTheme'

type HeaderSkeletonProps = {
  isMobile: boolean
}

const HeaderSkeleton = ({ isMobile }: HeaderSkeletonProps ) => {
  return (
    <CustomSkeletonTheme>
      <header className="w-full">
        {isMobile ? (
          <MobileHeaderSkeleton />
        ) : (
          <DesktopHeaderSkeleton />
        )}
      </header>
    </CustomSkeletonTheme>
  )
}

const MobileHeaderSkeleton = () => (
  <div className="flex items-center justify-between p-4">
    <Skeleton width={40} height={40} />
    <Skeleton width={120} height={30} />
    <div className="flex items-center space-x-2">
      <Skeleton circle width={40} height={30} />
    </div>
  </div>
)

const DesktopHeaderSkeleton = () => (
  <div className="flex items-center justify-between p-4 h-[156px]">
    <Skeleton width={150} height={40} />
    <div className="flex-1 mx-8 text center">
      <Skeleton width={`100%`} height={60} />
    </div>
    <div className="flex items-center space-x-4">
      <Skeleton width={100} height={30} />
    </div>
  </div>
)

export default HeaderSkeleton