import React from 'react'

type StepQueueRendererProps = {
    children: JSX.Element | React.ReactNode;
    isCurrentStep?: boolean;
    dynamicStepStatus?: boolean;
}

// this component is used to sort steps based on their stepStatus when directed to do so by the parent component
// this is useful when the parent component needs to dynamically change the order of the steps
// when a step is complete, it will move up in the queue, when a step is incomplete, it will move down in the queue
// this component also identifies the current step and adds an optional isCurrentStep prop to the child component

function StepQueueRenderer({ children, dynamicStepStatus } : StepQueueRendererProps ) : JSX.Element {
  const handleOrderChildren = ( children: React.ReactNode | React.ReactNode[] ) => {
    // when dynamicStepStatus is enabled, sort children by stepStatus
    const renderedChildren = dynamicStepStatus ?
      Array.from( React.Children?.toArray( children ) ).sort( ( a, b ) => {
        const statusA = React.isValidElement( a ) ? a.props?.stepStatus || false : false
        const statusB = React.isValidElement( b ) ? b.props?.stepStatus || false : false
        return statusB - statusA
      }) : Array.from( React.Children?.toArray( children ) )

    // after we have sorted the steps, find the first component where stepStatus is false, that is the current step
    // clone that element and add an optional isCurrentStep prop
    let foundFirstFalse = false
    const updatedChildren = renderedChildren.map( child => {
      if ( !foundFirstFalse && React.isValidElement( child ) && !child.props.stepStatus ) {
        foundFirstFalse = true
        return React.cloneElement( child, {
          isCurrentStep: true
        } as Partial<StepQueueRendererProps> )
      }
      return child
    })

    return updatedChildren
  }

  return (
    <>
      {handleOrderChildren( children )}
    </>
  )
}

export default StepQueueRenderer