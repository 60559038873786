import { useState } from 'react'
import { CheckboxInput } from 'components/forms/components'
import { PaymentMethod } from 'components/payments'
import { getNextMonthDate } from 'utils/time'
import { formatMoney } from 'utils/money'
import PropTypes from 'prop-types'
import { icoInfoAob } from 'resources/images'
import RentalTooltip from './RentalTooltip'
import { emptyCardMethod } from 'modules/aobPayments/constants'
import Tooltip from 'components/tooltip'

function RecurringPaymentBlock({invoice, showUseSamePaymentCheck, useSamePaymentCheck, onUseSamePaymentCheck, setRecurringPaymentMethod, setDisableSubmitPayment, recurringPaymentMethod }) {

  const startDate = getNextMonthDate()

  const handleEdit = () => {
    setDisableSubmitPayment( true )
  }

  const handleSubmit = () => {
    setDisableSubmitPayment( false )
    setRecurringPaymentMethod( payMethod )
  }

  const [ payMethod, setPayMethod ] = useState( recurringPaymentMethod )

  if ( !invoice?.est_monthly_due || !invoice?.number_of_months ) return null

  return (
    <div className="max-w-2xl mx-auto">
      <Tooltip
        id="rental"
        tooltipContent={<RentalTooltip />}
      >
        <div className="flex justify-center items-center pt-16">
          <p className="text-lg md:text-xl font-bold text-center">{`PAP Rental Payment Method`}</p>
          <img
            src={icoInfoAob}
            alt="ico-info"
            className="px-2 hover:opacity-80 cursor-pointer object-contain"
            name="oop"
            width={37}
          />
        </div>
      </Tooltip>
      <p className="md:text-lg text-center font-light max-w-2xl my-4">{`Your first payment will be $${invoice.est_monthly_due} and you will be charged for ${invoice.number_of_months} months starting on ${`${startDate.getMonth() + 1}/${startDate.getDate()}/${startDate.getFullYear()}`}.`}</p>
      <p className="md:text-lg text-center font-light max-w-2xl">{`${invoice.number_of_months} month total: `}{formatMoney( invoice.est_monthly_due * invoice.number_of_months )}</p>
      <div className="max-w-lg mx-auto mt-10 mb-4">
        {
          showUseSamePaymentCheck && (
            <CheckboxInput
              name="additionalPayments"
              label="Use the same payment method as above"
              className="mr-2 mb-6 focus:outline-none rounded-full cursor-pointer"
              labelClassName="cursor-pointer text-lg"
              onChange={( e ) => {
                setPayMethod({
                  ...emptyCardMethod
                })
                onUseSamePaymentCheck( e )
              }}
              value={useSamePaymentCheck}
              defaultChecked
            />
          )
        }
      </div>
      {( !showUseSamePaymentCheck || !useSamePaymentCheck ) &&
        <div className="max-w-lg mx-auto">
          <PaymentMethod
            payMethod={payMethod}
            showRecurringPaymentMethods={!useSamePaymentCheck}
            setPayMethod={setPayMethod}
            handleRemove={() => {
              setPayMethod( Object.assign({}, emptyCardMethod ) )
              setRecurringPaymentMethod( Object.assign({}, emptyCardMethod ) )
            }}
            onEdit={handleEdit}
            onSave={handleSubmit}
          />
        </div>}
    </div>
  )
}

RecurringPaymentBlock.propTypes ={
  invoice: PropTypes.shape({
    est_monthly_due: PropTypes.number,
    number_of_months: PropTypes.number
  }),
  showUseSamePaymentCheck: PropTypes.bool,
  useSamePaymentCheck: PropTypes.bool,
  onUseSamePaymentCheck: PropTypes.func,
  setRecurringPaymentMethod: PropTypes.func,
  setDisableSubmitPayment: PropTypes.func,
  recurringPaymentMethod: PropTypes.object
}

export default RecurringPaymentBlock