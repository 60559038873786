import { gql } from '@apollo/client'

import { productsFragment } from 'graphql/fragments'

export const productsQuery = gql`
  ${productsFragment}
  query products($productAttributeFilterInput: ProductAttributeFilterInput, $pageSize: Int) {
    products(filter: $productAttributeFilterInput, pageSize: $pageSize) {
      ...Products
    }
  }
`

export const getProductsQueryBySkuArray = gql`
  query getProductQueryBySku($skus: [String!]) {
    resupplyProducts(filter: {sku: {in: $skus} }) {
        items {
          id
          name
          sku
          homepage_most_popular
          small_image {
            label
            url
          }
          image {
            url
            label
          }
        }
      }
  }
`


export const getProductsQueryBySku = gql`
  query getProductQueryBySku($sku : String!) {
    resupplyProducts(filter: {sku: {eq: $sku} }) {
        items {
          id
          name
          sku
          homepage_most_popular
          small_image {
            label
            url
          }
          image {
            url
            label
          }
        }
      }
  }
`