import React from 'react'
import { inPersonAppointmentCopy, onlineAppointmentAgreementCopy } from '../utils/constants'
import AnimatedHeightDiv from 'components/animatedHeightDiv/AnimatedHeightDiv'
import OptionSelection from 'components/optionSelection'


type AppointmentOptionsProps = {
  displayOptions: boolean,
  handleToggle(): void,
  onlineOption: boolean
}

function AppointmentOptions({displayOptions, handleToggle, onlineOption }: AppointmentOptionsProps ) : JSX.Element {
  return (
    <AnimatedHeightDiv
      display
      heightDependencies={[ displayOptions ]}
    >
      <div className="p-10">
        {
          displayOptions ?
            <>
              <OptionSelection
                handleToggle={handleToggle}
                checked={onlineOption}
                header={`Online Telehealth Appointment`}
                detail={onlineAppointmentAgreementCopy}
                containerStyling="transform ease-in-out duration-150 hover:scale-105 bg-lightGray max-w-xl mb-5"
              />
              <OptionSelection
                handleToggle={handleToggle}
                checked={!onlineOption}
                header={`In-Person Consultation`}
                detail={inPersonAppointmentCopy}
                containerStyling="transform ease-in-out duration-150 hover:scale-105 bg-lightGray max-w-xl"
              />
            </>

            :
            <div className={`rounded-lg mx-4 p-4 bg-lightGray max-w-xl flex flex-col items-start gap-1`}>
              <div className={`flex gap-2 items-center my-1`}>
                <p className="font-bold text-base md:text-lg">{`Online Telehealth Appointment`}</p>
              </div>
              <span className="font-light text-sm md:text-base">{onlineAppointmentAgreementCopy}</span>
            </div>
        }
      </div>
    </AnimatedHeightDiv>
  )
}

export default AppointmentOptions