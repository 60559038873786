import React from 'react'

const OrderCanceledStep = () => {
  return (
    <>
      <p className="text-error font-bold">{`Order Cancelled`}</p>
      <p className="pt-2">
        {`Please email`}
        <span><a href="mailto:info@aeroflowsleep.com" className="text-[#0093C9] font-medium a">{` info@aeroflowsleep.com `}</a></span>
        {`if you have any questions.`}
      </p>
    </>
  )
}

export default OrderCanceledStep