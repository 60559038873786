import { debounce } from 'lodash'
import { useRef } from 'react'

interface DebouncedProps<T> {
  delay?: number
  executeFunction?: ( _value: T ) => void
}

export const useDebounce = <T = unknown>({ executeFunction, delay = 600 }: DebouncedProps<T> ) => {
  const dispatchValue = ( value: T ) => { return executeFunction?.( value ) }

  const setValueDebounced = useRef( debounce( dispatchValue, delay ) )

  return ( value: T ) => { return setValueDebounced.current( value ) }
}