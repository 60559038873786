import { useState, useEffect } from 'react'
import { useAobSegmentStore } from 'stores'
import { INTAKE_AOB_COPY, SLEEP_NEW_LEADS_AOB_COPY, RESUPPLY_AOB_COPY } from 'modules/aobPayments/constants'
import { getHash } from "modules/clinicalScheduling/utils"
import useGetInvoice from './useGetInvoice'
import { PapIntakePriceEstimateDetails, PapIntakePaymentDetails, PapIntakeUpfrontPaymentDetails } from 'modules/aobPayments/aobSegmentedContent/PapIntakeSegment'
import { SleepNewLeadsPriceEstimateDetails, SleepNewLeadsPaymentDetails, SleepNewLeadsUpfrontPaymentDetails } from 'modules/aobPayments/aobSegmentedContent/SleepNewLeadsSegment'
import { ResupplyPriceEstimateDetails, ResupplyPaymentDetails } from 'modules/aobPayments/aobSegmentedContent/ResupplySegment'

interface InfoDetailsType {
    header: string;
    details: string;
}

interface PaymentDisclaimerType {
  cost: string;
  shipment: string;
}

interface AobSegmentContent {
  totalCostIns: InfoDetailsType;
  totalCostYou: InfoDetailsType;
  deductible: InfoDetailsType;
  oop: InfoDetailsType;
  coIns: InfoDetailsType;
  paymentDisclaimer: PaymentDisclaimerType;
  PriceEstimateDetails?: React.ComponentType;
  PaymentDetails?: React.ComponentType;
  UpfrontPaymentDetails?: React.ComponentType;
}

interface Invoice {
    pap_setup_type?: string;
    aob_monthly_amount?: number;
    aob_number_of_months?: number;
}

const useGetAobSegmentedCopy = () => {
  const { aobSegmentType, setAobSegmentType } = useAobSegmentStore()
  const { invoice } = useGetInvoice( getHash() ? `hash` : `token` ) as { invoice: Invoice | null }
  const [ aobContent, setAobContent ] = useState<AobSegmentContent | null>( null )

  useEffect( () => {
    if ( invoice ) {
      setAobSegmentType(
        invoice?.pap_setup_type === `Intake` ? `Intake` :
          invoice?.pap_setup_type === `Sleep New Leads` ? `Sleep New Leads` :
            `Resupply`
      )
    }

    if ( aobSegmentType !== null ) {
      if ( aobSegmentType === `Intake` ) setAobContent({
        ...INTAKE_AOB_COPY,
        PriceEstimateDetails: PapIntakePriceEstimateDetails,
        PaymentDetails: PapIntakePaymentDetails,
        UpfrontPaymentDetails: PapIntakeUpfrontPaymentDetails
      })
      if ( aobSegmentType === `Sleep New Leads` ) setAobContent({
        ...SLEEP_NEW_LEADS_AOB_COPY,
        PriceEstimateDetails: SleepNewLeadsPriceEstimateDetails,
        PaymentDetails: SleepNewLeadsPaymentDetails,
        UpfrontPaymentDetails: SleepNewLeadsUpfrontPaymentDetails
      })
      if ( aobSegmentType === `Resupply` ) setAobContent({
        ...RESUPPLY_AOB_COPY,
        PriceEstimateDetails: ResupplyPriceEstimateDetails,
        PaymentDetails: ResupplyPaymentDetails
      })
    }
  }, [ invoice, aobSegmentType ] )

  return aobContent
}

const useGetInstallmentOptions = () => {
  const { invoice } = useGetInvoice( getHash() ? `hash` : `token` ) as { invoice: Invoice | null }

  return invoice ? {
    installmentAmount: invoice?.aob_monthly_amount,
    numberOfMonths: invoice?.aob_number_of_months
  } : {
    installmentAmount: null,
    numberOfMonths: null
  }
}

export { useGetAobSegmentedCopy, useGetInstallmentOptions }