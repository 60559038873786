import React from 'react'
import { Button } from 'components/Button'
import { ContentCardGridTypes } from '../types'

export const ImageTextCtaCard = ({ imgSrc, title, description, callToActionLink, calltoActionValue }: ContentCardGridTypes ): JSX.Element => {
  return (
    <div
      className="flex flex-col sm:py-6 md:py-16 justify-items-center items-start md:w-1/2"
    >
      {imgSrc && (
        <img
          src={imgSrc}
          alt={`${title} image`}
          className="rounded-md block border sm:mb-5 lg:mb-7 w-full"
        />
      )}
      <div className="text-center flex flex-col grow">
        {title && (
          <h3 className="font-source-serif-4 text-white sm:text-2xl md:text-[40px] md:leading-10 font-light mb-4">
            {title}
          </h3>
        )}
        {description && (
          <p className="grow pb-4 font-inter sm:text-sm md:text-base text-white">{description}</p>
        )}
        {callToActionLink && calltoActionValue && (
          <div className="w-48 mx-auto">
            <Button
              className="btn-unfixed-height btn-primary"
              onClick={() => {
                window.location.href = callToActionLink
              }}
            >
              {calltoActionValue}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
