import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ProductContainer = styled.li`
  :last-of-type {
    border: none;
  }
`

const ProductResult = ({ product }) => {
  const productURL = `${process.env.REACT_APP_MAGENTO_2_BASE_URL}/${product.url_key}`

  return (
    <ProductContainer className="py-6" style={{}}>
      <div className="bg-white box-shadow-light rounded p-4 grid sm:grid-cols-3 md:grid-cols-1 md:grid-rows-2">
        <a href={productURL} className="block sm:col-span-1 sm:m-auto">
          {product?.small_image || product?.image ? (
            <img
              className="sm:max-h-20 md:max-h-60"
              src={`${process.env.REACT_APP_MAGENTO_2_BASE_URL}/${product?.small_image.url || product?.image.url}`}
              alt={product?.small_image.label || product?.image.label}
            />
          ) : <p>{product.name}</p>
          }
        </a>

        <a href={productURL} className="sm:col-span-2 sm:text-center sm:text-sm md:text-xs lg:text-sm a">{product.name}</a>
      </div>
    </ProductContainer>
  )
}
ProductResult.propTypes = {
  product: PropTypes.object.isRequired
}

export default ProductResult
