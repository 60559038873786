import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CheckIcon from './CheckIcon'
import { maskImages } from 'modules/sleepNewLeads/constants'
import QuickViewModal from 'modules/sleepNewLeads/Components/QuickViewModal'

const MaskModelSelector = ({
  heading,
  maskNames,
  selectedValue,
  onChange,
  showOnMobile,
  baseEquipment,
  onDontWantMask,
  selectedBrand,
  selectedMaskType
}) => {
  const [ showModal, setShowModal ] = useState( false )
  const [ maskData, setMaskData ] = useState({
    mask: ``,
    maskSizes: [],
    maskImages: [],
    frameSystems: []
  })

  const availableImages = maskImages.filter( ({ mask }) => { return ( maskNames || [] ).includes( mask ) })

  if ( !availableImages || !availableImages.length ) return null

  const brandsByType = baseEquipment?.find( item => { return item.sleep_mask_category === selectedMaskType })?.brandsAvailable || []
  const maskModels = brandsByType?.find( item => item.brand === selectedBrand )?.masksAvailable || []
  const getMask = ( demid ) => {
    let findSize = maskModels.find( ( item ) => item.mask === maskData.mask )
    return findSize.sizesAvailable.find( ( size ) => size.dme_id.toString() === demid )
  }

  return (
    <>
      {showOnMobile && <div className="mt-4">
        {showModal && (
          <QuickViewModal
            maskData={maskData}
            onCloseModal={() => setShowModal( false )}
            isMask
            onDontWantMask={onDontWantMask}
            onMaskChange={( selectedSize ) => {
              const mask = {
                cpapMaskModel: maskData?.mask,
                cpapMaskSize: getMask( selectedSize ).size,
                cpapMaskHcpc: getMask( selectedSize ).hcpc_code,
                cpapMaskDmeID: selectedSize
              }
              return onChange({
                target: {
                  name: `modelSelection`,
                  value: mask
                }
              })
            }}
          />
        )}
        <div className="font-inter sm:text-xl md:text-[28px] sm:text-center lg:text-left font-medium sm:mb-5 md:mb-11">{heading}</div>
        <div className="grid sm:grid-cols-2 lg:grid-cols-4 sm:gap-[18px] lg:gap-[24px] sm:mx-auto lg:mx-0 sm:px-8 lg:px-0">
          {availableImages.map( ({ mask, imgSrc }) => {
            return (
              <div key={mask}>
                <div
                  className={`overflow-hidden cursor-pointer p-1 flex flex-col relative rounded-md ${mask === selectedValue ? `border border-deepSleepBlue` : ``}`}
                  onClick={() => {
                    setMaskData({
                      ...maskData,
                      mask: mask,
                      maskSizes: [ ...maskModels.find( item => item.mask === mask )?.sizesAvailable || [] ],
                      maskImages: {
                        mask: mask,
                        items: availableImages.find( item => item.mask === mask )?.imgSrc
                      },
                      frameSystems: []
                    })
                    setShowModal( true )
                  }}
                >
                  { mask === selectedValue && <CheckIcon className="rounded-tl-sm left-0" /> }
                  <img
                    src={imgSrc[ 0 ]}
                    className="w-full h-auto"
                    alt={mask}
                    data-type="mask"
                  />
                  <button className="btn-quick-view">{`Select`}</button>
                </div>
                <p
                  className="sm:text-sm md:text-xl font-medium font-inter text-center"
                >
                  {mask}
                </p>
              </div>
            )
          })}
        </div>
      </div>}
    </>
  )
}

MaskModelSelector.propTypes = {
  heading: PropTypes.string,
  name: PropTypes.string,
  maskNames: PropTypes.array,
  selectedValue: PropTypes.string,
  onChange: PropTypes.func,
  defaultLabel : PropTypes.string,
  showOnMobile: PropTypes.bool,
  maskBrand: PropTypes.string,
  frameSystems: PropTypes.array,
  baseEquipment: PropTypes.array,
  formValues: PropTypes.object,
  selectedBrand: PropTypes.string,
  selectedMaskType: PropTypes.string,
  onDontWantMask: PropTypes.func
}

export default MaskModelSelector

