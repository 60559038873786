import React from 'react'

type ContainerProps = {
    maxWidth?: number;
    classNames?: string;
    children: React.ReactNode;
  }

export const Container = ({ maxWidth, classNames, children }: ContainerProps ): JSX.Element => {
  const containerStyle = {
    maxWidth: `${maxWidth || 1280}px`,
    margin: `0 auto`
  }

  return <div style={containerStyle} className={classNames}>{children}</div>
}

export default Container