import { reportToSentry } from "utils/reportToSentry"

export default async function submitSMSOptOut( hash: string ) : Promise<void> {
  try {
    const response = await fetch( `${process.env.REACT_APP_SNL_URL}/new-leads/opt-out`, {
      method: `POST`,
      headers: {
        'Content-Type': `application/json`,
        'Authorization': `PatientHash ${hash}`
      },
      body: JSON.stringify({
        patient_hash: hash
      })
    })

    const data = await response.json()

    if ( data?.data?.success !== true ) {
      reportToSentry( `SMS Opt Out Failed`, {
        response: JSON.stringify( data )
      })
    }
  } catch ( e ) {
    reportToSentry( `SMS Opt Out Failed`, {
      error: JSON.stringify( e )
    })
  }

}
