import React, { useRef, useState } from 'react'
import { Builder } from '@builder.io/react'
import { EmailLink, PhoneLink } from 'components/ALinks'
import { VideoBlockTypes } from './types'
import { icoVidPlay } from 'resources/images'

export const VideoBlock = ({ headerText, bodyText, email, phoneNumber, videoSrc, videoTitle, videoThumbnailImage }: VideoBlockTypes ): JSX.Element => {
  const iframeRef = useRef<HTMLIFrameElement>( null )

  const [ isPlaying, setIsPlaying ] = useState( false )

  const handlePlay = () => {
    setIsPlaying( true )
    if ( iframeRef.current ) {
      iframeRef.current.src += `&autoplay=1`
    }
  }

  return (
    <div className="flex overflow-hidden sm:flex-col-reverse md:flex-row md:items-center md:justify-center mx-auto px-4 sm:py-8 md:py-[46px] md:gap-[88px] lg:w-10/12 xl:w-8/12">
      <div className="md:w-1/2">
        {headerText && (
          <h3 className="text-center font-source-serif-4 sm:text-2xl md:text-[40px] text-aeroflowNavy font-normal sm:mt-4 md:mt-0 sm:mb-2 md:mb-6">
            {headerText}
          </h3>
        )}
        {bodyText && email && phoneNumber && (
          <div className="sm:mb-8 my-0 mx-auto text-center text-graphite sm:text-sm md:text-base">
            <span>{bodyText}</span>
            <p className="inline sm:text-sm md:text-base">
              {` If you have any questions, email us at `}
              <EmailLink email={email} />
              {` or call at `}
              <PhoneLink displayNumber={phoneNumber} />
              {`. We’re here to help!`}
            </p>
          </div>
        )}
      </div>
      {videoSrc && (
        <div className="md:w-1/2 lg:max-w-lg relative cursor-pointer">
          <div className="bg-lightGray sm:hidden md:block absolute w-full h-150% bottom-[-22%] left-[128px]" />
          <div className="relative">
            {isPlaying || !videoThumbnailImage ? // thumbnail is optional
              <div className="h-full w-full overflow-hidden relative pt-[56.25%]">
                <iframe
                  src={`${videoSrc}?rel=0&controls=0&showinfo=0&autoplay=1`}
                  title={videoTitle}
                  className="absolute top-0 left-0 w-full h-full"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; modestbranding"
                  allowFullScreen
                >
                </iframe>
              </div>
              :
              <>
                {videoThumbnailImage && (
                  <div onClick={handlePlay}>
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <img src={icoVidPlay} />
                    </div>
                    <img src={videoThumbnailImage} />
                  </div>
                )}
              </>
            }
          </div>
        </div>
      )}
    </div>
  )
}

Builder.registerComponent( VideoBlock, {
  name: `VideoBlock`,
  inputs: [
    {
      name: `headerText`,
      type: `text`,
      defaultValue: `Sleep Apnea is Manageable`
    },
    {
      name: `bodyText`,
      type: `text`,
      defaultValue: `Aeroflow Sleep's experienced Sleep Specialists are here to guide you through every step of your sleep apnea journey.`
    },
    {
      name: `email`,
      type: `text`,
      defaultValue: `info@aeroflowsleep.com`
    },
    {
      name: `phoneNumber`,
      type: `text`,
      defaultValue: `(800) 480-5491`
    },
    {
      name: `videoSrc`,
      type: `text`,
      defaultValue: ``
    },
    {
      name: `videoTitle`,
      type: `text`,
      defaultValue: `Telehealth Solutions for Sleep Apnea`
    },
    {
      name: `videoThumbnailImage`,
      type: `file`,
      allowedFileTypes: [ `jpeg`, `jpg`, `png`, `svg` ]
    }
  ]
})

export default VideoBlock