import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { PaymentSummary, OrderSummaryDetails, HealthInsuranceInfoBlock } from '../components/pricingEstimate'

function OrderSummary({
  setAobIsCompleted,
  setPayLater,
  invoice,
  aobContent,
  setError,
  setInvoice,
  setRemovedItems,
  removedItems,
  setNotInterested,
  ...pageProps
}) {

  const [ blockProceed, setBlockProceed ] = useState( false )

  return (
    <div className="relative max-w-full mx-auto w-full md:w-10/12 lg:w-1/2 flex flex-col gap-9 bg-[#F7F7F7]">
      <PaymentSummary invoice={invoice} />
      {
        invoice?.details?.length > 0 &&
        <OrderSummaryDetails
          invoice={invoice}
          setInvoice={setInvoice}
          removedItems={removedItems}
          setRemovedItems={setRemovedItems}
          setBlockProceed={setBlockProceed}
          blockProceed={blockProceed}
        />
      }
      <HealthInsuranceInfoBlock
        invoice={invoice}
        nextPage={pageProps.nextPage}
        aobContent={aobContent}
        setAobIsCompleted={setAobIsCompleted}
        setError={setError}
        setPayLater={setPayLater}
        blockProceed={blockProceed}
        setNotInterested={setNotInterested}
      />
    </div>
  )
}

OrderSummary.propTypes = {
  setAobIsCompleted: PropTypes.func,
  invoice: PropTypes.object,
  allowPayment: PropTypes.bool,
  aobContent: PropTypes.string,
  setError: PropTypes.func,
  setPayLater: PropTypes.func,
  customerData: PropTypes.object,
  setInvoice: PropTypes.func,
  setRemovedItems: PropTypes.func,
  removedItems: PropTypes.array,
  setNotInterested: PropTypes.func,
  customerDataRefetch: PropTypes.func
}

export default OrderSummary