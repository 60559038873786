import React from 'react'
import TrackerStepIcon from '../../TrackerStepIcon'
import { rxIconWhite } from 'resources/images'
import { prescriptionNeededCopy } from '../constants'
import StepContentRenderer from '../../StepContentRenderer'
import { useDoctorDisplayModalStore } from 'stores/modalStore'

type PrescriptionNeededStepProps = {
  stepStatus: boolean;
  isCurrentStep?: boolean;
}

function PrescriptionNeededStep({ stepStatus, isCurrentStep } : PrescriptionNeededStepProps ) : JSX.Element {
  const { setIsRemoteModalOpen } = useDoctorDisplayModalStore()
  const handleStepAction = () => {
    setIsRemoteModalOpen( true )
  }

  return (
    <div className="flex">
      <TrackerStepIcon
        imageSrc={rxIconWhite}
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
        onStepAction={handleStepAction}
      />
      <StepContentRenderer
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
        stepCopy={{
          ...prescriptionNeededCopy,
          onStepAction: handleStepAction
        }}
      />
    </div>
  )
}

export default PrescriptionNeededStep
