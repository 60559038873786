import React, { useState, useRef, useEffect } from 'react'
import { submitAppointment, getHash } from '../utils'
import OnlineAppointmentDisplay from './OnlineAppointmentDisplay'
import InternalPatient from 'types/internalPatient'
import Appointment from 'types/appointment'
import { useFetchAppointments } from '../hooks/useFetchAppointments'
import { reportToSentry } from 'utils/reportToSentry'
import LoadingSpinner from 'components/LoadingSpinner'
import { TextInput, Validators } from 'components/forms/components'
import AppointmentOptions from './AppointmentOptions'
import SubmissionBlock from './SubmissionBlock'
import postDemographics from 'utils/auth/demographics'
import { CLINICAL_SCHEDULING_AOB_PATH } from 'modules/aobPayments/constants'
import { CSTStorage } from 'apollo/storage'
import { useNavigate } from 'react-router-dom'
import { SCHEDULE_THANK_YOU_PATH } from '../utils/constants'

type AppointmentTypeSelectionProps = {
  nextPage?(): void;
  setScheduledVirtually: React.Dispatch<React.SetStateAction<boolean>>;
  timeZone: string | null;
  setTimeZone: React.Dispatch<React.SetStateAction<string | null>>;
  patientData: InternalPatient | null;
}

function AppointmentTypeSelection({nextPage, setScheduledVirtually, timeZone, setTimeZone, patientData} : AppointmentTypeSelectionProps ) : JSX.Element {

  const [ onlineOption, setOnlineOption ] = useState<boolean>( true )
  const [ appointment, setAppointment ] = useState<Appointment | null>( null )
  const [ canContact, setCanContact ] = useState<boolean>( false )
  const [ loading, setLoading ] = useState<boolean>( false )
  const [ submitError, setSubmitError ] = useState<boolean>( false )
  const [ email, setEmail ] = useState<string>( `` )
  const [ emailError, setEmailError ] = useState<string>( `` )

  const emailRef = useRef<HTMLInputElement>( null )

  const { appointments, displayOptions, invoice, error } = useFetchAppointments( patientData, timeZone )
  const navigate = useNavigate()

  useEffect( () => {
    if ( CSTStorage.getScheduledStatus( getHash() ) ) return navigate( SCHEDULE_THANK_YOU_PATH.concat( window.location.search ) )
  }, [] )

  const handleToggle = () => {
    setAppointment( null )

    return setOnlineOption( !onlineOption )
  }

  const handleSubmit = async () => {
    const patientHash = getHash()

    if ( ( ( onlineOption && appointment ) || !onlineOption ) && patientData && invoice ) {
      setLoading( true )

      let submitEmail
      if ( patientData?.email && patientData.email !== `0` ) submitEmail = patientData.email
      else {
        submitEmail = email
        const success = postDemographics( `PatientHash ${patientHash}`, {
          emailAddress: email
        })
          .catch( error => {
            reportToSentry( `Error occurred updating patient demographics within Email Collection`, {
              error: JSON.stringify( error )
            })

            return setSubmitError( true )
          })
        if ( !success ) return setSubmitError ( true )
      }
      const submission = await submitAppointment( patientData, submitEmail, timeZone, invoice, patientHash, appointment )
        .catch( error => {
          setLoading( false )
          setSubmitError( true )
          reportToSentry( `Clinical Scheduling Tool: An exception occurred attempting to submit an appointment for a patient`, {
            patientHash,
            ...( appointment ?? {
              inPersonAppointment: true
            }),
            error
          })
        })
      setScheduledVirtually( !displayOptions || onlineOption )
      setLoading( false )
      if ( submission?.errors?.length ) setSubmitError( true )
      else if ( submission?.meta?.status === `OK` ) {
        CSTStorage.setScheduledStatus( patientHash )

        if ( nextPage ) return nextPage()
      } else setSubmitError( true )
    } else setSubmitError( true )
  }

  const handleTimeZone = ( value: string ) => {
    setTimeZone( value )
  }

  const handleEmailChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    setEmailError( `` )
    setEmail( e.target.value )
  }

  const handleEmailBlur = () => {
    if ( !Validators.email( email ) ) {
      setEmailError( `Please enter a valid email...` )
      emailRef?.current?.focus()
    }
  }

  const disableSubmission:boolean = ( onlineOption && ( !appointment || ( !canContact && window.location.pathname !== CLINICAL_SCHEDULING_AOB_PATH ) ) ) || ( ( patientData?.email === null || patientData?.email === `0` ) && ( email.length === 0 || emailError.length !== 0 ) )

  return (
    <div className="pb-16 px-3 flex items-center flex-col w-full bg-white relative">
      <h2 className={`text-center tracking-wide font-normal text-2xl md:text-3xl`}>{`Schedule an Appointment`}</h2>
      {
        loading ?
          <div className="mt-12 mb-20">
            <LoadingSpinner
              message={onlineOption ? `Submitting appointment time selection...` : `Submitting in person appointment selection...`}
              messageStyling={`text-deepSleepBlue text-center mb-10 font-light md:text-lg`}
            />
          </div>
          :
          <>
            <AppointmentOptions
              displayOptions={displayOptions}
              onlineOption={onlineOption}
              handleToggle={handleToggle}
            />
            {
              ( !patientData?.email || patientData.email === `0` ) &&
              <div className="w-full max-w-md px-6">
                <TextInput
                  name="email"
                  label="Email"
                  type="email"
                  value={email}
                  required
                  errorMessage={emailError}
                  reference={emailRef}
                  onBlur={handleEmailBlur}
                  onChange={handleEmailChange}
                  validator={undefined}
                  formatter={undefined}
                  id={undefined}
                />
              </div>

            }
            <OnlineAppointmentDisplay
              appointments={appointments}
              onlineOption={onlineOption}
              appointment={appointment}
              setAppointment={setAppointment}
              canContact={canContact}
              setCanContact={setCanContact}
              timeZone={timeZone}
              setTimeZone={handleTimeZone}
              error={error}
            />

            <SubmissionBlock
              handleSubmit={handleSubmit}
              disableSubmission={disableSubmission}
              onlineOption={onlineOption}
              submitError={submitError}
            />
          </>
      }
    </div>
  )
}

export default AppointmentTypeSelection