import React from 'react'
import PropTypes from 'prop-types'
import CheckIcon from './CheckIcon'
import { maskTypeImages } from 'modules/sleepNewLeads/constants'

const MaskTypeSelector = ({
  heading,
  name,
  maskTypes,
  selectedValue,
  onChange,
  showOnMobile
}) => {
  const availableImages = maskTypeImages.filter( item => { return ( maskTypes || [] ).includes( item.type ) })
  if ( !availableImages || !availableImages.length ) return null

  return (
    <>
      {showOnMobile && <div className="mt-6">
        <div className="font-inter sm:text-xl md:text-[28px] sm:text-center lg:text-left font-medium sm:mb-5 md:mb-11">{heading}</div>
        <div className="grid grid-cols-2 gap-6 sm:max-w-none lg:max-w-[625px] sm:mx-auto lg:mx-0 sm:px-8 lg:px-0">
          {availableImages.map( ({type, imgSrc}) => {
            return (
              <div key={type} className="lg:max-w-[300px]">
                <div
                  className={`cursor-pointer border rounded-md flex items-center overflow-hidden relative ${type === selectedValue ? `border-[#0057A8]` : `border-[#E1E1E1]`}`}
                  onClick={() => {
                    return onChange({
                      target: {
                        name,
                        value: type
                      }
                    })
                  }}
                >
                  { type === selectedValue && <CheckIcon className="rounded-tl-sm" /> }
                  <img
                    src={imgSrc}
                    className="w-full h-auto"
                    alt={type}
                    data-type="mask"
                  />
                </div>
                <p
                  onClick={() => {
                    return onChange({
                      target: {
                        name,
                        value: type
                      }
                    })
                  }}
                  className="cursor-pointer sm:text-sm pt-2 md:text-xl font-inter text-black font-light text-center"
                >
                  {type}
                </p>
              </div>
            )
          })}
        </div>
      </div>}
    </>
  )
}

MaskTypeSelector.propTypes = {
  heading: PropTypes.string,
  name: PropTypes.string,
  maskTypes: PropTypes.array,
  selectedValue: PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] ),
  onChange: PropTypes.func,
  showOnMobile: PropTypes.bool
}

export default MaskTypeSelector
