import React from 'react'
import { SfTooltip } from '@storefront-ui/react'
import { info } from '../../resources/images'

export type PillProps = {
  text: string;
  color: string;
  link?: string;
  disabled?: boolean; // if link should be clickable
  textColor?: string;
  tooltip?: string;
}

function Pill({ text, color, link, textColor, tooltip, disabled }: PillProps ) : JSX.Element{
  return (
    <>
      <div
        className={`w-[148px] py-1 px-2 rounded-md justify-center items-center flex gap-1`} style={{
          backgroundColor: `${color}`
        }}
      >
        { link?.length ? <a
          href={link} target="_blank" rel="noopener noreferrer"
          className={`p-sm underline`}
          style={{
            color: `${textColor}`,
            cursor: disabled ? `default` : `pointer`
          }}
        >
          {text}
          {tooltip && <SfTooltip label={tooltip}> <img src={info} alt={text} /></SfTooltip>}
        </a> :
          <>
            <p
              className={`p-sm`} style={{
                color: `${textColor}`
              }}
            >{text}</p>
            {tooltip && <SfTooltip label={tooltip}> <img src={info} alt={text} /></SfTooltip>}
          </>}
      </div>
    </>
  )
}

export default Pill