import { gql } from '@apollo/client'

export const addProductsToCartMutation = gql`
  mutation addProductsToCartMutation( $cartId: String!, $cartItems: [CartItemInput!]! ) {
    addProductsToCart(  cartId: $cartId, cartItems: $cartItems ) {
      cart {
        items {
          uid
        }
      }
      user_errors {
        message
        code
      }
    }
  }
`