import React from 'react'
import routes from 'routes'
import { Routes, Route, useSearchParams } from 'react-router-dom'
import { AuthRoute } from 'components/auth'
import { EnableAccount } from 'modules/enableAccount'
import * as Sentry from "@sentry/react"

function RouteMapping() : JSX.Element {
  const [ searchParams ] = useSearchParams()

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing( Routes )

  return (
    <SentryRoutes>
      {
        routes.filter( ( r ) => { return r?.path?.length }).map( route => {
          const { authRoute, ...routeProps} = route
          if ( searchParams.get( `afmcatoken` ) && !window.location.href.includes( `/payments/` ) ) return <Route key={routeProps.path} {...routeProps} element={<EnableAccount />} />
          if ( authRoute ) return <Route key={routeProps.path} {...routeProps} element={<AuthRoute {...routeProps} />} />

          return (
            <Route
              key={routeProps.path}
              {...routeProps}
            />
          )
        })
      }
    </SentryRoutes>
  )
}

export default RouteMapping