import React from "react"
import { SliderCustomArrowTypes } from '../types'
import { icoCaretLeftLightGrey, icoCaretRightLightGrey } from "resources/images"

export const PrevArrow = ( props: SliderCustomArrowTypes ) : JSX.Element => {
  const { onClick } = props
  return (
    <div className="slick-prev" onClick={onClick} >
      <img src={icoCaretLeftLightGrey} alt="Previous" />
    </div>
  )
}

export const NextArrow = ( props: SliderCustomArrowTypes ) : JSX.Element => {
  const { onClick } = props
  return (
    <div className="slick-next" onClick={onClick} >
      <img src={icoCaretRightLightGrey} alt="Previous" />
    </div>
  )
}