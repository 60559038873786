import { Tile } from 'components/tile'
import React, { useEffect } from 'react'
import { myPhysicianId } from '../constants'
import { useTokenStore } from '../../../stores'
import { PhoneLink } from 'components/ALinks'
import { PHONE } from 'components/footerBarV2/constants'
import DoctorBlock from 'components/doctorBlock/DoctorBlock'

type PhysicianBlockProps = {
  scrollIntoView?: boolean;
}

function Physician({scrollIntoView = false}: PhysicianBlockProps ) : JSX.Element {
  const { customerToken } = useTokenStore()

  useEffect( () => {
    if ( scrollIntoView ) {
      const doctorBlock = document.getElementById( `doctor-block` )
      if ( doctorBlock ) {
        doctorBlock.scrollIntoView({
          behavior: `smooth`
        })
      }
    }
  }, [] )

  if ( !customerToken ) return ( <p className="text-error">
    {`There was an error retrieving your physician details... Please give customer support a call at `}
    <PhoneLink displayNumber={PHONE} textClassName="inline-block whitespace-nowrap" />
    {`.`}
  </p> )

  return (
    <Tile headerContent="My Physician" id={myPhysicianId}>
      <DoctorBlock
        isMyAccount
        forceUpdate={false}
      />
    </Tile>
  )
}

export default Physician