import React, { useState } from 'react'
import { Builder } from '@builder.io/react'
import { HeroLinkItem } from './HeroLinkItem'
import { SlideToggleContent } from 'components/SlideToggle'
import { SleepLinearGradient } from 'components/sleepLinearGradient'
// import '../utils/glowingBorder.css' @todo: implement glowing border animation
import { NavBlockTypes } from '../../types'

export const CollapsibleBlock = ({blockTitle, desktopNavBlockImage, blockUrl, blockLinks} : NavBlockTypes ) : JSX.Element => {
  const [ isOpen, setIsOpen ] = useState( false )

  const handleNavigate = () => {
    if ( blockUrl ) {
      window.location.href = blockUrl
    }
  }

  const handleMouseEnter = () => {
    setIsOpen( true )
  }

  const handleMouseLeave = () => {
    setIsOpen( false )
  }

  const boxShadowStyle = isOpen ? `shadow-[4px_1px_9px_0_rgb(0,0,0,0.1),_0_-1px_2px_-1px_rgb(0,0,0,0.1)]` : ``

  return (
    <div
      className={`flex flex-col self-start overflow-hidden rounded-t-lg cursor-pointer z-100 flex-shrink w-1/4 ${boxShadowStyle}`}
      onClick={handleNavigate}
    >
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="basis-3/12 bg-white max-h-[234px]"
      >
        {desktopNavBlockImage &&
          <div className="border border-rounded border-white">
            <img className="w-full" src={desktopNavBlockImage} alt={blockTitle} />
          </div>}
        {blockTitle &&
          <div className="flex items-center min-h-16 p-2">
            <h3 className="text-deepSleepBlue text-xl text-center leading-6 w-full font-inter">{blockTitle}</h3>
          </div>}
      </div>
      <div
        className="bg-white overflow-hidden rounded-b-lg border border-rounded border-white"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {blockLinks?.length > 0 && (
          <SlideToggleContent isVisible={isOpen}>
            <div className="flex flex-col gap-1">
              {blockLinks.map( ( blockLink ) => {
                return (
                  <HeroLinkItem
                    key={blockLink.ctaName}
                    ctaName={blockLink.ctaName}
                    mobileCtaImage={blockLink.mobileCtaImage}
                    ctaDetails={blockLink.ctaDetails}
                    buttonText={blockLink.buttonText}
                    url={blockLink.url}
                  />
                )
              })}
            </div>
          </SlideToggleContent>
        )}
        <SleepLinearGradient height={8} />
      </div>
      {/* @todo: implement glowing border animation */}
      {/* <div className="glow-dot"></div>
      <div className="glow-dot-2"></div> */}
    </div>
  )
}

Builder.registerComponent( CollapsibleBlock, {
  name: `CollapsibleBlock`
})

export default CollapsibleBlock