import copy from './copy.json'

export const resupplyThankYouTransform = ( node ) => {
  if ( node.type === `tag` && node?.attribs[`data-element`] === `mobile_image` ) return null
  if ( node.type === `tag` ) node.attribs.style = ``
  if ( node?.attribs?.class ) {
    if ( node.attribs.class.includes( `lg-` ) ) node.attribs.class = node.attribs.class.replaceAll( `lg-`, `lg:` )
    if ( node.attribs.class.includes( `md-` ) ) node.attribs.class = node.attribs.class.replaceAll( `md-`, `md:` )
    if ( node.attribs.class.includes( `sm-` ) ) node.attribs.class = node.attribs.class.replaceAll( `sm-`, `sm:` )
  }

  if ( node.type === `tag` && node?.name === `img` ) {
    // change media folder from sleep to breastpumps ( should default to breastpumps but not the case in prod )
    node.attribs.src = node.attribs.src.replace( process.env.REACT_APP_MAGENTO_2_BASE_URL, process.env.REACT_APP_CMS_IMAGE_URL )
    node.attribs.loading = `lazy`
    node.attribs.width = 240
    node.attribs.height = 240
  }
}

export function getCopy( hasRx, eligible ) {
  if ( hasRx ) {
    if ( eligible ) return copy.simple
    else return copy.simpleEligibleSoon
  } else {
    if ( eligible ) return copy.verbose
    else return copy.verboseEligibleSoon
  }
}

export function addInDateByEligible( text, dateString ) {
  return text.replaceAll( `{Eligible}`, dateString ? dateString : `an upcoming date` )
}