import React from 'react'
import { dedicatedSpecialistImg, insuranceMadeEasyImg, maskSwapImg, virtualMastFittingImg } from 'resources/images'

const ValuePropositionBackup = () => {
  return (
    <div className="text-center mt-12 md:mb-24 lg:w-10/12 lg:my-0 lg:mx-auto lg:mb-24 xl:w-8/12">
      <h1 className="mb-12">{`The Aeroflow Difference`}</h1>
      <div className="grid justify-center md:grid-cols-2 lg:grid-cols-4">
        <div className="mb-12">
          <img
            src={insuranceMadeEasyImg}
            height={150}
            width={150}
            className="my-0 mx-auto mb-8"
            alt="Insurance Made Easy"
            loading="lazy"
          />
          <p className="uppercase">{`Insurance Made Easy`}</p>
        </div>
        <div className="mb-12">
          <img
            src={virtualMastFittingImg}
            height={150}
            width={150}
            className="my-0 mx-auto mb-8"
            alt="Mask Fitting Technology"
          />
          <p className="uppercase">{`Mask Fitting Technology`}</p>
        </div>
        <div className="mb-12">
          <img
            src={maskSwapImg}
            height={150}
            width={150}
            className="my-0 mx-auto mb-8"
            alt="30 Day Mask Swap"
          />
          <p className="uppercase">{`30 Day Mask Swap`}</p>
        </div>
        <div className="mb-12">
          <img
            src={dedicatedSpecialistImg}
            height={150}
            width={150}
            className="my-0 mx-auto mb-8"
            alt="Dedicated Sleep Specialist"
          />
          <p className="uppercase">{`Dedicated Sleep Specialist`}</p>
        </div>
      </div>
    </div>
  )
}

export default ValuePropositionBackup
