import React from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

/**
 * CustomSkeletonTheme component
 *
 * This component wraps the SkeletonTheme from react-loading-skeleton
 * and provides default values for the skeleton appearance.
 * use to customize skeleton colors and animations
 *
 */

/**
 * props for the CustomSkeletonTheme component
 * @typedef {Object} SkeletonThemeProps
 * @property {string} [baseColor] - base color for the skeleton
 * @property {string} [highlightColor] - highlight color for the skeleton animation
 * @property {string} [customHighlightBackground] - css for the highlight animation
 * @property {React.ReactNode} children - child components to be wrapped by the SkeletonTheme
 */

type SkeletonThemeProps = {
  baseColor?: string
  highlightColor?: string
  customHighlightBackground?: string
  children: React.ReactNode
}

const CustomSkeletonTheme = ({ baseColor, highlightColor, customHighlightBackground, children } : SkeletonThemeProps ) => {
  // default
  const defaultStyles = {
    baseColor: `#F0F0F0`,
    highlightColor: `#B5B5B5`,
    customHighlightBackground: `linear-gradient(90deg, #f5f6f8 40%, #e9eaec 50%, #f5f6f8 60%)`
  }

  return (
    <div className="w-full" aria-hidden="true">
      <SkeletonTheme
        baseColor={baseColor || defaultStyles.baseColor}
        highlightColor={highlightColor || defaultStyles.highlightColor}
        customHighlightBackground={customHighlightBackground || defaultStyles.customHighlightBackground}
      >
        {children}
      </SkeletonTheme>
    </div>

  )
}

export default CustomSkeletonTheme