import { forwardRef, useState} from "react"
import { TextInputProps } from "../types"
import TextInput from "./TextInput"
import { DropdownContainer, DropdownContent } from "components/dropdown"

export type DropdownTextInputProps = {
  items: string[];
  customItemFilter?: ( _item: string ) => boolean;
  onItemClick?: ( _itemText: string ) => void;
  dropdownBehavior?: `onFocus` | `onChange`;
} & Partial<TextInputProps>

const DropdownTextInput = forwardRef( ({items, customItemFilter, onItemClick, dropdownBehavior, ...textInputProps}: DropdownTextInputProps, ref ) => {
  const [ showDropdown, setShowDropdown ] = useState( false )

  const handleChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    setShowDropdown( true )
    textInputProps.onChange?.( e )
  }

  const handleChildClick = ( e: React.MouseEvent<HTMLLIElement> ) => {
    setShowDropdown( false )
    onItemClick?.( e.currentTarget.innerText )
  }

  const handleKeyDown = ( e: React.KeyboardEvent<HTMLLIElement> ) => {
    if ( e.key === `Enter` ) {
      setShowDropdown( false )
      onItemClick?.( e.currentTarget.innerText )
    }
  }

  const handleFocus = ( e: React.FocusEvent<HTMLInputElement> ) => {
    if ( dropdownBehavior === `onFocus` ) setShowDropdown( true )
    textInputProps.onFocus?.( e )
  }

  const handleBlur = ( e : React.FocusEvent<HTMLDivElement> ) => {
    // This closes the
    if ( e.relatedTarget === null || !e.currentTarget.contains( e.relatedTarget as Node ) ) {
      setShowDropdown( false )
    }
  }

  return (
    <DropdownContainer onBlur={handleBlur} tabIndex={-1}>
      <TextInput
        {...textInputProps}
        reference={ref}
        onChange={handleChange}
        onFocus={handleFocus}
        type="text"
      />
      <DropdownContent shouldShow={showDropdown} isLoading={false}>
        <ul className="border-deepSleepBlue">
          {items?.filter( ( i ) => {
            if ( typeof textInputProps?.value !== `string` ) return false
            if ( customItemFilter ) return customItemFilter( i )
            const textInputValue = textInputProps.value.toLowerCase() || ``
            return i.toLowerCase().startsWith( textInputValue )
          }).map( ( s ) => (
            <li
              key={s} tabIndex={0} onClick={handleChildClick}
              onKeyDown={handleKeyDown}
              className="cursor-pointer p-2 hover:bg-secondaryBlue-tint2 hover:text-deepSleepBlue"
            >{s}</li>
          ) )}
        </ul>
      </DropdownContent>
    </DropdownContainer>
  )
})

export default DropdownTextInput