import React from 'react'
import { useDoctorStore } from '../hooks/state'
import LocationChangeForm from './LocationChangeForm'

type ZipDisplayProps = {
  zipDisplay: boolean;
  setZipDisplay: React.Dispatch<React.SetStateAction<boolean>>;
}

function ZipDisplay({ zipDisplay, setZipDisplay } : ZipDisplayProps ) : JSX.Element {
  const { doctorInput } = useDoctorStore()
  const { range, zipCode } = doctorInput

  if ( zipDisplay ) return <LocationChangeForm setZipDisplay={setZipDisplay} />

  return (
    <div>
      <div className="flex flex-col base:flex-row justify-between px-5 my-3">
        <p className="font-light">{`Within ${range} mile${range > 1 ? `s` : ``} from ${zipCode}`}</p>
        <p
          className="underline text-deepSleepBlue decoration-deepSleepBlue hover:opacity-80 cursor-pointer"
          onClick={() => { setZipDisplay( true ) }}
        >
          {`Change`}
        </p>
      </div>
    </div>

  )

}

export default ZipDisplay