import { TERMS_AND_CONDITIONS_PATH } from 'routes'

export default function TermsBlock() : JSX.Element {
  return (
    <div className="my-6">
      <p className="max-w-md text-center mx-auto text-sm">{`By placing this order, I provide my consent for Aeroflow Healthcare to send me recurring text messages and calls, including with marketing, and sent via an automatic dialer or prerecorded voice, at the number provided. Consent is not a condition of purchase of any goods or services. Reply “STOP” to stop at any time. Message and data rates may apply.`}
        <span className="text-center text-sm">{` I also agree to Aeroflow’s `} <a href={TERMS_AND_CONDITIONS_PATH} target="_blank" className="a" rel="noreferrer">{`Terms and Conditions`}</a></span>
      </p>
    </div>
  )
}