import React from 'react'
import { Builder } from '@builder.io/react'
import { ImageTextCtaCard } from './components/ImageTextCtaCard'
import { ContentCardGridTypes } from './types'
import { nightSkyBkgDsktp, nightSkyBkgMobile } from 'resources/images'
import { useMediaQuery } from 'react-responsive'
import { Container } from '../../Container'

type ContentCardGridProps = {
  blocks: ContentCardGridTypes[]
}

export const ContentCardGrid = ({ blocks }: ContentCardGridProps ) : JSX.Element => {
  const isMobile = useMediaQuery({
    query: `(max-width: 976px)`
  })

  return (
    <>
      {blocks && (
        <div
          className="flex sm:flex-col md:flex-row justify-evenly w-full max-w-full bg-aeroflowNavy bg-no-repeat bg-center bg-cover sm:pt-2 px-4"
          style={{backgroundImage: `url(${isMobile ? nightSkyBkgMobile : nightSkyBkgDsktp})`}}
        >
          <Container maxWidth={1280} classNames="sm:block md:flex md:gap-[70px]">
            {blocks.map( ( block ) => (
              <ImageTextCtaCard key={block.title} {...block} />
            )
            )}
          </Container>
        </div>
      )}
    </>
  )
}

Builder.registerComponent( ContentCardGrid, {
  name: `ContentCardGrid`,
  inputs: [
    {
      name: `blocks`,
      type: `list`,
      subFields: [
        {
          name: `imgSrc`,
          type: `file`,
          allowedFileTypes: [ `jpeg`, `jpg`, `png`, `svg`, `webp` ]
        },
        {
          name: `title`,
          type: `text`
        },
        {
          name: `description`,
          type: `text`
        },
        {
          name: `callToActionLink`,
          type: `url`
        },
        {
          name: `calltoActionValue`,
          type: `text`
        }
      ]
    }
  ]
})

export default ContentCardGrid
