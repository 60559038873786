import { gql } from '@apollo/client'

import { xSearchBlogsOperation, xSearchRecentSearchesOperation, xSearchPopularSearchesOperation } from '../operations/xsearch'

export const getBlogsRecentAndPopularSearchesQuery = gql`
  query getBlogsRecentAndPopularSearches($searchTerm: String) {
    ${xSearchBlogsOperation}
    ${xSearchRecentSearchesOperation}
    ${xSearchPopularSearchesOperation}
  }
`
