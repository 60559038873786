import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button } from 'components/Button'

export const CallToActionButton = ({to, children, ...buttonProps }) => {
  return (
    <Link to={to} className="items-center justify-center">
      <Button {...buttonProps}>{children}</Button>
    </Link>
  )
}

export default CallToActionButton

CallToActionButton.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node
}