import AddressBlock, { overrideAddressBlockStyle } from 'components/addressBlock'
import { Tile } from 'components/tile'
import { ChangePasswordPrompt, ContactBlock } from '../components/AccountInfo'
import { useAccountStore } from '../state'
import { myAccountInfoId } from '../constants'
import Error from '../components/Error'

export default function AccountInfo() {
  const { customer } = useAccountStore()
  overrideAddressBlockStyle({
    headerStyle: `font-bold mb-1 text-left`,
    containerStyle: ``,
    wrapperStyle: ``,
    view: {
      textStyle: `font-normal capitalize`,
      editActionClassName: `underline decoration-deepSleepBlue text-deepSleepBlue capitalize mb-2 cursor-pointer`
    }
  })

  if ( customer?.error ) return ( <Error title="Account Information" id={myAccountInfoId} fillInTheBlank="account information" /> )


  return (
    <Tile headerContent="Account Information" id={myAccountInfoId}>

      {/* Address is set in the resupply options hook in the banner so we can skip initial mount fetch */}
      <AddressBlock
        headerText="Shipping Address"
        hideAddressConfirm
        shouldSkipMountFetch
      />
      {
        ( customer?.data?.addresses?.length || 0 ) > 0 &&
        <ContactBlock />
      }
      {customer?.data?.email && <ChangePasswordPrompt email={customer?.data?.email} />}
    </Tile>
  )
}