
export const removeSearchParams = ( searchParams ) => {
  // remove token and id from url search params
  const adjustedSearch = new URLSearchParams( searchParams )
  adjustedSearch.delete( `afmcatoken` )
  adjustedSearch.delete( `afmcid` )

  return adjustedSearch.toString()
}


export const getEmailFromResetToken = async ( customer_id, token ) => {
  const emailResponse = await fetch( `${process.env.REACT_APP_MAGENTO_2_BASE_URL}/rest/V1/customers/getCustomerEmailByToken`, {
    method: `POST`,
    headers: {
      "Content-Type" : `application/json`
    },
    body: JSON.stringify({
      customer_id,
      token
    })
  })

  return await emailResponse.json()
}
