/**
 * Formats number values and returns $amount as a string
 * @param {Number} money - any number representation of money
 * @returns Money in the format of $00.00
 */
export const formatMoney = ( money ) => {
  return new Intl.NumberFormat( `en-US`, {
    style: `currency`,
    currency: `USD`
  }).format( money )
}

/** This function is used to split a dollar amount into a splitBy amount
 * @param {Number} money - number representation of a dollar amount
 * @param {Number} splitBy - The amount to split the money into
*/
export const splitMoney = ( money, splitBy ) => {
  const splitValue = Math.round( money * 100 ) / ( splitBy * 100 )

  return Math.floor( splitValue * 100 ) / 100
}