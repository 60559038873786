import { useEffect, useState } from 'react'
import { fetchAppointmentsByPage, getHash } from 'modules/clinicalScheduling/utils'
import { reportToSentry } from 'utils/reportToSentry'

export const useFetchAppointments = ( patientData, timeZone ) => {
  const [ appointments, setAppointments ] = useState( null )
  const [ appointmentError, setError ] = useState( null )
  const [ invoice, setInvoice ] = useState( null )
  const [ displayOptions, setDisplayOptions ] = useState( false )

  const defaultErrorMessage = `Oops there was an error when attempting to get the appointments for this month...`

  useEffect( () => {
    const controller = new AbortController()
    if ( patientData && timeZone ) {
      setAppointments( null )
      const hash = getHash()
      fetchAppointmentsByPage( patientData, timeZone, hash, controller.signal )
        .then( data => {
          if ( data?.error ) return setError( data.error )
          if ( data?.meta?.status === `OK` && data?.data?.availabilities && data?.data?.meta ) {
            const metaData = data.data.meta
            const appointmentData = data.data.availabilities
            // we should be getting back either an in person count or not in person count. If both are present something is wrong
            if ( metaData?.total_in_person === undefined || metaData?.total_not_in_person === undefined || ( metaData.total_in_person !== 0 && metaData.total_not_in_person !== 0 ) ) return setError( defaultErrorMessage )
            if ( metaData?.invoices?.length && metaData.invoices[0]?.invoice_number ) setInvoice( metaData.invoices[0].invoice_number )
            else return setError( defaultErrorMessage )
            setError( null )
            setDisplayOptions( metaData.total_in_person > 0 )

            return setAppointments( appointmentData )

          }

          return setError( defaultErrorMessage )
        })
        .catch ( error => {
          if ( error.name !== `AbortError` ) {
            setError( defaultErrorMessage )
            reportToSentry( `Clinical Scheduling: There was an error getting appointment data...`, {
              error
            })
          }
        })
    }

    return () => {
      controller.abort()
    }
  }, [ patientData, timeZone ] )

  return {
    appointments,
    displayOptions,
    invoice,
    error: appointmentError
  }
}