import React from 'react'
import TerritoryTeamSubList from './components/TerritoryTeamSubList'
import { Builder } from '@builder.io/react'
import { ContentProps } from './types'

const SalesTeamByTerritoryList = ( props: ContentProps ) => {
  return (
    <div className="px-4 mt-4 mb-4 w-full">
      {
        props?.territoryTeams?.length && props.territoryTeams.map( ( territoryTeam, index ) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div className="mb-6" key={index}>
              <div className="mb-2">
                <h4 className="text-deepSleepBlue font-light text-3xl sm:text-center lg:text-left" id={`territory-${territoryTeam.id}`}>
                  {territoryTeam.name}
                </h4>
                <TerritoryTeamSubList salesTeam={territoryTeam.salesTeam} />
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

Builder.registerComponent( SalesTeamByTerritoryList, {
  name: `SalesTeamByTerritoryList`,
  inputs: [{
    name: `territoryTeams`,
    type: `list`,
    subFields: [{
      name: `salesTeam`,
      type: `list`,
      subFields: [
        {
          name: `name`,
          type: `string`
        },
        {
          name: `role`,
          type: `string`
        },
        {
          name: `desc`,
          type: `string`
        },
        {
          name: `email`,
          type: `email`
        },
        {
          name: `img`,
          type: `string`
        }
      ]
    }
    ]
  },
  {
    name: `name`,
    type: `string`
  },
  {
    name: `id`,
    type: `string`
  }]
})

export default SalesTeamByTerritoryList