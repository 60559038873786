import { NavLink } from 'react-router-dom'
import { navItems } from './constants'

export default function LeftNav() {
  return (
    <div className="my-4">
      {navItems.map( ( item ) => {
        return (
          <NavLink
            key={item.name}
            end
            to={item.url}
            className={({ isActive }) => { return `p-2 font-normal text-aeroflowNavy cursor-pointer block ${isActive && `font-bold`}` }}
          >
            {item.name}
          </NavLink>
        )
      })}
    </div>
  )
}
