import React from 'react'

type SleepLinearGradientProps = {
    height?: number
}

function SleepLinearGradient({ height } : SleepLinearGradientProps ) : JSX.Element {
  return (
    <>
      <div
        className={`sleep-gradient-lightToDark`}
        style={{
          height: `${height || 1}px`
        }}
      >
      </div>
    </>
  )
}

export default SleepLinearGradient