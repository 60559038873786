import { gql } from '@apollo/client'

import { customerAddressFragment } from 'graphql/fragments'

export const createCustomerAddressMutation = gql`
  ${customerAddressFragment}
  mutation CreateCustomerAddressMutation($customerAddressInput: CustomerAddressInput!) {
    createCustomerAddress(input: $customerAddressInput) {
      ...CustomerAddress
    }
  }
`
