import { CheckboxInput } from 'components/forms/components'
import React from 'react'
import { TERMS_AND_CONDITIONS_PATH } from 'routes'

export type TermsCheckboxProps = {
  checked: boolean;
  setChecked?: React.Dispatch<React.SetStateAction<boolean>>;
  onChange?: ( _e: React.ChangeEvent ) => void;
}

export default function TermsCheckbox( props: TermsCheckboxProps ): JSX.Element {

  function handleCheck( e: React.ChangeEvent ) {
    props.onChange && props.onChange( e )
    props.setChecked && props.setChecked( !props.checked )
  }

  return (
    <div
      className="my-2 p-3 text-center flex cursor-pointer"
      // toggle checkbox state when clicking anywhere in the terms checkbox container
      onClick={() => handleCheck({
        target: {
          checked: !props.checked
        }
      } as unknown as React.ChangeEvent )} // bypass type check and treat as React.ChangeEvent
    >
      <CheckboxInput
        name="terms"
        aria-label="terms-checkbox"
        className="cursor-pointer mr-2 focus:outline-none rounded-full font-bold text-sm w-7 h-7"
        labelClassName="cursor-pointer"
        onChange={handleCheck}
        value={props.checked}
        data-testid="terms"
      />
      <span className="max-w-md text-center mx-auto sm:text-xs md:text-sm">
        {`I agree to Aeroflow Health’s dispute resolution and binding arbitration agreement, including Aeroflow’s `}
        <span className="text-center text-sm">
          <a
            href={TERMS_AND_CONDITIONS_PATH}
            target="_blank"
            rel="noreferrer"
            className="a underline"
          >{`Terms and Conditions`}</a>
        </span>
        {` and `}
        <span className="text-center text-sm">
          <a href="/privacy-policy" target="_blank" className="a underline">{`Privacy Policy`}</a>
        </span>
        {`.`}
      </span>
    </div>
  )
}