import { gql } from '@apollo/client'

/*
  This query adds a little bit of loading time to render all Authenticated Routes,
  but it helps prevent a potentially frustrating user experience.

  Without this, if the user's token expired, they would still be able to navigate to auth routes,
  but as soon as they clicked a button or link that made a request with the bad token, it would then
  redirect them to the homepage.

  This query checks the validatity of the token upfront every time they try to hit an authenticated route.
*/

export const heartbeatQuery = gql`
  query heartbeatQuery {
      customer {
        email
      }
  }
`
