import * as React from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'
import { patientIdFromAppServer } from 'apollo'
import { customerQuery, getProductsQueryBySkuArray } from 'graphql/queries'
import { reportToSentry } from 'utils/reportToSentry'
import { ResupplyItemOption, ResupplyOption, SwapMaskItem } from '../types'
import { useClaimedState, useContactInfoState, useResupplySetting } from './state'
import { ERROR_MESSAGE_RESUPPLY_OPTIONS_FAILED_TO_FETCH, ERROR_MESSAGE_RESUPPLY_OPTIONS_GENERIC } from './constants'
import { apolloClient } from 'apollo/apolloClient'
import { isCurrentDateOrPastDate } from 'utils/time'
import { serializeOptions } from 'utils/serializeResupplyOptions'
import { Formatters } from 'components/forms/components'
import { fetchResupplyOptions } from './fetchPromises'
import { Address, useAddressStore } from 'components/addressBlock'
import { useDoctorStore } from '../../../components/doctorBlock/hooks/state'

export const useFetchResupplyOptions = ( source = `resupply` ) => {

  const [ resupplyOptions, setResupplyOptions ] = React.useState<ResupplyOption[]>( [] )
  const [ error, setError ] = React.useState<string>( `` )
  const [ loading, setLoading ] = React.useState<boolean>( true )
  const [ hasAddOns, setHasAddOns ] = React.useState<boolean>( false )
  const [ replaceMaskOptions, setReplaceMaskOptions ] = React.useState<ResupplyOption[]>( [] )
  const [ refetchOptions, setRefetchOptions ] = React.useState<boolean>( false )

  const [ claimedItems, setClaimedItems ] = useClaimedState()
  const [ address, setAddress ] = useAddressStore( store => [ store.address, store.setAddress ] )
  const setResupplySetting = useResupplySetting()[1]
  const setContactInfo = useContactInfoState()[1]

  const backupPatientId = useReactiveVar( patientIdFromAppServer )

  const { data: customerData, loading : customerLoading, error : customerError } = useQuery( customerQuery )

  const {doctor} = useDoctorStore()

  React.useEffect( () => {

    let mounted = true
    const controller = new AbortController()

    const cleanUpFn = () => {
      mounted = false
      controller.abort()
    }

    const patientId = customerData?.customer?.patient_id ?? backupPatientId

    if ( !patientId ) {
      if ( !customerLoading ) {
        setLoading( false )

        return cleanUpFn
      }
    }

    if ( patientId ) {
      setLoading( true )
      fetchResupplyOptions( patientId, source, controller.signal ).then( async ( responseData ) => {

        if ( mounted ) {
          // TODO / NOTE: we might want to use the data here to populate doctor block and state payer information
          if ( !responseData ) throw new Error( `Error fetching response from get resupply options` )

          const { data } = responseData

          // for patients without any resupply items data below will be null
          if ( !data ) return cleanUpFn

          const skusArray : string[] = data?.items?.filter( ( item: ResupplyOption ) => {
            return item.magento_sku
          }).map( ( item : ResupplyOption ) => {
            const { magento_sku } = item

            return magento_sku.toString()
          })

          await handleReplacementMasksCheck( data?.items )

          const allSkuItemsResult = await apolloClient.query({
            query: getProductsQueryBySkuArray,
            fetchPolicy: `network-only`,
            variables: {
              skus: skusArray
            }
          })
            .catch( ( gqlError: Error ) => {

              reportToSentry( new Error( `Resupply: Error fetching magento data from item sku`, {
                cause: gqlError
              }), {
                skusArray: JSON.stringify( skusArray )
              })
            })

          skusArray.forEach( ( itemSku: string ) => {
            if ( !allSkuItemsResult?.data?.resupplyProducts?.items?.find( ( item: ResupplyItemOption ) => {
              return itemSku === item.sku
            }) ) {
              reportToSentry( `Resupply: No items returned with sku`, {
                itemSku
              })
            }
          })

          const unfilteredResupplyOptions : Array<ResupplyOption | null> = allSkuItemsResult?.data?.resupplyProducts?.items?.map( ( item: ResupplyItemOption ) => {

            const _imageUrl = item?.image?.url ?? ``

            const optionItem = data?.items?.find( ( optionItemElement: ResupplyOption ) => {
              return optionItemElement.magento_sku === item.sku
            })

            if ( !item?.sku || !optionItem ) {
              reportToSentry( new Error( `Reupply: A product with no sku was returned or sku mismatch` ), {
                optionItem
              })

              return null // We don't want to show the product if the sku's do not match
            }
            if ( !_imageUrl ) {
              reportToSentry( new Error( `Resupply: There was an item without an image` ), {
                optionItem
              })
            }

            return {
              imageUrl: _imageUrl ?? ``,
              ...optionItem
            }
          })

          const _resupplyOptions = unfilteredResupplyOptions?.length ? [ ...unfilteredResupplyOptions.filter( ( i ) : i is ResupplyOption => { return i !== null }) ] : []
          setHasAddOns( Boolean( _resupplyOptions.find( item => { return item.additional_item_flag }) ) )

          setResupplySetting({
            patientId,
            updateDoctorRequired: data?.doctor_info_update,
            updatePrimaryPayerStatus: data?.payer1_info_update,
            updateSecondaryPayerStatus: data?.payer2_info_update,
            medicareChecklistRequired: Boolean( _resupplyOptions.find( ( item ) => { return item.checklist_required }) ),
            proceedToPayment: data?.proceed_to_payment_flag,
            // doctor info so we don't have to pull from product pricing
            doctorFirstName: doctor?.firstName || data?.doctor_first_name,
            doctorLastName: doctor?.lastName || data?.doctor_last_name,
            doctorNpi: data?.doctor_npi,
            doctorPhone: doctor?.phone || data?.doctor_phone,
            resupplyAOBSettings: !data?.proceed_to_payment_flag ? null : {
              payer1_id: data?.payer1_id,
              payer1_name: data?.payer1_name,
              payer2_id: data?.payer2_id,
              payer2_name: data?.payer2_name,
              payer3_id: data?.payer3_id,
              payer3_name: data?.payer3_name,
              pay_later_eligible_flag: Boolean( data?.pay_later_eligible_flag ),
              deductible: data?.deductible,
              deductible_remaining: data?.deductible_remaining,
              oop: data?.oop,
              oop_remaining: data?.oop_remaining,
              coinsurance_percentage: data?.coinsurance_percentage,
              rx_required: Boolean( _resupplyOptions.find( ( item ) => { return item.rx_required }) )
            }

          })
          setResupplyOptions( [ ..._resupplyOptions ] )

          const claimableOptions = filterClaimable( [ ..._resupplyOptions ] )

          setClaimedItems( serializeOptions( claimableOptions ) ?? [] )
          setAddress({
            firstName: customerData?.customer?.firstname,
            lastName: customerData?.customer?.lastname,
            street: data?.patient_street,
            city: data?.patient_city,
            state: data?.patient_state,
            zipCode: data?.patient_zip
          } as Address )
          setContactInfo({
            email: customerData?.customer?.email ?? ``,
            phone: Formatters.phone( data?.items[0] ? ( data.items[0]?.phone ?? `` ) : `` )
          })
        }
      })
        .catch( err => {

          // fetchResupplyOptions function threw an error, could have been in fetch promise, or could be thrown explicitly by our code in .then above
          if ( err && err.name && err.message && err?.name !== `AbortError` && mounted && ( err.message !== `The user  aborted a request.` ) ){
            handleDefinedError( err )
          } else {
            if ( err?.name !== `AbortError` ){
              handleUndefinedError( err )
            }
          }

        })
        .finally( () => {
          if ( mounted ) {
            setLoading( false )
          }
        })
    }

    return cleanUpFn
  }, [ customerData, customerError, refetchOptions ] )

  const handleDefinedError = ( definedError: Error ) => {

    const { message } = definedError

    if ( message === `Failed to fetch` ){

      // could be a firewall issue
      setError( ERROR_MESSAGE_RESUPPLY_OPTIONS_FAILED_TO_FETCH )
      reportToSentry( new Error( `FAILED_TO_FETCH Error Resupply - possible firewall related`, {
        cause: definedError
      }) )
    }

    if ( message !== `Failed to fetch` ){
      // could be a number of issues. not firewall - but it is defined
      setError( ERROR_MESSAGE_RESUPPLY_OPTIONS_GENERIC )
      reportToSentry( new Error( `DEFINED not FAILED_TO_FETCH Error fetching resupply options`, {
        cause: definedError
      }) )
    }

  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUndefinedError = ( undefinedError: any ) => {

    // the error either doesn't register as anything when thrown, or doesn't have .name/.message properties
    setError( ERROR_MESSAGE_RESUPPLY_OPTIONS_GENERIC )
    reportToSentry( new Error( `UNDEFINED Error fetching resupply options`, {
      cause: undefinedError
    }) )
  }

  const triggerRefetchOptions = () => { setRefetchOptions( !refetchOptions ) }

  const filterClaimable = ( options: ResupplyOption[] ) => {
    return options?.filter( ( option: ResupplyOption ) => {
      return isCurrentDateOrPastDate( option.eligible )
    })
  }

  const handleReplacementMasksCheck = async ( allItems: ResupplyOption[] ) => {
    const maskToReplace:ResupplyOption | undefined = allItems?.find( ( item: ResupplyOption ) => { return item.mask_change_eligible })
    if ( maskToReplace && maskToReplace?.swap_masks?.length ) return await getReplacementMasks( maskToReplace?.swap_masks, maskToReplace )

    return setReplaceMaskOptions( [] )
  }

  const getReplacementMasks = async ( replacementMasksArray: SwapMaskItem[], maskToReplace: ResupplyOption ) => {
    const replacementSkusArray = replacementMasksArray.map( ( replacementMask: SwapMaskItem ) => { return replacementMask.swap_mask_sku })
    const replacementSkuItemsResult = await apolloClient.query({
      query: getProductsQueryBySkuArray,
      fetchPolicy: `network-only`,
      variables: {
        skus: replacementSkusArray
      }
    })
      .catch( ( gqlError: Error ) => {
        reportToSentry( new Error( `Resupply: Error fetching magento data from item sku`, {
          cause: gqlError
        }), {
          replacementSkusArray: JSON.stringify( replacementSkusArray ),
          maskToReplace: JSON.stringify( maskToReplace )
        })
      })

    replacementSkusArray.forEach( ( itemSku: string ) => {
      if ( !replacementSkuItemsResult?.data?.resupplyProducts?.items?.find( ( item: ResupplyItemOption ) => {
        return itemSku === item.sku
      }) ) {
        reportToSentry( new Error( `Resupply: No items returned with sku` ), {
          itemSku
        })
      }
    })

    setReplaceMaskOptions( replacementSkuItemsResult?.data?.resupplyProducts?.items?.filter( ( replacementItem: ResupplyItemOption ) => {
      return replacementMasksArray.find( ( maskItem: SwapMaskItem ) => { return replacementItem.sku === maskItem.swap_mask_sku }) !== undefined
    }).map( ( replacementItem: ResupplyItemOption ) => {
      return Object.assign({}, maskToReplace, {
        description: replacementItem.name,
        magento_sku: replacementItem.sku,
        hcpc_id: replacementMasksArray.find( ( maskItem: SwapMaskItem ) => { return replacementItem.sku === maskItem.swap_mask_sku })?.swap_mask_hcpc_id,
        imageUrl: replacementItem.image.url
      })
    })
    )
  }

  return {
    resupplyOptions: [ ...resupplyOptions ],
    error,
    loading,
    claimedItems,
    hasAddOns,
    shippingAddress: address,
    replaceMaskOptions,
    triggerRefetchOptions,
    hasAvailable: Boolean( resupplyOptions?.find( item => { return isCurrentDateOrPastDate( item.eligible ) }) )
  }
}