import { sleepLogoHorizontalBlack } from 'resources/images'

const Logo = () : JSX.Element => {

  return (
    <img
      className="object-contain"
      src={sleepLogoHorizontalBlack}
      alt={`Aeroflow Sleep Logo`}
      height={100}
      width={500}
      loading="lazy"
    /> )

}

export default Logo
