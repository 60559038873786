import React from 'react'
import debounce from 'lodash.debounce'
import { useQuery } from '@apollo/client'

import { apolloClient } from 'apollo/apolloClient'
import { getProductsBlogsAndPopularSearchesQuery, getBlogsRecentAndPopularSearchesQuery } from 'graphql/queries'
import { reportToSentry } from 'utils/reportToSentry'

import MainSearchIcon from './MainSearchIcon'
import SearchResults from './SearchResults'
import LoadingSpinner from 'components/LoadingSpinner'

const SearchBar = () => {
  const mediaQueryList = React.useMemo( () => { return window.matchMedia( `(max-width: 767px)` ) }, [] )

  const [ isMobile, setIsMobile ] = React.useState( mediaQueryList.matches )
  const [ loading, setLoading ] = React.useState( false )
  const [ displaySearchIcon, setDisplaySearchIcon ] = React.useState( true )
  const [ searchTerm, setSearchTerm ] = React.useState( `` )
  const [ products, setProducts ] = React.useState( [] )
  const [ resultsAreDisplayed, setResultsAreDisplayed ] = React.useState( false )
  const [ popularSearches, setPopularSearches ] = React.useState( [] )
  const [ recentSearches, setRecentSearches ] = React.useState( [] )
  const [ blogs, setBlogs ] = React.useState( [] )

  const mobileSearchBarRef = React.useRef( null )
  const desktopSearchBarRef = React.useRef( null )


  const { loading: initialLoad } = useQuery( getBlogsRecentAndPopularSearchesQuery, {
    onCompleted: ({ xsearchRecentSearches, xsearchBlogs, xsearchPopularSearches }) => {
      if ( xsearchRecentSearches?.items?.length ) setRecentSearches( xsearchRecentSearches.items )
      if ( xsearchBlogs?.items?.length ) setBlogs( xsearchBlogs.items )
      if ( xsearchPopularSearches?.items?.length ) setPopularSearches( xsearchPopularSearches.items )
    },
    onError: ( error ) => {
      reportToSentry( new Error( `There was an error retrieving search data`, {
        error
      }) )
      setRecentSearches( [] )
      setBlogs( [] )
      setPopularSearches( [] )
    }
  })

  React.useEffect( () => {
    const handleClickOutside = event => {
      if ( isMobile && !mobileSearchBarRef?.current?.contains( event.target ) || !isMobile && !desktopSearchBarRef?.current?.contains( event.target ) ) {
        setResultsAreDisplayed( false )
      }
    }

    document.addEventListener( `click`, handleClickOutside )

    const handleMediaQueryChange = event => {
      setIsMobile( event.matches )
    }

    mediaQueryList.addListener( handleMediaQueryChange )

    return () => {
      mediaQueryList.removeListener( handleMediaQueryChange )
      document.removeEventListener( `click`, handleClickOutside )
    }
  }, [ isMobile ] )

  const debouncedSearch = React.useCallback( debounce( async searchTerm => {
    setLoading( true )

    if ( searchTerm.length >= 2 ) {
      const { data } = await apolloClient.query({
        query: getProductsBlogsAndPopularSearchesQuery,
        variables: {
          searchTerm
        }
      }).catch( error => {
        reportToSentry( error, {
          searchTerm
        })
        setProducts( [] )
        setBlogs( [] )
        setPopularSearches( [] )
      })
      if ( data?.xsearchProducts?.items?.length ) setProducts( data.xsearchProducts.items )
      if ( data?.xsearchBlogs?.items?.length ) setBlogs( data.xsearchBlogs.items )
      if ( data?.xsearchPopularSearches?.items?.length ) setPopularSearches( data.xsearchPopularSearches.items )
    }

    setLoading( false )
    setResultsAreDisplayed( true )
  }, 300 ), [] )

  const handleChange = event => {
    const { value } = event.currentTarget

    setSearchTerm( value )

    debouncedSearch( value )
  }

  const toggleDisplaySearchIcon = () => {
    setDisplaySearchIcon( !displaySearchIcon )
  }

  const handleCancelClick = () => {
    setSearchTerm( `` )
    setProducts( [] )
    setResultsAreDisplayed( false )
  }

  const handleDesktopInputFocus = () => {
    toggleDisplaySearchIcon()
    setResultsAreDisplayed( true )
  }

  const handleSearchEnter = ( e ) => {
    if ( e.key === `Enter` && searchTerm ) window.open( `${process.env.REACT_APP_MAGENTO_2_BASE_URL}/catalogsearch/result/?q=${searchTerm}`, `_self` )
  }

  // Mobile Search Bar
  if ( isMobile ) {
    return (
      <>
        <form
          className="flex relative row-start-2 col-span-full items-center w-full pt-2"
          ref={mobileSearchBarRef}
          onSubmit={event => { return event.preventDefault() }}
        >
          <input
            id="search-input"
            type="text"
            className="border pl-4 pr-6 py-1 default-input w-full"
            placeholder={`Search…`}
            value={searchTerm}
            onChange={handleChange}
            onFocus={toggleDisplaySearchIcon}
            onBlur={toggleDisplaySearchIcon}
            onKeyDown={handleSearchEnter}
          />
          <label htmlFor="search-input" className="cursor-pointer absolute right-3 flex items-center">
            {loading
              ? <LoadingSpinner rings={3} height="24px" width="24px" />
              : <MainSearchIcon displaySearchIcon={false} handleCancel={handleCancelClick} />
            }
          </label>
          <SearchResults
            results={products}
            loading={loading}
            resultsAreDisplayed={resultsAreDisplayed}
            searchTerm={searchTerm}
            popularSearches={popularSearches}
            recentSearches={recentSearches}
            blogs={blogs}
          />
        </form>
      </>
    )
  }

  // Desktop Search Bar
  return (
    <form
      className="flex relative justify-end items-center"
      ref={desktopSearchBarRef}
      onSubmit={event => { return event.preventDefault() }}
    >

      <input
        id="search-input"
        type="text"
        placeholder={`Search…`}
        className="pl-4 pr-6 py-1 sm:hidden md:inline default-input  md:w-5/6 xl:w-full"
        onFocus={handleDesktopInputFocus}
        onBlur={toggleDisplaySearchIcon}
        value={searchTerm}
        onChange={handleChange}
        onKeyDown={handleSearchEnter}
      />

      <label htmlFor="search-input" className="cursor-pointer absolute right-3 flex items-center">
        {loading
          ? <LoadingSpinner rings={3} height="24px" width="24px" />
          : <MainSearchIcon
            displaySearchIcon={displaySearchIcon}
            handleCancel={handleCancelClick}
            searchTerm={searchTerm}
          />
        }
      </label>

      <SearchResults
        results={products}
        loading={loading || initialLoad}
        resultsAreDisplayed={resultsAreDisplayed}
        searchTerm={searchTerm}
        popularSearches={popularSearches}
        recentSearches={recentSearches}
        blogs={blogs}
      />
    </form>
  )
}

export default SearchBar
