import React from 'react'
import { MONTHS } from 'modules/clinicalScheduling/utils/constants'
import { SelectInput } from 'components/forms/components'

type MonthSelectorProps = {
  fromMonth: Date;
  toMonth: Date;
  selectedMonth: Date;
  handleMonthSelection( _m: Date ): void;
}

function MonthSelector({fromMonth, toMonth, selectedMonth, handleMonthSelection }: MonthSelectorProps ) : JSX.Element {

  const filteredMonths = MONTHS.filter( ( _month: string, i: number ) => {
    const checkMonthDate = new Date( fromMonth.getMonth() < i ? fromMonth.getFullYear() : toMonth.getFullYear(), i )

    return ( ( fromMonth <= checkMonthDate && checkMonthDate <= toMonth ) || fromMonth.getMonth() === checkMonthDate.getMonth() )
  }).map( ( month: string ) => {
    const monthIndex = MONTHS.findIndex( monthLabel => {
      return monthLabel === month
    })
    const monthDate = new Date( fromMonth.getMonth() < monthIndex ? fromMonth.getFullYear() : toMonth.getFullYear(), monthIndex )

    return {
      label: month,
      date: monthDate
    }
  })

  return (
    <div className="gap-4 flex">
      <SelectInput
        name="month_selector"
        displayLabel={false}
        onChange={( e ) => {
          const monthDate = filteredMonths.find( month => {
            return e.target.value === month.label
          })

          return handleMonthSelection( monthDate?.date ?? new Date() )
        }}
        value={MONTHS[selectedMonth.getMonth()]}
        className="month-input text-center"
      >
        {
          filteredMonths.map( ( month: { label: string;}) => {
            return (
              <option key={month.label} value={month.label}>
                {month.label}
              </option>
            )
          })
        }
      </SelectInput>
      <p className="text-2xl m-auto font-light">{selectedMonth.getFullYear()}</p>
    </div>
  )
}

export default MonthSelector