import React, { useState } from 'react'
import styled from 'styled-components'
import { ImageBlockTypes } from '../types'

const HoverEffectImg = styled.img`
  transition: .3s ease-in-out;
  :focus, :hover {
    transform: scale(1.2);
    cursor: pointer;
  }
`
export const ImageBlock = ({ imgSrc, hoverImgSrc, href, title }: ImageBlockTypes ): JSX.Element => {
  const [ hover, setHover ] = useState( false )

  return (
    <div className="sm:w-1/4 md:w-1/5">
      <a href={href} target="_blank" rel="noreferrer">
        <HoverEffectImg
          src={hover ? hoverImgSrc : imgSrc}
          alt={`As seen on ${title}`}
          onMouseEnter={() => setHover( true )}
          onMouseLeave={() => setHover( false )}
        />
      </a>
    </div>
  )
}

export default ImageBlock