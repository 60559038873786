import { useEffect } from 'react'
import { fetchPatientInvoice } from "modules/aobPayments/fetchPromises"
import { getHash } from 'modules/clinicalScheduling/utils'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'

const useGetInvoiceByHash = ( type, setInvoice, setError, setInvoiceIneligible ) => {

  const hash = getHash()

  useEffect( () => {
    if ( type === `hash` ) {
      const controller = new AbortController()
      fetchPatientInvoice( hash, controller.signal ).then( ( data ) => {
        // check if we got eligible invoice back from response and if not assume invoice is eligible
        if ( data?.data && data?.meta?.status === `OK` ) {
          const invoice = data.data

          sessionStorage.setItem( `invoice-control`, JSON.stringify({
            hash: hash,
            fetchEnabled: false,
            data: invoice
          }) )

          return setInvoice( data.data )
        } else {
          if ( data?.errors?.length && data.errors[0].message === `invoice found but not eligible for payment` ) return setInvoiceIneligible( true )

          return setError( `Oops... We are having trouble getting your price estimate. Please try again or reach out to customer service at ${AF_SUPPORT_PHONE_NUMBER}` )
        }
      })
        .catch( () => {
          setError( `Oops... We are having trouble getting your price estimate. Please try again or reach out to customer service at ${AF_SUPPORT_PHONE_NUMBER}` )
        })
    }
  }, [] )
}

export default useGetInvoiceByHash