import React from 'react'
import TrackerStepIcon from '../../TrackerStepIcon'
import { personWithMaskWhite } from 'resources/images'
import StepContentRenderer from '../../StepContentRenderer'
import { convertIsoDateToDateString, dateHasPassed } from 'components/accountTracker/utils/utils'
import { RESUPPLY_CLAIMS_ITEMS_PATH } from 'modules/resupply2'

type SleepComplianceStepProps = {
    stepStatus: boolean;
    isCurrentStep?: boolean;
    isoResupplyDate: string;
}

function SleepComplianceStep({ stepStatus, isCurrentStep, isoResupplyDate } : SleepComplianceStepProps ) : JSX.Element {
  const getResupplyDate = convertIsoDateToDateString( isoResupplyDate )
  const resupplyDateHasPassed = dateHasPassed( isoResupplyDate )

  const cpapUsageStatusCopy = {
    notCompletedStepHeading: `Sleep Compliance Met`,
    completedStepHeading: `Sleep Compliance Met`,
    completedStepDetails: stepStatus && !resupplyDateHasPassed && getResupplyDate.date ? `You can order your Fresh PAP Supplies on ${getResupplyDate.date}.` : ``
  }

  return (
    <div className="flex flex-wrap">
      <TrackerStepIcon
        imageSrc={personWithMaskWhite}
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
      />
      <StepContentRenderer
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
        stepCopy={cpapUsageStatusCopy}
      />

      {/* render this link based on the resupply date and step status */}
      {stepStatus && resupplyDateHasPassed && (
        <a href={RESUPPLY_CLAIMS_ITEMS_PATH} className="text-sm text-sleepBlue font-medium pl-[83px] mt-[-36px] flexgrow">
          {`Reorder Fresh PAP Supplies`}
        </a>
      )}

    </div>
  )
}

export default SleepComplianceStep