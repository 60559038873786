import React from 'react'
import { GroupBtnsWrapper } from './styled'
import { TIME_ZONES } from './constants'

type TimezoneSelectorProps = {
  timezone: string | null;
  setTimezone( _value: string | null ): void;
}

const TimezoneSelector = ({
  timezone,
  setTimezone
}: TimezoneSelectorProps ) : JSX.Element => {
  return (
    <GroupBtnsWrapper>
      {TIME_ZONES.map( ({ name, label }) => {
        return (
          <div
            key={name}
            onClick={() => { return setTimezone( name ) }}
            className={`w-16 lg:w-20 p-1 lg:pb-2 text-lg lg:text-xl font-light text-center text-white cursor-pointer ${name === timezone ? `bg-deepSleepBlue` : `bg-sleepBlue`} hover:bg-deepSleepBlue`}
          >
            {label}
          </div>
        )
      })}
    </GroupBtnsWrapper>
  )
}

export default TimezoneSelector
