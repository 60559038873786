import React from 'react'
import { Builder } from '@builder.io/react'
import { HeroNav } from './components/heroNavBlocks/HeroNav'
import { SleepLinearGradient } from 'components/sleepLinearGradient'
import { NavBlockTypes, HeaderCtaTypes } from './types'
import { nightSky1Wide } from 'resources/images'

export const HomePageHero = ( props: { navBlocks: NavBlockTypes[]; headerCtas: HeaderCtaTypes }) : JSX.Element => {
  return (
    <>
      <div
        className="bg-aeroflowNavy sm:px-4 md:px-6 z-10 bg-cover bg-no-repeat"
        style={{backgroundImage: `url(${nightSky1Wide})`}}>
        <div className="max-w-[1280px] mx-auto">
          {props?.navBlocks &&
            <HeroNav blocks={props.navBlocks} headerCta={props.headerCtas} /> // each navBlock will create one nav block with mobile and desktop components
          }
        </div>
      </div>
      <SleepLinearGradient height={20} />
    </>
  )
}

Builder.registerComponent( HomePageHero, {
  name: `HomePageHero`,
  inputs: [
    { // nav blocks at the top of the hero
      name: `navBlocks`,
      type: `list`,
      subFields: [
        {
          name: `blockTitle`,
          type: `text`
        },
        {
          name: `desktopNavBlockImage`,
          type: `file`,
          allowedFileTypes: [ `jpeg`, `jpg`, `png`, `svg`, `webp` ],
          required: true,
          helperText: `Upload .jpeg, .jpg, .png, .svg, or .webp`
        },
        {
          name: `mobileNavBarIcon`,
          type: `file`,
          allowedFileTypes: [ `jpeg`, `jpg`, `png`, `svg`, `webp` ],
          required: true,
          helperText: `Upload .jpeg, .jpg, .png, .svg, or .webp`
        },
        {
          name: `blockUrl`,
          type: `text`
        },
        {
          name: `blockLinks`,
          type: `list`,
          subFields: [
            {
              name: `ctaName`,
              type: `text`,
              required: true
            },
            {
              name: `mobileCtaImage`,
              type: `file`,
              allowedFileTypes: [ `jpeg`, `jpg`, `png`, `svg`, `webp` ],
              required: true,
              helperText: `Upload .jpeg, .jpg, .png, .svg, or .webp`
            },
            {
              name: `ctaDetails`,
              type: `text`,
              required: true
            },
            {
              name: `buttonText`,
              type: `text`,
              required: true
            },
            {
              name: `url`,
              type: `text`,
              required: true
            }
          ]
        }
      ]
    },
    { // header CTA at the bottom of the hero
      name: `headerCtas`,
      type: `object`,
      subFields: [
        {
          name: `header`,
          type: `text`,
          required: true
        },
        {
          name: `subHeader`,
          type: `text`,
          required: true
        },
        {
          name: `headerButtonText`,
          type: `text`,
          required: true
        },
        {
          name: `headerButtonUrl`,
          type: `text`,
          required: true
        }
      ]
    }
  ]
})

export default HomePageHero