import { useEffect, useState } from 'react'
import PageNavigator from 'components/pageNavigator'
import { MachineBrand, MachineModel, MaskBrand, MaskModel, MaskType, SnlOrderSummary, SnlThankYou } from './index'
import { fetchSubmitNotInterestedReason, setupSessionTracking, submitSleepNewLead, updateSessionTracking } from './fetch'
import DoctorAddressCollection from './DoctorAddressCollection/DoctorAddressCollection'
import LoadingSpinner from '../../components/LoadingSpinner'
import { blockLeadsDisplay } from 'apollo'
import { reportToSentry } from 'utils/reportToSentry'
import {
  machineEligibleProgress,
  machineIneligibleProgress,
  pageNames,
  progressData,
  SNL_MACHINE_BRAND_PATH,
  SNL_MACHINE_MODEL_PATH,
  SNL_ORDER_SUMMARY_PATH,
  SNL_THANK_YOU_PATH
} from './constants'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { cmsBlocksQuery } from 'graphql/queries/cmsBlocks'
import { addMagentoEnvAuthHeaders } from 'utils/magentoEnvAuthHeaders'
import useCheckSAPRepeat from './hooks/useCheckSAPRepeat'
import { PhoneLink } from 'components/ALinks'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import { useQualifyFormStore } from 'modules/qualifyForm/state'
import { useAddressStore } from 'components/addressBlock'
import { useSnlPatientHashStore } from 'stores/snlPatientHashStore'
import { LOGIN_PATH } from 'routes'
import { getAuthHeader } from 'utils/auth/helpers'
import { getSessionData, useSNLStore } from './State/snlStorage'
import VerticalSNLStatusContainer from './Components/VerticalSNLStatusContainer'
import { useMediaQuery } from 'react-responsive'
import Logo from 'components/headerBarV2/Logo.tsx'
import { trackAnalyticEvent } from 'utils/analytic'
import { SleepLinearGradient } from 'components/sleepLinearGradient'
import CongratsModal from 'components/congratsModal'
import { useHealthTapStore } from 'modules/sleepNewLeads/State/healthTapStore'
import { useTokenStore } from 'stores/tokenStore'
import { useQualifyFieldsStore } from 'stores/qualifyFieldsStore'
import { getProductPricing } from 'utils/patientInfoApi'

// @NOTE still getting the first page of SNL to display when we block a user for a few ms
// need to likely have a finished state for the patient routing check to prevent this

const SleepNewLeadsController = () => {
  const sessionData = getSessionData()

  const [ loading, setLoading ] = useState( false )
  const [ highestStep, setHighestStep ] = useState( 0 )
  const [ activeStep, setActiveStep ] = useState( 0 )
  const [ displaySubmitError, setDisplaySubmitError ] = useState( false )
  const [ machineEligible, setMachineEligible ] = useState( true )
  const [ progressStatus, setProgressStatus ] = useState( progressData )

  const [ formValues, setFormValues ] = useState({
    ...useSNLStore.formValues
  })

  const navigate = useNavigate()
  const { snlPatientHash } = useSnlPatientHashStore( state => state )
  const { processId: snlProcessId, formCode: snlFormCode } = useQualifyFormStore( state => state )
  const { formFields, setFormFields } = useQualifyFieldsStore()
  const { addressPatientConfirmed, addressWebConfirmed } = useAddressStore()
  const { repeatMessage, loading: patientRoutingLoading } = useCheckSAPRepeat()
  const { snlUpdateSession, snlClearSession, setDoesNotWantMask, fromEnabledAccount } = useSNLStore()
  const { healthtapSelected } = useHealthTapStore()
  const { customerToken } = useTokenStore()
  const { setAddress, address } = useAddressStore()
  // useMedchatWidget( `BiebIPQTk0W_5dbXKQwGgg` )

  const pageHasLoaded = !loading && !patientRoutingLoading

  const [ getMaskFittingContent ] = useLazyQuery( cmsBlocksQuery, {
    errorPolicy: `all`,
    variables: {
      identifiers: `snl-mask-fitting-widget`
    }
  })

  const isMobile = useMediaQuery({
    query: `(max-width: 976px)`
  })

  if ( !snlPatientHash && !customerToken ) return navigate( LOGIN_PATH )

  useEffect( () => {
    if ( fromEnabledAccount ) {
      // if coming from enabled account we won't have these values from the qualify form so set them for later use
      getProductPricing( true ).then( data => {
        if ( data && data?.data && data.data?.patient ) {
          const patientData = data.data.patient
          const dob = patientData?.dob?.split( `-` )
          setFormFields({
            ...formFields,
            username: patientData?.email_address,
            insuranceType: patientData.payers?.payer1_name,
            memberID: patientData?.payers?.payer1_hic,
            secondaryInsuranceType: patientData?.payers?.payer2_name,
            secondaryMemberID: patientData?.payers?.payer2_hic,
            state: patientData?.address?.state,
            zipCode: patientData?.address?.zip,
            dobYear: dob[0],
            dobMonth: dob[1],
            dobDay: dob[2]?.substring( 0, 2 ),
            firstName: patientData?.first_name,
            lastName: patientData?.last_name,
            phoneNumber: patientData?.phone
          })
          setAddress({
            ...address,
            state: patientData?.address?.state,
            zipCode: patientData?.address?.zip,
            firstName: patientData?.first_name,
            lastName: patientData?.last_name
          })
        }
      })
        .catch( () => {
          reportToSentry( new Error( `Product Pricing Error: SNL` ) )
          navigate( `/` )
        })
    } else setupSessionTracking( snlPatientHash, formFields.username )
  }, [] )

  /* Initialize Cache State */
  useEffect( () => {
    let isMounted = true
    const unmount = () => { return isMounted = false }

    blockLeadsDisplay( false )

    // Check if snlPatientHash is null and navigate if the component is still mounted
    if ( !snlPatientHash && !customerToken && isMounted ) {
      snlClearSession()
      navigate( LOGIN_PATH )
      return unmount
    }

    if ( sessionData.formValues && isMounted ) {
      setFormValues( sessionData.formValues )
      setActiveStep( sessionData.activeStep || 1 )
      setHighestStep( sessionData.highestStep || 1 )
    }

    return unmount
  }, [ snlPatientHash ] )

  /* Update Cache State */
  useEffect( () => {
    if ( Object.values( formValues ).length && window.location.pathname !== SNL_THANK_YOU_PATH ) {
      snlUpdateSession( formValues, activeStep, highestStep )
    }

    // reset doesNotWantMask flag
    if ( formValues.cpapMaskType || formValues.cpapMaskBrand || formValues.cpapMaskModel ) setDoesNotWantMask( false )
  }, [ formValues, activeStep, highestStep ] )

  useEffect( () => {
    if ( machineEligible ) {
      setProgressStatus( Object.assign({}, progressStatus, {
        machine: machineEligibleProgress
      }) )
    } else {
      setProgressStatus( Object.assign({}, progressStatus, {
        machine: machineIneligibleProgress
      }) )
    }
  }, [ machineEligible ] )


  const onHandleChange = newValues => {
    const newFormVals = Object.assign({}, formValues, newValues )
    setFormValues( newFormVals )
  }

  const onHandleDoesNotWantMask = () => {
    const updatedFormValues = {
      ...formValues,
      cpapMaskDmeID: ``,
      cpapMaskBrand: ``,
      cpapMaskModel: ``,
      cpapMaskType: ``,
      cpapMaskSize: ``
    }
    setFormValues( updatedFormValues )
    setDoesNotWantMask( true )

    if ( formValues.cpapMachineDmeID && formValues.cpapMachineBrand && formValues.cpapMachineModel ) {
      return navigate( SNL_ORDER_SUMMARY_PATH )
    }

    if ( formValues.cpapMachineBrand ) {
      return navigate( SNL_MACHINE_MODEL_PATH )
    }

    return navigate( SNL_MACHINE_BRAND_PATH )
  }

  const handleSubmitNewLeadNoMachine = async () => {
    setLoading( true )
    const submitData = {
      lastOrderCpapMachine: formValues.lastOrderCpapMachine
    }
    const headers = {
      ...addMagentoEnvAuthHeaders(),
      "Authorization": getAuthHeader()
    }

    await submitSleepNewLead( submitData, headers, snlFormCode, snlProcessId ).then( ( data ) => {

      if ( !data || data?.message !== `Submitted` || ( data?.status !== 201 && data?.status !== 200 ) ) {

        // for these leads we simply get back data.data.success && data.meta.status === `OK`
        // so here we only want to fire to sentry if the above are true and the nested if below is true

        if ( !data?.data?.success || data?.meta?.status !== `OK` ){
          // @TODO if there is an issue here we need to not progress the user to the thank you page
          // need to display the error and allow them to try again
          reportToSentry( new Error( `Sleep New Leads w/ No Machine Submission Failure` ), {
            payload: submitData
          })
        }

      }
      setLoading( false )
      blockLeadsDisplay( true )

      updateSessionTracking( `PatientHash ${snlPatientHash}`, true )

      return navigate( SNL_THANK_YOU_PATH )

    })
      .catch( ( error ) => {
        reportToSentry( new Error( `Sleep New Leads w/ No Machine Submission Failure`, {
          cause: error
        }), {
          payload: submitData
        })
        blockLeadsDisplay( true )
        setLoading( false )
        return navigate( SNL_THANK_YOU_PATH )
      })
  }

  const handleSubmitNewLead = () => {
    setLoading( true )
    const submitData = {
      step_complete: 3,
      ...( formValues.cpapMachineDmeID ? {
        cpap_machine_dmeid: formValues.cpapMachineDmeID,
        cpap_machine_brand: formValues.cpapMachineBrand,
        cpap_machine_model: formValues.cpapMachineModel
      } : {}),
      last_order_cpapsupplies: `GT6MOS`, // default
      last_order_cpapmachine: formValues.lastOrderCpapMachine,
      ...( formValues.cpapMaskDmeID ? {
        cpap_mask_dmeid: formValues.cpapMaskDmeID
      } : {}),
      ...( formValues.cpapMaskBrand ? {
        cpap_mask_brand: formValues.cpapMaskBrand
      } : {}),
      ...( formValues.cpapMaskModel ? {
        cpap_mask_model: formValues.cpapMaskModel
      } : {}),
      ...( formValues.cpapMaskSize ? {
        cpap_mask_size: formValues.cpapMaskSize
      } : {}),
      tubing_dmeid: 12804, // default
      last_order_cpap_mask: `GT3MOS`, // default
      last_order_cpap_mask_headgear: `GT6MOS`, // default
      tubing_type: `Heated tubing`, // default
      satisfied_with_current_mask: true, // default
      intent_order_new_equipment: false, // default
      web_confirmed: addressWebConfirmed,
      patient_confirmed: addressPatientConfirmed,
      healthtap_selected: healthtapSelected
    }

    const headers = {
      ...addMagentoEnvAuthHeaders(),
      "Authorization": getAuthHeader()
    }

    submitSleepNewLead( submitData, headers, snlFormCode, snlProcessId ).then( ({ data, meta }) => {
      setLoading( false )
      if ( data?.success && meta?.status === `OK` ) {
        trackAnalyticEvent( `complete_registration` )
        updateSessionTracking( `PatientHash ${snlPatientHash}`, true )
        sessionStorage.setItem( `sleep_apnea_profile_complete`, true )
        blockLeadsDisplay( true )
        return navigate( SNL_THANK_YOU_PATH )
      }

      setDisplaySubmitError( true )

    })
      .catch( ({status, message}) => {
        setLoading( false )
        reportToSentry( new Error( `Sleep New Leads Submit failed with ${message}`, {
          payload: submitData,
          error: message,
          status: status
        }) )

        return setDisplaySubmitError( true )
      })
  }

  const handleOnSubmit = () => {
    setDisplaySubmitError( false )
    handleSubmitNewLead()
  }

  const handlePageNavigatorChange = ( newPageIndex ) => {
    setActiveStep( newPageIndex + 1 )
  }

  const handleNoLongerInterested = ( reason ) => {
    setLoading( true )
    // submit not interested reason in the background
    fetchSubmitNotInterestedReason( reason ).then( () => {
      setLoading( false )
      return navigate( SNL_THANK_YOU_PATH )
    })
      .catch( error => {
        setLoading( false )
        reportToSentry( new Error( `SNL Machine Shortage: Not Interested Submission Failure`, {
          cause: error
        }), {
          reason
        })
        return navigate( SNL_THANK_YOU_PATH )
      })
  }

  if ( repeatMessage ){
    return (
      <div className="my-16 max-w-lg mx-auto text-center px-4">
        <p className="md:text-lg">
          {repeatMessage}
          <PhoneLink displayNumber={AF_SUPPORT_PHONE_NUMBER} />
        </p>
      </div>
    )
  }

  return (
    <>
      <SleepLinearGradient height={34} />
      {!pageHasLoaded ? (
        <div className="my-16"><LoadingSpinner /></div>
      ) : (
        <div className="flex min-h-screen">
          <CongratsModal />
          {
            !isMobile && activeStep < 7 &&
            <VerticalSNLStatusContainer activeStep={activeStep} />
          }
          <div className={`sm:mx-auto ${activeStep < 7 ? `w-full lg:mx-0` : `lg:mx-auto`} sm:px-5 lg:px-[60px]`}>
            <div className="mx-auto sm:mb-5 md:mb-[60px] sm:mt-4 md:mt-20 relative">
              <div className={`flex-grow flex flex-row items-center sm:justify-center ${activeStep < 7 ? `lg:justify-start` : `lg:justify-center`}`}>
                <Logo withoutLink />
              </div>
            </div>
            <PageNavigator
              pageNames={pageNames}
              childSharedProps={
                {
                  onHandleChange,
                  formValues,
                  handleNoLongerInterested,
                  onHandleDoesNotWantMask,
                  activeStep
                }
              }
              onChange={handlePageNavigatorChange}
              pageClassName="mb-32 relative max-w-4xl"
            >
              <MaskType getMaskFittingContent={getMaskFittingContent} />
              <MaskBrand />
              <MaskModel />
              <MachineBrand setMachineEligible={setMachineEligible} submitLeadNoMachine={handleSubmitNewLeadNoMachine} />
              <MachineModel />
              <SnlOrderSummary />
              <DoctorAddressCollection onSubmit={handleOnSubmit} displaySubmitError={displaySubmitError} isSleepNewLeads />
              <SnlThankYou />
            </PageNavigator>
          </div>
        </div>

      )}
    </>
  )
}

export default SleepNewLeadsController