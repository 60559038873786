import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { accountMenuIsActive } from 'apollo/clientState'

const LocationChangeFactory = () => {

  let location = useLocation()

  // @NOTE all listeners on location change should be called from useEffect in this component

  useEffect( () => {

    if ( location.pathname === `/` && location.search === `?snafu=398e30a4-609f-45bf-aef5-0be51910552a` ){
      sessionStorage.setItem( `snafu`, `398e30a4-609f-45bf-aef5-0be51910552a` )
    }

    // close account mega mini menu
    accountMenuIsActive( false )
    // Scroll to top if component was redirected from a lower position
    window.scrollTo( 0, 0 )
  }, [ location ] )

  return null
}

export default LocationChangeFactory