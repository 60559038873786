import React from 'react'
import Head from './Head'
import { home as homeCopy, qualify as qualifyCopy, refer as referCopy } from './copy'

export const HomeHead = () => {
  return <Head {...homeCopy} />
}

export const QualifyHead = () => {
  return <Head {...qualifyCopy} />
}

// refer a friend disabled 03-14-22, may be re-enabled
export const ReferAFriendHead = () => {
  return <Head {...referCopy} />
}