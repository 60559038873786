import React from 'react'
import PropTypes from 'prop-types'

const SubmenuItem = ({ title, items, url }) => {

  return (
    <div className="font-thin ml-4 py-2">
      { url && <a className="cursor-pointer font-normal text-aeroflowNavy" href={url}>{title}</a>}
      { !url && <span className="font-normal text-aeroflowNavy">{title}</span>}
      {items.map( ( item ) => {

        let url = item.url === null ? `/#` : `${process.env.REACT_APP_MAGENTO_2_BASE_URL}${item.url}`
        // Hardcoded blog for now
        if ( item.title === `Blog` ) {
          url = `${process.env.REACT_APP_MAGENTO_2_BASE_URL}/blog`
        }
        // if it's an absolute url respect it - AFS-708
        if ( item?.url?.startsWith( `https://` ) ){
          // eslint-disable-next-line prefer-destructuring
          url = item.url
        }

        return <div className="py-2" key={item.title}><a href={url}>{item.title}</a></div>
      })}
    </div>
  )
}

export default SubmenuItem

SubmenuItem.propTypes = {
  title: PropTypes.string,
  items : PropTypes.arrayOf( PropTypes.object ),
  url: PropTypes.string
}