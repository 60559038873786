import PropTypes from 'prop-types'

function PricingEstimateBlockWrapper({children}) {
  return (
    <div className="shadow-[0px_4px_4px_0px_rgba(0,0,0,0.15)] w-full py-4 md:px-10 sm:px-4 bg-white flex flex-col items-center justify-center relative font-light">
      {children}
    </div>
  )
}

PricingEstimateBlockWrapper.propTypes = {
  children: PropTypes.node
}

export default PricingEstimateBlockWrapper