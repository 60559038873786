import React from 'react'
import { useDoctorStore } from '../hooks/state'
import { Formatters } from 'components/forms/components'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'

type CurrentDoctorProps = {
  setSearchActive: React.Dispatch<React.SetStateAction<boolean>>;
  headerStyle?: string;
  containerStyle?: string, isMyAccount?: boolean }

function CurrentDoctor({ isMyAccount = false, setSearchActive, headerStyle, containerStyle } : CurrentDoctorProps ) : JSX.Element {
  const { doctor } = useDoctorStore()

  if ( isMyAccount ) return (
    <div className="flex flex-col">
      <div className="flex gap-4 items-center justify-start">
        <p className="font-bold">{`Physician`}</p>
        <p
          className="text-deepSleepBlue cursor-pointer underline"
          onClick={() => { setSearchActive( true ) }}
        >
          {`Edit`}
        </p>
      </div>
      <div className="flex flex-col">
        <p className="capitalize">{`Dr. ${doctor?.firstName?.toLowerCase()} ${doctor?.lastName?.toLowerCase()}`}</p>
        <a href={`tel:+${doctor?.phone?.replaceAll( /[^0-9]/g, `` )}`} className="text-deepSleepBlue underline">
          <span>{Formatters.phone( doctor?.phone )}</span>
        </a>
        {!doctor?.npi && <p className="text-sm text-red-700 mt-4">
          {`We were unable to verify your doctor’s information. Please try re-entering your doctor’s information or contact customer service at `}
          <a href={`tel:+${AF_SUPPORT_PHONE_NUMBER.replaceAll( /[^0-9]/g, `` )}`} className="decoration-red-700 underline">
            <span className="font-semibold text-red-700">{`${AF_SUPPORT_PHONE_NUMBER}`}</span>
          </a>
          {` if this issue persists.`}
        </p>
        }
      </div>
    </div>
  )

  return (
    <div className="flex flex-col justify-center items-center gap-5 text-center">
      <h1 className={headerStyle ?? `text-3xl font-light text-center mb-3`}>{`Your Doctor`}</h1>
      <div className={containerStyle}>
        <div className="flex flex-col items-center">
          <p className="text-xl capitalize mb-2">{`${doctor?.firstName?.toLowerCase()} ${doctor?.lastName?.toLowerCase()}`}</p>
          <p className="text-lg font-light">{Formatters.phone( doctor?.phone )}</p>
        </div>
        <p
          className="underline text-deepSleepBlue text-lg cursor-pointer hover:opacity-80"
          onClick={() => { setSearchActive( true ) }}
        >
          {`Edit`}
        </p>
      </div>
    </div>
  )
}

export default CurrentDoctor