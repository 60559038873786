import React from 'react'
import { Builder } from '@builder.io/react'
import { HeaderCtaTypes } from '../types'

type HeroMainCtaProps = {
    isMobile: boolean;
    headerCta: HeaderCtaTypes;
}

export const HeroMainCta = ({ headerCta } : HeroMainCtaProps ) : JSX.Element => {
  const { header, subHeader, headerButtonText, headerButtonUrl } = headerCta
  return (
    <>
      <div className="mx-auto flex sm:flex-col lg:flex-row items-center justify-center md:gap-11 sm:pt-7 sm:pb-10 md:py-14 max-w-screen-lg">
        <div className="text-center">
          {headerCta?.header &&
            <h1 className="font-bold text-white sm:text-[26px] md:text-[40px] pb-3 leading-8 font-inter">
              {header}
            </h1>}
          {headerCta?.subHeader &&
            <p className="text-white font-normal sm:text-lg md:text-[26px] sm:pb-3 md:pb-0 max-w-[540px] mx-auto leading-normal">
              {subHeader}
            </p>}
        </div>
        {headerCta?.headerButtonText && headerCta?.headerButtonUrl &&
          <div className="flex gap-4">
            <a href={headerButtonUrl.toString()}>
              <button className="btn-primary w-full">{headerButtonText}</button>
            </a>
          </div>}
      </div>
    </>
  )
}

Builder.registerComponent( HeroMainCta, {
  name: `HeroMainCta`
})

export default HeroMainCta