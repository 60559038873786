import styled from 'styled-components'

export const ShakeDiv = styled.div`
  &.shake {
    animation: shake 0.8s;
  }
  @keyframes shake {
    0%  { transform: translateX(-1px);  }
    10% { transform: translateX(0px); }
    20% { transform: translateX(1px);  }
    30% { transform: translateX(0px);  }
    40% { transform: translateX(-1px);  }
    50% { transform: translateX(0px); }
    60% { transform: translateX(1px);  }
    70% { transform: translateX(0px); }
    80% { transform: translateX(-1px);  }
    90% { transform: translateX(0px);  }
    100%{ transform: translateX(1px); }
  }
`