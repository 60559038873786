import { useClaimedState } from "modules/resupply2/hooks"
import { CommerceDetailBlock } from "../Claims"
import { ResupplyOption } from "modules/resupply2/types"
import { RESUPPLY_CLAIMS_PATH } from "modules/resupply2"
import { useNavigate } from "react-router-dom"

export default function ItemsPreview() : JSX.Element {
  const claimedItems = useClaimedState()[0]
  const navigate = useNavigate()

  return (
    <div className="rounded-2xl bg-lightGray max-w-md mx-auto my-10 p-3">
      {
        claimedItems.map( ( item: ResupplyOption ) => {
          return ( <div key={item.hcpc_id} className="my-10 mx-5">
            <CommerceDetailBlock
              key={item.hcpc_id}
              productName={item.description}
              shipDate={item.Can_Ship_Date}
              productQty={item.max_qty}
              imgSrc={item.imageUrl}
              nextOrderDate={item.eligible}
            />

            <p className="a my-1 ml-20 pl-2" onClick={() => { navigate( RESUPPLY_CLAIMS_PATH ) }}>{`Edit/Remove Item`}</p>
          </div> )
        })
      }
    </div>
  )
}