import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useMediaQuery } from 'react-responsive'

// Skeleton for the h1 text and button in homepage hero section

const HeroMainCtaSkeleton = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: 976px)`
  })

  return (
    <div className="mx-auto flex sm:flex-col lg:flex-row items-center justify-center md:gap-11 sm:pt-7 sm:pb-10 md:py-14 max-w-screen-lg">
      <div className="text-center w-full">
        <Skeleton
          height={isMobile ? 76 : 40} width="80%" className="mb-3"
        />
        <Skeleton
          height={isMobile ? 48 : 26} width="60%" className="mb-3"
        />
      </div>
      {!isMobile && (
        <div className="flex gap-4">
          <Skeleton height={40} width={120} />
        </div>
      )}
    </div>
  )
}

export default HeroMainCtaSkeleton