import {useMediaQuery} from 'react-responsive'

export function useBreakPointCn( classNameSmaller: string, classNameLarger: string, customBreakPoint = `768px` ) {
  const isScreenLarger = useMediaQuery({
    query: `(min-width: ${customBreakPoint})`
  })
  const isScreenSmaller = useMediaQuery({
    query: `(max-width: ${customBreakPoint})`
  })
  if ( isScreenLarger ) return classNameLarger
  if ( isScreenSmaller ) return classNameSmaller
  return ``
}