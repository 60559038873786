
import React from 'react'
import throttle from 'lodash/throttle'
import { makeVar, useReactiveVar } from '@apollo/client'
import { myAccountInfoId, myInsuranceId, myPhysicianId } from 'modules/accountV2/constants'

export const accountMenuIsActive = makeVar( false )
export const activeMobileMenuTab = makeVar( `Account` )
export const networkErrorVar = makeVar( null )
export const qualifyFormSubmitSuccess = makeVar( false )

// refer a friend is getting disabled - 03-14-22
export const referAFriendFormSubmitSuccess = makeVar( false )

// Remove these when we remove resupply 1 from the codebase
export const claimedResupplyOptions = makeVar( [] )
export const resupplyAOBData = makeVar( null )


// Keep this for resupply 2.0
export const resupplyShippingAddress = makeVar( )

// TODO: we may be good to remove these
export const patientIdFromAppServer = makeVar( null )
export const patientEmailFromAppServer = makeVar( null )
export const patientPhoneFromAppServer = makeVar( null )
export const blockLeadsDisplay = makeVar( false )

export const homescreenCMSContent = makeVar({})

// export const userDeclinedResupplyPopup = makeVar( false )
export const addressFromQualifyForm = makeVar( null )


export const displayIdentifierTooltips = makeVar( false )

export const preLoginLocationVar = makeVar()

export const maintenancePreviousURL = makeVar( `` )

export const accountNavOptions = makeVar( [{
  label: `Account Info`,
  id: myAccountInfoId
}, {
  label:  `My Insurance`,
  id: myInsuranceId
}, {
  label: `My Physician`,
  id: myPhysicianId
}] )

// This is a custom hook and related function to manage when account menu is toggled on and off
// It uses a throttle function to prevent a race condition where it can be called by multiple
// click event listeners in different parts of the main menu
const throttleSetMenuIsActive = throttle( isActiveValue => {
  accountMenuIsActive( isActiveValue )
}, 200, {
  leading: true,
  trailing: false
})

export function useAccountMenuIsActive() {
  const isActive = useReactiveVar( accountMenuIsActive )
  const setIsActive = React.useCallback( throttleSetMenuIsActive, [] )

  return [ isActive, setIsActive ]
}