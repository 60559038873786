import React from 'react'
import { Builder } from '@builder.io/react'
import QualifyForm from 'modules/qualifyForm'
import QualifyFormCallToAction from './qualifyThroughInsurance/QualifyFormCallToAction'

type BuilderQualifyFormProps = {
  header: string;
  orderStatusText: string;
  orderStatusLinkText: string;
  signInText: string;
}

const BuilderQualifyForm = ( props: BuilderQualifyFormProps ) => {
  return (
    <>
      <QualifyFormCallToAction
        header={props.header}
        orderStatusText={props.orderStatusText}
        orderStatusLinkText={props.orderStatusLinkText}
        signInText={props.signInText}
      />
      <QualifyForm />
    </>
  )
}

Builder.registerComponent( BuilderQualifyForm, {
  name: `OnePageQualifyForm`,
  inputs: [
    {
      name: `header`,
      type: `text`,
      defaultValue: `Qualify Through Insurance In 3 Easy Steps!`
    },
    {
      name: `orderStatusText`,
      type: `text`,
      defaultValue: `If you've already been referred by your doctor, you're all set! `
    },
    {
      name: `orderStatusLinkText`,
      type: `text`,
      defaultValue: `Click here to see your order status`
    },
    {
      name: `signInText`,
      type: `text`,
      defaultValue: `Already have an account? Sign in`
    }
  ]
})

export default BuilderQualifyForm