import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const Modal = ({ isOpen, title, subHeading, children, onClose, closeBtnTitle, initialClose = false, defaultCloseButton = true, closeOnFocusChange = true }) => {

  const modalRef = useRef( null )
  const wasExecuted = useRef( false )

  useEffect( () => {
    document.removeEventListener( `click`, triggerClose )
    if ( isOpen ) document.addEventListener( `click`, triggerClose )

    return () => {
      document.removeEventListener( `click`, triggerClose )
      wasExecuted.current = false
    }
  }, [ isOpen ] )

  const triggerClose = ( e ) => {
    if ( !wasExecuted.current && initialClose ) return wasExecuted.current = true
    if ( modalRef && !modalRef?.current?.contains( e.target ) && closeOnFocusChange ) {
      onClose()
    }
  }

  if ( !isOpen ) return null

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-graphite bg-opacity-70 pb-10">
      <div className="px-5 min-w-screen h-screen animated fadeIn faster absolute flex justify-center items-center inset-0 z-50 outline-none focus:outline-none">
        <div className="w-full max-w-lg p-5 relative mx-auto rounded-xl shadow-lg bg-white my-10 flex flex-col justify-center max-h-full px-3" ref={modalRef}>
          <div className="text-center w-full max-w-lg">
            <h1>{title}</h1>
          </div>
          <h2 className="text-xl text-black pl-4">{subHeading}</h2>
          <div className="mx-auto">
            {children}
            <div className="mx-auto max-w-xs mt-5">
              {onClose && defaultCloseButton && <button className="btn-primary" onClick={onClose}>{closeBtnTitle}</button> }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  subHeading: PropTypes.string,
  children : PropTypes.node,
  onClose: PropTypes.func,
  closeBtnTitle: PropTypes.string,
  defaultCloseButton: PropTypes.bool,
  closeOnFocusChange: PropTypes.bool,
  initialClose: PropTypes.bool
}

Modal.defaultProps = {
  closeBtnTitle: `Close`
}

export default Modal
