import { reportToSentry } from "./reportToSentry"

export const getThisYear = () => {
  const now = new Date()

  return now.getFullYear()
}

/** Returns a date string in the format of YYYY-MM-DD
*  @param {Date | Number | String} date date constructer value
*  @returns {String} localeDateString in the format of YYYY-MM-DD
*/
export const getSnakeDate = ( date ) => {
  const now = date ? new Date( date ) : new Date()

  return now.toLocaleDateString( `en-US`, {
    year: `numeric`,
    month: `2-digit`,
    day: `2-digit`
  }).replaceAll( `/`, `-` )
}

/** Returns a date string in the format of MM/DD/YYYY
*  @returns {String} formatted date string in the format of MM/DD/YYYY
*/
export function getCurrentDateFormatted() {
  const date = new Date()
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  const formattedDate = `${month}/${day}/${year}`

  return formattedDate
}

/** Returns a Date object for next month
*  @param {Date | Number | String} startDate date constructer value
*  @returns {Date} Date object set to next months date
*/
export const getNextMonthDate = ( startDate ) => {
  const now = startDate ? new Date( startDate ) : new Date()
  let year = now.getFullYear()
  let month = now.getMonth() + 1
  if ( now.getMonth() === 11 ) {
    year += 1
    month = 0
  }

  return new Date( year, month, now.getDate() )
}

export function isCurrentDateOrPastDate( date ){

  try {

    return new Date( date ) <= new Date()

  } catch ( err ) {

    reportToSentry( new Error( `Could not process eligibility date for resupply`, {
      cause: err
    }), {
      error: `Bad date on an item for resupply 2 was received. ${JSON.stringify( date )}`
    })
    // if we can't create a date from the argv we can't perform the comparison - return false

    return false
  }

}