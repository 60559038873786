/* eslint-disable react/display-name */
import BackButton from "."
import { useBreakPointCn } from "utils/mediaQ"
import * as React from "react"


interface BackButtonProps {
  onBackClick: () => void;
  backText?: string;
  backClassName?: string;
}

interface HeadingProps {
  headingText: string;
  headingClassName?: string;
}

interface BackWithHeadingProps extends BackButtonProps, HeadingProps {
  shouldHideBackButton?: boolean;
  className?: string;
}

const HeadingWithBackButton = React.forwardRef<HTMLDivElement, BackWithHeadingProps>( ({
  className,
  onBackClick,
  backText = `Back`,
  backClassName,
  headingText,
  headingClassName,
  shouldHideBackButton = false
}, ref ) => {
  const smallParentClassName = `flex flex-col-reverse items-center flex-wrap text-center gap-2 ${className}`
  const normalParentClassName = `relative w-full text-center ${className}`
  const normalViewButtonClassName = `absolute top-1 ${backClassName}`
  const smallViewButtonClassName = `${backClassName}`

  const containerClassName = useBreakPointCn( smallParentClassName, normalParentClassName, `400px` )
  const buttonClassName = useBreakPointCn( smallViewButtonClassName, normalViewButtonClassName, `400px` )
  return (
    <div className={containerClassName} ref={ref}>
      {!shouldHideBackButton && <BackButton
        handleClick={onBackClick}
        buttonLabel={backText}
        tailwindPositioning={buttonClassName}
      />
      }
      <p className={headingClassName}>{headingText}</p>
    </div>
  )
})

export default HeadingWithBackButton