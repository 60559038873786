import React from 'react'
import PropTypes from 'prop-types'
import {
  airTouchImg,
  evoraImg,
  dreamwearImg,
  swiftImg
} from 'resources/images'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import './slick-override.css'

import { useQuery } from '@apollo/client'
import { productsQuery } from 'graphql/queries'
import styled from 'styled-components'


const PopularItemDiv = styled.div`
  grid-template-rows: 12rem 5rem;
`

// These will be used in the case there is an error with loading the most popular products
const backup_products = [
  {
    model: `ResMed AirTouch F20`,
    name: `Full Face Mask`,
    image: {
      url: airTouchImg,
      label: `ResMed AirTouch F20 Full Face Mask`
    }
  },
  {
    model: `Respironics DreamWear`,
    name: `Nasal Mask`,
    image: {
      url: dreamwearImg,
      label: `Respironics DreamWear Nasal Mask`
    }
  },
  {
    model: `ResMed Swift FX `,
    name: `Nasal Pillow Mask`,
    image: {
      url: swiftImg,
      label: `ResMed Swift FX Nasal Pillow Mask`
    }
  },
  {
    model: `Fisher & Paykel Evora CPAP`,
    name: `Nasal Mask`,
    image: {
      url: evoraImg,
      label: `Fisher & Paykel Evora CPAP Nasal Mask`
    }
  }
]

export const PopularProductCol = ({imgSrc, model, name, id}) => {
  const shop_link = id ? `${process.env.REACT_APP_MAGENTO_2_BASE_URL}/catalog/product/view/id/${id}` : `/#`

  return (
    <div className="p-4">
      <PopularItemDiv className="bg-white grid grid-rows-2 place-items-center p-2 hover:shadow-lg">
        {/* If we have an id, let's wrap it in a link to its shop page */}
        <a href={shop_link} className={id ? `cursor-pointer` : `cursor-default`}>
          <img
            src={imgSrc}
            alt={`${model} image`}
            height={240}
            width={240}
            className="object-contain max-h-48"
            loading="lazy"
          />
        </a>
        <div className="text-center">
          <a href={shop_link} className={id ? `cursor-pointer` : `cursor-default`}>
            <p className="p-sm">{model}</p>
            <p className="p-sm">{name}</p>
          </a>
        </div>
      </PopularItemDiv>
    </div>
  )
}

PopularProductCol.propTypes = {
  imgSrc: PropTypes.string,
  model: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] )
}


export const MostPopularBlock = () => {
  const { data : productData, loading, error: productError } = useQuery( productsQuery, {
    variables: {
      productAttributeFilterInput: {
        category_id: {
          eq: `120`
        }
      },
      pageSize: 999
    }
  })
  let items = productData?.products?.items
  let products = [ ...( items ?? backup_products ) ]

  if ( productError ) {
    products = backup_products
  }

  const productsMap = products.filter( product => {
    return product.homepage_most_popular
  }).map( ( product ) => {
    return (
      <PopularProductCol
        key={product.id}
        id={product.id}
        imgSrc={product.image.url}
        name={product.image.label}
      />
    )
  })

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    swipeToSlide: true,
    focusOnSelect: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 976,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  if ( loading ) return null

  return (
    <div className="bg-lightGray py-12 px-4 mb-16">
      <h5 className="text-center">{`MOST POPULAR`}</h5>
      <h3 className="mb-4 text-center">{`Sleep Apnea Products`}</h3>
      <div className="w-10/12 my-0 mx-auto">
        <Slider {...sliderSettings}>
          {productsMap}
        </Slider>
      </div>
    </div>
  )
}

export default MostPopularBlock
