
import { ViewPayMethodPropsNoSave } from "./props"
import { BankPaymentMethod, CardPaymentMethod, identifyPaymentMethodType } from "../types.d"
import { icoCheckAob, icoX } from 'resources/images'
import { capitalize } from "lodash"

export default function ViewPayMethod({payMethod, handleCancel, handleEdit } : ViewPayMethodPropsNoSave ) {

  const payMethodType = identifyPaymentMethodType( payMethod )

  return (
    <div className="mx-auto flex w-96 flex-col items-center justify-start my-8 p-4">
      <div className="flex gap-3 items-center">
        <div className="flex items-center">
          <div
            className="mx-1 flex flex-row gap-3 align-middle items-center justify-center"
          >
            <div>
              <img
                src={icoCheckAob}
                alt="check mark"
                width={24}
                height={24}
              />
            </div>
            { payMethodType === `ach` ? ( // we show bank
              <p
                className="cursor-pointer text-lg md:text-xl text-left font-light max-w-2xl whitespace-nowrap"
                onClick={handleEdit}
              >
                {`${capitalize( ( payMethod as BankPaymentMethod ).accountType )} account ending in ${( payMethod as BankPaymentMethod ).accountNumber?.slice( -4 )}`}
              </p>
            ) : ( // else we show card
              <p
                className="cursor-pointer text-lg md:text-xl text-center font-light max-w-2xl whitespace-nowrap mx-1"
                onClick={handleEdit}
              >
                {`Card ending in ${( payMethod as CardPaymentMethod ).cardNumber?.slice( -4 )}`}
              </p>
            )}
            <button
              className="hover:opacity-80"
              onClick={handleCancel}
            >
              <img
                src={icoX}
                width={20}
                height={20}
                alt="cancel icon"
              />
            </button>

          </div>
        </div>

      </div>
    </div>
  )
}