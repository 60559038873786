import Pill, { PillProps } from 'components/pill/Pill'
import { download } from 'resources/images'
import React from 'react'
import Order from 'types/order'

export type OrderBodyProps = {
  order: Order
  pillProps?: PillProps | null
}

export default function OrderBody({order, pillProps}: OrderBodyProps ) {
  return (
    <>
      <div className={`flex flex-wrap gap-3 py-4 max-w-[360px]`}>
        {order.order?.items.map( ( item ) => {
          return <div key={`${order.order?.invoice_number}-${item.item_name}`} className={`flex gap-3`}>
            <img
              className={`rounded-md border border-hardGray`}
              src={item?.image?.url} alt={item?.image?.label} height={50}
              width={50}
            />
            <p className={`p-sm flex items-center`}>{item.item_name || ``}</p>
          </div>
        })}
      </div>
      { pillProps && <div className={`pb-4`}>
        <Pill {...pillProps} />
      </div>
      }
      <div className={`pb-4`}>
        {order.order.order_number ? <p className={`p-sm text-[#676C73]`}>{`Order #${order.order.order_number}`}</p> :
          <p className={`p-sm text-[#676C73]`}>{`Invoice #${order.order.invoice_number}`}</p>}
      </div>
      <div className={`flex items-center justify-between`}>
        <p className={`p-sm text-[#676C73]`}>{`${order.order.order_source} Order`}</p>
        {/* TODO remove hidden className when invoice pdf functionality exists*/}
        <button className={`flex gap-2 p-sm text-[#676C73] hidden`}><img src={download} alt={`download`} />{`Invoice`}</button>
      </div>
    </>
  )
}