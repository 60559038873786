
export const SNLStorage = {
  getSession: () => {
    return {
      formValues: JSON.parse( sessionStorage.getItem( `formValues` ) ?? `false` ),
      activeStep: parseInt( sessionStorage.getItem( `activeStep` ) ),
      highestStep: parseInt( sessionStorage.getItem( `highestStep` ) )
    }
  },
  setSession:( formValues, activeStep, highestStep ) => {
    sessionStorage.setItem( `formValues`, JSON.stringify( formValues ) )
    sessionStorage.setItem( `activeStep`, activeStep )
    sessionStorage.setItem( `highestStep`, highestStep )
  },
  clearSession: () => {
    sessionStorage.removeItem( `formValues` )
    sessionStorage.removeItem( `activeStep` )
    sessionStorage.removeItem( `highestStep` )
  }
}

export default SNLStorage

export const CSTStorage = {
  getScheduledStatus: ( hash ) => {
    return sessionStorage.getItem( `clinical-scheduling-submitted` ) === hash
  },
  setScheduledStatus: ( hash ) => {
    sessionStorage.setItem( `clinical-scheduling-submitted`, hash )
  }
}

export const ResupplyStorage = {
  getOrderPlaced: () => {
    return sessionStorage.getItem( `resupply-order-placed` ) === `true`
  },
  setOrderPlaced: () => {
    sessionStorage.setItem( `resupply-order-placed`, true )
  }
}