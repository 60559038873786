import { builder, BuilderComponent } from '@builder.io/react'
import React, { useState, useEffect } from 'react'
import { reportToSentry } from 'utils/reportToSentry'

/**
 * Fetches content from Builder.io and renders it using the BuilderComponent
 * this is useful for pulling in configurable content from Builder.io and rendering it in normal react components
 * modelName - The name of the model to fetch from Builder.io
 * options - The options to fetch from Builder.io
 * */

builder.init( process.env.REACT_APP_BUILDER_IO_PUBLIC_API_KEY || `` )

interface BuilderContentFetchProps {
  modelName: string
  options: {
    url?: string
    id?: string
  }
}

const BuilderContentFetch = ({ modelName, options } : BuilderContentFetchProps ) => {
  const [ content, setContent ] = useState( null )

  useEffect( () => {
    builder.get( modelName, options )
      .promise()
      .then( setContent )
      .catch( error =>
        reportToSentry( new Error ( `there was a error fetching content from builder.io using BuilderContentFetch`, {
          cause: error
        }) ) )
  }, [] )

  if ( !content ) return null
  return <BuilderComponent model={modelName} content={content} />
}

export default BuilderContentFetch