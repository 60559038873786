import LinkTabSection from 'components/linkTabs/LinkTabSection'
import { useClaimedState } from '../hooks'
import { getCopy, addInDateByEligible } from './utils'
import { formatDate } from 'components/resupply2'
import { ResupplyOption } from '../types'
import { isCurrentDateOrPastDate } from 'utils/time'
import { reportToSentry } from 'utils/reportToSentry'
import { SurveyMonkey } from 'components/surveyMonkey'


export default function ThankYou() : JSX.Element | null {

  const claimedItems = useClaimedState()[0]
  const rx_required = Boolean( claimedItems?.find( ( item : ResupplyOption ) => { return item?.rx_required }) )

  let isValidDate = true
  const furthestEligibleToShip = claimedItems?.reduce( ( max : ResupplyOption, curr : ResupplyOption ) => {
    const maxDate = new Date( max?.Can_Ship_Date ?? `` )
    const currDate = new Date( curr?.Can_Ship_Date ?? `` )
    // Check for valid dates
    if ( isNaN( maxDate.getTime() ) || isNaN( currDate.getTime() ) ) {
      isValidDate = false
      reportToSentry( `Invalid Can_Ship_Date returned from internal`, {
        resupplyItem: JSON.stringify( isNaN( maxDate.getTime() ) ? max : curr )
      })
    }

    return ( maxDate > currDate ) ? max : curr
  })
  const isReadyToShip = isCurrentDateOrPastDate( furthestEligibleToShip?.Can_Ship_Date )

  const copy = getCopy( !rx_required, isReadyToShip )
  const eligibleDate = isValidDate && formatDate( furthestEligibleToShip?.Can_Ship_Date ) // Will get set to false and not display formatted date if we get an invalid date, else we have formatted date string


  return (
    <div className="flex flex-col justify-center items-center gap-4 mb-20 text-center">
      <h1 className="my-6">{copy.header}</h1>
      <p className="max-w-lg mb-2">{addInDateByEligible( copy.body, eligibleDate )}</p>
      {copy.subtitle && (
        <>
          <br className="border border-green"></br>
          <h2>{copy.subtitle}</h2>
          <h3>{copy.subheader}</h3>
          <p className="max-w-xl font-light text-center px-3 mb-20">{addInDateByEligible( copy.lowerbody, eligibleDate )}</p>
        </>
      )}
      <SurveyMonkey />
      <div className="mt-20">
        <LinkTabSection />
      </div>
    </div>
  )
}