import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import CustomSkeletonTheme from 'components/reactSkeleton/SkeletonTheme'

// skeleton for the video section of homepage

export const VideoBlockSkeleton = () => {
  return (
    <CustomSkeletonTheme>
      <div className="flex overflow-hidden sm:flex-col-reverse md:flex-row md:items-center md:justify-center mx-auto px-4 sm:py-8 md:py-[46px] md:gap-[88px] lg:w-10/12 xl:w-8/12">
        <div className="md:w-1/2">
          <Skeleton className="sm:text-2xl md:text-[40px] sm:mt-4 md:mt-0 sm:mb-2 md:mb-6" />
          <Skeleton count={3} className="my-0 mx-auto sm:text-sm md:text-base" />
        </div>
        <div className="md:w-1/2 lg:max-w-lg relative">
          <div className="aspect-video">
            <Skeleton height="100%" className="" />
          </div>
        </div>
      </div>
    </CustomSkeletonTheme>
  )
}

export default VideoBlockSkeleton