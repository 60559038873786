import React from 'react'
import { StyledDayPicker } from './styled'
import 'react-day-picker/dist/style.css'
import CustomCaption from './components/CustomCaption'

type dayRange = {
  before: Date;
  after: Date;
}

type CalendarProps = {
  initialMonth: Date;
  fromMonth?: Date;
  toMonth?: Date;
  selectedDays?: Array<Date | dayRange>;
  disabledDays?: Array<Date | dayRange>;
  selectedMonth?: Date;
  dayFromCal: Date | null;
  setDayFromCal( _day: Date | null ): void;
  handleMonthChange?( _m: Date ): void;
}

const Calendar = ({
  initialMonth,
  selectedDays,
  disabledDays,
  selectedMonth,
  dayFromCal,
  setDayFromCal,
  handleMonthChange,
  fromMonth,
  toMonth
} : CalendarProps ) : JSX.Element => {

  const handleSetDayFromCal = ( day: Date ) => {

    if ( selectedDays ) {
      const availableDay = selectedDays.find( ( selectedDay ) => {
        if ( ( selectedDay as Date ).getDate !== undefined ) {
          return ( selectedDay as Date ).getDate() === day.getDate()
        } else return ( selectedDay as dayRange ).before >= day && ( selectedDay as dayRange ).after <= day
      })

      return availableDay ? setDayFromCal( day ) : null
    } else return setDayFromCal( day )
  }

  return (
    <StyledDayPicker
      mode="single"
      onSelect={( day : Date | undefined ) => { return handleSetDayFromCal( day ?? new Date() ) }}
      disabled={disabledDays}
      defaultMonth={initialMonth}
      selected={dayFromCal ?? new Date()}
      month={selectedMonth}
      fromMonth={fromMonth}
      toMonth={toMonth}
      onMonthChange={handleMonthChange}
      modifiersClassNames={{
        selected: `selected`,
        available: `available`
      }}
      modifiers={{
        available: selectedDays ?? []
      }}
      components={{
        Caption: CustomCaption
      }}
    />
  )
}

export default Calendar
