export type TileProps = {
  headerContent?: JSX.Element | React.ReactNode;
  children: JSX.Element | React.ReactNode;
  className?: string;
  onClick?: () => void;
  id?: string;
  footer?: JSX.Element | React.ReactNode;
}
export default function Tile({ headerContent, children, className, onClick, id, footer } : TileProps ) : JSX.Element {
  return (
    <div className="p-3 w-full my-3 inline-block" id={id}>
      <div className={`tile-border rounded-t-md ${!footer && `rounded-b-md`} max-w-sm mx-auto`} onClick={onClick ? onClick : () => { return }}>
        {headerContent && (
          <div className="sleep-gradient-lightToDark text-white rounded-t-lg px-4 py-2 text-left text-lg">
            {headerContent}
          </div>
        )}
        <div className={`p-4 ${className ?? ``}`}>
          {children}
        </div>
      </div>
      {
        footer &&
        <div className="max-w-sm mx-auto">
          { footer }
        </div>
      }
    </div>
  )
}