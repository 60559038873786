import React from 'react'
import ContentLoader from 'react-content-loader'
import ValuePropositionLoader from 'modules/home/components/ValuePropositionBlock/ValuePropositionLoader'
import AsSeenInBlockLoader from 'modules/home/components/AsSeenInBlock/AsSeenInBlockLoader'

function PromoLoader() : JSX.Element {
  return (
    <div className="flex flex-col gap-5 w-full">
      <ContentLoader viewBox="0 0 100 28">
        <rect
          width="50" height="8" rx="2"
          ry="2" x="25" y="3"
        />
        <rect
          x="42" y="12" rx="1"
          ry="1" width="15" height="1.5"
        />
        <rect
          x="28" y="15" rx="1"
          ry="1" width="45" height="1.5"
        />
        <rect
          x="28" y="18" rx="1"
          ry="1" width="43" height="1.5"
        />
        <rect
          x="28" y="21" rx="1"
          ry="1" width="45" height="1.5"
        />
        <rect
          x="28" y="24" rx="1"
          ry="1" width="42" height="1.5"
        />
      </ContentLoader>
      <div className="w-full bg-secondaryBlue-tint3 pt-14"><ValuePropositionLoader /></div>
      <AsSeenInBlockLoader />
    </div>
  )
}

export default PromoLoader