import tailwindColors from 'config/tailwindColors'
import { sleepProductsThroughInsuranceData as copy } from 'constants/copy'
import React from 'react'
import PropTypes from 'prop-types'


import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { sliderSettings } from './util'
import styled from 'styled-components'
import CMSBlock from 'components/cmsBlock'

const StyledDiv = styled.div`
  @media (min-width: 976px) {
    :hover {
      .imgDiv {
        background-color: ${tailwindColors.deepSleepBlue};

        img {
          opacity: 0;
        }
      }
      .display-on-hover {
        visibility: visible;
      }
      .hidden-on-hover {
        visibility: hidden;
      }
    }
  }
`

const ProductThroughInsuranceSlide = ({ id, identifier, imgSrc, title, copy, pathname }) => {
  return (
    <StyledDiv key={id} className="w-11/12 mx-auto relative rounded-lg mb-4">
      <CMSBlock
        type="image"
        identifier={`${identifier}-image`}
        containerStyling="bg-black rounded-lg imgDiv"
        failSafe={imgSrc}
        customTag="div"
        customProps={{
          width: `526`,
          height: `216`,
          alt: title,
          className: `opacity-40 rounded-lg`,
          loading: `lazy`
        }}
      />
      <h2 className="sm:invisible lg:visible mb-4 absolute bottom-12 text-white ml-8 font-semibold hidden-on-hover">{title}</h2>
      <div className="text-white w-11/12 absolute lg:bottom-2 sm:bottom-6 left-4 lg:invisible display-on-hover">
        <h2 className="sm:text-2xl mb-2 font-semibold">{title}</h2>
        <CMSBlock
          type="text"
          identifier={`${identifier}-text`}
          styling="sm:text-sm mb-2"
          failSafe={copy}
          customTag="p"
        />
        <a href={`${`${process.env.REACT_APP_MAGENTO_2_BASE_URL}/${pathname}`}`}>
          <div className="w-fit">
            <button type="button" className="btn-primary w-fit">{`View All`}</button>
          </div>
        </a>
      </div>
    </StyledDiv>
  )
}

ProductThroughInsuranceSlide.propTypes = {
  id: PropTypes.number,
  imgSrc: PropTypes.string,
  title: PropTypes.string,
  copy: PropTypes.string,
  pathname: PropTypes.string,
  identifier: PropTypes.string
}

const SleepProductsThroughInsurance = () => {
  return (
    <div className="mb-16">
      <Slider {...sliderSettings} className="lg:w-11/12 mx-auto xl:w-2/3 grid">
        {copy.slideData.map( ( product ) => {
          return (
            <ProductThroughInsuranceSlide
              key={product.id}
              identifier={product.identifier}
              imgSrc={product.imgSrc}
              title={product.title}
              copy={product.copy}
              pathname={product.pathname}
            />
          )
        })}
      </Slider>
    </div>
  )
}

export default SleepProductsThroughInsurance
