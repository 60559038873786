import { useEffect, useState } from "react"
import { BuilderComponent, builder, useIsPreviewing } from "@builder.io/react"
import ContentPage from "modules/contentPage/ContentPage"
// @IMPORTANT please keep the following import for builder components - it keeps them registered with builder
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import * as BuilderComponents from 'components/builderComponents'
// Put your API key here
builder.init( process.env.REACT_APP_BUILDER_IO_PUBLIC_API_KEY )

// set whether you're using the Visual Editor,
// whether there are changes,
// and render the content if found
export default function BuilderPagesCatchAllRoute() {
  const isPreviewingInBuilder = useIsPreviewing()
  const [ notFound, setNotFound ] = useState( false )
  const [ content, setContent ] = useState( null )

  // set the meta description from Builder.io
  const setMetaDescription = ( content ) => {
    let metaDescription = document.querySelector( `meta[name="description"]` )
    if ( metaDescription ) {
      metaDescription.setAttribute( `content`, content )
      // remove the data-react-helmet attribute if it was created by Helmet
      if ( metaDescription.hasAttribute( `data-react-helmet` ) ) metaDescription.removeAttribute( `data-react-helmet` )
    } else {
      metaDescription = document.createElement( `meta` )
      metaDescription.setAttribute( `name`, `description` )
      metaDescription.setAttribute( `content`, content )
      document.head.appendChild( metaDescription )
    }
  }

  // get the page content from Builder
  useEffect( () => {
    async function fetchContent() {
      const content = await builder
        .get( `page`, {
          url: window.location.pathname
        })
        .promise()

      setContent( content )
      setNotFound( !content )

      // if the page title is found,
      // set the document title
      if ( content?.data.title ) {
        document.title = content.data.title
      }

      if ( content?.data.description ) {
        setMetaDescription( content.data.description )
      }
    }
    fetchContent()
  }, [ window.location.pathname ] )

  // If no page is found in builder we may have old magento content we still need to render, so we fallback to m2 for content after 1st querying builder
  if ( notFound && !isPreviewingInBuilder ) {
    return <ContentPage />
  }

  // return the page when found
  return <BuilderComponent model="page" content={content} />
}