import { displayIdentifierTooltips } from 'apollo'
import { useReactiveVar, useQuery } from '@apollo/client'
import {
  DIGITAL_ABN_PATH,
  EMBED_QUALIFY_FORM_PATH,
  MAINTENANCE_PAGE_PATH,
  MAINTENANCE_PAGE_THANK_YOU_PATH,
  PROMO_PAGE_PATH,
  SNL_ALL_PAGES_PATH
} from 'routes'
import { PAYMENT_PORTAL_PATH } from 'modules/aobPayments/constants'
import {
  Collapsible,
  LinksCol,
  PaymentOptionsCol
} from './components'
import { whichPathsMatch } from 'components/auth/routeUtils'
import { SleepLinearGradient } from 'components/sleepLinearGradient'
import { nightSkyBkgDsktp, nightSkyBkgMobile } from 'resources/images'
import { useMediaQuery } from 'react-responsive'
import { Container } from '../Container.tsx'
import PaymentOptionsImageGrid from './components/PaymentOptionsImageGrid'
import { reportToSentry } from 'utils/reportToSentry'
import { cmsBlocksQuery } from 'graphql/queries'
import { useLocation } from 'react-router-dom'
import { parseContent } from './utils'
import NewsLetterSignup from 'components/builderComponents/resourceCenter/components/NewsLetterSignup'
import { useTokenStore } from 'stores'
import { useMemo } from 'react'
import Masonry from 'react-layout-masonry'

export const Footer = () => {

  const location = useLocation()

  const { customerToken } = useTokenStore()

  const { data, loading, error } = useQuery( cmsBlocksQuery, {
    skip: location.pathname === `/maintenance`,
    variables: {
      identifiers: `footer_links_custom`
    },
    onError: ( error ) => {
      reportToSentry( `cmsPagesQuery Error -> Page.js`, {
        cause: error
      })
    }
  })


  const isMobile = useMediaQuery({
    query: `(max-width: 976px)`
  })

  const displayTooltips = useReactiveVar( displayIdentifierTooltips )

  const linkMapping = useMemo( () => {
    const mapping = parseContent( data?.cmsBlocks?.items[0]?.content )
    if ( !mapping ) return new Map()

    if ( customerToken ) {
      // modify Quick Links if logged in
      const currentQuickLinks = mapping.get( `Quick Links` )
      if ( !currentQuickLinks ) return mapping

      const updatedQuickLinks = currentQuickLinks.filter( linkItem => {
        return linkItem.display?.toLowerCase() !== `qualify through insurance`
      })

      // create a new Map
      const newMapping = new Map( mapping )
      newMapping.set( `Quick Links`, updatedQuickLinks )
      return newMapping
    }

    return mapping

  }, [ data, customerToken ] )

  const hideFooterPathsMatch = whichPathsMatch( [
    PAYMENT_PORTAL_PATH,
    MAINTENANCE_PAGE_PATH,
    MAINTENANCE_PAGE_THANK_YOU_PATH,
    PROMO_PAGE_PATH,
    DIGITAL_ABN_PATH,
    SNL_ALL_PAGES_PATH,
    EMBED_QUALIFY_FORM_PATH
  ] )

  if ( hideFooterPathsMatch.length || loading || error ) return null

  return (
    <footer className="relative font-light">
      {/* DESKTOP */}
      <div
        className="bg-aeroflowNavy bg-no-repeat bg-center bg-cover lg-wide:pt-5"
        style={{
          backgroundImage: `url(${nightSkyBkgDsktp})`
        }}
      >
        <Container>
          <div className="pb-16 block-container sm:hidden md:hidden lg-wide:block text-white">

            <Masonry columns={3} >
              {
                Array.from( linkMapping.entries() ).map( ( [ key, value ] ) => {
                  if ( key === `Payment Options` ) return <div className="px-6">
                    <PaymentOptionsCol />
                  </div>

                  return (
                    <div key={key} className="px-6">
                      <LinksCol panelHeader={key} links={value} row={key === `Social Media`} />
                    </div>
                  )
                })
              }

              <div className="px-6 py-3 hidden lg-wide:block max-w-xl">
                <NewsLetterSignup
                  newsLetterSignupText={`Subscribe to our newsletter:`}
                  textClassName="text-white font-family--inter text-base pb-2"
                />
              </div>
            </Masonry>

          </div>
        </Container>
      </div>

      {/* MOBILE */}
      <div
        className="md:block lg-wide:hidden pb-8 text-white bg-aeroflowNavy bg-no-repeat bg-center bg-cover"
        style={{
          backgroundImage: `url(${nightSkyBkgMobile})`
        }}
      >
        <div className="flex flex-col justify-start cursor-pointer pt-6">
          {
            Array.from( linkMapping.entries() ).map( ( [ key, value ] ) => {
              if ( key === `Payment Options` || key === `Contact Us` || key === `Social Media` ) return null

              return (
                <div key={key} className="px-14 md:px-10">
                  <Collapsible panelHeader={key} links={value} row={key === `Social Media`} />
                </div>
              )
            })
          }
          <div className="px-14 md:px-10">
            <Collapsible panelHeader="Payment Options" displayBlock={PaymentOptionsImageGrid} />
            <div className="max-w-xs mx-auto py-8">
              <LinksCol
                panelHeader="Social Media" links={linkMapping.get( `Social Media` )} row
                hideHeader
              />
            </div>
            <div className="px-8 pt-3 mx-auto w-full max-w-md">
              <NewsLetterSignup
                newsLetterSignupText={`Enter your email to subscribe to our monthly newsletter:`}
                textClassName="text-white font-family--inter text-base pb-2"
              />
            </div>
            <div className="flex flex-col items-center text-center">
              <LinksCol panelHeader="Contact Us" links={linkMapping.get( `Contact Us` )} hideHeader />
            </div>
          </div>
        </div>
      </div>
      {
        process.env.REACT_APP_ENVIRONMENT !== `production` &&
        <button
          className="bg-deepSleepBlue p-2 absolute bottom-0 text-sm text-white rounded-tr-lg cursor-pointer hover:opacity-90"
          onClick={() => { displayIdentifierTooltips( !displayTooltips ) }}
        >
          {`${displayTooltips ? `Disable` : `Enable` } CMS Identifier Tooltips`}
        </button>
      }
      <SleepLinearGradient height={isMobile ? 34 : 60} />
    </footer>
  )
}

export default Footer
