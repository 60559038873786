import * as React from 'react'
import QualifyForm from 'modules/qualifyForm'

function MaintenancePage() {
  return (
    <div className="text-center w-full mx-auto">
      <div className="mt-8 max-w-3xl w-11/12 mx-auto pb-4">
        <h1 className="mb-6 sm:text-3xl md:text-5xl">{`Our server is taking a quick nap!`}</h1>
        <p className="mt-7 mb-2">
          {`If you already have an account with us or were recently referred to us by your doctor and are trying to complete your order, we apologize for any inconvenience this may cause, but please check back later.`}
        </p>
        <p className="mt-7 mb-2">
          {`If you are new to Aeroflow Sleep, fill out the form below to estimate your out-of-pocket costs with insurance for fresh CPAP supplies.`}
        </p>
      </div>
      <QualifyForm isSiteOffline />
    </div>
  )
}

export default MaintenancePage