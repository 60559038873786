import CMSBlock from 'components/cmsBlock'
import React from 'react'
import { homeMosaicImg } from 'resources/images'

const ImageStackBlock = () => {
  return (
    <CMSBlock
      type="image"
      styling="object-contain"
      customTag="div"
      identifier="react-image-stack-block-image"
      failSafe={homeMosaicImg}
      customProps={{
        alt: `Home Mosaic Image`,
        width: `930`,
        height: `812`,
        loading: `lazy`
      }}
    />
  )
}

export default ImageStackBlock
