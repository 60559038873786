import { getAuthHeader } from 'utils/auth/helpers'
import { addMagentoEnvAuthHeaders } from 'utils/magentoEnvAuthHeaders'

export const accountTrackerDataFetch = async () => {
  if ( !getAuthHeader() ) return

  const response = await fetch( `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/sleep-my-account`, {
    headers: {
      'Content-Type': `application/json`,
      ...addMagentoEnvAuthHeaders(),
      'Authorization': getAuthHeader()
    }
  })
  return await response.json()
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const extractNestedObj = ( obj: any, nestedObj: any ) => {
  const extractedData = ( obj?.[nestedObj] ) || null
  return extractedData ? extractedData[0] : null
}

// convet iso date time to a date string (dd/mm/yyyy), set showTime to true to include time (hh:mm AM/PM)
export const convertIsoDateToDateString = ( isoDate: string ) => {
  // check if date is empty or null
  if ( !isoDate || isoDate.trim() === `` ) return {
    date: ``,
    time: ``
  }

  const date = new Date ( isoDate )

  // check if date is valid
  if ( Object.prototype.toString.call( date ) !== `[object Date]` ) return {
    date: ``,
    time: ``
  }

  let day = date.getDate().toString()
  let month = ( date.getMonth() + 1 ).toString()
  const year = date.getFullYear()

  day = day.length < 2 ? `0` + day : day
  month = month.length < 2 ? `0` + month : month

  const time = date.toLocaleTimeString( [], {
    timeStyle: `short`
  })

  return {
    date: `${month}/${day}/${year}`,
    time: time.toLowerCase()
  }
}

export const dateHasPassed = ( date: string ) => {
  if ( !date || date.trim() === `` ) return false

  const inputDate = new Date( date )

  if ( Object.prototype.toString.call( inputDate ) !== `[object Date]` ) return false

  // reset hours, minutes, seconds, and milliseconds to 0, we want to check the day only
  const today = new Date()
  today.setHours( 0, 0, 0, 0 )

  return inputDate <= today
}