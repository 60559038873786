
export const addMagentoEnvAuthHeaders = () => {
  const { magentoEnv, magentoTld } = getMagentoEnv()
  const isNotProduction = process.env.REACT_APP_ENVIRONMENT !== `production`
  return {
    ...( isNotProduction ?
      {
        'x-aeroflow-magento-environment': magentoEnv, // This let's the middleware know what magneto environment to use for token authentication
        'x-aeroflow-magento-tld': magentoTld // This let's the middleware know what top level domain to use for the respective magneto environment
      } : {}),
    "x-aeroflow-magento-store": `sleep`
  }
}

/* This function takes the REACT_APP_MAGENTO_2_BASE_URL and returns the respective magento environment and the top level domain */
export const getMagentoEnv = () => {
  const env = process.env.REACT_APP_MAGENTO_2_BASE_URL
  const domainSplit = env?.split( `.` )
  if ( !domainSplit?.length ) return {
    magentoEnv: `stage`,
    magentoTld: `dev`
  } // This is the default value for the staging environment
  // the domain is always as follows `https://${magentoStore}.${magentoEnv}.aeroflow.${magentoTld}`
  const magentoEnv = domainSplit?.[1] || `stage`
  const magentoTld = domainSplit?.[ domainSplit.length - 1 ] || `dev`
  return {
    magentoEnv,
    magentoTld
  }
}