import { create } from 'zustand'
import { ApolloError } from '@apollo/client'
import { createJSONStorage, persist } from 'zustand/middleware'
import { TMenuItem } from './types'

type StoreData<T> = {
  data: T | undefined;
  error: ApolloError | undefined
  loading: boolean
}

type SnowdogMenusStore = {
  menus: StoreData<TMenuItem[]>
  setMenus: ( _menus: StoreData<TMenuItem[]> ) => void;
}

export const useSnowdogMenusStore = create<SnowdogMenusStore>()(
  persist(
    ( set ) => {
      return {
        menus: {
          data: undefined,
          error: undefined,
          loading: false
        },
        setMenus: ( _menus: StoreData<TMenuItem[]> ) => ( set({
          menus: {
            ..._menus
          }
        }) )
      }
    },
    {
      name: `snowdog-menus-store`,
      storage: createJSONStorage( () => sessionStorage )
    })
)


