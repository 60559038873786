import { Modal } from 'components/modal'
import { useTriggerSleepConfetti } from 'hooks/useTriggerSleepConfetti'
import { useEffect, useState } from 'react'
import ReactCanvasConfetti from 'react-canvas-confetti'
import { useQualifyFieldsStore } from 'stores/qualifyFieldsStore'
import { icoXAob } from 'resources/images'

function CongratsModal() {
  const { onInit, triggerZs } = useTriggerSleepConfetti()
  const { formFields } = useQualifyFieldsStore()

  const medicare =
    formFields.insuranceType?.toLowerCase() === `medicare`

  const [ congratsModal, setCongratsModal ] = useState( medicare )

  // congratulations and confetti if user has medicare and made it here
  useEffect( () => {
    if ( medicare ) {
      triggerZs()
    }
  }, [] )

  return (
    <Modal
      isOpen={congratsModal}
      onClose={() => setCongratsModal( false )}
      defaultCloseButton={false}
    >
      <ReactCanvasConfetti onInit={onInit} />
      <div onClick={() => setCongratsModal( false )} className="cursor-pointer">
        <img
          src={icoXAob}
          alt="close-icon"
          className="absolute right-4 top-4"
        />
      </div>
      <p className="text-center font-light p-3">{`Congratulations! We estimate that your insurance will cover the majority of cost for new CPAP supplies from Aeroflow. Complete our short Sleep Apnea Profile to tell us about your supply needs and see an estimate.`}</p>
    </Modal>
  )
}

export default CongratsModal
