import React from 'react'
import { ValuePropositionItemTypes } from '../types'

export const ValuePropositionItem = ({ imgSrc, altText, content }: ValuePropositionItemTypes ): JSX.Element => {
  return (
    <div>
      {imgSrc && (
        <img
          src={imgSrc}
          height={150}
          width={150}
          className="my-0 mx-auto sm:mb-1 md:mb-4"
          alt={altText}
          loading="lazy"
        />
      )}
      {content && (
        <p className="capitalize font-inter text-graphite sm:text-sm md:text-xl">{content}</p>
      )}
    </div>
  )
}

export default ValuePropositionItem