import React, { useEffect } from 'react'
import { AuthBorderWrap } from 'components/auth'
import { EmailPhoneGroup } from 'components/auth/input'
import useInputState, { useValidInputs } from 'components/auth/input/useInputState'
import { FormButton } from 'components/forms/components'
import { isEmailAvailableQuery } from 'graphql/queries'
import { useLazyQuery } from '@apollo/client'
import { preLoginLocationVar} from 'apollo'
import LoadingSpinner from 'components/LoadingSpinner'
import { useNavigate, useLocation } from 'react-router-dom'
import { LOGIN_LEGACY_PATH, LOGIN_M2_PATH, LOGIN_OTP_PATH, MY_ACCOUNT_PATH } from 'routes'
import { reportToSentry } from 'utils/reportToSentry'
import { useTokenStore } from 'stores/tokenStore'

export default function Login() : JSX.Element {

  const { email } = useInputState()

  const { isValidEmail, isValidPhone } = useValidInputs()

  const [ checkEmailAvailability, { loading: isEmailAvailableQueryLoading, error: isEmailAvailableQueryError }] = useLazyQuery(
    isEmailAvailableQuery, {
      variables: {
        email
      },
      onError: ( ( error ) => {
        reportToSentry( new Error ( `isEmailAvailableQuery -> Login`, {
          cause: error
        }) )
      })
    })

  const navigate = useNavigate()
  const location = useLocation()
  const { customerToken, setCustomerToken } = useTokenStore()

  useEffect( () => {
    const currentQueryParams = new URLSearchParams( window.location.search )

    if ( customerToken ) return navigate( location?.state?.from ?? MY_ACCOUNT_PATH )

    // this code is to auto login stakeholders so they can remotely troubleshoot patient issues
    if ( currentQueryParams?.get( `sct` ) ) {
      setCustomerToken( currentQueryParams?.get( `sct` ) ?? `` )
      navigate( MY_ACCOUNT_PATH )
    }

    // Land on login page, we need to store the LoginState (from : {search,  pathname}),
    // for the redirect after a successful login
    if ( location?.state ) preLoginLocationVar( location.state )
  })


  const handleFormSubmit = async ( event : React.SyntheticEvent ) : Promise<void> => {
    event.preventDefault()

    // Skip if email input is not there
    if ( !email ) return navigate( LOGIN_LEGACY_PATH, {
      replace: true
    })

    const emailAvailableResponse = await checkEmailAvailability().catch( error => {
      console.warn( error )
    })

    if ( emailAvailableResponse?.error ) {
      reportToSentry( `Login - Email Available M2 Request Failed`, () => {
        JSON.stringify( emailAvailableResponse?.error )
      })

      return
    }

    if ( !emailAvailableResponse?.data?.isEmailAvailable.is_email_available ) {
      // Email is already taken - redirect to M2 Login
      return navigate( LOGIN_M2_PATH )
    }

    // No email, no errors - redirect to legacy login
    return navigate( LOGIN_LEGACY_PATH )
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <AuthBorderWrap title={`Sign In`}>

        <EmailPhoneGroup initialShowPhone initialShowEmail />

        <br />

        {isEmailAvailableQueryError &&
          <p className="text-error mb-4 ml-2">{isEmailAvailableQueryError?.message}</p>
        }

        <FormButton className="btn-primary mx-auto" disabled={( !isValidEmail && !isValidPhone ) || isEmailAvailableQueryLoading}>{isEmailAvailableQueryLoading ? <LoadingSpinner width={`2rem`} height={`2rem`} rings={5} /> : `Continue`}</FormButton>
        <p
          className="text-center a mt-2 mb-5 hover:opacity-80 w-fit mx-auto"
          onClick={() => { return navigate( LOGIN_OTP_PATH ) }}
        >
          {`Send a one-time passcode to my mobile phone`}
        </p>
      </AuthBorderWrap>
    </form>
  )
}