import React, { useState } from 'react'
import PropTypes from 'prop-types'
import groupBy from 'lodash/groupBy'
import { machineImages, maskImages, machineBrands, maskBrands } from 'modules/sleepNewLeads/constants'
import CheckIcon from './CheckIcon'

const BrandSelector = ({
  heading,
  name,
  brandNames,
  selectedValue,
  onChange,
  showOnMobile,
  type
}) => {
  const groupedMachineImages = groupBy( ( type === `machine` ? machineImages : maskImages ), `brand` )

  const [ errorMsg, setErrorMsg ] = useState( `` )

  const availableImages = ( type === `machine` ? machineBrands : maskBrands ).filter( ({ brand }) => {
    return ( brandNames || [] ).includes( brand )
  })

  if ( !availableImages || !availableImages.length ) return null

  const handleChange = ( name, brand ) => {
    if ( groupedMachineImages[brand]?.length > 0 || brand === `Bleep LLC` ) {
      setErrorMsg( `` )
      onChange( name, brand )
    } else {
      setErrorMsg( `No machines for that brand` )
      onChange( name, `` )
    }
  }

  return (
    <>
      {showOnMobile && <div className="mt-4">
        <div className="font-inter sm:text-xl md:text-[28px] sm:text-center lg:text-left font-medium sm:mb-5 md:mb-11">{heading}</div>
        <div className="grid sm:grid-cols-2 lg:grid-cols-4 sm:gap-[18px] lg:gap-[24px] sm:mx-auto lg:mx-0 sm:px-8 lg:px-0">
          {availableImages.map( ({ brand, imgSrc }) => {
            return (
              <div key={brand} className="flex justify-center">
                <div
                  className={`w-full pt-[100%] cursor-pointer border flex rounded-md overflow-hidden relative ${brand === selectedValue ? `border-deepSleepBlue` : `border-[#E1E1E1]`}`}
                  onClick={() => { return handleChange( name, brand ) }}
                >
                  { brand === selectedValue && <CheckIcon /> }
                  <img
                    src={imgSrc}
                    className="object-contain absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-3"
                    alt={brand}
                    data-type={type === `mask` ? `mask` : `machine`}
                  />
                </div>
              </div>
            )
          })}
        </div>
        <div className="text-error">{errorMsg}</div>
      </div>}
    </>
  )
}

BrandSelector.propTypes = {
  heading: PropTypes.string,
  name: PropTypes.string,
  brandNames: PropTypes.array,
  selectedValue: PropTypes.string,
  onChange: PropTypes.func,
  defaultLabel: PropTypes.string,
  showOnMobile: PropTypes.bool,
  navigateToStep: PropTypes.func,
  type: PropTypes.string
}

export default BrandSelector
