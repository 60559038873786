import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

type ApneaHealthConditions = {
  [key : string] : boolean
}

type HealthTapSurveyStore = {
  apneaType: string,
  setApneaType: ( value: string ) => void,
  apneaFrequency: string,
  setApneaFrequency: ( value: string ) => void,
  apneaHealthCondition: ApneaHealthConditions,
  toggleApneaHealthCondition: ( key: string ) => void
  setApneaHealthCondition: ( data: ApneaHealthConditions ) => void
  healthtapBloodPressure: string,
  setHealthTapBloodPressure: ( value: string ) => void,
  apneaLastDiscussed: string,
  setApneaLastDiscussed: ( value: string ) => void
}

export type HealthTapScreenerResponse = {
  apneaType: string,
  apneaFrequency: string,
  apneaHealthCondition: ApneaHealthConditions,
  healthtapBloodPressure: string,
  apneaLastDiscussed: string
}

export const useHealthTapSurveyStore = create<HealthTapSurveyStore>()(
  persist(
    ( set, get ) => ({
      reasonForVisit: ``,
      apneaDevice: ``,
      apneaType: ``,
      apneaFrequency: ``,
      apneaHealthCondition: {
        'sleep_apnea_q_5_a1': false,
        'sleep_apnea_q_5_a2': false,
        'sleep_apnea_q_5_a3': false,
        'sleep_apnea_q_5_a4': false
      },
      healthtapBloodPressure: ``,
      apneaLastDiscussed: ``,
      setApneaType: ( value: string ) => set({
        apneaType: value
      }),
      setApneaFrequency: ( value: string ) => set({
        apneaFrequency: value
      }),
      toggleApneaHealthCondition: ( key ) => {
        const { apneaHealthCondition } = get()
        const currentKeyVal = apneaHealthCondition[key]
        set({
          apneaHealthCondition: {
            ...apneaHealthCondition,
            [key]: !currentKeyVal
          }
        })
      },
      setApneaHealthCondition: ( data ) => set({
        apneaHealthCondition: {
          ...data
        }
      }),
      setHealthTapBloodPressure: ( value: string ) => set({
        healthtapBloodPressure: value
      }),
      setApneaLastDiscussed: ( value: string ) => set({
        apneaLastDiscussed: value
      })
    }),
    {
      name: `healthTapSurvey-store`,
      storage: createJSONStorage( () => sessionStorage )
    }
  )
)