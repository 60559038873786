import React from 'react'
import PropTypes from 'prop-types'

const AuthPageWrapper = ({children}) => {
  return (
    <div className="flex sm:flex-col md:flex-row sm:px-4 sm:py-2 md:px-0 md:py-24 md:items-center md:justify-center md:mr-60">
      {children}
    </div>
  )
}

AuthPageWrapper.propTypes = {
  children : PropTypes.node
}

export default AuthPageWrapper
