import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'components/modal'
import { icoXAob } from 'resources/images'
import { ineligibleCopy } from '../constants'

function NewMachineIneligibleModal({ displayModal, handleCloseModal, handleNoLongerInterested }) {
  return (
    <Modal
      isOpen={displayModal}
      defaultCloseButton={false}
      onClose={handleCloseModal}
      initialClose
    >
      <img
        src={icoXAob}
        alt="ico-x"
        className="absolute right-1 top-3 px-3 hover:opacity-80 cursor-pointer object-contain"
        width={40}
        onClick={handleCloseModal}
      />
      <p className="text-xl font-bold text-center py-3">{`You Are Not Eligible`}</p>
      <p className="text-lg w-9/12 text-center font-light mx-auto">
        {ineligibleCopy}
      </p>
      <div className="my-5 max-w-xs mx-auto flex flex-col gap-5">
        <button className="btn-secondary" onClick={handleCloseModal}>{`Order Fresh CPAP Supplies`}</button>
        <button className="btn-secondary" onClick={() => { handleNoLongerInterested( `ineligible for new machine` ) }}>{`Not Interested in Supply Order`}</button>
      </div>
    </Modal>
  )
}

NewMachineIneligibleModal.propTypes = {
  displayModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  handleNoLongerInterested: PropTypes.func
}

export default NewMachineIneligibleModal