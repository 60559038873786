import React from 'react'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'

function LoadingCMS({type}) {

  if ( type === `video` ) {
    return (
      <ContentLoader viewBox="0 0 100 80">
        <rect
          width="100" height="80" rx="3"
          ry="3"
        />
      </ContentLoader>
    )
  }

  if ( type === `image` ) {
    return (
      <ContentLoader viewBox="0 0 100 80">
        <rect
          width="100" height="75" rx="3"
          ry="3"
        />
        <rect
          x="1" y="76" rx="1"
          ry="1" width="90" height="1.5"
        />
        <rect
          x="1" y="78.5" rx="1"
          ry="1" width="80" height="1.5"
        />
      </ContentLoader>
    )
  }

  return (
    <ContentLoader viewBox="0 0 100 35">
      <rect
        x="2" y="5" rx="3"
        ry="3" width="97" height="5"
      />
      <rect
        x="2" y="15" rx="3"
        ry="3" width="80" height="5"
      />
      <rect
        x="2" y="25" rx="3"
        ry="3" width="97" height="5"
      />
    </ContentLoader>
  )
}

LoadingCMS.propTypes = {
  type: PropTypes.string
}

export default LoadingCMS