import { getAuthHeader } from "utils/auth/helpers"
import { addMagentoEnvAuthHeaders } from "utils/magentoEnvAuthHeaders"
import { reportToSentry } from "utils/reportToSentry"

export const submitMedicareChecklist = async ( body ) => {
  const medicareSubmit = await fetch ( `${process.env.REACT_APP_LAMBDA_RESUPPLY_MEDICARE_URL}/medicare-checklist`, {
    method: `POST`,
    headers: {
      ...addMagentoEnvAuthHeaders(),
      "Authorization": getAuthHeader(),
      "Content-Type": `application/json`
    },
    body: JSON.stringify( body )
  })
    .catch( ( error ) => {
      reportToSentry( new Error( `Resupply: Exception submitting medicare checklist`, {
        cause: error
      }), {
        itemData: body
      })
    })

  return await medicareSubmit.json()
}