import PercentageBar from "./PercentageBar"

export default function PricingEstimatePercentageBar({ percentFilled, values, isCoInsurance } : { percentFilled : number, values : {paid: number, toGo: number}, isCoInsurance : boolean | undefined}) : JSX.Element {

  return (
    <PercentageBar
      percentFilled={percentFilled}
      labelText={{
        left: `$${values?.paid?.toFixed( 2 )} Paid`,
        right: `$${values?.toGo?.toFixed( 2 )} to go`
      }}
      showPercentageLabels={isCoInsurance}
      showLabelText={!isCoInsurance}
    />
  )
}