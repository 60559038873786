// free
import { gql } from "@apollo/client"

export const setPaymentMethodOnCartMutation = gql`
  mutation setPaymentMethodOnCartMutation($input : SetPaymentMethodOnCartInput!) {
    setPaymentMethodOnCart(input : $input) {
      cart {
        selected_payment_method {
          code
          title
        }
      }
    }
  }
`