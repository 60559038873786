import {
  addProductsToCartMutation,
  customerCartMutation,
  placeOrderMutation,
  removeItemFromCartMutation,
  setBillingAddressOnCartMutation,
  setPaymentMethodOnCartMutation,
  setShippingAddressesOnCartMutation,
  setShippingMethodsOnCartMutation
} from 'graphql/mutations/resupply2'

import { useLazyQuery, useMutation } from '@apollo/client'
import { reportToSentry } from 'utils/reportToSentry'
import { useClaimedState, useContactInfoState, useResupplySetting } from '../hooks'
import { ResupplyOption } from '../types'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LAST_STEP_COMPLETED_SESSION_KEY, LastStepCompleted, SUMMARY_PAGE_PATH } from 'modules/aobPayments/constants'
import { ResupplyStorage } from 'apollo/storage'
import { resupplyAOBData } from 'apollo'
import { useAddressStore } from 'components/addressBlock'
import { trackAnalyticEvent } from 'utils/analytic'

export default function useApi() {

  const [ apiError, setApiError ] = useState<string>( `` )
  const [ apiLoading, setApiLoading ] = useState<boolean>( false )
  const [ claimedItems ] = useClaimedState()
  const shippingAddress = useAddressStore( state => state.address )
  const { addressPatientConfirmed, addressWebConfirmed } = useAddressStore()
  const [ contactInfo ] = useContactInfoState()
  const [ gqlCallFailed, setGqlCallFailed ] = useState<number>( 0 )
  const [ gqlCallActive, setGqlCallActive ] = useState<number>( 0 )
  const [ cartId, setCartId ] = useState<string | number | null>( null )

  const navigate = useNavigate()
  const { proceedToPayment, patientId } = useResupplySetting()[0]

  // @NOTE useEffect IS the event loop
  // - as we complete gql calls in this subroutine the incrementing gqlCallActive triggers subsequent calls
  useEffect( () => {

    switch ( gqlCallActive ) {
    case 1: {
      customerCart()
        .catch( ( error ) => {
          setApiLoading( false )
          setGqlCallFailed( 1 )
          reportToSentry( new Error( `customerCartQuery Error -> create customer cart hook`, {
            cause: error
          }), {
            patientId
          })
        })
      break
    }
    case 2: {
      addProductsToCart({
        variables: {
          cartId,
          cartItems: claimedItems.map( ( i : ResupplyOption ) => {
            return {
              sku: i.magento_sku,
              quantity: i.max_qty
            }
          })
        }
      })
        .catch( error => {
          setApiLoading( false )
          setGqlCallFailed( 2 )
          reportToSentry( new Error( `addProductsToCartMutation Error`, {
            cause: error
          }), {
            patientId
          })
        })

      break
    }
    case 3: {
      setBillingAddressOnCart({
        variables: {
          input: {
            cart_id: cartId,
            billing_address: {
              address: {
                postcode: shippingAddress.zipCode,
                firstname: shippingAddress.firstName,
                lastname: shippingAddress.lastName,
                region: shippingAddress.state,
                city: shippingAddress.city,
                street: shippingAddress.street,
                country_code: `US`,
                telephone: contactInfo.phone.replace( `-`, `` ).replace( `(`, `` )
                  .replace( `)`, `` )
                  .replace( ` `, `` ) // We might want to swap this with a rexp
              }
            }
          }
        }
      })
        .catch( ( error ) => {
          setApiLoading( false )
          setGqlCallFailed( 3 )
          reportToSentry( new Error( `setBillingAddressOnCart Error`, {
            cause: error
          }), {
            patientId
          })
        })
      break
    }
    case 4: {
      setShippingAddressesOnCart({
        variables: {
          input: {
            cart_id: cartId,
            shipping_addresses: [{
              address: {
                postcode: shippingAddress.zipCode,
                firstname: shippingAddress.firstName,
                lastname: shippingAddress.lastName,
                region: shippingAddress.state,
                city: shippingAddress.city,
                street: shippingAddress.street,
                country_code: `US`,
                telephone: contactInfo.phone.replace( `-`, `` ).replace( `(`, `` )
                  .replace( `)`, `` )
                  .replace( ` `, `` ) // We might want to swap this with a rexp
              }
            }]
          }
        }
      })
        .catch( ( error ) => {
          setApiLoading( false )
          setGqlCallFailed( 4 )
          reportToSentry( new Error( `setShippingAddressesOnCartMutation Error`, {
            cause: error
          }), {
            patientId
          })
        })
      break
    }
    case 5: {
      setShippingMethodsOnCart({
        variables: {
          input: {
            cart_id: cartId,
            shipping_methods: [{
              carrier_code: `freeshipping`,
              method_code: `freeshipping`
            }]
          }
        }
      })
        .catch( ( error ) => {
          setApiLoading( false )
          setGqlCallFailed( 5 )
          reportToSentry( new Error( `setShippingMethodsOnCartMutation Error`, {
            cause: error
          }), {
            patientId
          })
        })
      break
    }
    case 6: {
      setPaymentMethodOnCart({
        variables: {
          input: {
            cart_id: cartId,
            payment_method: {
              code: `free`
            }
          }
        }
      })
        .catch( ( error ) => {
          setApiLoading( false )
          setGqlCallFailed( 5 )
          reportToSentry( new Error( `setShippingMethodsOnCartMutation Error`, {
            cause: error
          }), {
            patientId
          })
        })
      break
    }
    case 7: {
      placeOrder({
        variables: {
          input: {
            cart_id: cartId,
            patientConfirmed: addressPatientConfirmed ? 1 : 0, // The custom magento resolver is using internal's 1/0 for true/false
            webConfirmed: addressWebConfirmed ? 1 : 0
          }
        }
      })
        .catch( ( error ) => {
          setApiLoading( false )
          setGqlCallFailed( 7 )
          reportToSentry( new Error( `placeOrderMutation Error`, {
            cause: error
          }), {
            patientId
          })
        })
      break
    }
    case 8: {
      // order complete! do we need to dispatch a metric?
      break
    }
    default: {
      // do nothing - we will get a 0 passed to this switch case on component mount
    }
    }

  }, [ gqlCallActive ] )

  interface FailMessagesInterace {
    [key:number]: string,
  }

  const errTxtTail = `: Please contact customer service. Your order was not able to be placed`
  const errTxtHead = `Server Error `
  const PLACE_ORDER_API_CALL_STACK_FAILURE_MESSAGES:FailMessagesInterace = {
    1: `${errTxtHead}PO-1${errTxtTail}`,
    2: `${errTxtHead}PO-2${errTxtTail}`,
    3: `${errTxtHead}PO-3${errTxtTail}`,
    4: `${errTxtHead}PO-4${errTxtTail}`,
    5: `${errTxtHead}PO-5${errTxtTail}`,
    6: `${errTxtHead}PO-6${errTxtTail}`,
    7: `${errTxtHead}PO-7${errTxtTail}`
  }

  useEffect( () => {

    if ( gqlCallFailed > 0 ){
      setApiLoading( false )
      setApiError( PLACE_ORDER_API_CALL_STACK_FAILURE_MESSAGES[ gqlCallFailed ] )
    }
  }, [ gqlCallFailed ] )

  const [ customerCart ] = useLazyQuery( customerCartMutation, {
    fetchPolicy: `network-only`,
    onError: ( error ) => {
      setApiLoading( false )
      setGqlCallFailed( 1 )
      reportToSentry( new Error( `customerCartQuery Error -> create customer cart hook`, {
        cause: error
      }), {
        patientId
      })
    },
    onCompleted: ( data ) => {
      // if there are items in the cart we need to clear them out for the resupply order
      if ( data?.customerCart?.items?.length && data?.customerCart?.id ){

        for ( let i=0; i<data.customerCart.items.length; i++ ){
          removeItemFromCart({
            variables: {
              input :{
                cart_id: data.customerCart.id,
                cart_item_id: parseInt( data.customerCart.items[i].id )
              }
            }
          })

        }
      }

      if ( data?.customerCart?.id ){
        setCartId( data?.customerCart?.id )
        setGqlCallActive( 2 )
      } else {
        setApiLoading( false )
        setGqlCallFailed( 1 )
        reportToSentry( new Error( `customerCartQuery Error -> create customer cart hook` ), {
          patientId
        })
      }
    }
  })

  // ############## BEGIN useMutation hooks ##############

  const [ removeItemFromCart ] = useMutation( removeItemFromCartMutation, {
    onError: ( error ) => {
      setApiLoading( false )
      setGqlCallFailed( 1 )
      reportToSentry( new Error( `removeItemFromCartMutation Error`, {
        cause: error
      }), {
        patientId,
        claimedItems: JSON.stringify( claimedItems )
      })
    }
  })

  const [ addProductsToCart ] = useMutation( addProductsToCartMutation, {
    onError: ( error ) => {
      setApiLoading( false )
      setGqlCallFailed( 2 )
      reportToSentry( new Error( `addProductsToCartMutation Error`, {
        cause: error
      }), {
        patientId,
        claimedItems: JSON.stringify( claimedItems )
      })
    },
    onCompleted: ( data ) => {
      if ( data?.addProductsToCart?.user_errors?.length ) {
        // this will give us the error messages when an item has failed to be added to the cart
        // have caught a few magento configuration errors here so need to lookout post launch
        reportToSentry( new Error( `Resupply 2.0: addProductsToCart User Errors` ), {
          patientId,
          addProductErrors: JSON.stringify( data.addProductsToCart.user_errors ),
          claimedItems: JSON.stringify( claimedItems )
        })
      }
      if ( data?.addProductsToCart?.cart ) setGqlCallActive( 3 )
      else {
        setApiLoading( false )
        setGqlCallFailed( 2 )
        reportToSentry( new Error( `addProductsToCartMutation Error` ), {
          patientId,
          claimedItems: JSON.stringify( claimedItems )
        })
      }
    }
  })

  const [ setBillingAddressOnCart ] = useMutation( setBillingAddressOnCartMutation, {
    onError: ( error ) => {
      setApiLoading( false )
      setGqlCallFailed( 3 )
      reportToSentry( new Error( `setBillingAddressOnCart Error`, {
        cause: error
      }), {
        patientId
      })
    },
    onCompleted: ( data ) => {
      if ( data?.setBillingAddressOnCart?.cart ) setGqlCallActive( 4 )
      else {
        setApiLoading( false )
        setGqlCallFailed( 3 )
        reportToSentry( new Error( `setBillingAddressOnCart Error` ), {
          patientId
        })
      }
    }
  })

  const [ setShippingAddressesOnCart ] = useMutation( setShippingAddressesOnCartMutation, {
    onError: ( error ) => {
      setApiLoading( false )
      setGqlCallFailed( 4 )
      reportToSentry( new Error( `setShippingAddressesOnCartMutation Error`, {
        cause: error
      }), {
        patientId
      })
    },
    onCompleted: ( data ) => {
      if ( data?.setShippingAddressesOnCart?.cart ) setGqlCallActive( 5 )
      else {
        setApiLoading( false )
        setGqlCallFailed( 4 )
        reportToSentry( new Error( `setShippingAddressesOnCartMutation Error` ), {
          patientId
        })
      }
    }
  })

  const [ setShippingMethodsOnCart ] = useMutation( setShippingMethodsOnCartMutation, {
    onError: ( error ) => {
      setApiLoading( false )
      setGqlCallFailed( 5 )
      reportToSentry( new Error( `setShippingMethodsOnCartMutation Error`, {
        cause: error
      }), {
        patientId
      })
    },
    onCompleted: ( data ) => {
      if ( data?.setShippingMethodsOnCart?.cart ) setGqlCallActive( 6 )
      else {
        setApiLoading( false )
        setGqlCallFailed( 5 )
        reportToSentry( new Error( `setShippingMethodsOnCartMutation Error` ), {
          patientId
        })
      }
    }
  })

  const [ setPaymentMethodOnCart ] = useMutation( setPaymentMethodOnCartMutation, {
    onError: ( error ) => {
      setApiLoading( false )
      setGqlCallFailed( 6 )
      reportToSentry( new Error( `setPaymentMethodOnCartMutation Error`, {
        cause: error
      }), {
        patientId
      })
    },
    onCompleted: ( data ) => {
      if ( data?.setPaymentMethodOnCart?.cart ) setGqlCallActive( 7 )
      else {
        setApiLoading( false )
        setGqlCallFailed( 6 )
        reportToSentry( new Error( `setPaymentMethodOnCartMutation Error` ), {
          patientId
        })
      }
    }
  })

  const [ placeOrder ] = useMutation( placeOrderMutation, {
    onError: ( error ) => {
      setApiLoading( false )
      setGqlCallFailed( 7 )
      reportToSentry( new Error( `placeOrderMutation Error`, {
        cause: error
      }), {
        patientId
      })
    },
    onCompleted: ( data ) => {
      setApiLoading( false )
      if ( data?.placeOrder?.order ) {
        setGqlCallActive( 8 )// 8 is the magic number ;) - order is placed!!
        ResupplyStorage.setOrderPlaced()
        trackAnalyticEvent( `resupply_submit` )

        if ( proceedToPayment ) {
          resupplyAOBData({
            firstName: shippingAddress.firstName
          } as any )
          // if below is true we need to set a flag in state, flag needs to be inspected on the payments summary and if present do not show the shipping address input block
          sessionStorage.setItem( `patientShippingAddressCollectedPriorToPaymentPortalMount`, `true` )
          // this is to track where the patient came from when accessing aob payments page
          sessionStorage.setItem( LAST_STEP_COMPLETED_SESSION_KEY, LastStepCompleted.Resupply )

          return navigate( SUMMARY_PAGE_PATH )
        }
      } else {
        setGqlCallFailed( 7 )
        reportToSentry( new Error ( `placeOrderMutation Error` ), {
          patientId
        })
      }
    }
  })

  const submitOrder = () => {
    setApiLoading( true )
    setGqlCallActive( 1 )
  }

  return {
    submitOrder,
    loading: apiLoading,
    error: apiError,
    orderSuccess: gqlCallActive === 8
  }
}