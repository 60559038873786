import React from 'react'
import PropTypes from 'prop-types'
import { StyledCMSPageDiv } from './styled'
import ReactHtmlParser from 'react-html-parser'
import { convertTags, formatTailwindClass } from './util'
import { NotFound } from 'modules/notFound'

const CMSContent = ( props ) => {

  if ( !props.content ) return props.fallback ?? <NotFound />

  return (
    <StyledCMSPageDiv>
      {
        ReactHtmlParser( convertTags( props.content ), {
          transform: formatTailwindClass
        })
      }
    </StyledCMSPageDiv>
  )
}

CMSContent.propTypes = {
  content: PropTypes.string,
  fallback: PropTypes.node
}

export default CMSContent
