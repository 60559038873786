import React from 'react'
import PropTypes from 'prop-types'
import { useSpring, animated } from 'react-spring'

export const SlideToggleContent = ({ isVisible, children, hasSubmenus }) => {
  const contentRef = React.useRef( null )
  const [ contentHeight, setContentHeight ] = React.useState( 0 )

  // add 4px to content height so we can have some vertical whitespace pre/post content
  const expand = useSpring({
    height: isVisible ? `${contentHeight + 4}px` : `0px`,
    config: {
      mass: 6.5,
      tension: 500,
      friction: 100
    }
  })

  React.useEffect( () => {
    setContentHeight( contentRef?.current?.clientHeight ?? 0 )
  }, [ isVisible, children ] )

  // submenus indicate a bi/tri + column layout in the submenu. If that's the case we flex
  return (
    <animated.div style={expand} className="overflow-hidden">
      <div className={hasSubmenus ? `flex justify-evenly` : ``} ref={contentRef}>{children}</div>
    </animated.div>
  )
}

SlideToggleContent.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  hasSubmenus: PropTypes.bool
}
