import { useState, useEffect } from 'react'
import { fetchTermsAndConditions } from 'modules/aobPayments/fetchPromises'
import { reportToSentry } from 'utils/reportToSentry'
import { getHash } from 'modules/clinicalScheduling/utils'

const useGetAobDetail = ( setError ) => {

  const [ aobDetail, setAobDetail ] = useState( undefined )

  useEffect( () => {

    if ( !aobDetail ) {
      fetchTermsAndConditions()
        .then( data => {
          const aob_content = data?.data?.aob_content ?? data?.[0]?.aob_content
          if ( aob_content ) setAobDetail( aob_content )
          else {
            setError( `There has been an issue with the terms and conditions. Please try again.` )
            reportToSentry( new Error( `AOB: terms and conditions response data error` ), {
              patientHash: getHash()// @REVIEW since this may be token based we prob need to pass pt id if no hash??
            })
          }
        })
        .catch( () => {
          setError( `There has been an issue with the terms and conditions. Please try again.` )
        })
    }

  }, [ ] )


  return aobDetail
}

export default useGetAobDetail