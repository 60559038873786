import { useEffect, useState } from 'react'
import { getProductPricing } from 'utils/patientInfoApi'
import { reportToSentry } from 'utils/reportToSentry'
import { Formatters } from 'components/forms/components'
import { useDoctorStore } from './state'

function useRetrieveDoctor( onExistingDoctor?: () => void ) : boolean {
  const [ loading, setLoading ] = useState<boolean>( false )
  const { doctor, setDoctor } = useDoctorStore()

  useEffect( () => {
    const controller = new AbortController()

    if ( !doctor ) {
      setLoading( true )
      getProductPricing( true ).then( currentDoctor => {
        const doctor = currentDoctor?.data?.patient?.doctor
        if ( doctor ) {
          if ( onExistingDoctor && doctor?.first_name && doctor?.last_name && doctor?.phone ) onExistingDoctor()
          setDoctor({
            firstName: doctor?.first_name ?? ``,
            lastName: doctor?.last_name ?? ``,
            phone: doctor?.phone ? Formatters.phone( doctor.phone ) : ``,
            npi: doctor?.npi ?? ``
          })
        }
        setLoading( false )
      })
        .catch( error => {
          if ( error.name !== `AbortError` ) {
            setLoading( false )
            reportToSentry( new Error( `error getting patient doctor`, {
              cause: JSON.stringify( error ?? `` )
            }) )
          }
        })
    }
    return () => { controller.abort() }
  }, [] )

  return loading
}

export default useRetrieveDoctor