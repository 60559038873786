import { formatDate } from '../../utils'
import Pill, { PillProps } from 'components/pill/Pill'
import React from 'react'
import Order from 'types/order'

export type OrderHeaderProps = {
  order: Order,
  pillProps?: PillProps | null
}

export default function OrderHeader({order, pillProps}: OrderHeaderProps ) {
  return (
    <div className={`flex flex-col`}>
      <p>{formatDate( order.order?.order_date, `string` )}</p>
      <div className={`flex gap-3 py-4 max-w-[360px] items-center`}>
        {order.order?.items.map( ( item, index ) => {
          return <div key={`${order.order?.invoice_number}-${item.item_name}`}>
            {( ( index < 4 ) || ( index === 4 && order.order.items.length === 5 ) ) &&
              <div>
                <img
                  className={`rounded-md border border-hardGray`}
                  src={item?.image?.url} alt={item?.image?.label} height={50}
                  width={50}
                />
              </div>
            }
            {( index === 4 && order.order.items.length !== 5 ) &&
              <div
                className={`flex items-center justify-center w-[54px] h-[28px] rounded-full bg-deepSleepBlue`}
              >
                <p className={`text-white p-lg`}>{`+${order.order.items.length - 4}`}</p>
              </div>
            }
          </div>
        })}
      </div>
      {pillProps &&
        <div className={`pb-4`}>
          <Pill {...pillProps} disabled />
        </div>
      }
    </div>
  )
}