import CustomSkeletonTheme from 'components/reactSkeleton/SkeletonTheme'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

// homepage review section - 3 x 1 dsktp

type ReviewWidgetSkeletonProps = {
  isMobile?: boolean;
}

const ReviewWidgetSkeleton = ({isMobile} : ReviewWidgetSkeletonProps ) => {
  return (
    <CustomSkeletonTheme>
      <div className="max-w-[1280px] w-full mx-auto overflow-hidden sm:pt-9 pb-7 md:pt-[42px] md:pb-[50px] sm:px-4 md:px-6 sm:min-h-[350px] lg:min-h-[450px]">
        <div className="flex flex-col gap-4 w-full text-center">
          <Skeleton height={40} width={`75%`} />
          <div className="flex gap-4 w-full mt-5 justify-center">
            {[ ...Array( !isMobile ? 3 : 1 ) ].map( ( _, index ) => (
              <div key={index} className="sm:w-full md:w-1/3"><Skeleton height={318} width={`100%`} /></div>
            ) )}
          </div>
        </div>
      </div>
    </CustomSkeletonTheme>
  )
}

export default ReviewWidgetSkeleton