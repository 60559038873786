import React from 'react'
import TrackerStepIcon from '../../TrackerStepIcon'
import { referralReceivedCopy } from '../constants'
import { checkmarkWhite } from 'resources/images'
import StepContentRenderer from '../../StepContentRenderer'

type ReferralReceivedStepProps = {
    stepStatus: boolean;
    isCurrentStep?: boolean;
 }

function ReferralReceivedStep({ stepStatus, isCurrentStep } : ReferralReceivedStepProps ) : JSX.Element {
  return (
    <div className="flex">
      <TrackerStepIcon imageSrc={checkmarkWhite} stepStatus={stepStatus} isCurrentStep={isCurrentStep} />
      <StepContentRenderer
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
        stepCopy={referralReceivedCopy}
      />
    </div>
  )
}

export default ReferralReceivedStep