import { Tile } from 'components/tile'
import Insurance from 'modules/resupply2/Insurance'
import { myInsuranceId } from '../constants'

function InsuranceTile() : JSX.Element | null {
  return (
    <Tile headerContent="My Insurance" id={myInsuranceId}>
      <div>
        <Insurance myAccount />
      </div>
    </Tile>
  )
}

export default InsuranceTile