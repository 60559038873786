/* eslint-disable no-console */
import React from 'react'
import PropTypes from 'prop-types'

import Submenu from './submenu'

const SubmenuList = ({ submenus = [] }) => {
  if ( !submenus.length ) return null

  return (
    <div className={`flex justify-evenly font-light`}>
      {submenus.map( ( submenu, index ) => {

        return (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={`p-4 mx-4`}
          >
            <Submenu
              className={`py-6`}
              title={submenu.title}
              submenuItems={submenu.submenuItems}
              url={submenu?.url}
            />
          </div>
        )
      })}
    </div>
  )
}
SubmenuList.propTypes = {
  submenus: PropTypes.array
}

export default SubmenuList