import * as React from 'react'
import { thanksImg } from 'resources/images'

function MaintenanceThankYou() {
  const handleReset = () => {
    // Completely resets the app by forcing a page refresh back to the slash route
    window.location.href = `/`
  }

  return (
    <div className="container mx-auto max-w-7xl pb-8 min-h-50vh mt-6">
      <div className="text-center">
        <h1 className="mb-2 sm:text-3xl md:text-5xl">{`Website Currently Napping`}</h1>
        <p>
          {`Our server is taking a quick nap! Please try again in a few minutes.`}
        </p>
      </div>
      <h2 className="m-6 text-center">
        {`Thank You!`}
      </h2>
      <img
        src={thanksImg} height={305} width={240}
        className="mx-auto w-60" alt="thanks for becoming a user graphic of human face behind notepad"
      />
      <div className="text-center">
        <button type="button" className="mt-8 text-xs rounded-full border border-deepSleepBlue rmin-w-32 px-4 py-2 my-3" onClick={handleReset}>{`Refresh`}</button>
      </div>

    </div>
  )
}

export default MaintenanceThankYou