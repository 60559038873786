import React, { useState } from 'react'
import { MAINTENANCE_PAGE_PATH, MAINTENANCE_PAGE_THANK_YOU_PATH } from 'routes'
import LoadingSpinner from 'components/LoadingSpinner'
import PropTypes from 'prop-types'

function hasGqlError( operation ) {
  const hasQuery = operation?.query?.definitions?.length > 0

  return hasQuery && operation.query.definitions.filter( def => {
    return def.operation === `query`
  }).length > 0

}

function ErrorDisplay({ error }) {

  const [ displayError, setDisplayError ] = useState( false )

  const { operation } = error

  function displayMaintenancePage( ){

    return window.location.replace( MAINTENANCE_PAGE_PATH )
  }

  function displayGenericErrorInComponent(){
    if ( !displayError ) {
      setDisplayError( true )
    }
  }

  React.useEffect( ( ) => {

    const networkError = error?.message.includes( `Network error` )

    if ( hasGqlError( operation ) && window.location.pathname !== MAINTENANCE_PAGE_THANK_YOU_PATH && !networkError ) {
      displayMaintenancePage()

    } else {

      displayGenericErrorInComponent()
    }

  }, [] )

  if ( !displayError ){
    return (
      <div className="mt-24">
        <LoadingSpinner />
      </div>
    )
  } else {
    return window.location.replace( MAINTENANCE_PAGE_PATH )
  }

}

ErrorDisplay.propTypes = {
  error: PropTypes.shape({
    operation: PropTypes.object
  }),
  resetErrorBoundary: PropTypes.func
}

export default ErrorDisplay
