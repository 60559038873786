import React, { useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { BrandSelector } from 'components/SNLSelectors'
import { PageTracker } from '..'
import LoadingSpinner from 'components/LoadingSpinner'
import { EditSelection } from '../EditSelection/EditSelection'
import { useMediaQuery } from 'react-responsive'
import ActionLink from 'components/SNLSelectors/ActionLink'
import { INSURANCE_ADVANTAGE_COPY } from '../InsuranceAdvantage/constants'
import InsuranceAdvantage from '../InsuranceAdvantage/InsuranceAdvantage'
import { useBaseEquipmentStore } from '../State/baseEquipmentStore'
import { SNL_MASK_TYPE_PATH } from '../constants'

const MaskBrand = ({ onHandleChange, formValues, ...pageProps }: any ) => {
  const { isLoading, maskData } = useBaseEquipmentStore()
  const navigate = useNavigate()

  const maskBrands = useMemo( () => {
    const category = maskData.find( item => item.sleep_mask_category === formValues?.cpapMaskType )
    return category?.brandsAvailable.map( brand => brand.brand ) || []
  }, [ maskData, formValues ] )

  const handleNext = () => {
    return pageProps.nextPage()
  }

  const isMobile = useMediaQuery({
    query: `(max-width: 976px)`
  })

  const handleChange = ( name: string, value: string ) => {
    if ( name === `cpapMaskBrand` ) {
      onHandleChange({
        [name]: value,
        cpapMaskDmeID: ``,
        cpapMaskModel: ``,
        cpapMaskSize: ``
      })
    } else {
      onHandleChange({
        [name]: value
      })
    }
    handleNext()
  }

  // ensure a mask type is selected before navigating to the mask-brand page to avoid empty results
  useEffect( () => {
    if ( !formValues.cpapMaskType ) return navigate( SNL_MASK_TYPE_PATH )
  }, [ ] )

  // clear mask model when brand changes
  useEffect( () => {
    onHandleChange({
      cpapMaskModel: ``
    })
  }, [ formValues.cpapMaskBrand ] )

  if ( isLoading ) {
    return (
      <div>
        <PageTracker title={`Your Mask`} />
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <div>
      <PageTracker
        title={`Your Mask`}
        description={`To get started, select a mask so that we can provide you with an estimated cost through insurance.`}
      />

      {
        isMobile && <EditSelection activeStep={pageProps.activeStep} />
      }

      <BrandSelector
        heading={`Select your current mask brand`}
        name="cpapMaskBrand"
        type="mask"
        onChange={( name, value ) => {
          return handleChange(
            name,
            value
          )
        }}
        brandNames={maskBrands}
        selectedValue={formValues.cpapMaskBrand}
        defaultLabel="Select a mask manufacturer"
        showOnMobile
      />

      {
        isMobile &&
            <InsuranceAdvantage
              className="mt-14"
              description={INSURANCE_ADVANTAGE_COPY.maskDescription}
            />
      }

      <ActionLink
        handleClick={pageProps.onHandleDoesNotWantMask}
        text={`Don't want a mask?`}
      />
    </div>
  )
}

export default MaskBrand