import React from 'react'

type LinkProps = {
  url: string;
  text: string;
}

type ContentProps = {
  wwwLinks: LinkProps[];
}

export const MemberWebLinks = ( props: ContentProps ) => {
  return (
    <ul className="list-disc pl-4">
      {props.wwwLinks.map( ( link, index ) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index}>
            <a className="a" href={link.url}>{link.text}</a>
          </li>
        )
      })}
    </ul>
  )
}

export default MemberWebLinks