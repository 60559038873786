import React from 'react'
import { doctorStepImg } from 'resources/images'
import { CheckboxInput } from 'components/forms/components'
import PropTypes from 'prop-types'

function Item({detail, loading, editMode, handleCheck }) {

  return (
    <div className={`flex ${editMode && `cursor-pointer`}`} onClick={() => { if ( editMode ) return handleCheck( detail.dme_id ) }}>
      {
        editMode &&
          <CheckboxInput
            value={detail?.include}
            onChange={() => { return }}
            disabled={loading}
            className="cursor-pointer mr-2 focus:outline-none rounded-full"
          />
      }
      {
        ( ( !editMode && detail?.include ) || editMode ) &&
        <div className="mb-5">
          {
            detail?.image_source &&
            <div>
              <img
                src={doctorStepImg}
                alt={`product_img`}
                className="border border-solid border-lightgray object-contain p-2 flex-1"
                width="65"
                height="65"
              />
            </div>
          }
          <div className="text-left flex-1 px-2 md:px-4">
            <p className="sm:text-sm md:text-base">{`${detail?.item_description}.`}</p>
            <p className="text-sm md:text-base font-light opacity-60">{`Quantity: ${detail?.quantity}`}</p>
            {
              detail?.ins_payment !== undefined && detail?.patient_owes !== undefined &&
              <>
                <p className="text-sm md:text-base font-light">{`Insurance Pays: $${detail?.ins_payment.toFixed( 2 )}`}</p>
                <p className="text-sm md:text-base font-light">{`What You Owe${detail?.rental_flag ? ` Monthly` : ``}: $${detail?.patient_owes.toFixed( 2 ) }`}</p>
              </>
            }
          </div>
        </div>
      }
    </div>
  )
}

Item.propTypes = {
  detail: PropTypes.shape({
    image_source: PropTypes.string,
    dme_id: PropTypes.number,
    item_description: PropTypes.string,
    quantity: PropTypes.number,
    ins_payment: PropTypes.number,
    rental_flag: PropTypes.bool,
    patient_owes: PropTypes.number,
    include: PropTypes.bool
  }),
  editMode: PropTypes.bool,
  loading: PropTypes.bool,
  handleCheck: PropTypes.func
}

export default Item