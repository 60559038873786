import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import ProductResult from './ProductResult'
import tailwindColors from 'config/tailwindColors'
import { ALink } from 'components/ALinks'


const StyledUpArrow = styled.div`
  position: absolute;
  left: 90%;
  top: -0.3rem;
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid ${tailwindColors.lightGray};
`

const SearchResults = ({ results, loading, resultsAreDisplayed, searchTerm, popularSearches, recentSearches, blogs }) => {

  if ( loading ) return null

  return (
    <div className={`absolute z-100 sm:w-full md:min-w-full md:w-max md:max-w-3xl lg:max-w-4xl sm:right-0 md:-right-14 top-12 m-auto rounded box-shadow-light p-0 z-10${resultsAreDisplayed ? ` visible`: ` invisible`}`}>
      <StyledUpArrow className="sm:hidden md:block" />

      <div className="bg-lightGray py-6 px-12 grid gap-6 md:grid-cols-12 md:grid-rows-none sm:grid-rows-2">
        <div className="md:col-span-3 md:row-auto sm:row-start-2">
          <XSearchItemResults listTitle={`Blogs`} searchItems={blogs} />
          <hr className="text-lightGray my-2"></hr>
          <XSearchItemResults listTitle={`Popular Searches`} searchItems={popularSearches} />
          <hr className="text-lightGray my-2"></hr>
          <XSearchItemResults listTitle={`Recent Searches`} searchItems={recentSearches} />
        </div>

        <div className="md:col-span-9 md:row-auto sm:row-start-1">
          <p>{`Products`}</p>
          <ul className="grid sm:grid-flow-row md:grid-flow-col md:gap-4 lg:gap-8 xl:gap-12">
            {results?.length ? results.map( product => {
              return <ProductResult key={product.id} product={product} />
            }) : (
              <li>{`No Results...`}</li>
            )}
          </ul>

          { results?.length ? (
            <ALink
              href={`${process.env.REACT_APP_MAGENTO_2_BASE_URL}/catalogsearch/result/?q=${searchTerm}`}
              target="_self"
              rel="noopener noreferrer"
            >
              <p>{`See more results for ${searchTerm}`}</p>
            </ALink>
          ) : null}
        </div>
      </div>
    </div>
  )
}
SearchResults.propTypes = {
  results: PropTypes.array,
  loading: PropTypes.bool,
  resultsAreDisplayed: PropTypes.bool.isRequired,
  searchTerm: PropTypes.string.isRequired,
  popularSearches: PropTypes.array,
  recentSearches: PropTypes.array,
  blogs: PropTypes.array
}

export default SearchResults

export const XSearchItemResults = ({ listTitle, searchItems }) => {
  return (
    <div className="border-b border-secondaryBlue-tint2">
      <p className="my-2">{listTitle}</p>
      <ul>
        {searchItems.map( ( searchItem ) => {

          return (
            <li
              key={searchItem.url}
              className="p-sm ml-2 mb-2 text-xs"
            >
              <ALink
                href={`https://shop.aeroflowsleep.com/${searchItem.url}`}
                target="_blank"
              >
                {/* Hope to switch this out for a p tag once we get M2 to not return html */}
                <span
                  dangerouslySetInnerHTML={{
                    __html: searchItem.name
                  }}
                >
                </span>
              </ALink>
            </li>
          )
        })
        }
      </ul>
    </div>
  )
}

XSearchItemResults.propTypes = {
  listTitle: PropTypes.string.isRequired,
  searchItems: PropTypes.array
}

