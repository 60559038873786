import { abnResponse, abnSubmitBody } from "./types/types"

export async function getAbnInfo( patient_hash: string ): Promise<abnResponse> {
  const response = await fetch( `${process.env.REACT_APP_DIGITAL_ABN}/get-abn?patient_hash=${patient_hash}` )

  return await response?.json()
}

export async function submitAbn( body: abnSubmitBody ) {
  const response = await fetch( `${process.env.REACT_APP_DIGITAL_ABN}/submit-abn`, {
    method: `POST`,
    body: JSON.stringify( body )
  })

  return await response?.json()
}