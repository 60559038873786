import { gql } from '@apollo/client'

export const createPatientMutation = gql`
  mutation CreatePatientMutation($patientCreateInput: PatientCreateInput!) {
    createPatient(input: $patientCreateInput) {
      customer {
        firstname
        lastname
      }
    }
  }
`
