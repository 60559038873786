import LoadingSpinner from 'components/LoadingSpinner'
import { Heading, ShippingPreview, ItemsPreview, ContactBlock, TermsBlock } from 'components/resupply2'
import useApi from './api'
import { PagePropsAbstract } from 'components/pageNavigator'
import { useContactInfoState } from '../hooks'
import { Validators } from 'components/forms/components'
import { useState } from 'react'

export default function Confirm( props : PagePropsAbstract ) : JSX.Element {

  const { submitOrder, loading, error, orderSuccess } = useApi()
  const [ contactInfo ] = useContactInfoState()

  const isValidPhone = Validators.phone( contactInfo.phone )

  const [ isContactComplete, setIsContactComplete ] = useState( isValidPhone )

  const handleSubmit = () => {
    submitOrder()
  }

  if ( orderSuccess ){
    props.nextPage()
  }

  return (
    <div className="max-w-3xl min-w-xs mx-auto">
      <Heading title="Place Your Order" description={``} />

      <ItemsPreview />
      <ShippingPreview />

      <ContactBlock
        initialReadOnly={!isValidPhone}
        onEdit={() => { setIsContactComplete( false ) }}
        onComplete={() => { setIsContactComplete( true ) }}
      />

      <TermsBlock />

      <div className="max-w-xs w-full mx-auto mb-5 flex flex-col items-center gap-4">
        {error && <p className="text-error text-center font-light">{error}</p>}
        <button
          className="btn-secondary" onClick={handleSubmit} type="submit"
          disabled={loading || !isValidPhone || !isContactComplete}
        >{!loading ? `Submit Order` : <div className="flex gap-3 justify-center"><LoadingSpinner height="1.5rem" width="1.5rem" rings={4} />{`Submitting Order...`}</div>}</button>
        <button className="btn-primary" onClick={props.prevPage}>{`Back`}</button>
      </div>

    </div>
  )
}