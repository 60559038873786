import React from 'react'
import PropTypes from 'prop-types'

const FormButton = ({type = `submit`, className = ``, disabled=false, children, ...rest}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={`text-xs rounded-full min-w-32 px-4 py-2 ${className}`}
      {...rest}
    >
      {children}
    </button>
  )
}
FormButton.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool
}

export default FormButton
