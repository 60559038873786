import React from "react"
import ReactMarkdown from 'react-markdown'

type DetailsBlockProps = {
  imageSrc?: string;
  imageAlt?: string;
  title: string;
  additionalTitle?: string;
  description?: string;
  additionalDescription?: string;
  button?: {
    text: string;
    link: string;
    externalLink: boolean;
  };
}
export default function DetailsBlock({imageSrc, imageAlt, title, description, additionalDescription, button, additionalTitle} : DetailsBlockProps ) {

  // LinkRenderer looks for a [text](link) pattern in normal strings and renders a link using ReactMarkdown
  const LinkRenderer: React.FC<{ href?: string, children?: React.ReactNode, className?: string }> = ({ href, children }) => {
    return <a
      href={href ?? `#`}
      target="_blank"
      rel="noopener noreferrer"
      className={`text-deepSleepBlue hover:underline`}
    >
      {children}
    </a>
  }

  return (
    <div className="flex my-8 sm:max-w-md md:max-w-lg md:gap-0 sm:gap-4">
      <div className="w-1/4 pr-4">
        {imageSrc &&
          <img
            src={imageSrc}
            alt={imageAlt}
            width={90}
            height={90}
          />}
      </div>
      <div className="w-3/4">
        <p className="font-semibold mt-4 mb-1">{title}</p>
        {description &&
          <p className="font-normal">{description}</p>
        }

        { additionalTitle &&
          <p className="font-semibold mt-4 mb-1">{additionalTitle}</p>

        }
        { additionalDescription &&
        <p className="font-normal mb-6">
          <ReactMarkdown
            components={{
              a: LinkRenderer
            }}
          >
            {additionalDescription}
          </ReactMarkdown>
        </p>}
        { button?.text && button?.link &&
        <a
          href={button.link}
          target={button.externalLink ? `_blank` : ``}
          rel={button.externalLink ? `noopener noreferrer` : ``}
          className="btn-secondary text-center block !px-3"
        >
          {button.text}
        </a>
        }
      </div>
    </div>
  )
}