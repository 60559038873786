import React, { useEffect } from 'react'
import Order, { Item } from 'types/order'
import { PillProps } from 'components/pill/Pill'
import { formatDate } from '../../utils'
import Accordion from 'components/accordion/Accordion'
import OrderHeader from './OrderHeader'
import OrderBody from './OrderBody'

function OrderRows({ orders, showPill = false }: { orders: Order[], showPill?: boolean }): JSX.Element {
  const [ showMore, setShowMore ] = React.useState( false )
  const [ displayedOrders, setDisplayedOrders ] = React.useState( orders?.slice( 0, 2 ) )
  const getPillProps = ( items: Item[] ): PillProps | null => {
    if ( !showPill ) return null

    const isShipped = items.some( item => item.status === `Shipped` )
    if ( isShipped ) {
      return {
        text: `Shipped Via USPS`,
        color: `#E1EAE4`,
        link: items.find( item => item.status === `Shipped` && item.url !== `Unable to provide tracking link.` )?.url || ``,
        textColor: `#308247`
      }
    }

    const isAwaitingFulfillment = items.some( item => ( item.status === `Awaiting Fulfillment` ) )
    if ( isAwaitingFulfillment ) {
      return {
        text: `Processing`,
        color: `#E5E7EB`,
        tooltip: `Your order has been received and is awaiting fulfillment from our shipping department. We will provide you with tracking details as soon as your order has been shipped.`
      }
    }

    const isProcessing = !items.every( item => ( item.status === `Delivered` || item.status === `Cancelled` ) )
    if ( isProcessing ) {
      return {
        text: `Processing`,
        color: `#E5E7EB`,
        tooltip: `Your order is currently on hold and cannot be shipped due to one of the following reasons: we need an updated prescription from your doctor, prior authorization from your insurance, or there is an outstanding balance on your account. Our representatives are actively working to obtain any outstanding information or documentation required.`
      }
    }

    return null
  }

  useEffect( () => {
    if ( showMore ) setDisplayedOrders( orders )
    else setDisplayedOrders( orders.slice( 0, 2 ) )
  }, [ showMore ] )

  return (
    <>
      {orders.length > 0 &&
        <>{displayedOrders.map( order => {
          return order?.order && <div key={`${order.order?.invoice_number}`} className={`border-b -mx-4 p-4`}>
            <Accordion
              id={`${order.order?.invoice_number}`}
              header={<OrderHeader order={order} pillProps={getPillProps( order.order.items )} />}
              openHeader={<p>{formatDate( order.order?.order_date, `string` )}</p>}
              body={<OrderBody order={order} pillProps={getPillProps( order.order.items )} />}
            />
          </div>
        })}
        {orders.length > 2 &&
        <div className={`border-b -mx-4 p-4`}>
          <button className={`w-full text-deepSleepBlue underline`} onClick={() => setShowMore( !showMore )}>{showMore ? `show less`: `show more`}</button>
        </div>}
        </>}
    </>
  )
}

export default OrderRows