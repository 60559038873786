import React, { useState, useRef } from "react"
import { SelectInput, TextInput, ErrorField, Validators} from "components/forms/components"
import { StatePayer } from "hooks/Insurance"
import { regexErrors } from 'components/forms/constants'
import { InsuranceEditProps, Region } from './types'
import { reportToSentry } from "utils/reportToSentry"
import LoadingSpinner from "components/LoadingSpinner"

export default function InsuranceEdit({ formFields, onChange, onClose, onSubmit, regions, payerData } : InsuranceEditProps ) : JSX.Element {

  const memberIDRef = useRef<HTMLInputElement>( null )

  const [ errorMessage, setErrorMessage ] = useState<string>( `` )
  const [ submitLoading, setSubmitLoading ] = useState<boolean>( false )
  const [ memberIdError, setMemberIdError ] = useState<string>( `` )

  function removeErrors() {
    setMemberIdError( `` )
  }

  function handleChange( e : React.ChangeEvent<HTMLInputElement> ) {
    if ( e.target.name === `regionCode` ) payerData.refetchPayers()
    onChange( e )
  }

  const handleSubmit = async ( event: React.FormEvent<HTMLFormElement> ) => {
    event.preventDefault()
    setSubmitLoading( true )

    if ( payerData.payersLoading ) return

    // Validate Fields before submit
    if ( !Validators.memberID( formFields.memberID ) ) return memberIDRef?.current?.focus()

    // Update insurance request
    const response = await onSubmit().catch( ( error : Error ) => {
      reportToSentry( new Error( `Error Occurred updating insurance`, {
        cause: error
      }) )
    })

    setSubmitLoading( false )

    if ( !response ) return setErrorMessage( `There was an error updating your insurance` )

    if ( response?.meta?.status === `OK` ) return onClose( formFields, formFields.payerFriendlyName )

    reportToSentry( new Error( `Error Occurred updating insurance: Failed Response Status` ), {
      responseStatus: response?.meta?.status
    })
    setErrorMessage( `There was an error updating your insurance` )
  }

  return (
    <form onSubmit={handleSubmit} className="flex justify-center relative">
      <div className="p-2 w-full">
        <p className="text-center pb-2">{`Update your insurance information below.`}</p>
        <SelectInput
          label="State"
          name="regionCode"
          className="input"
          value={formFields.regionCode}
          onChange={handleChange}
          asteriskClassName="input-asterisk"
          required
          id={`RegionCodeInput` as never}
          reference={undefined}
          disabled={false}
        >
          <option value="" disabled hidden>{`Select a State`}</option>
          {regions.map( ( region : Region ) => {
            return <option key={region.id} value={region.code}>{region.name}</option>
          })}
        </SelectInput>
        <br></br>

        <SelectInput
          label="Insurance Name"
          name="payerFriendlyName"
          className="input"
          value={formFields.payerFriendlyName || ``}
          onChange={handleChange}
          asteriskClassName="input-asterisk"
          required
          id={`ParentPayerPKInput` as never}
          reference={undefined}
          disabled={payerData.payersLoading || !formFields.regionCode}
        >
          <option value="" disabled hidden>{!formFields.regionCode ? `Please Select a State` : payerData.payersLoading ? `Loading Insurance Providers...` : `Select an Insurance Provider`}</option>
          {( payerData.payers as StatePayer[] ).map( ( p : StatePayer, i: number ) => {
            // eslint-disable-next-line react/no-array-index-key
            return <option key={i} value={p.friendly_name}>{p.friendly_name}</option>
          })}
        </SelectInput>

        <br></br>
        <TextInput
          label="Member ID"
          name="memberID"
          value={formFields.memberID}
          onChange={handleChange}
          onBlur={( event: React.ChangeEvent<HTMLInputElement> ) => { return setMemberIdError( !Validators.memberID( event.currentTarget.value ) ? regexErrors.memberID : `` ) }}
          onFocus={removeErrors}
          errorMessage={memberIdError}
          errorMessageClassName="text-error text-sm mb-1"
          asteriskClassName="input-asterisk"
          className="input"
          errorClassName="input-error"
          reference={memberIDRef}
          required formatter={undefined} validator={undefined}
          id={`MemberIdInput` as never}
        />
        <br></br>
        <div className="max-w-xs w-full mx-auto">
          <button className="btn btn-secondary" type="submit" disabled={submitLoading || Boolean( memberIdError )}>{submitLoading ? <LoadingSpinner height="24px" width="24px" /> : `Save`}</button>
        </div>
        {errorMessage && <ErrorField message={errorMessage} />}
      </div>
    </form>
  )
}