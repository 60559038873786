import { gql } from '@apollo/client'

const removeItemFromCartMutation = gql`
  mutation RemoveItemFromCartMutation( $input: RemoveItemFromCartInput ) {
    removeItemFromCart(  input: $input ) {
      cart {
        items {
          id
        }
      }
    }
  }
`

export default removeItemFromCartMutation