import React, { useState, useEffect } from 'react'
import { BankPaymentMethod, CardPaymentMethod, IPaymentMethod, identifyPaymentMethodType } from 'components/payments/types.d'
import { ViewPayMethod, EditCardMethod, EditBankMethod } from 'components/payments/payMethods'
import { emptyBankMethod, emptyCardMethod } from 'modules/aobPayments/constants'
import HeadingWithBackButton from 'modules/aobPayments/components/backButton/Heading'

type PaymentMethodProps = {
  payMethod: IPaymentMethod,
  setPayMethod: ( _c : IPaymentMethod ) => void,
  handleRemove: () => void,
  onEdit: () => void;
  onSave: () => void;
  onSetPaymentMethod: ( _c : boolean ) => void;

  splitSinglePayment?: boolean | null;
  onBackToUpfrontPayment?: () => void;
}

const PaymentMethod = ({ payMethod, setPayMethod, handleRemove, onSave, onEdit, onSetPaymentMethod } : PaymentMethodProps ) => {

  const [ paymentMethodType, setPaymentMethodType ] = useState<null | `card` | `ach`>( identifyPaymentMethodType( payMethod ) )
  const [ isEditView, setIsEditView ] = useState( true )

  const handleEdit = () => {
    setIsEditView( true )
    onEdit() // disable submit button while in edit mode
  }

  function savePaymentMethod() {
    setIsEditView( false )
    onSave()
  }

  function resetPaymentMethodType() {
    setPaymentMethodType( null )
    handleEdit()
  }

  useEffect( () => {
    if ( !paymentMethodType ) {
      if ( onSetPaymentMethod ) onSetPaymentMethod( true )
      setPayMethod( paymentMethodType === `card` ? Object.assign({}, emptyCardMethod ) : Object.assign({}, emptyBankMethod as IPaymentMethod ) )
    } else if ( paymentMethodType === `card` && !( payMethod as CardPaymentMethod )?.cardNumber ) {
      setPayMethod( Object.assign({}, emptyCardMethod ) )
    } else if ( paymentMethodType === `ach` && !( payMethod as BankPaymentMethod )?.routingNumber ) {
      setPayMethod( Object.assign({}, emptyBankMethod as IPaymentMethod ) )
    }
  }, [ paymentMethodType ] )

  /* --- Select Payment Type --- */
  if ( paymentMethodType === null ) {
    // TODO: render initial select payment type
    return (
      <div className="max-w-2xl mx-auto">
        <div className="flex flex-col items-center w-full max-w-[348px] mx-auto mb-5 gap-2">
          <p className="md:text-lg font-light text-center pb-2">{`Choose One`}</p>
          <button
            className="btn-primary mb-[12px] w-full flex-grow"
            onClick={() => {
              setPaymentMethodType( `card` )
              if ( onSetPaymentMethod ) onSetPaymentMethod( false )
            }}
          >
            <p className="md:text-lg">{`Pay by credit or debit card`}</p>
          </button>
          <p>{`OR`}</p>
          <button
            className="btn-primary mt-[12px]"
            onClick={() => {
              setPaymentMethodType( `ach` )
              if ( onSetPaymentMethod ) onSetPaymentMethod( false )
            }}
          >
            <p className="md:text-lg">{`Pay by bank account`}</p>
          </button>
        </div>
      </div>
    )
  }

  /* --- Edit Current Payment Method --- */
  if ( isEditView ) {
    const isCardType = paymentMethodType === `card`
    return (
      <div className="w-full mx-auto">

        <HeadingWithBackButton
          headingText={`Add ${isCardType ? `Card` : `Bank Account`}`}
          headingClassName="md:text-lg font-medium my-4 text-center"
          onBackClick={resetPaymentMethodType}
          backText="Change"
          backClassName="sm:ml-0 md:ml-4"
        />

        {isCardType ? (
          <EditCardMethod
            payMethod={payMethod as CardPaymentMethod}
            setPayMethod={setPayMethod}
            onSave={savePaymentMethod}
          />
        ) : (
          <EditBankMethod
            payMethod={payMethod as BankPaymentMethod}
            setPayMethod={setPayMethod}
            onSave={savePaymentMethod}
          />
        )}
      </div>
    )
  }

  /* --- View Current Payment Method --- */
  return (
    <ViewPayMethod
      handleEdit={handleEdit}
      handleCancel={() => {
        handleRemove()
        handleEdit()
      }}
      payMethod={payMethod}
    />
  )
}

export default PaymentMethod