import React from 'react'
import PropTypes from 'prop-types'
import SubmenuItem from './SubmenuItem'
import { icoCaretLeftNavy, icoCaretRightNavy } from 'resources/images'

const SubmenuList = ( props ) => {
  const isActive = props.activeSubmenu === props.title
  const isHidden = Boolean( !isActive && props.activeSubmenu )

  const handleClick = () => {
    if ( isActive ) return props.setActiveSubmenu( `` )

    return props.setActiveSubmenu( props.title )
  }

  return (
    <>
      <button
        type="button"
        className={`flex justify-start items-center cursor-pointer focus:outline-none w-full text-aeroflowNavy ${isHidden && `hidden`}`}
        onClick={handleClick}
      >
        {props.submenuStaticLength > 0 && (
          <>
            <img
              src={icoCaretLeftNavy}
              className={`mr-2 ${!isActive ? `hidden` : ``}`}
              alt="Closed Submenu Caret"
              width="6"
              height="13"
            />
            <img
              src={icoCaretRightNavy}
              className={`mr-2 ${isActive ? `hidden` : ``}`}
              alt="Opened Submenu Caret"
              width="6"
              height="13"
            />
          </>
        )
        }

        {props.submenuStaticLength > 0 ? <div className={`py-2 ${isActive && `font-bold text-aeroflowNavy`}`} >{props.title}</div> : <a href={props.url}>{props.title}</a>}
      </button>

      { isActive && props.subMenus.map( ( subMenu, index ) => {
        return (
          <SubmenuItem
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}-${subMenu.title}`}
            items={subMenu.submenuItems}
            title={subMenu.title}
            url={subMenu.url}
          />
        )
      })}
    </>
  )
}

export default SubmenuList

SubmenuList.propTypes = {
  title: PropTypes.string.isRequired,
  subMenus: PropTypes.arrayOf( PropTypes.object ).isRequired,
  activeSubmenu: PropTypes.string.isRequired,
  setActiveSubmenu: PropTypes.func.isRequired,
  submenuStaticLength: PropTypes.number,
  url: PropTypes.string
}
