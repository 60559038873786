import { useSpring, animated as a } from "react-spring"

export default function ProgressHeader({ percentProgress, stepText } : { percentProgress : number, stepText: string }) : JSX.Element {

  const progressFillStyling = useSpring({
    to: {
      width: `${percentProgress}%`
    },
    config: {
      duration: 800
    }
  })

  return (
    <div className="text-center mb-4">
      <div className="bg-deepSleepBlue">
        <p className="uppercase p-4 text-white font-light">{`Products Covered by Insurance`}</p>
      </div>
      <div className="flex w-full h-5 bg-ctaYellow bg-opacity-50">
        <a.div
          className="bg-ctaYellow"
          style={progressFillStyling}
        ></a.div>
      </div>
      <h5 className="mt-6 font-thin text-graphite opacity-80">{stepText}</h5>
    </div>
  )
}