import { useState } from 'react'
import RadioInput from 'components/forms/components/RadioInput'
import CheckboxInput from 'components/forms/components/CheckboxInput'
import { useHealthTapSurveyStore } from '../../State/healthTapSurveyStore'
import { apneaFreqOptions, apneaLastDiscussedOptions, apneaMedConditions, apneaOptions, healthTapBloodPressureOptions } from './constants'
import { healthTapLogoImg } from 'resources/images'
import LoadingSpinner from 'components/LoadingSpinner'
import { buildHealthTapInsuranceArray, buildHealthTapProductArray, buildHealthTapScreenerArray, submitHealthTapScreener } from './utils'
import { HealthTapScreener } from 'types/healthtapScreener'
import { useQualifyFieldsStore } from 'stores/qualifyFieldsStore'
import { useSNLStore } from 'modules/sleepNewLeads/State/snlStorage'
import { useAddressStore } from 'components/addressBlock'
import { useDoctorStore } from 'components/doctorBlock/hooks/state'
import { reportToSentry } from 'utils/reportToSentry'
import { useTokenStore } from 'stores'

export default function HealthTapModalForm({ onSuccess, patientId } : { onSuccess?: () => void, patientId: number | null }) : JSX.Element {

  const [ loading, setLoading ] = useState<boolean>( false )
  const [ error, setError ] = useState<string>( `` )
  const [ submitError, setSubmitError ] = useState<string>( `` )

  const { formFields } = useQualifyFieldsStore()
  const { snlEmail, formValues } = useSNLStore()
  const { address } = useAddressStore()
  const { doctor } = useDoctorStore()
  const { customerToken } = useTokenStore()

  const {
    apneaHealthCondition,
    apneaType,
    apneaFrequency,
    healthtapBloodPressure,
    apneaLastDiscussed,
    setApneaType,
    setApneaFrequency,
    toggleApneaHealthCondition,
    setHealthTapBloodPressure,
    setApneaLastDiscussed
  } = useHealthTapSurveyStore()

  const handleSubmit = async () => {
    if ( !apneaType || !apneaFrequency || !healthtapBloodPressure || !apneaLastDiscussed || !patientId ) return setSubmitError( `Please fill out all required fields.` )

    setLoading( true )

    const insuranceMatchData = buildHealthTapInsuranceArray( formFields )

    // build healthtap screener payload
    const healthTapPayload:HealthTapScreener = {
      email_address: formFields.username || snlEmail,
      first_name: formFields.firstName,
      last_name: formFields.lastName,
      date_of_birth: `${formFields.dobYear}-${formFields.dobMonth}-${formFields.dobDay}`,
      phone_number: formFields.phoneNumber.replace( /\D/g, `` ),
      address_line_1: address.street,
      ...( address.street2 ? {
        address_line_2: address.street2
      } : {}),
      city: address.city,
      state: address.state,
      postcode: address.zipCode,
      // external_person_id: snl,
      pcp_first_name: doctor?.firstName ?? ``,
      pcp_last_name: doctor?.lastName ?? ``,
      ...( doctor?.npi ? {
        npi: doctor.npi
      } : {}),
      pcp_phone: doctor?.phone?.replace( /\D/g, `` ) ?? ``,
      insurances: insuranceMatchData.insurances,
      insurance_match: insuranceMatchData.match,
      // this is for middleware to lookup the accessories for a mask and machine and pass off to healthtap as well
      ...( formValues?.cpapMachineDmeID ? {
        crosswalk_machine_dmeid: formValues.cpapMachineDmeID?.toString()
      } : {}),
      ...( formValues?.cpapMaskDmeID ? {
        crosswalk_mask_dmeid: formValues.cpapMaskDmeID?.toString()
      } : {}),
      products: buildHealthTapProductArray( formValues ),
      screener_answers: buildHealthTapScreenerArray({
        apneaType,
        apneaFrequency,
        apneaHealthCondition,
        healthtapBloodPressure,
        apneaLastDiscussed
      }, formValues.cpapMachineBrand ),
      external_person_id: patientId?.toString() ?? ``
    }

    // submit screener here
    const healthtapResponse = await submitHealthTapScreener( healthTapPayload, customerToken )
      .catch( error => {
        reportToSentry( new Error( `HealthTap Screener submission failed`, {
          cause: error
        }), {
          payload: JSON.stringify( healthTapPayload )
        })
        setLoading( false )
        return setError( `There was an error submitting your screener. Please try again.` )
      })

    setLoading( false )

    // check for successful response
    if ( !healthtapResponse || healthtapResponse?.error_message || !healthtapResponse?.magic_link ) return setSubmitError( healthtapResponse?.error_message ?? `There was an error submitting your screener. Please try again.` )

    // direct user to healthtap
    window.location.href = healthtapResponse.magic_link

    // after successfully submitting healthtap clear store and trigger reset password
    if ( onSuccess ) onSuccess()
  }

  const checkBoxContainerStyle = `my-3 w-full`
  const radioBtnStyle = `themed-radio--showCheck inline-flex shrink-0 -translate-y-[-4px]`

  return (
    <div className="flex flex-col items-start justify-start p-3 gap-5 font-light h-screen max-h-[900px] overflow-auto">
      <img
        src={healthTapLogoImg}
        height={31}
        width={175}
        className="mt-8 mb-5 mx-auto"
      />
      {
        error ? (
          <p className="text-error text-center mx-auto">{error}</p>
        ): (
          <>
            <div>
              <p className="text-lg text-left inline-block font-light">
                {`Were you diagnosed with a specific Sleep Apnea type?`}
                <span className="text-error">{`*`}</span>
              </p>
              <div className={checkBoxContainerStyle}>
                {apneaOptions.map( option => (
                  <RadioInput
                    key={option.value}
                    name="apnea_type"
                    checked={option.value === apneaType}
                    wrapperClassName="flex gap-2"
                    onChange={() => setApneaType( option.value )}
                    label={option.label}
                    labelClassName="text-black font-light text-lg"
                    className={radioBtnStyle}
                  />
                ) )}
              </div>
            </div>

            <div>
              <p className="text-lg text-left inline-block font-light">
                {`How consistently do you use your Sleep Apnea device?`}
                <span className="text-error">{`*`}</span>
              </p>
              <div className={checkBoxContainerStyle}>
                {apneaFreqOptions.map( option => (
                  <RadioInput
                    key={option.value}
                    name="apnea_frequency"
                    checked={option.value === apneaFrequency}
                    wrapperClassName="flex gap-2"
                    onChange={() => setApneaFrequency( option.value )}
                    label={option.label}
                    labelClassName="text-black font-light text-lg"
                    className={radioBtnStyle}
                  />
                ) )}
              </div>
            </div>

            <div>
              <p className="text-lg text-left inline-block font-light">
                {`Do you have any of the following medical conditions that are often associated with Sleep Apnea? (select any that apply)`}
                <span className="text-error">{`*`}</span>
              </p>
              <div className={checkBoxContainerStyle}>
                {apneaMedConditions.map( option => (
                  <CheckboxInput
                    key={option.value}
                    name={option.value}
                    value={apneaHealthCondition[option.value]}
                    onChange={() => toggleApneaHealthCondition( option.value )}
                    label={option.label}
                    labelClassName="text-black font-light text-lg"
                    wrapperClassName="themed-checkboxes-snl flex items-center gap-2"
                  />
                ) )}
              </div>
            </div>

            <div>
              <p className="text-lg text-left inline-block font-light">
                {`Do you have (or are you being treated for) high blood pressure?`}
                <span className="text-error">{`*`}</span>
              </p>
              <div className={checkBoxContainerStyle}>
                {healthTapBloodPressureOptions.map( option => (
                  <RadioInput
                    key={option.value}
                    name="healthtap_blood_pressure"
                    checked={healthtapBloodPressure === option.value}
                    onChange={() => setHealthTapBloodPressure( option.value )}
                    label={option.label}
                    wrapperClassName="flex gap-2"
                    labelClassName="text-black font-light text-lg"
                    className={radioBtnStyle}
                  />
                ) )}
              </div>
            </div>

            <div>
              <p className="text-lg text-left inline-block font-light">
                {`When did you last discuss your Sleep Apnea with your clinical team?`}
                <span className="text-error">{`*`}</span>
              </p>
              <div className={checkBoxContainerStyle}>
                {apneaLastDiscussedOptions.map( option => (
                  <RadioInput
                    key={option.value}
                    name="last_discussed"
                    checked={option.value === apneaLastDiscussed}
                    onChange={() => setApneaLastDiscussed( option.value )}
                    label={option.label}
                    wrapperClassName="flex gap-2"
                    labelClassName="text-black font-light text-lg"
                    className={radioBtnStyle}
                  />
                ) )}
              </div>
            </div>
            <div className="w-full mx-auto flex flex-col items-center my-4">
              <button className="btn-primary" onClick={handleSubmit} disabled={loading}>
                { loading ? (
                  <div className="flex gap-2 mx-auto items-center justify-center">
                    <LoadingSpinner height="24px" width="24px" rings={3} />
                    {`Submitting Screener...`}
                  </div>
                ) : `Continue`}
              </button>
              { submitError && <p className="text-error text-center mt-3" id="submit_error">{submitError}</p> }
            </div>
          </>
        )
      }
    </div>
  )
}