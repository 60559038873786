import { reportToSentry } from "utils/reportToSentry"

export default function putPartialLead( email : string ) : void {
  fetch( `${process.env.REACT_APP_LAMBDA_PARTIAL_LEADS_URL}/`, {
    method: `POST`,
    headers: {
      'Content-Type': `application/json`
    },
    body: JSON.stringify({
      email: email,
      form_code: `sleep_qualify_through_insurance`
    })
  }).then( ( res ) => {
    if ( !res.ok ) {
      reportToSentry( `Partial Lead Response Failed`, {
        response: JSON.stringify( res )
      })
    }
  })
    .catch( error => {
      reportToSentry( error )
    })
}
