import React from 'react'
import Collapsible from './Collapsible'
import { ColWrapper, ColHeader } from './tailwindWrappers'
import PaymentOptionsImageGrid from './PaymentOptionsImageGrid'

const PaymentOptionsCol = () => {
  return (
    <ColWrapper>
      <ColHeader>{`Payment Options`}</ColHeader>
      <PaymentOptionsImageGrid />
    </ColWrapper>
  )
}

export const PaymentOptionsColMobile = () => {
  return (
    <Collapsible
      panelHeader="Payment Options"
      displayBlock={PaymentOptionsImageGrid}
    />
  )
}

export default PaymentOptionsCol
