export const sliderSettings = {
  dots: true,
  arrows: false,
  infinite: false,
  swipeToSlide: true,
  focusOnSelect: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 976,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1
      }
    }
  ]
}