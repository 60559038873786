import { getAuthHeader } from "utils/auth/helpers"
import { addMagentoEnvAuthHeaders } from "utils/magentoEnvAuthHeaders"

export const submitMaskReplacement = async ( new_mask_id, patient_id ) => {
  const response = await fetch( `${process.env.REACT_APP_LAMBDA_RESUPPLY_URL}/mask-swap`, {
    method: `POST`,
    headers: {
      ...addMagentoEnvAuthHeaders(),
      "Authorization": getAuthHeader(),
      "Content-Type": `application/json`
    },
    body: JSON.stringify({
      new_mask_id,
      patient_id
    })
  })

  return await response.json()
}

export const replacementMaskCopy = `The mask we have on file for you is currently being recalled due to its magnetic components and how they might interact with any medically implanted devices that patients might have. If you have an implantable medical device (i.e. pacemaker, ICD, metal implant or stents, magnetic dentures, etc), please talk with your doctor or go ahead and select a safe replacement mask below.`
export const replacementMaskCopy2 = `If you do not have any implanted medical devices and would like to keep the mask you currently have, simply check the corresponding checkbox at the bottom of this page to move forward with your order.`