import { getAuthHeader } from 'utils/auth/helpers'
import { addMagentoEnvAuthHeaders } from 'utils/magentoEnvAuthHeaders'

export const fetchResupplyOptions = async ( patientId: string, source: string, signal: AbortSignal ) => {

  const response = await fetch(
    `${process.env.REACT_APP_LAMBDA_RESUPPLY_URL}/options?profit_center_pk=5&patient_id=${patientId}&source=${source}`,
    {
      signal,
      headers: {
        ...addMagentoEnvAuthHeaders(),
        'Authorization': getAuthHeader()
      }
    }
  ).catch( error => {

    return Promise.reject( error )
  })


  if ( response && response.status === 200 ) {
    return await response.json()
  }

  return Promise.reject( response )
}
