import React from 'react'
import Tooltip from 'components/tooltip'
import { icoInfoAob } from 'resources/images'

const ResupplyPriceEstimateDetails = () : JSX.Element => {
  return (
    <div className="py-5 text-left">
      <p className="pb-1"><strong>{`READ ME - Transparency with billing and other important information`}</strong></p>
      <p>{`At Aeroflow, we provide transparent billing information based on your insurance. Below is your personalized and `}<strong>{`estimated`}</strong> {`breakdown of how your insurance pays for your new PAP supplies.`}</p>
      <ol className="py-6 list-decimal text-base font-normal">
        <li>
          <p><u>{`PAP Supplies`}</u>{` - Insurance coverage for PAP supplies varies. Some plans cover the full cost, while others require the patient to pay a portion of the cost (copayments or deductibles). 
              You can see your full `}<strong>{`estimated`}</strong> {`breakdown of your supply order in the “Order Summary Details” section below.`}</p>
        </li>
      </ol>
      <p className="pb-6">{`Certain insurance plans require prior authorization before shipping orders. To always secure approval, consistently use your machine for at least 4 hours each night.`}</p>
      <p>{`IMPORTANT NOTE: `}{`Please be aware that if your deductible resets or insurance coverage changes, you may be susceptible to different copayment or deductible costs in the future.`}</p>
    </div>
  )
}

const ResupplyPaymentDetails = () : JSX.Element => {
  return (
    <>
      <p className="sm:text-sm lg:text-lg text-graphite text-center font-light max-w-2xl">{`Your payment amounts displayed below are the estimated costs for your order based on how your plan traditionally covers supplies.`}</p>
      <div className="sm:text-sm lg:text-lg text-graphite text-center font-light max-w-2xl">
        {`Your item(s) will be shipped upon receipt of payment and any required medical documents as mandated by your health insurance provider. `}
        <span className="inline-block">
          <Tooltip
            tooltipContent={
              <p className="text-graphite md:text-lg">
                {`Although we might have a prescription on file, some plans mandate a new one annually. Additionally, certain insurance plans may necessitate prior authorization before shipping orders. To always secure approval for this, consistently use your machine for at least 4 hours each night.`}
              </p>
            }
          >
            <div className="flex items-center">
              <p className="sm:text-sm lg:text-lg text-graphite text-center font-light max-w-2xl">{`What medical documents are required?`}</p>
              <img
                src={icoInfoAob}
                alt="ico-info"
                className="px-2 hover:opacity-80 cursor-pointer object-contain"
                width={37}
              />
            </div>
          </Tooltip>
        </span>
      </div>
    </>
  )
}

export { ResupplyPriceEstimateDetails, ResupplyPaymentDetails }

