import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { icoX, icoXWhite } from 'resources/images'
import { messages } from './alertMessages'
import ContentSlider from 'components/contentSlider/ContentSlider'
import { useFetchResupplyOptions } from 'modules/resupply2/hooks/resupplyOptions'
import { useNavigate } from 'react-router-dom'


const AlertBar = ({ setDisplay }) => {

  const [ content, setContent ] = useState( [] )

  const navigate = useNavigate()

  const {error: optionsError, loading: resupplyLoading, hasAvailable } = useFetchResupplyOptions( `banner` )

  useEffect( () => {
    const buildContent = messages.data.map( ( message ) => {
      const isLink = `linkTo` in message

      return (
        <div
          key={message}
          className={`bg-${message.color} text-${message.textColor} flex justify-center content-center text-center py-2 w-full`}
        >
          <p
            className="text-xs md:text-sm cursor-pointer"
            onClick={() => { isLink && navigate( message?.linkTo ) }}
          >
            {message.message}
          </p>
          <button type="button" className="px-4" onClick={() => { return setDisplay( false ) }}><img src={message.textColor === `white` ? icoXWhite : icoX} alt="Alert Cancel Button Icon" /></button>
        </div>
      )
    })

    setContent( [ ...buildContent ] )
  }, [] )

  if ( !content?.length || resupplyLoading || optionsError || !hasAvailable ) return null

  return (
    <ContentSlider
      content={content}
      containerStyle="w-full relative"
      aDivStyle="w-full"
    />
  )
}

AlertBar.propTypes = {
  setDisplay: PropTypes.func,
  handleRedirect: PropTypes.func
}

export default AlertBar
