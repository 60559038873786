import PropTypes from 'prop-types'
import Logo from './logos/Logo'
import { useNavigate } from 'react-router-dom'

function AobError({message}) {
  const navigate = useNavigate()

  const searchParams = new URLSearchParams( window.location.search )

  return (
    <div className="w-full mx-auto text-center mt-24 bg-white px-5">
      <div className="absolute z-50 top-3 left-0 right-0 w-full max-w-md px-3 mx-auto">
        <Logo />
      </div>
      <div className="max-w-xl m-auto">
        <p className="text-error">{message}</p>
      </div>
      <div className="mt-5 lg:mt-10 min-w-fit w-1/4 m-auto">
        {
          // want to hide refresh button if use is coming from resupply since refreshing will only clear claimed items and send user back to homepage when aob mounts again
          ( searchParams.get( `sgh` ) || searchParams.get( `txt` ) ) &&
          <button className="btn-secondary" onClick={() => { window.location.reload() }}>{`Refresh`}</button>
        }
        <button className="btn-primary" onClick={() => { navigate( `/logout` ) }}>{`Logout`}</button>
      </div>
    </div>
  )
}

AobError.propTypes = {
  message: PropTypes.string
}

export default AobError