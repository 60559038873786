import React from 'react'

function NoMachineThankYou() {
  return (
    <div className="max-w-5xl p-8 mx-auto flex justify-center flex-col text-center">
      <p className="text-2xl font-semibold mb-10">
        {`Thank you for your interest in Aeroflow Sleep!`}
      </p>
      <p className="my-4 text-lg font-light">{`As a newly diagnosed sleep apnea patient, insurance requires that we collect the following documents from your doctor in order to get you set up on a new PAP machine and supplies:`}</p>
      <ul className="text-graphite text-left list-disc text-lg my-6 mx-auto">
        <li>{`Detailed written order & prescription for a CPAP machine`}</li>
        <li>{`Copy of your sleep study`}</li>
        <li>{`Clinical office visit notes from before your sleep study`}</li>
      </ul>
      <p className="mt-8 text-lg font-light">
        {`Please have your doctor fax these documents to Aeroflow Sleep at fax number `}
        <span className="text-deepSleepBlue underline whitespace-nowrap">{`800-769-8365`}</span>
        {` and then we can move forward with getting your new equipment through insurance. These details will also be sent to your email address.`}</p>
    </div>
  )
}

export default NoMachineThankYou