import { ResupplyOption } from "modules/resupply2/types"

/** returns a new options map with added attributes
* @param {Array<ResupplyOption>} options - Array of Resupply Options to be modified with new attributes
* @param {Boolean} isMedicarePatient - Determines if medicareChecklist should be prefilled out or not
*/
export const serializeOptions = ( options: ResupplyOption[] ) => {
  return options?.map( ( option: ResupplyOption ) => {
    return {
      ...option,
      qty: option.max_qty,
      dmeid: option.hcpc_id,
      medicareChecklist: {
        hcpc_id: option.hcpc_id,
        reason: {
          qualityQuestionResponse: true,
          quantityQuestionResponse: true
        }
      },
      thruInsurance: true
    }
  })
}