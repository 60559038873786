import React from 'react'
import LinkTab from './LinkTab'
import { myAccountTab, blogTab, faqTab } from 'resources/images'
import { MY_ACCOUNT_PATH } from 'routes'


function LinkTabSection() {
  return (
    <div className="flex flex-col gap-2">
      <LinkTab
        src={myAccountTab}
        alt="my_account_tab"
        text="My Account"
        href={MY_ACCOUNT_PATH}
      />
      <LinkTab
        src={blogTab}
        alt="blog_tab"
        text="Blog"
        href={`https://shop.aeroflowsleep.com/blog`}
      />
      <LinkTab
        src={faqTab}
        alt="faq_tab"
        text="FAQs"
        href={`${process.env.REACT_APP_MAGENTO_2_BASE_URL}/frequently-asked-questions`}
      />
    </div>
  )
}

export default LinkTabSection