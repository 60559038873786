import { makeVar, useReactiveVar } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'
import { Validators } from 'components/forms/components'
import { IDateFields } from './types'

export const emailVar = makeVar<string>( `` )
export const phoneVar = makeVar<string>( `` )
const dateFieldsVar = makeVar<IDateFields>({
  dobDay: ``,
  dobMonth: ``,
  dobYear: ``
})

export default function useInputState() : { email: string, phone: string, dateFields: IDateFields } {
  return {
    email: useReactiveVar( emailVar ),
    phone: useReactiveVar( phoneVar ),
    dateFields: useReactiveVar( dateFieldsVar )
  }
}

export function useEmailInputState() : [string, ( ( _input : string ) => void )] {
  const email = useReactiveVar( emailVar )
  const setEmail = useCallback( ( input : string ) => { emailVar( input ) }, [] )

  return [ email, setEmail ]
}

export function usePhoneInputState() : [string, ( ( _input : string ) => void )] {
  const phone = useReactiveVar( phoneVar )
  const setPhone = useCallback( ( input : string ) => { phoneVar( input ) }, [] )

  return [ phone, setPhone ]
}

export function useDateInputState() : [IDateFields, ( _input: IDateFields ) => void] {
  const dateFields = useReactiveVar( dateFieldsVar )
  const setDateFields = useCallback( ( input: IDateFields ) => { dateFieldsVar( input ) }, [] )

  return [
    dateFields,
    setDateFields
  ]
}

export function useValidInputs() : { isValidEmail: boolean, isValidPhone: boolean, isValidDate: boolean } {
  const email = useReactiveVar( emailVar )
  const phone = useReactiveVar( phoneVar )
  const dateFields = useReactiveVar( dateFieldsVar )
  const [ isValidEmail, setIsValidEmail ] = useState<boolean>( false )
  const [ isValidPhone, setIsValidPhone ] = useState<boolean>( false )
  const [ isValidDate, setIsValidDate ] = useState<boolean>( false )
  useEffect( () => {
    setIsValidPhone( Validators.phone( phone ) )
    setIsValidEmail( Validators.email( email ) )
    const date = `${dateFields.dobYear}-${dateFields.dobMonth}-${dateFields.dobDay}`
    const isValidDate = (
      date.length === 10 &&
      Validators.dobYear( dateFields.dobYear ) &&
      Validators.dobMonth( dateFields.dobMonth ) &&
      Validators.dd( dateFields.dobDay )
    )
    setIsValidDate( isValidDate )
  }, [ email, phone, dateFields ] )

  return {
    isValidEmail,
    isValidPhone,
    isValidDate
  }
}
