import React, { useEffect, useState } from 'react'
import { useDoctorStore } from '../hooks/state'
import DoctorInfo from '../types/DoctorInfo'
import CurrentDoctor from './CurrentDoctor'
import DoctorSearch from './DoctorSearch'
import { Modal } from 'components/modal'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import { useDoctorDisplayModalStore } from '../../../stores/modalStore'

type DoctorDisplayProps = {
  onSuccess?: ( _doctor: DoctorInfo ) => void;
  forceUpdate: boolean;
  headerStyle?: string;
  containerStyle?: string;
  isMyAccount?: boolean;
  doctorSearchStyle?: string;
  scrollIntoView: boolean;
  doctorSearchHeader?: string;
}

function DoctorDisplay({ isMyAccount = false, onSuccess, forceUpdate, headerStyle, containerStyle, doctorSearchStyle, scrollIntoView, doctorSearchHeader } : DoctorDisplayProps ) : JSX.Element {
  const { doctor, showDoctorDisplayModal, setShowDoctorDisplayModal } = useDoctorStore()
  const [ searchActive, setSearchActive ] = useState<boolean>( !doctor || ( !doctor.firstName && !doctor.lastName ) || !doctor.phone || forceUpdate )
  const { isRemoteModalOpen, setIsRemoteModalOpen } = useDoctorDisplayModalStore()

  const modalBodyText = `We were unable to verify your doctor’s information. Please try re-entering your doctor’s information or contact customer service at ${AF_SUPPORT_PHONE_NUMBER} if this issue persists.`

  const doctorSearchProps = {
    headerStyle,
    containerStyle,
    setSearchActive,
    onSuccess,
    forceUpdate,
    isMyAccount,
    doctorSearchStyle,
    scrollIntoView,
    doctorSearchHeader
  }

  useEffect( () => {
    setSearchActive( ( !doctor?.firstName && !doctor?.lastName ) || !doctor?.phone )
  }, [ doctor ] )

  return (
    <>
      {!searchActive ? (
        <>
          <CurrentDoctor
            setSearchActive={setSearchActive}
            headerStyle={headerStyle}
            containerStyle={containerStyle}
            isMyAccount={isMyAccount}
          />

          {showDoctorDisplayModal && (
            <Modal isOpen onClose={() => { setShowDoctorDisplayModal( !showDoctorDisplayModal ) }}>
              <p className="pb-4">{modalBodyText}</p>
            </Modal>
          )}
        </>
      ) : (
        <DoctorSearch {...doctorSearchProps} />
      )}

      {isRemoteModalOpen && (
        <Modal isOpen={isRemoteModalOpen}>
          <DoctorSearch
            {...doctorSearchProps}
            onHandleBack={() => { setIsRemoteModalOpen( false ) }}
          />
        </Modal>
      )}
    </>
  )
}

export default DoctorDisplay