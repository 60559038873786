import React from 'react'
import PropTypes from 'prop-types'
import CMSImage from './CMSImage'
import CMSText from './CMSText'
import CMSVideo from './CMSVideo'
import DefaultCMS from './DefaultCMS'
import { useQuery, useReactiveVar } from '@apollo/client'
import { homescreenCMSContent, displayIdentifierTooltips } from 'apollo'
import { homeCMSIdentifiers } from './constants'
import { cmsBlocksQuery } from 'graphql/queries/cmsBlocks'
import IdentifierTooltip from './IdentifierTooltip'
import { reportToSentry } from 'utils/reportToSentry'
import LoadingCMS from './LoadingCMS'

const CMSBlock = ( props ) => {

  const { identifier, type } = props
  const [ displayTooltip, setDisplayTooltip ] = React.useState( false )
  const blockContent = useReactiveVar( homescreenCMSContent )
  const displayTooltips = useReactiveVar( displayIdentifierTooltips )
  // Do not query for content if the block content variable has been set already
  const skipQuery = Object.keys( blockContent ).length !== 0

  const { loading } = useQuery( cmsBlocksQuery, {
    errorPolicy: `ignore`,
    skip: skipQuery,
    variables: {
      identifiers: homeCMSIdentifiers
    },
    onCompleted: ( data ) => {
      const newBlocks = {}
      data?.cmsBlocks?.items?.forEach( item => {
        if ( item?.content && item?.identifier ) {
          newBlocks[item?.identifier] = item?.content
        }
      })
      homescreenCMSContent( newBlocks )
    },
    onError: ( error ) => {
      reportToSentry( new Error( `There was an error retrieving CMS content`, {
        error
      }) )
    }
  })

  if ( loading ) return <div className="w-3/4 min-w-50"><LoadingCMS type={type} /></div>

  if ( !( identifier in blockContent ) || !blockContent[identifier] ){
    // If there is an error retreiving the CMS return the default value
    return <DefaultCMS {...props} />
  }

  return (
    <>
      {
        displayTooltips &&
        <IdentifierTooltip
          identifier={identifier}
          display={displayTooltip && process.env.REACT_APP_ENVIRONMENT !== `production`}
        />
      }
      {
        type === `text` ?
          <CMSText
            {...props}
            textItem={blockContent[identifier]}
            setDisplayTooltip={setDisplayTooltip}
            displayTooltips={displayTooltips}
          />
          :
          type === `image` ?
            <CMSImage
              {...props}
              srcItem={blockContent[identifier]}
              setDisplayTooltip={setDisplayTooltip}
              displayTooltips={displayTooltips}
            />
            :
            <CMSVideo
              {...props}
              srcItem={blockContent[identifier]}
              setDisplayTooltip={setDisplayTooltip}
              displayTooltips={displayTooltips}
            />
      }
    </>
  )
}

CMSBlock.propTypes = {
  identifier: PropTypes.string,
  customTag: PropTypes.string,
  styling: PropTypes.string,
  failSafe: PropTypes.oneOfType( [
    PropTypes.string,
    PropTypes.object
  ] ),
  type: PropTypes.string,
  customProps: PropTypes.object,
  containerStyling: PropTypes.string
}

export default CMSBlock