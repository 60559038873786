import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Item from './Item'
import EditOrderButtonBlock from './EditOrderButtonBlock'
import { postEstimate, getEstimate } from 'modules/aobPayments/fetchPromises'
import { reportToSentry } from 'utils/reportToSentry'
import AnimatedHeightDiv from 'components/animatedHeightDiv/AnimatedHeightDiv'

function OrderItemsDetails({
  invoice,
  displayOrderItems,
  setEditOrderError,
  setInvoice,
  setRemovedItems,
  removedItems,
  blockProceed,
  setBlockProceed
}) {

  const [ editMode, setEditMode ] = useState( false )
  const [ editMapping, setEditMapping ] = useState( null )
  const [ loading, setLoading ] = useState( false )

  useEffect( () => {
    if ( invoice?.details?.length ) {
      const initialEditMapping = {}
      invoice.details.forEach( ( item ) => {
        initialEditMapping[item.dme_id] = {
          ...item,
          include: !removedItems.find( removedDmeId => { return item.dme_id === removedDmeId })
        }
      })
      setEditMapping( Object.assign({}, initialEditMapping ) )
    }
  }, [] )

  const handleSuccessfulOrderEdit = ( itemsToRemove, {
    deductible,
    deductible_remaining,
    monthly_max,
    total_charge,
    coinsurance_percentage,
    number_of_months,
    est_monthly_due,
    total_to_collect
  }) => {
    // update invoice to use pricing from estimate
    setInvoice( Object.assign({}, invoice, {
      deductible,
      deductible_remaining,
      coinsurance_percentage,
      monthly_max,
      number_of_months,
      est_monthly_due,
      total_ins_payment: total_charge - total_to_collect,
      total_to_collect,
      total_patient_owes: total_to_collect
    }) )

    // after order was estimated successfully update remove items array for invoice update after charge
    if ( itemsToRemove.length ) setRemovedItems( [ ...itemsToRemove ] )

    setEditMode( false )
    setBlockProceed( false )
  }

  const handleGetEstimate = async ( itemsToRemove, patientHash, batchId ) => {
    const defaultEditOrderError = `Oops... We are having trouble getting your estimate please try again or reach out to customer service if the issue persists.`
    const getEstimateResponse = await getEstimate( batchId )
      .catch( error => {
        setBlockProceed( false )
        setEditOrderError( defaultEditOrderError )
        reportToSentry( `AOB Estimate Error: Exception getting estimate...`, {
          error,
          patientHash,
          invoice: invoice.invoice_number
        })
      })

    setLoading( false )

    if ( getEstimateResponse?.meta?.status !== `OK` || !getEstimateResponse?.data?.length ) return setEditOrderError( defaultEditOrderError )

    return handleSuccessfulOrderEdit( itemsToRemove, getEstimateResponse.data[0] )

  }

  const handlePostEstimate = async () => {
    const defaultEditOrderError = `Oops... We are having trouble getting your estimate please try again or reach out to customer service if the issue persists.`
    setEditOrderError( null )
    const urlParams = new URLSearchParams( window.location.search )
    const patientHash = urlParams.get( `sgh` ) ?? urlParams.get( `txt` )
    setLoading( true )
    const itemsToRemove = []
    const includedItems = Object.keys( editMapping ).filter( dme_id => {
      if ( editMapping[dme_id].include ) return true
      itemsToRemove.push( parseInt( dme_id ) )

      return false
    })
      .map( dme_id => {
        return {
          dme_id: dme_id,
          qty: editMapping[dme_id].quantity
        }
      })
    const postEstimateResponse = await postEstimate({
      patient_hash: patientHash,
      items: includedItems
    })
      .catch( error => {
        setLoading( false )
        setEditOrderError( defaultEditOrderError )
        reportToSentry( `AOB Estimate Error: Exception posting estimate...`, {
          error,
          patientHash,
          invoice: invoice.invoice_number
        })
      })
    if ( postEstimateResponse?.meta?.status !== `OK` || !postEstimateResponse?.data?.batch_id ) {
      setLoading( false )

      return setEditOrderError( defaultEditOrderError )
    }

    return await handleGetEstimate( itemsToRemove, patientHash, postEstimateResponse.data.batch_id )
  }

  const handleCheck = ( dmeId ) => {
    const newEditMapping = editMapping
    newEditMapping[ dmeId ].include = !newEditMapping[ dmeId ].include
    setEditMapping( Object.assign({}, newEditMapping ) )

    for ( const key in newEditMapping ) {
      if ( newEditMapping[key].include && removedItems.find( removedDmeId => { return removedDmeId === parseInt( key ) }) ) return setBlockProceed( true )
      if ( !newEditMapping[key].include && !removedItems.find( removedDmeId => { return removedDmeId === parseInt( key ) }) ) return setBlockProceed( true )
    }

    return setBlockProceed( false )
  }

  const handleCancel = () => {
    setEditMode( false )
    setBlockProceed( false )
    const resetEditMapping = editMapping

    Object.keys( resetEditMapping ).forEach( dmeId => {
      resetEditMapping[dmeId].include = !removedItems.find( removedDmeId => { return removedDmeId === parseInt( dmeId ) })
    })

    setEditMapping( Object.assign({}, resetEditMapping ) )
  }

  return (
    <AnimatedHeightDiv
      display={displayOrderItems}
      heightDependencies={[ displayOrderItems, editMode, blockProceed, editMapping ]}
    >
      <div className="flex flex-col justify-center items-center mt-2 pb-10 mb-8 min-w-fit">
        <p className="md:text-lg text-center">{`Supply items available now`}</p>
        <div className="flex flex-col w-10/12 md:w-8/12 justify-center items-left my-6">
          {
            editMapping &&
            Object.keys( editMapping ).map( ( dmeId ) => {
              return (
                <Item
                  key={dmeId}
                  detail={editMapping[dmeId]}
                  loading={loading}
                  editMode={editMode}
                  handleCheck={handleCheck}
                />
              )
            })
          }
        </div>
        {
          invoice?.allow_invoice_edit_flag &&
            <EditOrderButtonBlock
              editMode={editMode}
              setEditMode={setEditMode}
              handleEditOrderSubmit={handlePostEstimate}
              loading={loading}
              userHasEdited={blockProceed}
              removedItems={removedItems}
              handleCancel={handleCancel}
            />
        }
      </div>
    </AnimatedHeightDiv>
  )
}

OrderItemsDetails.propTypes = {
  invoice: PropTypes.object,
  displayOrderItems: PropTypes.bool,
  patientId: PropTypes.string,
  setInvoice: PropTypes.func,
  setEditOrderError: PropTypes.func,
  setRemovedItems: PropTypes.func,
  removedItems: PropTypes.array,
  blockProceed: PropTypes.bool,
  setBlockProceed: PropTypes.func
}


export default OrderItemsDetails