import { TextInput, Formatters } from 'components/forms/components'
import { usePhoneInputState, useValidInputs } from './useInputState'
import { regexErrors } from 'components/forms/constants'
import { useState, useEffect, useRef } from 'react'

interface PhoneInputProps {
  required: boolean | undefined;
  disabled?: boolean;
  textCurtain?: string
}

export default function PhoneHOC({ required = false, disabled = false, textCurtain } : PhoneInputProps ) : JSX.Element {

  const [ phone, setPhone ] = usePhoneInputState()
  const { isValidPhone } = useValidInputs()
  const [ hasFocus, setHasFocus ] = useState<boolean>( false )
  const [ touched, setTouched ] = useState<boolean>( false )
  const [ readOnly, setReadOnly ] = useState<boolean>( Boolean( phone?.length ) )
  const inputRef = useRef<HTMLInputElement>()
  const firstCall = useRef<boolean>( true )

  useEffect( () => {
    // If the parent opens the textCurtain, we want to reset and add focus to the input
    if ( !textCurtain && !readOnly && !firstCall.current ) {
      setTouched( false )
      setPhone( `` )
      inputRef?.current?.focus()
    }
    firstCall.current = false
  }, [ textCurtain ] )

  function handleFocus() {
    setHasFocus( !hasFocus )
  }

  if ( textCurtain ) { // Hides the input behind an underline-text
    return (
      <p className="underline cursor-pointer text-deepSleepBlue">
        {textCurtain}
      </p>
    )
  }

  if ( readOnly && Boolean( phone?.length ) && !touched ) {
    return (
      <>
        <p className="p font-bold ml-1 mt-1">{`Phone Number`}</p>
        <p className="ml-4 mt-2 mb-4">{phone} <span className="cursor-pointer text-deepSleepBlue font-semibold ml-2" onClick={() => { setReadOnly( false ) }}>{`change`}</span></p>
      </>
    )
  }

  return (
    <TextInput
      id="phone"
      label="Phone Number"
      name="phone"
      value={phone}
      onChange={( e: React.ChangeEvent<HTMLInputElement> ) => { setTouched( true ); setPhone( e.target.value ) }}
      autoComplete="tel"
      className="px-3 mt-2 mb-5 input"
      formatter={{
        function: Formatters.phone
      }}
      disabled={disabled}
      errorMessage={( !hasFocus && !isValidPhone && touched ) ? regexErrors.phone : ``}
      onBlur={handleFocus}
      onFocus={handleFocus}
      required={required}
      reference={inputRef} validator={undefined}
    />
  )
}