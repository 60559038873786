import React from 'react'
import StaticStepIndicator from './InsuranceStepsDisplay'
import { Link } from 'react-router-dom'
import { LOGIN_PATH } from 'routes'
import { useQualifyFormStore } from 'modules/qualifyForm/state'

interface QualifyFormCallToActionProps {
  header?: string;
  orderStatusText?: string;
  orderStatusLinkText?: string;
  signInText?: string;
  useDefaultCopy?: boolean;
  registeredEmailUrlParam?: string;
  isSiteOffline?: boolean;
  isEmbedded?: boolean;
}

// falback to the default copy for embedded QF
const DEFAULT_COPY = {
  header: `Qualify Through Insurance In 3 Easy Steps!`,
  orderStatusText: `If you've already been referred by your doctor, you're all set! `,
  orderStatusLinkText: `Click here to see your order status`,
  signInText: `Already have an account? Sign in`
}

const QualifyFormCallToAction = ( props: QualifyFormCallToActionProps ) => {
  const { submitStepActive, emailNotAvailable } = useQualifyFormStore()

  // default to base path if no param is passed
  const buildPath = ( base: string, param?: string ) => param ? `${base}${param}` : base
  const loginPath = buildPath( LOGIN_PATH, props.registeredEmailUrlParam )

  /* hide sign in when:
    ** - not in the submit step
    ** - email is not available
    ** - in maint. mode
  */
  const showSignIn = !submitStepActive && !emailNotAvailable && ( props.isEmbedded || !props.isSiteOffline )

  const RenderLink = ({ children, className, link }: { children: React.ReactNode, className?: string, link?: string }) => (
    <Link
      to={link || loginPath}
      target={props.isEmbedded ? `_blank` : `_self`}
      className={`text-deepSleepBlue sm:text-base md:text-xl ${className}`}
    >
      {children}
    </Link>
  )

  return (
    <div className="text-center sm:px-6 md:px-10 sm:pb-0 md:pb-10 sm:pt-2 md:pt-5">
      <h1 className=" text-graphite text-center sm:text-[27px] md:text-[64px] sm:leading-normal md:leading-[75px] font-source-serif-4 max-w-screen-md mx-auto">
        {props.header || DEFAULT_COPY.header}
      </h1>
      <StaticStepIndicator />
      { showSignIn &&
      <>
        <p className="sm:text-base md:text-xl sm:pb-6 md:pb-[18px]">
          {props.orderStatusText || DEFAULT_COPY.orderStatusText}
          <RenderLink className="text-deepSleepBlue sm:text-base md:text-xl" link={LOGIN_PATH}>
            {props.orderStatusLinkText || DEFAULT_COPY.orderStatusLinkText}
          </RenderLink>
        </p>
        <RenderLink className="text-deepSleepBlue sm:text-base md:text-xl block">
          {props.signInText || DEFAULT_COPY.signInText}
        </RenderLink>
      </>
      }
    </div>
  )
}

export default QualifyFormCallToAction