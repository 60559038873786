import { usaShippingRegions } from 'components/forms/constants'

/** Takes payers and a payerNumber, and reduces the returned object into primary, secondary or tertiary insurance
  * @param payerNumber interger representing primary, secondary, or tertiary insurance
  */
export const reducePayerObject = ( payers, payerNumber ) => {
  return Object.keys( payers ).reduce( ( finalPayerObject, payerKey ) => {
    if ( payerKey.includes( `payer${payerNumber}_` ) ) {
      const newKey = payerKey.split( `payer${payerNumber}_` ).pop()
      let newValue = payers[payerKey]

      if ( newKey === `name` && ( !newValue || newValue === `NULL` ) ) newValue = ``

      return {
        ...finalPayerObject,
        [newKey]: newValue
      }
    }

    return finalPayerObject
  }, {})
}

export const clearedPayer = {
  group_number: null,
  hic: ``,
  id: null,
  name: ``,
  parent_payer_name: null,
  parent_payer_pk: null
}

export function getStateByRegionId( regionID ){

  const { code} = usaShippingRegions.find( region => { return region.id === regionID })

  return code
}