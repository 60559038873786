import React from 'react'
import { ladyStretchingImg } from 'resources/images'
import {
  AsSeenInBlock,
  BlockOne,
  FaqBlogBlock,
  HeroBlock,
  MostPopularBlock,
  SleepProductsThroughInsuranceBlock,
  ValuePropositionBlock,
  VideoBlock
} from 'modules/home/components'

const LegacyHomepage = () => {
  return (
    <>
      <div className="pb-20">
        <HeroBlock />
        <ValuePropositionBlock />
        <div className="xl:hidden lg:hidden md:hidden sm:block bg-hero-image-small">
          <img
            src={ladyStretchingImg}
            width="700"
            height="802"
            alt="Lady Stretching"
            className="object-contain"
          />
        </div>
        <div className="flex flex-col">
          <div className="mb-20">
            <BlockOne />
          </div>
          <div className="mb-12">
            <SleepProductsThroughInsuranceBlock />
          </div>
          <div className="mb-12">
            <AsSeenInBlock />
          </div>
          <div className="mb-12">
            <MostPopularBlock />
          </div>
          <div className="mb-12">
            <VideoBlock />
          </div>
          <div className="mb-12">
            <FaqBlogBlock />
          </div>
        </div>
      </div>
    </>
  )
}

export default LegacyHomepage