import React from 'react'
import PropTypes from 'prop-types'

// the default behaviour of this class is to show rounded borders at md width +
// on mobile top and bottom borders are preserved, right + left borders removed and no rounding for full screen box
// noBorderOnMobile overrides the behavior and removes all borders if screen width < md
export const ResponsiveSectionBox = ({ children, customClassNames, noBorderOnMobile }) => {
  return (
    <div
      className={`border border-graphite md:rounded sm:border-r-0 sm:border-l-0 md:border-r md:border-l ${noBorderOnMobile ? `sm:border-t-0 sm:border-b-0 md:border-t md:border-b`: ``} p-4 ${customClassNames}`}
    >
      {children}
    </div>
  )
}

ResponsiveSectionBox.propTypes = {
  children: PropTypes.node,
  customClassNames: PropTypes.string,
  noBorderOnMobile: PropTypes.bool
}

export default ResponsiveSectionBox
