import React, { useState } from 'react'
import IdentifierTooltip from 'components/cmsBlock/IdentifierTooltip'
import { useReactiveVar } from '@apollo/client'
import { displayIdentifierTooltips } from 'apollo'
import PropTypes from 'prop-types'

function CMSWidgetTooltipWrapper({className, identifier, children}) {
  const [ pointerOver, setPointerOver ] = useState( false )

  const displayTooltips = useReactiveVar( displayIdentifierTooltips )

  const copyToClipboard = () => {
    return navigator.clipboard.writeText( identifier )
  }

  const activateTooltip = displayTooltips && process.env.REACT_APP_ENVIRONMENT !== `production`

  return (
    <div
      className={`${className} ${activateTooltip && `cursor-pointer` }`}
      {...( activateTooltip ? {
        onMouseEnter: () => { return setPointerOver( true ) },
        onMouseLeave: () => { return setPointerOver( false ) },
        onClick: copyToClipboard
      }: {})}
    >
      {
        activateTooltip &&
        <IdentifierTooltip
          identifier={identifier}
          display={pointerOver}
        />
      }
      {
        React.cloneElement( children ?? <></> )
      }
    </div>
  )
}

CMSWidgetTooltipWrapper.propTypes = {
  className: PropTypes.string,
  identifier: PropTypes.string,
  children: PropTypes.oneOfType( [
    PropTypes.arrayOf( PropTypes.node ),
    PropTypes.node
  ] )
}

export default CMSWidgetTooltipWrapper