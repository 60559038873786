
import { Link } from 'react-router-dom'

function LinkTab({src, alt, text, href} : LinkTabProps ) {

  return (
    <div className="relative transform hover:scale-105 ease-in-out duration-150">
      <Link to={href}>
        <img
          src={src}
          alt={alt}
          className="cursor-pointer"
        />
        <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-lg md:text-2xl underline font-light pointer-events-none">
          {text}
        </p>
      </Link>
    </div>
  )
}

export type LinkTabProps = {
  src: string;
  alt: string;
  text: string;
  href: string;
}

export default LinkTab