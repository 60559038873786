import React from 'react'
import { useQuery } from '@apollo/client'
import { cmsBlocksQuery } from 'graphql/queries/cmsBlocks'
import ReactHtmlParser from 'react-html-parser'
import ValuePropositionBackup from './ValuePropsositionBackup'
import ValuePropositionLoader from './ValuePropositionLoader'
import CMSWidgetTooltipWrapper from 'components/cmsWidgetTooltipWrapper'

const ValueProposition = () => {
  const { data, loading, error } = useQuery( cmsBlocksQuery, {
    errorPolicy: `all`,
    variables: {
      identifiers: `react-value-proposition-widget`
    }
  })

  const transformWidget = ( node ) => {

    if ( node.type === `tag` && node?.attribs[`data-element`] === `mobile_image` ) return null
    if ( node.type === `tag` && node?.name === `img` ) {
      // change media folder from sleep to breastpumps ( should default to breastpumps but not the case in prod )
      node.attribs.src = node.attribs.src.replace( process.env.REACT_APP_MAGENTO_2_BASE_URL, process.env.REACT_APP_CMS_IMAGE_URL )
      node.attribs.loading = `lazy`
      node.attribs.width = `150`
      node.attribs.height = `150`
    }
    if ( node.type === `tag` && node?.attribs?.class === `pagebuilder-column-group` ) {
      node.attribs.style = ``
      node.attribs.class = `grid justify-center grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7 pb-12`
    }
  }

  if ( loading ) return <ValuePropositionLoader />

  if ( data?.cmsBlocks?.items?.length && data?.cmsBlocks?.items[0]?.content && !error ) {
    return (
      <CMSWidgetTooltipWrapper
        className="text-center mt-12 md:mb-24 lg:w-10/12 lg:my-0 lg:mx-auto lg:mb-24 xl:w-8/12"
        identifier={`react-value-proposition-widget`}
      >
        <>
          {
            ReactHtmlParser( data.cmsBlocks.items[0].content, {
              transform: transformWidget
            })
          }
        </>
      </CMSWidgetTooltipWrapper>
    )
  }

  return <ValuePropositionBackup />
}

export default ValueProposition
