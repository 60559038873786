import { getToken } from 'stores/tokenStore'
import { getSnlPatientHash } from 'stores/snlPatientHashStore'

export function getHashFromUrl() : string {
  const urlParams = new URLSearchParams( window.location.search )
  const patientHash = urlParams.get( `sgh` ) ?? urlParams.get( `txt` ) ?? ``

  return patientHash
}

export function getAuthHeader() : string {
  const hash = getHashFromUrl()
  const token = getToken()
  const snlPatientHash = getSnlPatientHash()

  if ( !hash?.length && !token && !snlPatientHash ) return ``

  return hash ? `PatientHash ${hash}` : ( snlPatientHash ? `PatientHash ${snlPatientHash}` : `Bearer ${token}` )

}