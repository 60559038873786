import { thumbsUpList, thumbsUpListLightBlue, decStarCheck, decStarCheckLightBlue, minimalistBox, minimalistBoxLightBlue } from "resources/images"

export const ThankYouDetailsFromAOB = [
  {
    "title": `Verifying Benefits`,
    "description": `An Aeroflow Sleep Specialist will work to verify your benefits and check which supplies you are eligible to receive through insurance.`,
    "imageAlt": `Blue Verify Check Mark`,
    "imageSrc": decStarCheck
  },
  {
    "title": `Documentation Requirements`,
    "description": `Health insurance companies require that Aeroflow Sleep obtain a prescription for your CPAP Supplies prior to shipping the order. We’ll be working with you and your doctor to obtain any required documentation.`,
    "imageAlt": `List with thumbs up`,
    "imageSrc": thumbsUpList
  },
  {
    "title": `Order Confirmed and Shipped`,
    "description": `Once all necessary documentation is received, an Aeroflow Sleep Specialist will confirm your final order with you and ship your new CPAP Supplies.`,
    "imageAlt": `Minimalist box`,
    "imageSrc": minimalistBox
  }
]

export const ThankYouDetailsFromSNL = [
  {
    "title": `Aeroflow Will Verify Your Benefits`,
    "imageAlt": `Blue Verify Check Mark`,
    "imageSrc": decStarCheckLightBlue
  },
  {
    "title": `Aeroflow Will Gather All Necessary Documentation`,
    "description": `Insurance requires that we obtain an Rx for your order prior to shipping.`,
    "additionalTitle": `If your CPAP prescription is expired,`,
    "additionalDescription": `You can get a new one through [HealthTap](https://www.healthtap.com/). 
    They link you with licensed physicians via telehealth, guaranteeing insurance-compliant prescriptions for your CPAP Supplies.`,
    "imageAlt": `List with thumbs up`,
    "imageSrc": thumbsUpListLightBlue,
    "button": {
      "text": `Get A New Prescription`,
      "link": `https://www.healthtap.com/?utm_source=aeroflow&utm_medium=referral`,
      "externalLink": true
    }
  },
  {
    "title": `Aeroflow will Confirm your Order and Ship!`,
    "imageAlt": `Minimalist box`,
    "imageSrc": minimalistBoxLightBlue
  }
]
