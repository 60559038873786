export const convertTags = ( htmlString ) => {
  return htmlString
    .replace( /&gt;/g, `>` )
    .replace( /&lt;/g, `<` )
    .replace( /&amp;/g, `&` )
}

export const formatTailwindClass = ( node ) => {
  if ( node?.attribs?.class ) {
    if ( node.attribs.class.includes( `lg-` ) ) node.attribs.class = node.attribs.class.replaceAll( `lg-`, `lg:` )
    if ( node.attribs.class.includes( `md-` ) ) node.attribs.class = node.attribs.class.replaceAll( `md-`, `md:` )
    if ( node.attribs.class.includes( `sm-` ) ) node.attribs.class = node.attribs.class.replaceAll( `sm-`, `sm:` )
  }
}
