import { Navigate } from 'react-router-dom'

import { AuthPageWrapper, PasswordResetForm } from 'components/auth'

const PasswordResetScreen = () => {

  const resetPasswordToken = new URLSearchParams( window.location.search ).get( `token` )

  if ( !resetPasswordToken ) return <Navigate to="/request-password-reset" />

  return (
    <AuthPageWrapper>
      <div className="mt-8 md:mx-16">
        <h3 className="sm:text-2xl md:text-4xl font-bold">{`Reset Your Password`}</h3>
      </div>

      <div className="my-8">
        <p className="text-xs font-light">{`Let's reset your password. Please enter your email and a new password.`}</p>
        <PasswordResetForm resetPasswordToken={resetPasswordToken} />
      </div>
    </AuthPageWrapper>
  )
}

export default PasswordResetScreen
