import React from 'react'
import { icoMastercard, icoPaypal, icoDiscover, icoFsa, icoVisa, icoInc, icoAuthNet, icoAmex, icoBBB, icoAffirm, icoGreat } from 'resources/images'

export const PaymentOptionsImageGrid = () => {
  return (
    <>
      <div className="pt-0 pb-[11px] sm:pl-12 lg:pl-0 m-0">
        <div className="flex flex-row items-center justify-center max-w-60">
          <div className="py-2 px-2">
            <img
              src={icoVisa}
              alt="Visa Icon"
              width="53"
              height="30"
            />
          </div>
          <div className="py-2 px-2">
            <img
              src={icoMastercard}
              width="52"
              height="30"
              alt="MasterCard Icon"
            />
          </div>
          <div className="py-2 px-2">
            <img
              src={icoAmex}
              alt="American Express Icon"
              width="52"
              height="30"
            />
          </div>
          <div className="py-2 px-2">
            <img
              src={icoDiscover}
              alt="Discover Icon"
              width="52"
              height="30"
            />
          </div>
        </div>
        <div className="flex flex-row items-center justify-center max-w-60">
          <div className="py-2 px-2">
            <img
              src={icoAffirm}
              alt="Affirm Icon"
              width="50"
              height="15"
            />
          </div>
          <div className="py-2 px-2">
            <img
              src={icoPaypal}
              alt="Paypal Icon"
              width="16"
              height="19"
            />
          </div>
          <div className="py-2 px-2">
            <img
              src={icoFsa}
              alt="FSA / HSA Icon"
              width="52"
              height="15"
            />
          </div>
        </div>
        <div className="flex flex-row items-center justify-center sm:border-0 md:border-t-2 max-w-60">
          <div className="py-2 px-2">
            <img
              src={icoBBB}
              alt="BBB Icon"
              width="31"
              height="30"
            />
          </div>
          <div className="py-2 px-2">
            <img
              src={icoInc}
              alt="Inc. Icon"
              width="39"
              height="39"
            />
          </div>
          <div className="py-2 px-2">
            <img
              src={icoAuthNet}
              alt="Authorize.net Icon"
              width="52"
              height="40"
            />
          </div>
          <div className="py-2 px-2">
            <img
              src={icoGreat}
              alt="Great Place to Work Icon"
              width="24"
              height="39"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentOptionsImageGrid
