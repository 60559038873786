import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { reportToSentry } from 'utils/reportToSentry'

export const NotFound = () => {
  useEffect( () => {
    const { location: { pathname, search } } = window
    if ( pathname.includes( `promo` ) ) {
      reportToSentry( new Error( `Marketing Page 404: A patient attempted to navigate to a promotional page that doesn't exist` ), {
        search,
        pathname
      })
    }
  }, [] )

  return (
    <div className="bg-hero-image bg-cover bg-no-repeat bg-center bg-fixed min-h-screen text-graphite" >
      <div className="md:p-40 sm:p-8">
        <div className="flex font-semibold flex-wrap md:m-0 sm:m-auto w-full sm:w-8/12 md:text-7xl sm:text-4xl md:justify-start sm:justify-center py-8">
          <div className="mr-2">{`404.`}</div>
          <div>{`Page not found.`}</div>
        </div>
        <div className="md:text-3xl sm:text-lg md:text-left sm:text-center font-light leading-relaxed">
          <p>{`Unfortunately, this page does not exist.`}</p>
          <p>{`Please check your URL or return to the `} <Link to="/" className="text-deepSleepBlue cursor-pointer underline">{`Home Page`}</Link>{`.`}</p>
        </div>
      </div>
    </div>
  )
}

export default NotFound
