import React, { useState } from 'react'
import { TextInput } from 'components/forms/components'
import { Validators } from 'components/forms/components/utils'
import { regexErrors } from 'components/forms/constants'
import { Modal } from 'components/modal'
import { reportToSentry } from 'utils/reportToSentry'
import { newsLetterSubmit } from '../utils/utils'

type NewsLetterSignupProps = {
  newsLetterSignupHeader: string | null;
  newsLetterSignupText: string | null;
  textClassName?: string;
}

export const NewsLetterSignup = ( props: NewsLetterSignupProps ) : JSX.Element => {
  const [ formState, setFormState ] = useState({
    email: ``,
    modalContent: ``,
    disableSignup: false,
    displayError: false
  })

  const handleBlur = () => {
    setFormState( prevState => ({
      ...prevState,
      displayError: !Validators.email( formState.email )
    }) )
  }

  const handleChange = ( event: { target: { name: string; value: string | boolean } }) => {
    setFormState( prevState => ({
      ...prevState,
      email: event.target.value.toString(),
      displayError: false
    }) )
  }

  const modalContent = {
    success: `Thank you for signing up!`,
    error: `There was an error signing up. Please check your email address and try again.`
  }

  const handleSubmit = ( event: React.FormEvent<HTMLFormElement> ) => {
    event.preventDefault()

    if ( Validators.email( formState.email ) ) {
      newsLetterSubmit( formState.email )
        .then( res => {

          if ( res?.meta?.status === `OK` ) {
            setFormState( prevState => ({
              ...prevState,
              modalContent: modalContent.success,
              disableSignup: true,
              displayError: false
            }) )
          }

          if ( res?.meta?.status === `Bad Request` ) {
            setFormState( prevState => ({
              ...prevState,
              modalContent: modalContent.error
            }) )
          }

        })
        .catch( ( error ) => {
          setFormState( prevState => ({
            ...prevState,
            modalContent: modalContent.error
          }) )
          return reportToSentry( new Error( `Resource Center: Error signing up user to newsletter`, {
            cause: error
          }) )
        })
    } else {
      setFormState( prevState => ({
        ...prevState,
        displayError: true
      }) )
    }
  }

  return (
    <div className="py-3 relative">
      {props?.newsLetterSignupHeader && ( <h2 className="pb-3 text-deepSleepBlue sm:text-lg lg:text-xl font-inter font-normal">{props.newsLetterSignupHeader}</h2> )}
      {props?.newsLetterSignupText && ( <p className={props?.textClassName ?? `font-family--inter text-sm text-[#222] pb-2`}>{props.newsLetterSignupText}</p> )}
      <form onSubmit={handleSubmit}>
        <div className="flex items-center flex-wrap gap-y-2 gap-x-2">
          <div className="max-w-[420px] grow shrink">
            <TextInput
              type="text"
              name="email"
              placeholder="Email"
              autoComplete="username"
              className={`border border-gray-300 !rounded !px-1 sm:!py-0 lg:!py-1 !mb-0 !h-auto min-w-full !font-normal text-sm md:text-base text-black ${formState.displayError ? `input-error` : ``}`}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={formState.disableSignup}
              value={formState.email}
            />
          </div>
          <button
            className="font-family--inter text-sm text-[#222] hover:text-white bg-[#FFD875] hover:bg-[#0057a8] sm:px-5 lg:px-14 sm:py-1 lg:py-2 rounded"
            type="submit"
            disabled={formState.disableSignup}
          >
            {`Submit`}
          </button>
        </div>
        {formState.displayError && <p className="text-error pt-1 min-w-full">{regexErrors.email}</p>}
      </form>
      {
        formState.modalContent &&
        <Modal
          isOpen
          onClose={() => setFormState( prevState => ({
            ...prevState,
            modalContent: ``
          }) )}
        >
          <p className="text-xl text-center text-black py-4">{formState.modalContent}</p>
        </Modal>
      }
    </div>
  )
}

export default NewsLetterSignup