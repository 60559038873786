import React from 'react'
import { Tile } from 'components/tile'
import { dateHasPassed } from 'components/accountTracker/utils/utils'
import { SleepTherapyBeginsStep, UsageComplianceStep, AppointmentScheduleStep, FaceToFaceRequirementsStep, SleepComplianceStep } from './steps'
import StepQueueRenderer from '../StepQueueRenderer'
import LoadingSpinner from 'components/LoadingSpinner'

type ComplianceStatusType = {
  sleep_therapy_begin_date: string;
  usage_compliance_met_flag: boolean;
  md_appt_scheduled_flag: boolean;
  md_appt_scheduled_date?: string;
  f2f_compliant: boolean;
  compliant: boolean;
  next_resupply_date?: string;
};

type CpapComplianceStatusTrackerProps = {
  complianceTrackerDetails: ComplianceStatusType;
}

function CpapComplianceStatusTracker({ complianceTrackerDetails }: CpapComplianceStatusTrackerProps ): JSX.Element {
  return (
    <Tile headerContent="CPAP Compliance Status" id="cpap-compliance-tracker" className="account-tracker">
      {
        !complianceTrackerDetails ? <LoadingSpinner /> :
          <StepQueueRenderer dynamicStepStatus>
            {/* when compliant is true, all steps are considered completed */}
            <SleepTherapyBeginsStep
              stepStatus={dateHasPassed( complianceTrackerDetails?.sleep_therapy_begin_date ) || complianceTrackerDetails?.compliant}
              isoTherapyDate={complianceTrackerDetails?.sleep_therapy_begin_date || ``}
            />
            <UsageComplianceStep stepStatus={complianceTrackerDetails?.usage_compliance_met_flag || complianceTrackerDetails?.compliant} />
            <AppointmentScheduleStep
              stepStatus={complianceTrackerDetails?.md_appt_scheduled_flag || complianceTrackerDetails?.f2f_compliant || complianceTrackerDetails?.compliant} // if f2f_compliant is true, this step is considered completed
              isoApptDate={complianceTrackerDetails?.md_appt_scheduled_date || ``}
            />
            <FaceToFaceRequirementsStep stepStatus={complianceTrackerDetails?.f2f_compliant || complianceTrackerDetails?.compliant} />
            <SleepComplianceStep
              stepStatus={complianceTrackerDetails?.compliant}
              isoResupplyDate={complianceTrackerDetails?.next_resupply_date || ``}
            />
          </StepQueueRenderer>
      }
    </Tile>
  )
}

export default CpapComplianceStatusTracker