import React from 'react'
import { NewsLetterListTypes } from '../types'

type NewsLetterListProps = {
    newsLetters: NewsLetterListTypes[];
    newsLetterHeader: string | null;
}

export const NewsLetterList = ( props: NewsLetterListProps ) : JSX.Element | null => {
  return props?.newsLetters?.length ? (
    <div className="sm:pb-2 lg:pb-4">
      { props?.newsLetterHeader && ( <h2 className="sm:pb-4 lg:pb-10 text-deepSleepBlue sm:text-lg lg:text-2xl lg:text-center font-inter font-normal">{props.newsLetterHeader}</h2> ) }
      <ul>
        {props.newsLetters.map( ( newsLetter ) => {
          return (
            <li key={newsLetter.newsLetterTitle}>
              <a
                href={newsLetter.newsLetterUrl}
                target="_self"
                rel="noopener noreferrer"
                className="text-deepSleepBlue pb-2 block font-normal"
              >
                {newsLetter.newsLetterTitle}
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  ) : null
}

export default NewsLetterList