import styled from 'styled-components'
import tailwindColors from 'config/tailwindColors'

export const AppointmentButton = styled.button.attrs({
  className: `p-2 px-6 mx-1 my-2 border-none`
})`
  background-color: ${props => {
    return props.name === `active`? tailwindColors.deepSleepBlue : tailwindColors.white
  }};
  color: ${props => {
    return props.name === `active` ? tailwindColors.white : tailwindColors.deepSleepBlue
  }};
  pointer-events: ${props => {
    return props.name === `active` ? `none` : `auto`
  }};
  width: fit-content;
  border-radius: 10px;
  border: 1px solid ${tailwindColors.deepSleepBlue};
  box-sizing: border-box;
  transition: all 0.3s;

  &:active {
    transform: scale(0.98);
  }
  &:hover {
    cursor: pointer;
    background-color: ${tailwindColors.deepSleepBlue};
    color: white;
  }
`
