import React from 'react'
import { icoWhiteCheckBlueBg, icoWhiteMaskBlueBg, icoWhiteThumbsUpBlueBg } from 'resources/images'

interface Step {
  label: string;
  icon: string;
}

const StaticStepIndicator: React.FC = () => {
  const steps: Step[] = [
    {
      label: `Qualify`,
      icon: icoWhiteThumbsUpBlueBg
    },
    {
      label: `Shop`,
      icon: icoWhiteMaskBlueBg
    },
    {
      label: `Confirm`,
      icon: icoWhiteCheckBlueBg
    }
  ]

  return (
    <div className="flex justify-between items-center max-w-[460px] mx-auto sm:py-7 md:pt-[50px] pb-11 relative">
      <div className="border-t-4 border-dotted border-[#E0E2E2] mb-9 right-0 left-0 absolute w-[calc(100%-30px)] mx-auto z-0"></div>
      {steps.map( ( step ) => (
        <div
          key={step.label}
          className="text-center flex items-center z-10"
        >
          <div className="flex flex-col">
            <img
              src={step.icon}
              alt={step.label}
              className="sm:min-w-[55px] md:min-w-[87px] sm:h-[55px] md:h-[87px]"
            />
            <p className="text-sleepBlue sm:text-lg md:text-2xl font-semibold mt-3">{step.label}</p>
          </div>
        </div>
      ) )}
    </div>
  )
}

export default StaticStepIndicator
