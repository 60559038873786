import React from 'react'
import { CollapsibleBar } from './CollapsibleBar'
import { CollapsibleBlock } from './CollapsibleBlock'
import { useMediaQuery } from 'react-responsive'
import { HeroMainCta } from '../HeroMainCta'
import { NavBlockTypes, HeaderCtaTypes } from '../../types'
import { useTokenStore } from 'stores/tokenStore'
import classNames from 'classnames'

type HeroNavProps = {
  blocks: NavBlockTypes[];
  headerCta: HeaderCtaTypes;
}

// creates one nav block with mobile and desktop components
export const HeroNav = ({blocks, headerCta} : HeroNavProps ) : JSX.Element | null => {
  const { customerToken } = useTokenStore()

  const isMobile = useMediaQuery({
    query: `(max-width: 976px)`
  })

  // using fixed height on dsktp so CollapsibleBar can expand below the hero
  const containerClasses = classNames( `z-100`, `overflow-visible`, `lg:h-[276px]`,
    {
      'mb-9': customerToken,
      'mb-0': !customerToken
    }
  )

  return blocks?.length ? (
    <>
      <div className={containerClasses}>
        {isMobile ?
          <>
            <p className="text-center text-white pt-4">{`Choose a category to expand +`}</p>
            {blocks?.map( ( block ) => {
              return (
                <CollapsibleBar
                  key={Math.random()}
                  blockTitle={block.blockTitle}
                  mobileNavBarIcon={block.mobileNavBarIcon}
                  blockLinks={block.blockLinks}
                  blockUrl={block.blockUrl}
                />
              )
            })}
          </> :
          <div className="flex gap-[26px] pt-9 justify-center">
            {blocks?.map( ( block ) => {
              return (
                <CollapsibleBlock
                  key={Math.random()}
                  blockTitle={block.blockTitle}
                  desktopNavBlockImage={block.desktopNavBlockImage}
                  blockLinks={block.blockLinks}
                  blockUrl={block.blockUrl}
                />
              )
            })}
          </div>
        }
      </div>
      {!customerToken &&(
        <HeroMainCta isMobile={isMobile} headerCta={headerCta} />
      )}
    </>
  ) : null
}

export default HeroNav