import React from 'react'
import { Builder } from '@builder.io/react'
import { TextCarouselTypes } from './types'
import { SlickSlider } from './utils/SlickSlider'

type TextCarouselProps = {
    slides: TextCarouselTypes[];
    infiniteCarouselScroll: boolean;
    showDots: boolean;
    text: string;
    linkText: string;
    url: string;
    openInNewTab: boolean;
}

export const TextCarousel = ( props: TextCarouselProps ) : JSX.Element => {
  return (
    <div className="sm:px-5 lg:px-7 lg:max-w-3xl mx-auto w-full py-7 rc-text-carousel">
      {props.slides &&
        <>
          <SlickSlider infiniteCarouselScroll={props.infiniteCarouselScroll} showDots={props.showDots}>
            {props.slides?.map( ( slide ) => (
              <div key={slide.text}>
                <div className="pb-3">
                  <p className="text-center font-serif italic sm:text-xl lg:text-2xl text-[#222222]">{slide.text}</p>
                </div>
              </div>
            ) )}
          </SlickSlider>
          <div className="mt-9">
            <a
              href={props.url}
              target={props.openInNewTab ? `_blank` : `_self`}
              className="block text-center text-deepSleepBlue sm:text-small lg:text-xl font-family--inter"
            >
              {props.linkText}
            </a>
          </div>
        </>
      }
    </div>
  )
}

Builder.registerComponent( TextCarousel, {
  name: `TextCarousel`,
  inputs: [
    {
      name: `slides`,
      type: `list`,
      required: true,
      subFields: [
        {
          name: `text`,
          type: `longText`,
          required: true
        }
      ]
    },
    {
      name: `linkText`,
      type: `text`
    },
    {
      name: `url`,
      type: `text`,
      required: true
    },
    {
      name: `openInNewTab`,
      type: `boolean`,
      defaultValue: true
    },
    {
      name: `infiniteCarouselScroll`,
      type: `boolean`,
      defaultValue: true
    },
    {
      name: `showDots`,
      type: `boolean`,
      defaultValue: true
    }
  ]
})


export default TextCarousel