import React from 'react'
import PropTypes from 'prop-types'

function CMSImage({identifier, styling, customProps, srcItem, customTag, containerStyling = ``, setDisplayTooltip, displayTooltips }) {
  const CMSTag = customTag
  const sleepSrc = srcItem.replace( /(<([^>]+)>)/gi, `` ).replace( process.env.REACT_APP_MAGENTO_2_BASE_URL, process.env.REACT_APP_CMS_IMAGE_URL )

  const copyToClipboard = () => {
    if ( displayTooltips ) {
      navigator.clipboard.writeText( identifier )
    }
  }

  return (
    <CMSTag
      className={`${containerStyling} ${displayTooltips && `cursor-pointer`}`}
      onMouseEnter={() => { return setDisplayTooltip( true ) }}
      onMouseLeave={() => { return setDisplayTooltip( false ) }}
      onClick={copyToClipboard}
    >
      <img
        src={sleepSrc} alt="" className={styling}
        {...customProps}
      />
    </CMSTag>
  )
}

CMSImage.propTypes = {
  identifier: PropTypes.string,
  styling: PropTypes.string,
  customProps: PropTypes.object,
  srcItem: PropTypes.string,
  customTag: PropTypes.string,
  containerStyling: PropTypes.string,
  setDisplayTooltip: PropTypes.func,
  displayTooltips: PropTypes.bool
}

export default CMSImage
