import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import CustomSkeletonTheme from 'components/reactSkeleton/SkeletonTheme'

// skeleton for the 50/50 cta on homepage

const ContentCardGridSkeleton = () : JSX.Element => {
  return (
    <CustomSkeletonTheme>
      <div className="flex sm:flex-col md:flex-row gap-[70px] justify-evenly w-full max-w-full sm:pt-2 px-4">
        {[ ...Array( 2 ) ].map( ( _, index ) => (
          <div key={index} className="flex flex-col sm:py-6 md:py-16 justify-items-center items-start md:w-1/2">
            <div className="text-center flex flex-col grow w-full">
              <Skeleton height={300} width={`100%`} className="rounded-md mb-5 md:mb-7" />
              <Skeleton height={40} width={`80%`} className="mx-auto mb-4" />
              <Skeleton count={4} className="mb-4" />
              <div className="w-48 mx-auto">
                <Skeleton height={40} />
              </div>
            </div>
          </div>
        ) )}
      </div>
    </CustomSkeletonTheme>
  )
}

export default ContentCardGridSkeleton