import React from 'react'
import { Builder } from '@builder.io/react'
import MemberWebLinks from './components/MemberWebLinks'
import { LeadershipTeamMemberType } from './types'

type ContentProps = {
   leadershipTeam?: LeadershipTeamMemberType[];
}

const LeadershipListDisplay = ( props: ContentProps ) => {

  return (
    <div className="px-4 mt-4 mb-4 w-full">
      {
        props?.leadershipTeam?.length && props.leadershipTeam.map( ( leadershipTeamMember, index ) => {
          return (
            <div
              className="flex sm:flex-col lg:flex-row sm:items-center lg:items-start gap-3 sm:mb-12 lg:mb-16"
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              <div className="flex sm:justify-center lg:justify-start">

                <img
                  className="max-w-none max-h-none object-contain"
                  src={leadershipTeamMember.img}
                  alt={leadershipTeamMember.name}
                  height="215"
                  width="215"
                />

              </div>

              <div>

                <p className="sm:text-center lg:text-left"><b className="text-lg">{leadershipTeamMember.name}</b></p>

                <p className="mb-2 sm:text-center lg:text-left font-light text-xl">{leadershipTeamMember.role}</p>

                <p className="mb-2">
                  {leadershipTeamMember.desc}
                  {leadershipTeamMember.email &&
                    <a
                      className="a"
                      href={`mailto:${leadershipTeamMember.email}`}
                    >
                      {leadershipTeamMember.email}
                    </a>
                  }
                </p>

                {
                  leadershipTeamMember.links?.length &&
                  <MemberWebLinks wwwLinks={leadershipTeamMember.links} />
                }
              </div>



            </div>
          )
        })
      }
    </div>
  )
}

Builder.registerComponent( LeadershipListDisplay, {
  name: `LeadershipListDisplay`,
  inputs: [
    {
      name: `name`,
      type: `string`
    },
    {
      name: `role`,
      type: `string`
    },
    {
      name: `desc`,
      type: `string`
    },
    {
      name: `email`,
      type: `email`
    },
    {
      name: `img`,
      type: `string`
    },
    {
      name: `links`,
      type: `list`,
      subFields: [{
        name: `url`,
        type: `string`
      },
      {
        name: `text`,
        type: `string`
      }],
      required: false
    }
  ]
})

export default LeadershipListDisplay