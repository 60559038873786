import { dangerAlertTriangle } from 'resources/images'
export default function Alert({text} : {text?:string}) : JSX.Element {

  const defaultText = `We couldn’t verify your address. Please make sure it’s correct before you save it.`

  return (
    <div className="bg-[#FDF3D9] rounded-xl max-w-md">
      <div className="flex flex-row items-center justify-between py-3 px-6 gap-5">
        <img
          src={dangerAlertTriangle}
          alt="alert icon"
          height={28}
          width={28}
          className="w-[28px] h-[28px] mt-2"
        />
        <p className="text-md text-left font-light text-[#B30000]">{text ? text : defaultText}</p>
      </div>
    </div>
  )
}