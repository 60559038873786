import { Formatters, TextInput, Validators } from 'components/forms/components'
import { regexErrors } from 'components/forms/constants'
import React, { useRef } from 'react'
import { useDateInputState } from './useInputState'


export default function DateOfBirthInput() : JSX.Element {

  const [ dateFields, setDateFields ] = useDateInputState()

  const dobYYYYRef = useRef<HTMLElement>( null )
  const dobDDRef = useRef<HTMLElement>( null )


  const onHandleChange = ( event : React.ChangeEvent<HTMLInputElement> ) => {
    setDateFields({
      ...dateFields,
      [event.currentTarget.name]: event.currentTarget.value
    })
  }

  /** onHandleChange for month and day fields */
  const onXXChange = ( event : React.ChangeEvent<HTMLInputElement> ) => {
    if ( event.target.name === `dobMonth` && event.target.value?.length >= 2 ) dobDDRef?.current?.focus()
    if ( event.target.name === `dobDay` && event.target.value?.length >= 2 ) dobYYYYRef?.current?.focus()
    setDateFields({
      ...dateFields,
      [event.currentTarget.name]: event.currentTarget.value.slice( -2 )
    })
  }

  return (
    <div className="mb-6">
      <div className="mb-3">
        <label className="input-label">{`Patient's Date of Birth `}<span className="text-error">{`*`}</span></label>
      </div>

      <div className={`flex flex-row mx-auto`}>
        <div className="flex w-4/12 px-0">
          <TextInput
            name={`dobMonth`}
            placeholder={`MM`}
            type="number"
            value={dateFields.dobMonth}
            required
            onChange={onXXChange}
            validator={{
              function: Validators.dobMonth,
              failureMessage: regexErrors.expmm
            }}
            formatter={{
              function: Formatters.dateXX,
              onBlur: true
            }}
            autoComplete="bday-month"
            id="dob-month"
          />
          <span className="text-inputGray text-2xl px-[7px]">{`/`}</span>
        </div>
        <div className="flex flex-row w-4/12">
          <TextInput
            name={`dobDay`}
            placeholder={`DD`}
            type="number"
            value={dateFields.dobDay}
            required
            onChange={onXXChange}
            validator={{
              function: Validators.dd,
              failureMessage: regexErrors.dd
            }}
            formatter={{
              function: Formatters.dateXX,
              onBlur: true
            }}
            autoComplete="bday-day"
            reference={dobDDRef}
            id="dob-day"
          />
          <span className="text-inputGray pa-0 ma-0 text-2xl px-[7px]">{`/`}</span>
        </div>
        <div className="w-6/12">
          <TextInput
            name={`dobYear`}
            placeholder={`YYYY`}
            type="number"
            value={dateFields.dobYear}
            required
            onChange={onHandleChange}
            validator={{
              function: Validators.dobYear,
              failureMessage: regexErrors.dob_yyyy
            }}
            formatter={{
              function: Formatters.dateYYYY
            }}
            autoComplete="bday-year"
            reference={dobYYYYRef}
            id="dob-year"
          />
        </div>
      </div>
    </div>
  )
}