import { useEffect } from 'react'
import { checkMaintenanceFlag, getMaintenancePageStatus } from './utils'
import { useNavigate } from 'react-router'
import { MAINTENANCE_PAGE_PATH } from 'routes'
import { maintenancePreviousURL } from 'apollo/clientState'
import { reportToSentry } from '../../utils/reportToSentry'

function MaintenanceListener() : null {

  const navigate = useNavigate()
  const isMaintenancePage = getMaintenancePageStatus()

  useEffect( () => {
    checkMaintenanceFlag()
      .then( data => {
        if ( data?.meta?.status === `OK` && data?.data?.status ) {
          // save where the user was in case they keep their screen up and site is taken out of maintenance mode
          if ( !isMaintenancePage ) maintenancePreviousURL( location.pathname.concat( location.search ) )

          return navigate( MAINTENANCE_PAGE_PATH )
        }
      })
      .catch( err => {
        // no need to prevent these folks from accessing the site, they likely have that .io domain blocked
        reportToSentry( new Error( `Issue fetching maintenance mode status`, {
          cause: err
        }) )
      })
  }, [] )

  return null

}

export default MaintenanceListener