import { gql } from '@apollo/client'

export const customerAddressFragment = gql`
  fragment CustomerAddress on CustomerAddress {
    id
    firstname
    lastname
    telephone
    street
    city
    region {
      region_id
      region
      region_code
    }
    postcode
    default_shipping
    default_billing
    country_id
  }
`
