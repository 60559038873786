import { gql } from '@apollo/client'

import { xSearchProductsOperation, xSearchBlogsOperation, xSearchPopularSearchesOperation } from '../operations/xsearch'

export const getProductsBlogsAndPopularSearchesQuery = gql`
  query getProductsBlogsAndPopularSearches($searchTerm: String) {
    ${xSearchProductsOperation}
    ${xSearchBlogsOperation}
    ${xSearchPopularSearchesOperation}
  }
`
