import PropTypes from 'prop-types'
import styled from 'styled-components'
import tailwindColors from 'config/tailwindColors'
import Logo from './headerBarV2/Logo'

const StyledSpinner = styled.div`
  --spinner-color: ${tailwindColors.secondaryBlue.tint2};
  --spinner-color-top: ${tailwindColors.deepSleepBlue};
  --spinner-2-color: ${tailwindColors.deepSleepBlue};
  --spinner-3-color: ${tailwindColors.secondaryBlue.tint2};
  --spinner-3-color-top: ${tailwindColors.deepSleepBlue};
  --spinner-4-color-top: ${tailwindColors.deepSleepBlue};
  --spinner-5-color: ${tailwindColors.deepSleepBlue};
  --spinner-6-color: ${tailwindColors.secondaryBlue.tint2};
  --spinner-7-color: ${tailwindColors.deepSleepBlue};

  --spinner-width: ${props => { return props.width }};

  bottom: 0;
  border: calc(var(--spinner-width) * 0.055) solid var(--spinner-color);
  border-top-color: var(--spinner-color-top);
  border-radius: 100%;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;

  &:before, &:after {
    content: '';
    background-color: var(--spinner-color-top);
    border-radius: 50%;
    height: 5%;
    width: 5%;
    position: absolute;
    top: 15%;
  }

  &:before {
    left: 13%;
  }

  &:after {
    right: 13%;
  }

  &.two {
    border-color: var(--spinner-2-color);
    border-width: calc(var(--spinner-width) * 0.031);
    height: 87%;
    width: 87%;
    opacity: 0.22;
    &:before, &:after {
      display: none;
    }
  }

  &.three {
    border-color: var(--spinner-3-color);
    border-top-color: var(--spinner-3-color-top);
    border-width: calc(var(--spinner-width) * 0.031);
    height: 76%;
    width: 76%;

    &:before, &:after {
      background-color: var(--spinner-3-color-top);
      height: 3%;
      width: 3%;
      top: 10%;
    }

    &:before {
      left: 8%;
    }

    &:after {
      right: 8%;
    }
  }

  &.four {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: var(--spinner-4-color-top);
    border-width: calc(var(--spinner-width) * 0.031);
    height: 58%;
    width: 58%;
    z-index: 9;

    &:before, &:after {
      background-color: var(--spinner-4-color-top);
      height: 3%;
      width: 3%;
      top: 6%;
    }

    &:before {
      left: 5.5%;
    }

    &:after {
      right: 5.5%;
    }
  }

  &.five {
    border-color: var(--spinner-5-color);
    border-top-color: rgba(255, 255, 255, 0);
    border-width: calc(var(--spinner-width) * 0.016);
    height: 58%;
    width: 58%;
    &:before, &:after {
      display: none;
    }
  }

  &.six {
    border-color: var(--spinner-6-color);
    border-width: calc(var(--spinner-width) * 0.024);
    height: 50%;
    width: 50%;
    &:before, &:after {
      display: none;
    }
  }

  &.seven {
    border-color: var(--spinner-7-color);
    border-width: calc(var(--spinner-width) * 0.008);
    height: 50%;
    width: 50%;
    &:before, &:after {
      display: none;
    }
  }
`

const Spinner = ({ width = `5rem`, height = `5rem`, rings = 7 }) => {
  const style = {
    width,
    height
  }

  const spinners = [
    <StyledSpinner key={0} className="animate-spin-mid one" width={width} />,
    <StyledSpinner key={1} className="two" width={width} />,
    <StyledSpinner key={2} className="animate-spin-reverse-extra-slow three" width={width} />,
    <StyledSpinner key={3} className="animate-spin four" width={width} />,
    <StyledSpinner key={4} className="animate-spin-reverse five" width={width} />,
    <StyledSpinner key={5} className="six" width={width} />,
    <StyledSpinner key={6} className="animate-spin-mid seven" width={width} />
  ]

  return (
    <div className="relative m-auto" style={style}>
      {spinners.slice( 0, rings ).map( ( Spinner ) => {
        return Spinner
      })}
    </div>
  )
}

Spinner.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  rings: PropTypes.number
}

const LoadingSpinner = ({
  width = `5rem`,
  height = `5rem`,
  rings = 7,
  message = ``,
  messageStyling = ``,
  logo = false,
  logoStyling = `max-w-md px-3 mx-auto my-10`,
  containerStyling = `flex flex-col gap-2`
}) => {

  return (
    <div className={containerStyling}>
      {
        logo &&
        <div className={logoStyling}>
          <Logo withoutLink />
        </div>
      }
      {
        message &&
        <p className={messageStyling}>{message}</p>
      }
      <Spinner
        width={width}
        height={height}
        rings={rings}
      />
    </div>
  )
}

LoadingSpinner.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  rings: PropTypes.number,
  message: PropTypes.string,
  messageStyling: PropTypes.string,
  logo: PropTypes.bool,
  logoStyling: PropTypes.string,
  containerStyling: PropTypes.string
}

export default LoadingSpinner
