import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

export type QualifyStore = {
  processId: string;
  setProcessId: ( _processId: string ) => void;
  formCode: string | null;
  setFormCode: ( _formCode: string | null ) => void;
  resetPasswordToken: string;
  setResetPasswordToken: ( _resetPasswordToken: string ) => void;
  setQualifyLeadResponseVars: ( _processId : string, _formCode : string, _resetPasswordToken : string ) => void;
  submitStepActive: boolean;
  setSubmitStepActive: ( _submitStepActive: boolean ) => void;
  emailNotAvailable: boolean;
  setEmailNotAvailable: ( _emailNotAvailable: boolean ) => void;
  internalAccountExists: boolean;
  setInternalAccountExists: ( _emailNotAvailable: boolean ) => void;
  skipSubmitted: boolean,
  setSkipSubmitted: ( _skipSubmitted: boolean ) => void;
}

export const useQualifyFormStore = create<QualifyStore>()( persist( ( set ) => ({
  processId: ``,
  setProcessId: ( processId: string ) => set({
    processId
  }),
  formCode: null,
  setFormCode: ( formCode: string | null ) => set({
    formCode
  }),
  resetPasswordToken: ``,
  setResetPasswordToken: ( resetPasswordToken: string ) => set({
    resetPasswordToken
  }),
  setQualifyLeadResponseVars: ( processId : string, formCode : string, resetPasswordToken : string ) => set({
    processId,
    formCode,
    resetPasswordToken
  }),
  submitStepActive: false,
  setSubmitStepActive: ( submitStepActive: boolean ) => set({
    submitStepActive
  }),
  emailNotAvailable: false,
  setEmailNotAvailable: ( emailNotAvailable: boolean ) => set({
    emailNotAvailable
  }),
  internalAccountExists: false,
  setInternalAccountExists: ( internalAccountExists: boolean ) => set({
    internalAccountExists
  }),
  skipSubmitted: false,
  setSkipSubmitted: ( skipSubmitted: boolean ) => set({
    skipSubmitted
  })
}), {
  name: `qualifyFormStore`,
  storage: createJSONStorage( () => sessionStorage )
}) )