import PropTypes from 'prop-types'
import LoadingSpinner from 'components/LoadingSpinner'

function EditOrderButtonBlock({
  editMode,
  setEditMode,
  handleEditOrderSubmit,
  loading,
  userHasEdited,
  handleCancel
}) {

  if ( !editMode ) {
    return (
      <p
        className="mx-auto w-fit cursor-pointer underline hover:opacity-80 text-deepSleepBlue"
        onClick={() => { setEditMode( true ) }}
      >
        {`Edit Order`}
      </p>
    )
  }

  return (
    <div className="w-full max-w-sm px-7">
      <button
        type="submit"
        className="btn-secondary mx-auto"
        onClick={handleEditOrderSubmit}
        disabled={loading || !userHasEdited}
      >
        {
          loading ?
            <LoadingSpinner rings={3} width="2.5rem" height="2.5rem" />
            :
            <>
              {`Save and Continue`}
            </>

        }
      </button>
      <button
        className="btn-primary mx-auto"
        onClick={handleCancel}
        disabled={loading}
      >
        {`Cancel`}
      </button>
    </div>
  )

}

EditOrderButtonBlock.propTypes = {
  editMode: PropTypes.bool,
  setEditMode: PropTypes.func,
  handleEditOrderSubmit: PropTypes.func,
  loading: PropTypes.bool,
  userHasEdited: PropTypes.bool,
  handleCancel: PropTypes.func
}

export default EditOrderButtonBlock