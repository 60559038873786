/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

// import { icoPlus, icoMinus } from 'resources/images'
import PropTypes from 'prop-types'
import { SlideToggleContent } from 'components/SlideToggle'
import { SleepLinearGradient } from 'components/sleepLinearGradient'


export const Collapsible = ({
  panelHeader,
  links = [],
  displayBlock
}) => {

  const [ isOpen, setIsOpen ] = useState( false )

  const DisplayBlock = displayBlock || null

  const handleClick = ( ) => {
    setIsOpen( !isOpen )
  }

  return (
    <div className="px-0">
      <div className="mx-6">
        <div className="flex flex-row justify-between align-middle pa-10 cursor-pointer py-[14px] items-center" onClick={handleClick}>
          <span className="pa-4 font-semibold">{panelHeader}</span>

          <span className="pa-4" >
            <div className="text-bold text-xl">
              {isOpen ? `-` : `+`}
            </div>
          </span>
        </div>
      </div>
      <SlideToggleContent isVisible={isOpen}>
        <div>

          <ul className="pt-0 pb-[11px] pl-12 m-0">
            {links.map( ( link, i ) => {

              const { display, href, CMSPage } = link

              return (
                <li key={i}>
                  {CMSPage ? <Link to={href}>{display}</Link> : <a href={href} rel="noreferrer">{display}</a>}
                </li>
              )
            })}
          </ul>
          {
            displayBlock &&
           <DisplayBlock />
          }
        </div>
      </SlideToggleContent>
      <div className="mx-6">
        <SleepLinearGradient height={1} />
      </div>
    </div>
  )
}

Collapsible.propTypes = {
  links:PropTypes.array,
  displayBlock: PropTypes.elementType,
  panelHeader: PropTypes.string.isRequired
}

export default Collapsible
