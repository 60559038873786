import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation, useReactiveVar } from '@apollo/client'

import { preLoginLocationVar } from 'apollo'
import { generateCustomerTokenMutation } from 'graphql/mutations'
import { getRedirectable } from 'modules/auth/redirectable'

import { ErrorField, FormButton, PasswordInput } from 'components/forms/components'
import LoadingSpinner from 'components/LoadingSpinner'
import { EmailInput, useInputState, useValidInputs } from './input'
import { reportToSentry } from 'utils/reportToSentry'
import { MY_ACCOUNT_PATH } from 'routes'
import { PAYMENT_PORTAL_PATH } from 'modules/aobPayments/constants'
import { useTokenStore } from 'stores/tokenStore'

const LoginForm = () => {
  const { email } = useInputState()
  const { isValidEmail } = useValidInputs()

  const [ password, setPassword ] = useState( `` )
  const [ loading, setLoading ] = useState( false )
  const [ errorMessage, setErrorMessage ] = useState( `` )


  const navigate = useNavigate()
  const locationState = useReactiveVar( preLoginLocationVar )

  const { setCustomerToken } = useTokenStore()

  // navigate to AOB when coming from alokai resupply and proceedToPayment is true
  useEffect( () => {
    // when coming from resupply we will have a m2 token and isResupply flag in the url
    // authenticate using the m2 token and then navigate to AOB where we will use the claimedItems data + patientId from the url to populate the invoice
    const { m2Token: customerToken, isResupply, isMyAccount } =
      Object.fromEntries( new URLSearchParams( window.location.search ) )

    if ( !customerToken ) return

    setCustomerToken( customerToken )

    // navigate to AOB with the original URL params or to my account
    if ( isResupply ) return navigate( `${PAYMENT_PORTAL_PATH}${window.location.search}` )
    if ( isMyAccount ) return navigate( MY_ACCOUNT_PATH )
  }, [] )

  const [ login ] = useMutation( generateCustomerTokenMutation, {
    fetchPolicy: `no-cache`, // Prevent caching user login creds
    variables: {
      email,
      password
    },
    onCompleted: ({ generateCustomerToken }) => {
      if ( generateCustomerToken?.token ) {
        setCustomerToken( generateCustomerToken.token )

        const { from } = locationState || {
          from: {
            pathname: MY_ACCOUNT_PATH,
            search: window.location.search
          }
        }
        const redirectable = getRedirectable( from )

        return navigate( redirectable?.redirectLocation ?? MY_ACCOUNT_PATH, {
          state: locationState,
          replace: true
        })
      }

      setErrorMessage( `There was an error logging in.` )
      setLoading( false )
    },
    onError: ( data ) => {

      const { graphQLErrors: errors } = data

      // we want to display something different if there is an error of a certain message
      const signInAuthError = errors?.find( error => {
        return error.message === `The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.`
      })

      if ( !signInAuthError ){
        reportToSentry( new Error( `non auth related generateCustomerTokenMutation error in LoginForm`, {
          cause: data
        }) )
      }

      setErrorMessage( signInAuthError ? `The username/password combination was not found` : errors.map( errorObject => { return errorObject.message }).join( ` ` ) )
      setLoading( false )
    }
  })

  const handleSubmit = event => {
    event.preventDefault()
    setLoading( true )
    login()
  }

  if ( loading ) {
    return (
      <div className="my-8">
        <p className="mb-2">{`Logging in...`}</p>
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit} className="mx-auto flex flex-col items-center">
      <div className="mb-5">
        <EmailInput initialReadOnly required />
        <PasswordInput
          name="password" label="Password"
          value={password}
          onChange={( e ) => { setPassword( e.currentTarget.value ) }}
          required
          autoComplete="current-password"
        />
      </div>
      <FormButton className="sm:mr-0 mt-2 mb-8 btn-primary" disabled={!isValidEmail}>{`Sign In`}</FormButton>

      <Link to="/request-password-reset" className="a">
        <p className="mx-auto w-full text-center sm:text-sm md:text:lg">{`Forgot your Password?`}</p>
      </Link>
      {errorMessage && <ErrorField message={errorMessage} />}

      <p className="text-xs text-center text-error my-4">{`* Required Fields`}</p>
    </form>
  )
}
LoginForm.propTypes = {
  initialEmail: PropTypes.string,
  locationAfterLogin: PropTypes.string
}

export default LoginForm
