import React from 'react'
import TrackerStepIcon from '../../TrackerStepIcon'
import { listWhite } from 'resources/images'
import { insurancePriorAuthCopy } from '../constants'
import StepContentRenderer from '../../StepContentRenderer'

type InsurancePriorAuthStepProps = {
  stepStatus: boolean;
  isCurrentStep?: boolean | false;
}

function InsurancePriorAuthStep({ stepStatus, isCurrentStep } : InsurancePriorAuthStepProps ) : JSX.Element {
  return (
    <div className="flex">
      <TrackerStepIcon imageSrc={listWhite} stepStatus={stepStatus} isCurrentStep={isCurrentStep} />
      <StepContentRenderer
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
        stepCopy={insurancePriorAuthCopy}
      />
    </div>
  )
}

export default InsurancePriorAuthStep
