import React, { useEffect, useState } from 'react'
import { Heading } from 'components/resupply2'
import { CheckboxInput, Formatters } from 'components/forms/components'
import { isValidDoctor } from './utils'
import { useDoctorSelectionState } from '../hooks'
import copy from './copy.json'
import DoctorInfo from 'components/doctorBlock/types/DoctorInfo'
import DoctorBlock from 'components/doctorBlock/DoctorBlock'

export default function PrescriptionExpired({ doctorOnLoad, setDoctorComplete} : {doctorOnLoad: DoctorInfo | null, setDoctorComplete: React.Dispatch<React.SetStateAction<boolean>>}) : JSX.Element {

  const [ error, setError ] = useState( `` )
  const [ doctorSelection, setDoctorSelection ] = useDoctorSelectionState()
  const [ selectedDoctor, setSelectedDoctor ] = useState<DoctorInfo>( doctorOnLoad ?? {
    firstName: ``,
    lastName: ``,
    phone: ``
  })

  const handleNewDoctorSuccess = ( d : DoctorInfo ) => {
    setError( `` )
    if ( !doctorSelection && d?.firstName?.toLowerCase() === doctorOnLoad?.firstName?.toLowerCase() && d?.lastName?.toLowerCase() === doctorOnLoad?.lastName?.toLowerCase() && Formatters.phone( doctorOnLoad?.phone ) === Formatters.phone( d?.phone ) ) {
      setError( copy.sameDoctorAsCurrent )

      return setDoctorComplete( true )
    }
    setSelectedDoctor( d )
    setDoctorSelection( isValidDoctor( d ) )
    window.scrollTo({
      top: 0,
      behavior: `smooth`
    })
  }

  const handleCurrentDoctorSelection = () => {
    setDoctorSelection( true )
    window.scrollTo({
      top: 0,
      behavior: `smooth`
    })

  }

  useEffect( () => {
    setDoctorComplete( Boolean ( doctorSelection && isValidDoctor( selectedDoctor ) && !error ) )
  }, [ selectedDoctor, doctorSelection ] )

  return (
    <div className="max-w-xl mx-auto mb-10">
      <Heading
        title={copy.updateRequiredTitle}
        description={copy.updateRequiredDescription}
      />
      <div className="max-w-sm mx-auto pl-4 mt-10">
        {
          isValidDoctor( selectedDoctor ) &&
            <div
              className="flex cursor-pointer mt-5"
              onClick={handleCurrentDoctorSelection}
            >
              <CheckboxInput
                name="currentDoctorStatus"
                value={doctorSelection === null ? false : doctorSelection}
                onChange={() => { return }}
                id={`useCurrentDoctor`}
                reference={undefined}
                label=""
              />
              <p className="text-lg ml-2">
                <span className="capitalize">{`Dr. ${selectedDoctor.firstName.toLowerCase()} ${selectedDoctor.lastName.toLowerCase()}`}</span>
                {` is my current doctor`}</p>
            </div>
        }
        <br></br>
        <div
          className="flex cursor-pointer mt-5"
          onClick={() => { setDoctorSelection( false ) }}
        >
          <CheckboxInput
            label=""
            name="currentDoctorStatus"
            value={doctorSelection === null ? false : !doctorSelection}
            onChange={() => { return }}
            id={`useNewDoctor`}
            reference={undefined}
          />
          <p className="text-lg ml-2">{`I have a new doctor`}</p>
        </div>
      </div>

      <br></br>

      { doctorSelection === false && (
        <div className="max-w-sm w-full mx-auto mb-10">
          <DoctorBlock
            onSuccess={handleNewDoctorSuccess}
            forceUpdate
            scrollIntoView
          />
        </div>
      )}
      <p className="text-error font-light text-lg text-center">{error}</p>
    </div>
  )
}