import React from 'react'

interface AobContactBlockProps {
  invoice: {
    phone_number: string;
    email: string;
  };
  header: string;
  contactBlockStyle: string;
}

const AobContactBlock: React.FC<AobContactBlockProps> = ({ invoice, header, contactBlockStyle }) => {
  const { phone_number, email } = invoice
  return (
    <div className={`${contactBlockStyle} md:px-8 py-4 w-full mt-9 mb-6 !max-w-none`}>
      <p className="text-xl tracking-wide font-medium text-center my-4">{header ? header : `Contact`}</p>
      <div className="text-center">
        {phone_number && <p className="sm:text-sm lg:text-lg font-light capitalize">{`Phone: `}{phone_number}</p>}
        {email && <p className="sm:text-sm lg:text-lg font-light capitalize">{`Email: `}{email}</p>}
      </div>
    </div>
  )
}

export default AobContactBlock