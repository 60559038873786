import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Submit from '../../qualifyForm/pages/Submit'
import { useQualifyFormStore } from '../../qualifyForm/state'
import { LOGIN_PATH } from 'routes'
import { SleepLinearGradient } from 'components/sleepLinearGradient'
import Logo from 'components/headerBarV2/Logo'

const AobAccountCreation = (): JSX.Element | null => {
  const navigate = useNavigate()
  const { resetPasswordToken } = useQualifyFormStore()

  useEffect( () => {
    if ( !resetPasswordToken ) {
      navigate( LOGIN_PATH )
    }
  }, [ ] )

  if ( !resetPasswordToken ) {
    return null
  }

  return (
    <>
      <SleepLinearGradient height={34} />
      <div className="mx-auto sm:px-5 lg:px-[60px]">
        <div className="mx-auto sm:mb-5 md:mb-[60px] sm:mt-4 md:mt-20 relative">
          <div className={`flex-grow flex flex-row items-center sm:justify-center`}>
            <Logo withoutLink />
          </div>
        </div>
        <div className="mx-auto max-w-[700px]">
          <Submit isEmbedded={false} isFromAob />
        </div>
      </div>
    </>
  )
}

export default AobAccountCreation
