import React from 'react'
import LoadingSpinner from 'components/LoadingSpinner'
import PropTypes from 'prop-types'
import Logo from './logos/Logo'

function AobLoader({message, withLogo = true}) {
  return (
    <div className="m-auto bg-white my-24">
      {
        withLogo &&
        <div className="w-full max-w-md px-3 mx-auto mb-10">
          <Logo />
        </div>
      }
      <p className="text-center mb-10">{message}</p>
      <LoadingSpinner />
    </div>
  )
}

AobLoader.propTypes = {
  message: PropTypes.string,
  withLogo: PropTypes.bool
}

export default AobLoader