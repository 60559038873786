import { useState, useEffect, useRef, RefObject } from 'react'
import { Formatters, getExpiryYearList, SelectInput, TextInput } from 'components/forms/components'
import { getValidationHelper } from '../validation'
import { usaStates, months } from 'components/forms/constants'
import { EditCardMethodProps } from './props'

export default function EditCardMethod({payMethod, setPayMethod, onSave } : EditCardMethodProps ) {

  const [ currentFocus, setCurrentFocus ] = useState( `` )
  const [ errors, setErrors ] = useState<Record<string, string>>({
    cardholderFirstName: ``,
    cardholderLastName: ``,
    cardNumber: ``,
    expMonth: ``,
    expYear: ``,
    address: ``,
    city: ``,
    state: ``,
    zip: ``,
    ccv: ``
  })

  const inputRefs = {
    cardholderFirstName: useRef<HTMLInputElement>( null ),
    cardholderLastName: useRef<HTMLInputElement>( null ),
    cardNumber: useRef<HTMLInputElement>( null ),
    expMonth: useRef<HTMLSelectElement>( null ),
    expYear: useRef<HTMLSelectElement>( null ),
    address: useRef<HTMLInputElement>( null ),
    city: useRef<HTMLInputElement>( null ),
    state: useRef<HTMLSelectElement>( null ),
    zip: useRef<HTMLInputElement>( null ),
    ccv: useRef<HTMLInputElement>( null )
  } as Record<string, RefObject<HTMLInputElement | HTMLSelectElement>>

  const evaluateErrors = () => {
    return Boolean( Object.keys( inputRefs ).find( ( fieldName ) => {
      const hasError = errors[fieldName] !== ``
      const isIncomplete = !inputRefs[fieldName]?.current?.value

      return hasError || isIncomplete
    }) )
  }

  const [ shouldSubmitBeDisabled, setShouldSubmitBeDisabled ] = useState( evaluateErrors() )


  useEffect( () => {
    setShouldSubmitBeDisabled( evaluateErrors() )
  }, [ errors ] )

  function handleInputChange( event : React.ChangeEvent<HTMLInputElement> ) {
    const { name, value } = event.currentTarget
    // Pass up data
    setPayMethod({
      ...payMethod,
      [name]: value
    })
    // Check for errors
    const { errorMessage, validator } = getValidationHelper( name )
    const _errors = {
      ...errors
    }
    // If valid set error to empty string
    _errors[ name ] = validator( value ) ? `` : errorMessage
    setErrors( _errors )
  }

  function handleBlur() {
    setCurrentFocus( `` )
  }

  function handleFocus( event : React.ChangeEvent<HTMLInputElement> ) {
    setCurrentFocus( event.target.name )
  }


  const inputHandlers = {
    onChange: handleInputChange,
    onBlur: handleBlur,
    onFocus: handleFocus
  }

  const labelClasses = `sm:text-sm lg:text-base !text-left block pb-2`
  const inputClasses = `input placeholder:text-sm placeholder:text-softGray placeholder:font-light`

  return (
    <div className="px-6 w-full max-w-xl mx-auto">
      <hr className="my-4"></hr>
      <div className="flex flex-col md:flex-row w-full justify-between gap-4 pb-4">
        <div className="w-full md:w-1/2">
          <TextInput
            id={`cardholderFirstName`}
            name="cardholderFirstName"
            label="Cardholder's First Name"
            value={payMethod.cardholderFirstName}
            errorMessage={currentFocus !== `cardholderFirstName` ? errors.cardholderFirstName : ``}
            required
            reference={inputRefs.cardholderFirstName as RefObject<HTMLInputElement>}
            {...inputHandlers}
            labelClassName={labelClasses}
            className={inputClasses}
            placeholder="Please type..."
          />
        </div>
        <div className="w-full md:w-1/2">
          <TextInput
            id={`cardholderLastName`}
            name="cardholderLastName"
            label="Cardholder's Last Name"
            value={payMethod.cardholderLastName}
            errorMessage={currentFocus !== `cardholderLastName` ? errors.cardholderLastName : ``}
            reference={inputRefs.cardholderLastName as RefObject<HTMLInputElement>}
            required
            {...inputHandlers}
            labelClassName={labelClasses}
            placeholder="Please type..."
            className={inputClasses}
          />
        </div>
      </div>
      <div className="pb-4">
        <TextInput
          id="cardNumber"
          name="cardNumber"
          label="Card Number"
          type="tel"
          value={payMethod.cardNumber ?? ``}
          formatter={{
            function: Formatters.creditCard
          }}
          errorMessage={currentFocus !== `cardNumber` ? errors.cardNumber : ``}
          reference={inputRefs.cardNumber as RefObject<HTMLInputElement>}
          required
          {...inputHandlers}
          labelClassName={labelClasses}
          placeholder="Please type..."
          className={inputClasses}
        />
      </div>
      <div className="flex flex-row w-full justify-between gap-4 pb-4">
        <div className="w-1/2">
          <SelectInput
            id="expMonth"
            name="expMonth"
            label="Exp. Month"
            value={payMethod.expMonth}
            errorMessage={currentFocus !== `expMonth` ? errors.expMonth : ``}
            reference={inputRefs.expMonth as RefObject<HTMLSelectElement>}
            required
            {...inputHandlers}
            labelClassName={labelClasses}
          >
            <option value="" hidden>{`Exp. Month`}</option>
            {months.map( month => {
              return <option key={month.value} value={month.value}>{month.text}</option>
            })}
          </SelectInput>
        </div>
        <div className="w-1/2">
          <SelectInput
            id="expYear"
            name="expYear"
            label="Exp. Year"
            value={payMethod.expYear}
            errorMessage={currentFocus !== `expYear` ? errors.expYear : ``}
            reference={inputRefs.expYear as RefObject<HTMLSelectElement>}
            required
            {...inputHandlers}
            labelClassName={labelClasses}
            className={inputClasses}
          >
            <option value="" hidden>{`Exp. Year`}</option>
            {getExpiryYearList().map( expiry => {
              return <option key={expiry.YY} value={expiry.YY}>{expiry.YYYY}</option>
            })}
          </SelectInput>
        </div>
      </div>
      <div className="pb-4">
        <TextInput
          id="address"
          name="address"
          label="Address"
          value={payMethod.address}
          errorMessage={currentFocus !== `address` ? errors.address : ``}
          reference={inputRefs.address as RefObject<HTMLInputElement>}
          required
          {...inputHandlers}
          labelClassName={labelClasses}
          placeholder="Please type..."
          className={inputClasses}
        />
      </div>
      <div className="flex flex-row w-full justify-between gap-4 pb-4">
        <div className="w-9/12">
          <TextInput
            id="city"
            name="city"
            label="City"
            value={payMethod.city}
            errorMessage={currentFocus !== `city` ? errors.city : ``}
            reference={inputRefs.city as RefObject<HTMLInputElement>}
            required
            {...inputHandlers}
            labelClassName={labelClasses}
            placeholder="Please type..."
            className={inputClasses}
          />
        </div>
        <div className="w-3/12">
          <SelectInput
            id="state"
            name="state"
            label="State"
            value={payMethod.state}
            errorMessage={currentFocus !== `state` ? errors.state : ``}
            formatter={{
              function: Formatters.state
            }}
            reference={inputRefs.state as RefObject<HTMLSelectElement>}
            {...inputHandlers}
            required
            labelClassName={labelClasses}
            className={inputClasses}
          >
            <option value="" hidden>{`State`}</option>
            {usaStates.map( state => {
              return <option key={state.value} value={state.value}>{state.value}</option>
            })}
          </SelectInput>
        </div>
      </div>
      <div className="flex flex-row w-full justify-between gap-4">
        <TextInput
          id="zip"
          name="zip"
          label="Zip"
          type="tel"
          value={payMethod.zip}
          formatter={{
            function: Formatters.zipCode
          }}
          reference={inputRefs.zip as RefObject<HTMLInputElement>}
          required
          errorMessage={currentFocus !== `zip` ? errors.zip : ``}
          {...inputHandlers}
          labelClassName={labelClasses}
          placeholder="Please type..."
          className={inputClasses}
        />
        <TextInput
          id="ccv"
          name="ccv"
          label="CCV"
          type="tel"
          value={payMethod.ccv}
          errorMessage={currentFocus !== `ccv` ? errors.ccv : ``}
          reference={inputRefs.ccv as RefObject<HTMLInputElement>}
          required
          {...inputHandlers}
          labelClassName={labelClasses}
          placeholder="Please type..."
          className={inputClasses}
        />
      </div>
      <hr className="my-8"></hr>
      <div className="flex flex-wrap mx-auto gap-2 max-w-xs">
        <button
          className="btn btn-primary"
          disabled={shouldSubmitBeDisabled}
          onClick={onSave}
        >
          {`Use Payment Method`}
        </button>
      </div>
    </div>
  )
}