import React, { useRef, useState } from 'react'
import { Formatters, TextInput, Validators } from 'components/forms/components'
import LoadingSpinner from 'components/LoadingSpinner'
import { useMutation } from '@apollo/client'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import { updateCustomerAddressMutation } from 'graphql/mutations'
import AnimatedHeightDiv from 'components/animatedHeightDiv/AnimatedHeightDiv'
import postDemographics from 'utils/auth/demographics'
import { reportToSentry } from 'utils/reportToSentry'
import AobInvoice from 'types/aobInvoice'
import { getToken } from 'stores/tokenStore'
import { useAccountStore } from '../state'
import HeadingWithBackButton from '../../aobPayments/components/backButton/Heading'
import { Customer } from '../../aobPayments/components/paymentCustomerDetailsBlock/types/CustomerDataType'

type ContactInfoBlockProps = {
  initialEdit?: boolean,
  invoice?: AobInvoice,
  containerClass?: string,
  onSuccess?: ( _updatedPhone: any ) => void
}

function ContactInfoBlock({invoice, onSuccess, containerClass = `bg-lightGray`, initialEdit = false }: ContactInfoBlockProps ) : JSX.Element {
  const { customer, setCustomer } = useAccountStore()
  const [ view, setView ] = useState<string>( initialEdit ? `edit` : `read` )
  const [ loading, setLoading ] = useState<boolean>( false )
  const [ error, setError ] = useState<string | null>( null )
  const [ phone, setPhone ] = useState<string | undefined>( invoice?.phone_number ?? customer?.data?.addresses?.length ? customer?.data?.addresses[0].telephone : `` )
  const [ latestPhoneSave, setLatestPhoneSave ] = useState<string | undefined>( invoice?.phone_number ?? customer?.data?.addresses?.length ? customer?.data?.addresses[0].telephone : `` )

  // if we don't have an address.default_shipping, we should not call this function
  const [ updatePatientPhone ] = useMutation( updateCustomerAddressMutation, {
    onCompleted: ( data ) => {
      setCustomer({
        data: {
          ...( customer.data as Customer ),
          addresses: [
            data.updateCustomerAddress,
            ...( customer.data?.addresses || [] )
          ]
        },
        error: undefined,
        loading: false
      })
      setLatestPhoneSave( phone )
      setLoading( false )
      setView( `read` )
      if ( onSuccess ) onSuccess({
        telephone: phone
      })
    },
    onError: ( error ) => {
      setLoading( false )
      setError( `Update contact info unsuccessful. Please try again or reach out to support at ${AF_SUPPORT_PHONE_NUMBER}.` )
      setView( `read` )

      reportToSentry( new Error ( `update patient phone error -> ContactInfoBlock`, {
        cause: error
      }) )
    }
  })

  const phoneRef = useRef<HTMLInputElement>( null )

  const handleChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    setPhone( e.target.value )
  }

  const handleSaveContactInfo = async () => {
    if ( !Validators.phone( phone ) && phoneRef?.current ) return phoneRef.current.focus()
    setError( null )
    setLoading( true )
    const searchParams = new URLSearchParams( window.location.search )
    const hash = searchParams.get( `sgh` ) ?? searchParams.get( `txt` )
    const auth = hash ? `PatientHash ${hash}` : `Bearer ${getToken()}`
    postDemographics( auth, {
      phone
    })
      .then( response => {

        if ( !response ) {
          setView( `read` )
          return setError( `Update shipping address info unsuccessful. Please try again or reach out to support at ${AF_SUPPORT_PHONE_NUMBER}.` )
        }

        const displayAddress = customer?.data?.addresses?.find( ( address: any ) => {
          return address?.default_shipping
        })

        if ( displayAddress?.id ) {

          updatePatientPhone({
            variables: {
              id: displayAddress.id,
              customerAddressInput: {
                telephone: phone
              }
            }
          })
        }

        setView( `read` )
      })
      .catch( ( error ) => {
        reportToSentry( `AOB: There was an exception when trying to update a patients demographics on payments page`, {
          hash,
          error: error?.name,
          attemptedUpdateNumber: phone
        })
        setLoading( false )
        if ( !initialEdit ) setView( `read` )

        return setError( `Update contact info unsuccessful. Please try again or reach out to support at ${AF_SUPPORT_PHONE_NUMBER}.` )
      })
      .finally( () => {
        setLoading( false )
      })
  }

  return (
    <div className={`p-5 ${containerClass} rounded-xl flex flex-col text-lg max-w-xl w-full relative`}>
      <AnimatedHeightDiv
        display
        heightDependencies={[ view, loading ]}
      >
        <>
          {
            view === `edit` ?
              <div className="flex flex-col items-center">

                <HeadingWithBackButton
                  headingText="Contact"
                  headingClassName="font-base mb-1 text-lg"
                  onBackClick={() => setView( `read` )}
                  shouldHideBackButton={initialEdit}
                />

                <div className="w-3/4 mb-5">
                  {
                    loading ?
                      <div className="my-5">
                        <LoadingSpinner />
                      </div>
                      :
                      <TextInput
                        label="Phone:"
                        labelClassName="block text-left font-light ml-5"
                        name="phone"
                        value={phone}
                        onChange={handleChange}
                        validator={{
                          function: Validators.phone,
                          failureMessage: `Please input a valid phone number`
                        }}
                        formatter={{
                          function: Formatters.phone
                        } as any}
                        reference={phoneRef}
                        onBlur={undefined}
                        id={undefined}
                      />
                  }
                </div>
                {
                  error &&
                  <p className="text-error my-5">{error}</p>
                }
                <button className="btn-secondary max-w-xs" disabled={loading || phone === latestPhoneSave} onClick={handleSaveContactInfo}>{`Save Contact`}</button>
              </div>
              :
              <>
                <p className="font-base mb-1 text-lg text-center">{`Contact:`}</p>
                <p className="font-light text-lg truncate text-center">{`Cell: ${phone ?? ``}`}</p>
                {
                  error &&
                  <p className="text-error my-5">{error}</p>
                }
                {
                  !initialEdit &&
                  <p className="underline decoration-deepSleepBlue text-deepSleepBlue text-lg max-w-fit my-2 mx-auto">
                    <span className="cursor-pointer" onClick={() => { return setView( `edit` ) }}>{`Edit Contact Info`}</span>
                  </p>
                }
              </>
          }
        </>
      </AnimatedHeightDiv>
    </div>
  )
}

export default ContactInfoBlock