import React from 'react'
import PropTypes from 'prop-types'

import { searchImg, icoX } from 'resources/images'


const MainSearchIcon = ({ displaySearchIcon, handleCancel, searchTerm }) => {
  if ( displaySearchIcon ) {
    return (
      <div>
        {searchTerm ? (
          <a
            href={`${process.env.REACT_APP_MAGENTO_2_BASE_URL}/catalogsearch/result/?q=${searchTerm}`}
            target="_self" rel="noreferrer"
          >
            <img
              className="w-4 h-4"
              src={searchImg}
              alt="Search Image"
            />
          </a>
        ) : (
          <img
            className="w-4 h-4"
            src={searchImg}
            alt="Search Image"
          />
        )}
      </div>
    )
  }

  return (
    <button
      type="button"
      className="w-4 focus:outline-none"
      {...( handleCancel && {
        onMouseDown: handleCancel
      })}
    >
      <span className="block">
        <img
          src={icoX}
          className="w-4 h-4 cursor-pointer"
          alt="close icon"
        />
      </span>
    </button>
  )
}
MainSearchIcon.propTypes = {
  displaySearchIcon: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func,
  searchTerm: PropTypes.string
}

export default MainSearchIcon
