import { CommerceDetailBlock } from "./"
import { ResupplyOption } from "modules/resupply2/types"

export default function CommerceDetailCheckbox({item, isChecked, onChange, minimalDetails = false} : {item: ResupplyOption, isChecked: boolean, onChange: () => void, minimalDetails?: boolean }) : JSX.Element | null {

  return (
    <div key={item.hcpc_id} className="flex mb-8 w-8/12 mx-auto cursor-pointer" onClick={onChange}>
      <input
        type="checkbox"
        name={item.description}
        id={`${item.magento_sku}`}
        onChange={() => { return }}
        className="mt-2 mr-4 self-start"
        checked={isChecked}
        value={item.max_qty}
      />
      <label className="cursor-pointer">
        <CommerceDetailBlock
          productName={item.description}
          shipDate={!minimalDetails ? item.Can_Ship_Date : undefined}
          productQty={item.max_qty}
          imgSrc={item.imageUrl}
        />
      </label>
    </div>
  )
}