import React, { useState, useRef, useEffect } from 'react'

interface HeightTransitionProps {
  children: React.ReactNode;
}

const HeightTransition = ({ children }: HeightTransitionProps ) => {
  const [ height, setHeight ] = useState( 0 )
  const contentRef = useRef<HTMLDivElement>( null )

  useEffect( () => {
    if ( contentRef.current ) {
      const resizeObserver = new ResizeObserver( entries => {
        for ( const entry of entries ) {
          setHeight( entry.contentRect.height + 40 ) // add extra height to smooth out height transition
        }
      })

      resizeObserver.observe( contentRef.current )

      // Set initial height to 0
      setHeight( 0 )

      setTimeout( () => {
        setHeight( contentRef.current?.scrollHeight ?? 0 )
      }, 10 )

      return () => resizeObserver.disconnect()
    }
  }, [] )

  return (
    <div
      style={{
        height: `${height}px`,
        transition: `height 0.6s ease-in-out`
      }}
    >
      <div ref={contentRef}>
        {children}
      </div>
    </div>
  )
}

export default HeightTransition