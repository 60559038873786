import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import playButton from 'resources/images/intermediate-svg/icon-video.svg'

export const StyledVideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 100%;

  iframe, img {
    height: 100%
  }
`

export const StyledPlaceholderImgContainer = styled( StyledVideoContainer )`
  ::before  {
    content: "";
    display: block;
    width: 54px;
    height: 54px;
    background: url("${playButton}");
    background-size: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
`

/** YouTube Player */
const YouTubeEmbed = ( props ) => {
  return (
    <StyledVideoContainer>
      <iframe
        title={props.title} className="absolute w-full min-h-full top-0 left-0"
        src={props.src ?? `https://www.youtube.com/embed/${props.videoID}?autoplay=${props.autoPlay ? 1 : 0}&start=${props.startTime ?? `0`}`}
        autoPlay={props.autoPlay}
      />
    </StyledVideoContainer>
  )
}
export default YouTubeEmbed

YouTubeEmbed.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  videoID: PropTypes.string, // YouTube VideoID, an alternate to using a video source (props.src)
  autoPlay: PropTypes.bool,
  startTime: PropTypes.string // time in seconds at which the video should start
}

/** YouTube Image Placeholder. Will render an img based on props.imgSrc or a YouTube thumbnail based on yt videoID */
export const YouTubeImgPlaceholder = ( props ) => {
  return (
    <StyledPlaceholderImgContainer onClick={props.onClick} >
      <img
        width="1280"
        height="720"
        id="yt-img"
        alt={props.title}
        src={props.imgSrc ?? `https://img.youtube.com/vi/${props.videoID}/sddefault.jpg`}
        className="absolute w-full min-h-full top-0 left-0 cursor-pointer"
        loading="lazy"
      />
    </StyledPlaceholderImgContainer>
  )
}

YouTubeImgPlaceholder.propTypes = {
  title: PropTypes.string,
  imgSrc: PropTypes.string, // If not present a YouTube thumbnail will be used, based on the videoID. One of the two is required
  videoID: PropTypes.string,
  onClick: PropTypes.func.isRequired
}

/**  Renders a YouTubeImgPlaceholder as a facade video, when user clicks on the img, it will will be swapped with a video and loaded */
export const YouTubeFacade = ( props ) => {
  const [ lazy, setLazy ] = useState( true )

  function loadVideo() {
    setLazy( false )
  }

  if ( lazy ) return <YouTubeImgPlaceholder {...props} onClick={loadVideo} />

  return <YouTubeEmbed {...props} autoPlay />
}

YouTubeFacade.propTypes = {
  autoPlay: PropTypes.bool,
  title: PropTypes.string,
  videoID: PropTypes.string.isRequired, // YouTube VideoID
  imgSrc: PropTypes.string.isRequired, // An alterante to YouTube videoID, which will result in use of yt thumbnail as imgSrc
  src: PropTypes.string, // alternate to videoID for the Video
  startTime: PropTypes.string // time in seconds at which the video should start
}
