import React from 'react'
import { INSURANCE_ADVANTAGE_COPY } from './constants'
import { aeroflowAdvantageIcon } from 'resources/images'

interface InsuranceAdvantageProps {
    className?: string;
    description?: string;
    title?: string;
  }

const InsuranceAdvantage = ({ className, description, title } : InsuranceAdvantageProps ) : JSX.Element => {
  return (
    <div
      className={`bg-white sm:rounded-t-[29px] md:rounded-[29px] ${className}`}
      style={{ boxShadow: `6px 0 5px -7px #00000040, -6px 0 5px -7px #00000040` }}>
      <div className="flex items-center bg-[#BBEBFC] rounded-t-[29px] py-1 max-h-[46px]">
        <div className="pr-2">
          <img className="w-[50px]" src={aeroflowAdvantageIcon} alt="Aeroflow Advantage" />
        </div>
        <h3 className="font-normal sm:text-xl md:text-[28px]">{title || INSURANCE_ADVANTAGE_COPY.title}</h3>
      </div>
      <div className="px-5 py-[10px]">
        <p className="text-aeroflowNavy sm:text-sm md:text-base">{description || INSURANCE_ADVANTAGE_COPY.maskDescription}</p>
      </div>
    </div>
  )
}

export default InsuranceAdvantage