import { gql } from '@apollo/client'

export const updateCustomerEmailMutation = gql`
  mutation UpdateCustomerEmail($email: String!, $password: String!) {
    updateCustomerEmail(email: $email, password: $password) {
      customer {
        email
      }
    }
  }
`
