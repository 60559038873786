import React from 'react'
import TrackerStepIcon from '../../TrackerStepIcon'
import { cartIconWhite } from 'resources/images'
import StepContentRenderer from '../../StepContentRenderer'
import usePatientRouting from 'hooks/PatientRouting/usePatientRouting'

type ActionNeededStepProps = {
  stepStatus: boolean;
  isCurrentStep?: boolean;
}

function ActionNeededStep({ stepStatus, isCurrentStep } : ActionNeededStepProps ) : JSX.Element {
  const { data } = usePatientRouting()
  const hash = data?.patient_hash

  // handling the copy for this step within the component because we need to dynamically create the stepLinkUrl
  const actionNeededCopy = {
    notCompletedStepHeading: `Confirm Your Order`,
    currentStepDetails: `Confirm your order to move forward. Once your order is confirmed, we will obtain all necessary documentation that your insurance requires.`,
    stepLinkText: `Confirm order`,
    stepLinkUrl: hash ? `/payments/summary?sgh=${hash}` : ``,
    stepToolTip: `We must obtain your order confirmation first before we can move forward with gathering all necessary medical documentation that your insurance provider requires.`,
    completedStepHeading: `Order Confirmed`
  }

  return (
    <div className="flex">
      <TrackerStepIcon
        imageSrc={cartIconWhite}
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
        stepLinkUrl={actionNeededCopy.stepLinkUrl || ``}
      />
      <StepContentRenderer
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
        stepCopy={actionNeededCopy}
      />
    </div>
  )
}

export default ActionNeededStep
