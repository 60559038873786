const complianceBlogLink = `https://shop.aeroflowsleep.com/blog/10-tips-to-improve-cpap-compliance`
const complianceBlogLinkText = `Tips to improve usage`

export const usageComplianceCopy = {
  notCompletedStepHeading: `Usage Compliance Not Met`,
  currentStepDetails: `Insurance requires that you use your machine for at least 4 hours per night in order to meet your usage requirements.`,
  stepLinkText: complianceBlogLinkText,
  stepLinkUrl: complianceBlogLink,
  stepToolTip: `In order to meet the requirements for your usage compliance, you must use your machine for at least 4 hours per night for a 
    minimum of 21 days within a consecutive 30 day period.`,
  completedStepHeading: `Usage Compliance Met`,
  completedStepLinkText: complianceBlogLinkText,
  completedStepLinkUrl: complianceBlogLink,
  openLinkInNewTab: true
}

export const faceToFaceRequirementsCopy = {
  notCompletedStepHeading: `Face to Face Notes Needed from MD`,
  currentStepDetails: `These notes are required per insurance guidelines in order to pass compliance and must indicate from your doctor that you 
    are using and benefiting from the use of your machine`,
  stepToolTip: `Insurance requires that we obtain follow-up appointment notes from your doctor that state you are using and benefiting from your machine. `,
  completedStepHeading: `Face to Face Notes Completed`
}