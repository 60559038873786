import React from "react"
import { icoLeftArrowRounded, icoRightArrowRounded } from "resources/images"

type SliderCustomArrowProps = {
    children?: React.ReactNode
    infiniteCarouselScroll?: boolean;
    showDots?: boolean;
    onClick?: () => void
}

export const PrevArrow = ( props: SliderCustomArrowProps ) : JSX.Element => {
  const { onClick } = props
  return (
    <div className="slick-prev sm:!hidden lg:!block" onClick={onClick} >
      <img src={icoLeftArrowRounded} alt="Previous" />
    </div>
  )
}

export const NextArrow = ( props: SliderCustomArrowProps ) : JSX.Element => {
  const { onClick } = props
  return (
    <div className="slick-next sm:!hidden lg:!block" onClick={onClick} >
      <img src={icoRightArrowRounded} alt="Previous" />
    </div>
  )
}