import React from 'react'
import { Builder } from '@builder.io/react'
import { ImageHoverCardTypes } from '../types'
import { SleepLinearGradient } from 'components/sleepLinearGradient'
import { Link } from 'react-router-dom'

export const ImageHoverCard = ( card : ImageHoverCardTypes ) : JSX.Element => {

  const { image, title, url, openInNewTab } = card

  return (
    <>
      {image && url && (
        <div
          className="cursor-pointer aspect-square flex flex-col sm:w-[calc(50%-10px)] lg:w-[calc(25%-20px)] hover:scale-110 ease-out duration-300" // using calc to maintain gutter
          key={title}
        >
          <Link to={url} target={openInNewTab ? `_blank` : `self`} rel={openInNewTab ? `noopener noreferrer` : ``} >
            {title && (
              <div className="mb-2 bg-transparent">
                <p className="sm:text-sm md:text-2xl font-medium text-graphite">{title}</p>
                <SleepLinearGradient height={2} />
              </div>
            )}
            <div>
              <img src={image} alt={title} className="rounded w-full" />
            </div>
          </Link>
        </div>
      )}
    </>
  )
}

Builder.registerComponent( ImageHoverCard, {
  name: `ImageHoverCard`
})

export default ImageHoverCard