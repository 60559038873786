import { useSnowdogMenusStore } from './state'
import { useQuery } from '@apollo/client'
import { snowdogMenusQuery } from '../../graphql/queries'
import { reportToSentry } from '../../utils/reportToSentry'
import { parseSnowdogMenu } from './util'
import { SnowdogMenusData } from './snowdogMenusData'


export function fetchSnowdogMenus() {
  const { setMenus } = useSnowdogMenusStore()

  useQuery( snowdogMenusQuery, {
    onCompleted: ( data ) => {
      setMenus({
        data: parseSnowdogMenu( data ),
        error: undefined,
        loading: false
      })
    },
    onError: ( error ) => {
      setMenus({
        data: parseSnowdogMenu( SnowdogMenusData ),
        error: undefined,
        loading: false
      })
      reportToSentry( `snowDogMenusQuery Error -> fetchSnowdogMenus`, {
        error: error?.message,
        jsonError: JSON.stringify( error )
      })
    }
  })
}