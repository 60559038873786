import React from 'react'
import TrackerStepIcon from '../../TrackerStepIcon'
import { truckWhite } from 'resources/images'
import StepContentRenderer from '../../StepContentRenderer'
import { convertIsoDateToDateString } from 'components/accountTracker/utils/utils'

type OrderShippedStepProps = {
  stepStatus: boolean;
  isCurrentStep?: boolean;
  trackingNumber: string;
  trackingLink?: string;
  shipDate?: string;
}

function OrderShippedStep({ stepStatus, isCurrentStep, trackingNumber, trackingLink, shipDate } : OrderShippedStepProps ) : JSX.Element {
  const getShipDate = convertIsoDateToDateString( shipDate || `` )

  // handling the copy for this step within the component because we need to dynamically create the trackingLinkUrl and trackingLinkText
  const orderShippedCopy = {
    notCompletedStepHeading: `Order Pending`,
    currentStepDetails: `Your order is confirmed and currently pending shipment from our warehouse. Please allow up to 48 hours for your order to be shipped.`,
    trackingLinkText: trackingNumber ? `Tracking Number: # ${trackingNumber}` : ``,
    trackingLinkUrl: trackingLink,
    stepToolTip: `Once all previous steps are complete, we will get your order shipped out!`,
    completedStepHeading: `Order Shipped`,
    dateOfShipmentText: shipDate ? `Date of Shipment: ${getShipDate.date}` : ``
  }

  return (
    <div className="flex flex-wrap">
      <TrackerStepIcon
        imageSrc={truckWhite}
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
      />
      <StepContentRenderer
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
        stepCopy={orderShippedCopy}
      />

      {stepStatus && (
        <>
          {orderShippedCopy?.dateOfShipmentText && (
            <p className="text-sm pt-2 pl-[83px] mt-[-40px] font-bold flexgrow text-stone-800">{orderShippedCopy.dateOfShipmentText}</p>
          )}
          {orderShippedCopy?.trackingLinkUrl && orderShippedCopy?.trackingLinkText && (
            <a
              href={orderShippedCopy.trackingLinkUrl}
              target="_blank"
              rel="noreferrer"
              className="text-sm text-sleepBlue font-medium pl-[83px] mt-[-12px] flexgrow"
            >
              {orderShippedCopy.trackingLinkText}
            </a>
          )}
        </>
      )}
    </div>
  )
}

export default OrderShippedStep
