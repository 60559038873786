import styled from 'styled-components'

export const GroupBtnsWrapper = styled.div.attrs({
  className: `flex gap-0.5 items-center mx-auto mb-8`
})`
  div:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  div:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`
