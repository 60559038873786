/* eslint-disable arrow-body-style */
/* eslint-disable object-property-newline */
/* eslint-disable object-curly-newline */

import React from 'react'
import PropTypes from 'prop-types'
import { animated, a, useSpring, useTrail } from 'react-spring'

/* Used on the menu items */
export const opacityTransformSpring = ( ) => {
  return useSpring({
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    },
    reset: false
  })
}

export const OpacityTransform = ( props ) => {
  const style = opacityTransformSpring()

  return (
    <animated.div style={style} {...props}>
      {props.children}
    </animated.div>
  )
}

OpacityTransform.propTypes = {
  children: PropTypes.node
}

export const leftTransformSpring = ( ) => {
  return useSpring({
    from: {
      transform: `translate(-500px)`
    },
    to: {
      transform: `translate(0px)`
    },
    reset: true
  })
}

export const LeftTransform = ( props ) => {
  const style = leftTransformSpring()

  return (
    <animated.div style={style} {...props}>
      {props.children}
    </animated.div>
  )
}

LeftTransform.propTypes = {
  children: PropTypes.node
}

/* Causes a seires of children elements to appear in a trailing dropdown animation
*  This is used in the account dropdown
*/
export const TrailDrop = ({ open, children, ...props }) => {
  const items = React.Children.toArray( children )
  const trail = useTrail( items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: open ? 1 : 0,
    x: open ? 0 : 0,
    height: open ? `auto` : 0,
    from: { opacity: 0, x: 5, height: 0 }
  })

  return (
    <div {...props}>
      <div>
        {trail.map( ({ x, height, ...rest }, index ) => (
          <a.div
            key={items[index]}
            style={{ ...rest, transform: x.interpolate( ( x ) => `translate3d(0,${x}px,0)` ) }}
          >
            <a.div style={{ height }}>{items[index]}</a.div>
          </a.div>
        ) )}
      </div>
    </div>
  )
}

TrailDrop.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node
}

export default animated
