import React, { useState } from 'react'
import { ResupplyOption } from '../types'
import { PagePropsAbstract } from 'components/pageNavigator'
import { CommerceDetailBlock, CommerceDetailCheckbox } from 'components/resupply2'
import { useResupplySetting } from '../hooks'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import LoadingSpinner from 'components/LoadingSpinner'
import { replacementMaskCopy, replacementMaskCopy2, submitMaskReplacement } from './utils'
import { reportToSentry } from 'utils/reportToSentry'
import { useAddressStore } from 'components/addressBlock'

function ReplacementMaskSelection({ ...pageProps } : PagePropsAbstract ) {

  const [ selectedReplacementMask, setSelectedReplacementMask ] = useState<ResupplyOption | null>( null )
  const [ loading, setLoading ] = useState<boolean>( false )
  const [ error, setError ] = useState<string>( `` )
  const [ skip, setSkip ] = useState<boolean>( false )

  const { firstName, lastName } = useAddressStore( store => store.address )
  const { patientId } = useResupplySetting()[0]

  const { resupplyOptions, replaceMaskOptions, triggerRefetchOptions } = pageProps.resupplyOptionResponse

  const maskToReplace = resupplyOptions.find( ( item: ResupplyOption ) => { return item.mask_change_eligible })

  const handleReplaceMask = async () => {
    if ( skip ) return pageProps.nextPage()
    if ( selectedReplacementMask && patientId ) {
      setLoading( true )
      const submitMaskResponse = await submitMaskReplacement( selectedReplacementMask.hcpc_id, patientId )
        .catch( ( error: Error ) => {
          setLoading( false )
          reportToSentry( new Error( `Resupply 2.0: Error submitting mask swap`, {
            cause: error
          }), {
            patientId,
            selectedReplacementMask: JSON.stringify( selectedReplacementMask ),
            maskToReplace: JSON.stringify( maskToReplace )
          })

          return setError( `We encountered an error attempting to swap your masks. Please try again or reach out to customer service at ${AF_SUPPORT_PHONE_NUMBER}.` )

        })

      setLoading( false )

      if ( submitMaskResponse?.meta?.status === `OK` && !submitMaskResponse?.errors?.length ) return triggerRefetchOptions()

      reportToSentry( new Error( `Resupply 2.0: Error submitting mask swap` ), {
        patientId,
        selectedReplacementMask: JSON.stringify( selectedReplacementMask ),
        maskToReplace: JSON.stringify( maskToReplace ),
        submitMaskResponse: JSON.stringify( submitMaskResponse )
      })

      return setError( `We encountered an error attempting to swap your masks. Please try again or reach out to customer service at ${AF_SUPPORT_PHONE_NUMBER}.` )
    }

    return setError( `We encountered an error attempting to swap your masks. Please try again or reach out to customer service at ${AF_SUPPORT_PHONE_NUMBER}.` )
  }

  const toggleSkip = () => {
    if ( !skip ) setSelectedReplacementMask( null )
    setSkip( !skip )
  }

  const selectReplacementMask = ( replacementMask: ResupplyOption ) => {
    setSkip( false )
    setSelectedReplacementMask( replacementMask )
  }

  if ( resupplyOptions.length === 0 ) return <p className="text-center my-10">{`There are currently no resupply options available`}</p>

  if ( loading ) return (
    <div className="my-24">
      <LoadingSpinner
        message="Please hold tight while we submit your mask swap..."
        messageStyling="font-light text-lg text-center my-5"
      />
    </div>
  )

  return (
    <div className="flex flex-col justify-center items-center mt-10 mb-16">
      <div className="flex flex-col items-center px-5 mb-10 max-w-xl text-center">
        <p className="text-center text-3xl capitalize font-sans tracking-wide">{`Hi ${firstName && lastName ? `${firstName.toLowerCase()} ${lastName.toLowerCase()}!` : ` There!`}`}</p>
        <p className="font-light text-lg my-5">
          <span className="font-bold text-error">{`Important Supplies Alert: `}</span>
          {replacementMaskCopy}
        </p>
        <p className="font-light text-lg">{replacementMaskCopy2}</p>
      </div>
      <div>
        <p className="text-center mb-8 text-xl">{`Recalled Mask`}</p>
        {
          maskToReplace &&
          <CommerceDetailBlock
            productName={maskToReplace.description}
            productQty={maskToReplace.max_qty}
            imgSrc={maskToReplace.imageUrl}
          />
        }
      </div>

      <div className="flex flex-col items-center justify-center mt-16 w-fit">
        <p className="text-center mb-8 text-xl">{`Replacement Mask Options`}</p>
        {
          replaceMaskOptions?.length &&
          replaceMaskOptions.map( ( replacementMask: ResupplyOption ) => {
            return <CommerceDetailCheckbox
              key={replacementMask.magento_sku}
              item={replacementMask}
              isChecked={replacementMask.magento_sku === selectedReplacementMask?.magento_sku}
              onChange={() => { selectReplacementMask( replacementMask ) }}
              minimalDetails
            />
          })
        }
      </div>
      <div className="flex mt-8 mx-auto cursor-pointer items-center justify-center" onClick={toggleSkip}>
        <input
          type="checkbox"
          name="skip"
          onChange={() => { return }}
          className="mr-4 self-start"
          checked={skip}
        />
        <label className="cursor-pointer">
          {`I do not want to replace my mask`}
        </label>
      </div>
      <div className="max-w-xs w-full mx-auto mt-16 mb-5 flex flex-col items-center gap-4">
        <button
          className="btn-secondary"
          onClick={handleReplaceMask}
          disabled={!selectedReplacementMask && !skip}
        >
          {`Next`}
        </button>
        {
          error &&
          <p className="text-center mt-5 text-error">{error}</p>
        }
      </div>
    </div>
  )
}

export default ReplacementMaskSelection