import React from 'react'
import ProductCarousel from './productCarousel/ProductCarousel'
import { TextInput, CheckboxInput, Formatters } from 'components/forms/components'
import { useMediaQuery } from 'react-responsive'

interface MachineQuickViewProps {
  machineData: {
    cpapMachineModel: string;
    machineImages: {
      items: string[]
    };
  };
  lastOrderCpapMachine: string;
  cpapDateUnknown: boolean;
  displayErrors: boolean;
  handleUpdateLastOrderMachine: ( _e: React.ChangeEvent<HTMLInputElement> ) => void;
  handleCheck: () => void;
  handleBlur: () => void;
  onMachineChange: ( _lastOrderCpapMachine: string | null ) => void;
}

const MachineQuickView: React.FC<MachineQuickViewProps> = ({
  machineData,
  lastOrderCpapMachine,
  cpapDateUnknown,
  displayErrors,
  handleUpdateLastOrderMachine,
  handleCheck,
  handleBlur,
  onMachineChange
}) => {
  const isMobile = useMediaQuery({
    query: `(max-width: 976px)`
  })

  return (
    <div className="flex sm:flex-col lg:flex-row sm:items-start lg:items-center">
      <ProductCarousel productImages={machineData.machineImages} />
      <div className="sm:w-full lg:w-1/2 sm:mt-4 sm:pl-0 lg:pl-10 flex flex-col sm:max-w-none lg:max-w-[588px]">
        {!isMobile &&
          <h2 className="text-[#4D4C4D] text-[42px] font-inter font-semibold leading-[47px] pb-7">{machineData.cpapMachineModel}</h2>
        }
        <label htmlFor="lastOrderCpapMachine" className="text-[#4D4C4D] text-base mb-3">
          {`What year did you receive your current CPAP machine?`}
          <span className="text-[#FF0000] inline-block">{`*`}</span>
        </label>
        <div className="sm:max-w-none lg:max-w-[421px] flex items-center flex-wrap sm:justify-between lg:justify-start">
          <div className="flex-1 sm:max-w-[138px] lg:max-w-[216px] sm:mr-2 lg:mr-5">
            <TextInput
              type="tel"
              name="lastOrderCpapMachine"
              errorMessageClassName="text-error text-center text-sm"
              value={lastOrderCpapMachine}
              errorMessage={displayErrors ? `Please enter a valid 4-digit year or leave blank.` : ``}
              onChange={handleUpdateLastOrderMachine}
              onBlur={handleBlur}
              formatter={{
                function: Formatters.dateYYYY,
                onBlur: true
              }}
              placeholder="YYYY"
              maxLength={4}
              minLength={4}
              required={false}
            />
          </div>
          <CheckboxInput
            name="dontKnowLastOrderDate"
            wrapperClassName="flex items-center mb-2"
            className={`w-10 h-10 sm:mr-1 lg:mr-[14px] border border-[#c2c2c2] rounded-[0.375rem] cursor-pointer ${!cpapDateUnknown ? `appearance-none` : ``}`}
            labelClassName="cursor-pointer"
            onChange={handleCheck}
            value={cpapDateUnknown}
            data-testid="terms"
            label={`I don't know`}
          />
          <div className="sm:w-full lg:w-auto grow flex items-center sm:justify-center lg:justify-start mt-7">
            <button
              className="btn-primary sm:mx-auto lg:mx-0"
              disabled={displayErrors || !cpapDateUnknown && lastOrderCpapMachine===``}
              onClick={() => onMachineChange( lastOrderCpapMachine )}
            >
              {`This is my machine`}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MachineQuickView