import { PAYMENT_PORTAL_PATH } from 'modules/aobPayments/constants'
import { Navigate } from 'react-router-dom'
import ExternalRedirect from 'utils/ExternalRedirect'
import { HomeScreen } from 'modules/home/HomeScreen'
import { Ineligible, ThankYou, ThroughInsurance } from 'modules/qualify'
import { Login, M2LoginScreen, LegacyLoginScreen, RequestPasswordResetScreen, PasswordResetScreen, Logout, EmailCollection } from 'modules/auth'
import { NotFound } from 'modules/notFound'
import AobPayments from 'modules/aobPayments'
import { HowAeroflowSleepWorks } from 'modules/howAeroflowSleepWorks'
import SleepNewLeadsController from 'modules/sleepNewLeads/SleepNewLeadsController'
import { SNL_BASE_PATH } from 'modules/sleepNewLeads/constants'
import { EnableAccount } from 'modules/enableAccount'
import MaintenancePage from 'components/maintenance/MaintenancePage'
import { QUALIFY_PROMOTION_PATH } from 'modules/qualify/constants'
import MaintenanceThankYou from 'components/maintenance/MaintenanceThankYou'
import ResupplyController, { RESUPPLY_PORTAL_PATH, RESUPPLY_REDIRECT_PATH } from 'modules/resupply2'
import ClinicalScheduling from 'modules/clinicalScheduling/ClinicalScheduling'
import SleepPromo from 'modules/sleepPromo/SleepPromo'
import DigitalAbn from 'modules/abn/Abn'
import OneTimePassword from 'modules/auth/OneTimePassword'
import { UnAuthorized } from 'modules/aobPayments/unAuthorized'
import { InstantLoginForm } from './components/auth'
import AccountV2 from 'modules/accountV2/AccountV2'
import BuilderPagesCatchAllRoute from 'modules/builderPagesCatchAllRoute'
import NoMachineThankYou from './modules/sleepNewLeads/ThankYou/NoMachineThankYou'
import AobAccountCreation from 'modules/aobPayments/pages/AobAccountCreation'

import QualifyForm from 'modules/qualifyForm'
import ResupplyRedirect from 'modules/resupply2/ResupplyRedirect'
export const LOGIN_PATH = `/login`
export const LOGIN_M2_PATH = `/login-m2`
export const LOGIN_LEGACY_PATH= `/login-legacy/*`
export const LOGIN_OTP_PATH = `/one-time-password`
export const INSTANT_LOGIN_PATH = `/instant-login`
export const SLEEP_APNEA_DIAGNOSIS_PATH = `/home-sleep-test`
export const MAINTENANCE_PAGE_PATH = `/maintenance`
export const MAINTENANCE_PAGE_THANK_YOU_PATH = `/maintenance/thank-you`
export const CLINICAL_SCHEDULING_PATH = `/scheduling/*`
export const PROMO_PAGE_PATH = `/sleep-promo/:page/*`
export const SNL_BASE = `/new-patient-intake/sleep`
export const SNL_ALL_PAGES_PATH = `/new-patient-intake/sleep/:page/*`
export const DIGITAL_ABN_PATH = `/abn`
export const UNAUTHORIZED_PAGE_PATH = `/unauthorized`
export const EMBED_QUALIFY_FORM_PATH = `/embedded-qualify`
export const MY_ACCOUNT_PATH = `/account`
export const PAP_INTAKE_MACHINE_SETUP = `/new-patient-machine-setup`
export const PSA_THANK_YOU = `/pap-psa-thank-you`
export const AOB_ACCOUNT_CREATION_PATH = `/payments/thank-you/create-account`
export const TERMS_AND_CONDITIONS_PATH = `/terms-and-conditions`

export const SHOULD_DISABLE_INSTANT_LOGIN = true // Note: InstantLogin is disabled until we can come up with a solution for non-magento patients attempting to login
export const HEALTHTAP_FEATURE_FLAG = true

const routes = [
  {
    path: `/`,
    element: <HomeScreen />

  },
  {
    path: `/homepage`, // may remove this route in the future, this path was for a homepage ab test, some users may have /homepage bookmarked
    element: <Navigate to="/" />
  },
  {
    path: MAINTENANCE_PAGE_THANK_YOU_PATH,
    element: <MaintenanceThankYou />
  },
  {
    path: MAINTENANCE_PAGE_PATH,
    element: <MaintenancePage />
  },
  {
    path: `/villages`,
    element: <Navigate to={`/?utm_source=villages&utm_medium=print&utm_campaign=18694_VillagesAd`} />
  },
  {
    path: `/cliffs`,
    element: <Navigate to={`/?utm_source=cliffs&utm_medium=print&utm_campaign=18807_CliffsMag`} />
  },
  {
    path: `/mcconnellgolf`,
    element: <Navigate to={`/?utm_source=mcconnell&utm_medium=print&utm_campaign=18876_McConnellsGolf`} />
  },
  {
    path: `/griffon`,
    element: <Navigate to={`/?utm_source=griffon&utm_medium=print&utm_campaign=18898_GriffonMag`} />
  },
  {
    path: `/blog`,
    element: <ExternalRedirect to={`${process.env.REACT_APP_MAGENTO_2_BASE_URL}/blog`} />
  },
  {
    path: `/thank-you`,
    element: <ThankYou />
  },
  {
    path: `/refer/thank-you`,
    element: <ThankYou />
  },
  {
    path: `/promo/thank-you`,
    element: <ThankYou />
  },
  {
    path: LOGIN_PATH,
    element: <Login />
  },
  {
    path: LOGIN_M2_PATH,
    element: <M2LoginScreen />
  },
  {
    path: LOGIN_LEGACY_PATH,
    element: <LegacyLoginScreen />
  },
  {
    path: LOGIN_OTP_PATH,
    element: <OneTimePassword />
  },
  {
    ...( SHOULD_DISABLE_INSTANT_LOGIN ? {} : {
      path: INSTANT_LOGIN_PATH,
      element: <InstantLoginForm />
    })
  },
  {
    path: `/request-password-reset`,
    element: <RequestPasswordResetScreen />
  },
  {
    path: `/password-reset`,
    element: <PasswordResetScreen />
  },
  {
    path: `/logout`,
    element: <Logout />
  },
  {
    path: MY_ACCOUNT_PATH,
    element: <AccountV2 />,
    authRoute: true
  },
  {
    path: CLINICAL_SCHEDULING_PATH,
    element: <ClinicalScheduling />
  },
  {
    path: PAYMENT_PORTAL_PATH,
    element: <AobPayments />,
    authRoute: !SHOULD_DISABLE_INSTANT_LOGIN // Instant Login needs AOB to be an authRoute
  },
  {
    path: `${SNL_BASE_PATH}/*`,
    element: <SleepNewLeadsController />
  },
  {
    path: PROMO_PAGE_PATH,
    element: <SleepPromo />
  },
  {
    path: `/qualify-through-insurance/*`,
    element: <ThroughInsurance />
  },
  {
    path: EMBED_QUALIFY_FORM_PATH,
    element: <QualifyForm isEmbedded />
  },
  {
    path: `${QUALIFY_PROMOTION_PATH}/*`,
    element: <ThroughInsurance />
  },
  {
    path: DIGITAL_ABN_PATH,
    element: <DigitalAbn />
  },
  {
    path: `/ask-a-cpap-clinician`,
    element: <Navigate to={`https://shop.aeroflowsleep.com/insurance-rx-resources`} />
  },
  {
    path: `/how-aeroflow-sleep-works`,
    element: <HowAeroflowSleepWorks />
  },
  {
    path: `/sleep-apnea-diagnosis`,
    element: <Ineligible />
  },
  {
    path: RESUPPLY_REDIRECT_PATH,
    element: <ResupplyRedirect />,
    authRoute: true
  },
  {
    path: RESUPPLY_PORTAL_PATH,
    element: <ResupplyController />,
    authRoute: true
  },
  {
    path: `/enable-account`,
    element: <EnableAccount />
  },
  {
    path: `/email-collect`,
    element: <EmailCollection />
  },
  {
    path: PAP_INTAKE_MACHINE_SETUP,
    element: <NoMachineThankYou />
  },
  {
    path: AOB_ACCOUNT_CREATION_PATH,
    element: <AobAccountCreation />
  },
  {
    path: `/:contentPage`,
    element: <BuilderPagesCatchAllRoute />
  },
  {
    path: UNAUTHORIZED_PAGE_PATH,
    element: <UnAuthorized />
  },
  {
    path: `*`,
    element: <NotFound />
  }
]

export default routes