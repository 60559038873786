import React from 'react'
import PropTypes from 'prop-types'
import { account_img_blue } from 'resources/images'
import { useTokenStore } from 'stores/tokenStore'
import { useAccountStore } from '../../modules/accountV2/state'

const AccountMenuButton = ({isMobile, menuIsActive}) => {
  const { customerToken } = useTokenStore()
  const { customer } = useAccountStore()

  const getCustomerInitials = () => {
    return `${customer?.data?.firstname.charAt( 0 )}${customer?.data?.lastname.charAt( 0 )}`
  }

  if ( isMobile && menuIsActive ) return null

  if ( customerToken ) {
    return (
      <div
        className="flex items-center"
      >
        <button type="button" className="focus:outline-none cursor-pointer min-w-7">
          <img
            src={account_img_blue}
            width="27"
            height="27"
            className="object-cover" alt="Account Menu Button"
          />
        </button>
        {customer?.data && (
          <p className="text-deepSleepBlue sm:text-xs md:text-base">
            {isMobile ? (
              <>
                {`Hello,`} <br />
                {getCustomerInitials()}
              </>
            ) : (
              `Hello ${getCustomerInitials()}`
            )}
          </p>
        )}
      </div>
    )
  }
}
AccountMenuButton.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  menuIsActive: PropTypes.bool.isRequired
}

export default AccountMenuButton
