import { BankPaymentMethod, CardPaymentMethod, IPaymentMethod } from "components/payments/types"

export type RecurringDetails = {
  intervalLength?: number,
  totalOccurrences?: number,
  startDate?: string | Date
}

export type AdditionalDetails = {
  invoiceNumber?: string,
  [property: string] : any
} & RecurringDetails

export function buildChargeBody( amount : string, paymentMethod : IPaymentMethod, additionalDetails?: AdditionalDetails ) {

  const { intervalLength, startDate, totalOccurrences } = additionalDetails as AdditionalDetails

  // ach payment method is formatted exactly how the endpoint is expecting
  const payMethodFields = ( paymentMethod as CardPaymentMethod )?.cardNumber ? {
    cardNumber: ( paymentMethod as CardPaymentMethod ).cardNumber.replaceAll( `-`, `` ),
    expirationDate: `${( paymentMethod as CardPaymentMethod ).expMonth}/${( paymentMethod as CardPaymentMethod ).expYear}`,
    cardCode: ( paymentMethod as CardPaymentMethod ).ccv
  } : {
    routingNumber: ( paymentMethod as BankPaymentMethod ).routingNumber,
    accountNumber: ( paymentMethod as BankPaymentMethod ).accountNumber,
    nameOnAccount: ( paymentMethod as BankPaymentMethod ).nameOnAccount
  }

  return {
    amount,
    name,
    orderDescription: name,
    // Begin Recurring Charge Details
    ...( intervalLength && { // If there is an intervalLength in additionalDetails, this is a recurring charge and we want all the other required that come along with that
      intervalLength,
      intervalUnit: `months`, // For all use-cases we use months, in the future, this hard-code could be removed
      startDate,
      totalOccurrences
    }),
    // Begin Misc Body Details
    ...additionalDetails,
    // Begin PaymentMethod
    ...payMethodFields
  }
}