import React from 'react'
import QualifyPlugBlock from 'components/qualify/QualifyPlugBlock'

const ThreeStepsBlock = () => {
  return (
    <div className="min-w-300 w-full">
      <QualifyPlugBlock />
    </div>
  )
}

export default ThreeStepsBlock
