import React from 'react'
import { Link } from 'react-router-dom'
import { BlockLinkTypes } from '../../types'

export const HeroLinkItem = ({ctaName, mobileCtaImage, ctaDetails, buttonText, url} : BlockLinkTypes ) : JSX.Element => {
  return (
    <div className="flex items-center sm:p-3 md:px-5 md:py-[10px] lg:text-center bg-white">
      {mobileCtaImage &&
        <div className="mr-[18px] lg:hidden">
          <img src={mobileCtaImage} alt={ctaName} className="max-w-28 border-solid rounded-lg" />
        </div>}
      <div>
        {ctaName&&
          <p className="sm:text-aeroflowNavy lg:text-deepSleepBlue sm:text-lg lg:text-lg font-medium">
            {ctaName}
          </p>}
        {ctaDetails &&
          <p className="text-graphite sm:text-xs lg:text-base sm:mb-[10px] md:mb-2">
            {ctaDetails}
          </p>}
        {url && buttonText &&
          <Link to={url} >
            <button
              type="button"
              className="btn-unfixed-height btn-primary !text-[#000] sm:text-[13px] !leading-5 font-normal sm:min-w-40 sm:w-max lg:py-[6px] lg:w-full sm:py-0"
            >
              {buttonText}
            </button>
          </Link>}
      </div>
    </div>
  )
}

export default HeroLinkItem