export const homeCMSIdentifiers = [
  `react-hero-block-header`,
  `react-hero-block-video`,
  `react-hero-block-subheader`,
  `react-image-stack-block-image`,
  `react-products-machine-image`,
  `react-products-machine-text`,
  `react-products-mask-image`,
  `react-products-mask-text`,
  `react-products-accessories-image`,
  `react-products-accessories-text`,
  `react-video-block-header`,
  `react-video-block-text`,
  `react-video-block-video`,
  `react-block-one-button-text`,
  `react-header-button-text`,
  `react-banner-messages`
]