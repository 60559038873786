import { AuthBorderWrap } from "components/auth"
import {EmailInput, useInputState, useValidInputs } from "components/auth/input"
import { FormButton } from "components/forms/components"
import { regexErrors } from "components/forms/constants"
import LoadingSpinner from "components/LoadingSpinner"
import { TermsCheckbox } from "components/terms"
import { AF_SUPPORT_PHONE_NUMBER } from "constants/phoneNumbers"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import postDemographics from "utils/auth/demographics"
import { reportToSentry } from "utils/reportToSentry"

export default function EmailCollection() : JSX.Element {

  const { email } = useInputState()
  const { isValidEmail } = useValidInputs()

  const [ termsChecked, setTermsChecked ] = useState( true )

  const [ loading, setLoading ] = useState( false )
  const [ success, setSuccess ] = useState<boolean>( false )
  const [ errorMessage, setErrorMessage ] = useState<string>( `` )

  const navigate = useNavigate()

  const handleFormSubmit = ( e : React.FormEvent ) => {
    e.preventDefault()
    if ( !termsChecked ) return setErrorMessage( regexErrors.terms )
    setLoading( true )

    const urlParams = new URLSearchParams( window.location.search )
    const patientHash = urlParams.get( `sgh` )
    if ( !patientHash ) {
      return navigate( `/`, {
        replace: true
      })
    }

    postDemographics( `PatientHash ${patientHash}`, {
      emailAddress: email
    }).then( success => {
      setSuccess( success )
      setErrorMessage( success ? `` : `Something went wrong processing your request. If this issue persists, please contact customer support at ${AF_SUPPORT_PHONE_NUMBER}.` )
    })
      .catch( error => {
        reportToSentry( `Error occurred updating patient demographics within Email Collection`, {
          error: JSON.stringify( error )
        })
        setErrorMessage( `Something went wrong processing your request. If this issue persists, please contact customer support at ${AF_SUPPORT_PHONE_NUMBER}.` )
      })
      .finally( () => { setLoading( false ) })
  }

  if ( success ) {
    return (
      <AuthBorderWrap>
        <h3 className="text-center">{`Thank you!`}</h3>
        <p className="text-center my-3">{`Your email address will be used to receive important updates on your Aeroflow Sleep order. This email address will also be used to access your Aeroflow Sleep account online in the future.`}</p>
      </AuthBorderWrap>
    )
  }

  return (
    <AuthBorderWrap>
      <form onSubmit={handleFormSubmit}>
        <p className="text-center my-3">{`Enter your email address to receive important updates on your Aeroflow Sleep order. This email address will also be used to access your Aeroflow Sleep account online in the future.`}</p>
        <EmailInput />
        <TermsCheckbox checked={termsChecked} setChecked={setTermsChecked} />
        {errorMessage && <p className="text-error text-center">{errorMessage}</p>}
        <FormButton className="btn-primary" disabled={!isValidEmail || loading || !termsChecked}>{loading ? <LoadingSpinner height="2rem" width="2rem" rings={3} /> : `Submit`}</FormButton>
      </form>
    </AuthBorderWrap>
  )
}