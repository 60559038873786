import React from 'react'
import { icoInfoAob } from 'resources/images'
import PropTypes from 'prop-types'

function EstimateTooltipTemplate({header, details}) {

  return (
    <>
      <div className="flex justify-between">
        <div className="flex w-fit">
          <img
            src={icoInfoAob}
            alt="ico-info"
            className="inline mr-3 object-contain"
            width={21}
          />
          <h4 className="text-base md:text-lg tracking-wide text-black text-left">{header}</h4>
        </div>
      </div>
      <p className="text-sm md:text-base text-left text-black">{details}</p>
    </>
  )
}

EstimateTooltipTemplate.propTypes = {
  header: PropTypes.string,
  details: PropTypes.string
}

export default EstimateTooltipTemplate