import { useEffect, useState } from "react"
import { PrescriptionExpired, NewDoctorRequired } from "."
import { PagePropsAbstract } from 'components/pageNavigator'
import { useResupplySetting } from "../hooks"
import DoctorInfo from "components/doctorBlock/types/DoctorInfo"
import LoadingSpinner from "components/LoadingSpinner"

type DoctorProps = PagePropsAbstract
export default function DoctorController( props : DoctorProps ) {

  const { updateDoctorRequired, doctorFirstName, doctorLastName, doctorNpi, doctorPhone } = useResupplySetting()[0]

  const [ doctorOnLoad, setDoctorOnLoad ] = useState<DoctorInfo | undefined | null>( undefined )
  const [ doctorComplete, setDoctorComplete ] = useState<boolean>( false )

  useEffect( () => {
    if ( doctorFirstName && doctorLastName && doctorPhone ) {
      const doctorFormatted = {
        firstName: doctorFirstName,
        lastName: doctorLastName,
        npi: doctorNpi,
        phone: doctorPhone
      }
      setDoctorOnLoad( doctorFormatted )
    } else setDoctorOnLoad( null )
  }, [] )

  if ( doctorOnLoad === undefined ) return (
    <div className="my-24">
      <LoadingSpinner
        messageStyling="font-light text-lg text-center my-5"
        message="Please hold tight while we get your doctor's information..."
      />
    </div>
  )

  return (
    <>
      {
        updateDoctorRequired !== `Required` ?
          <PrescriptionExpired
            doctorOnLoad={doctorOnLoad}
            setDoctorComplete={setDoctorComplete}
          />
          :
          <NewDoctorRequired
            doctorOnLoad={doctorOnLoad}
            setDoctorComplete={setDoctorComplete}
            nextPage={props.nextPage}
          />
      }
      <div className="max-w-xs w-full mx-auto mt-10 mb-5 flex flex-col items-center gap-4">
        <button className="btn-secondary" onClick={props.nextPage} disabled={!doctorComplete}>{`Next`}</button>
        <button className="btn-primary" onClick={props.prevPage}>{`Back`}</button>
      </div>
    </>
  )
}