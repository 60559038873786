import React from 'react'
import TrackerStepIcon from '../../TrackerStepIcon'
import { listWithCheckmark } from 'resources/images'
import StepContentRenderer from '../../StepContentRenderer'
import { convertIsoDateToDateString } from 'components/accountTracker/utils/utils'
import { clinicalEduApptCopy } from '../constants'
import { AF_SLEEP_CLINICIAN_PHONE_NUMBER } from 'constants/phoneNumbers'

type ClinicalEduApptStepProps = {
  stepStatus: boolean;
  isCurrentStep?: boolean;
  apptDate?: string;
  apptStatus: string;
}

function ClinicalEduApptStep({ stepStatus, isCurrentStep, apptDate, apptStatus } : ClinicalEduApptStepProps ) : JSX.Element {
  const getApptDate = convertIsoDateToDateString( apptDate || `` )

  const getCurrentStepDetailText = ( ) => {
    switch ( apptStatus ) {
    case `scheduled`:
    case `complete`:
      return clinicalEduApptCopy.apptIsScheduledDetails
    case `no show`:
      return clinicalEduApptCopy.apptNoShowDetails
    case `canceled`:
      return clinicalEduApptCopy.apptCancelledDetails
    default:
      return ``
    }
  }

  const getClinicalEduApptLinkText = ( ) => {
    switch ( apptStatus ) {
    case `scheduled`:
    case `complete`:
      return `Your Clinical Education Appointment is Scheduled for ${getApptDate.date} at ${getApptDate.time}`
    case `no show`:
    case `canceled`:
      return AF_SLEEP_CLINICIAN_PHONE_NUMBER
    default:
      return ``
    }
  }

  const clinicalEduApptInfo = {
    notCompletedStepHeading: clinicalEduApptCopy.stepHeaderIncomplete,
    currentStepDetails: getCurrentStepDetailText(),
    stepLinkText: getClinicalEduApptLinkText(),
    stepLinkUrl: apptStatus === `no show` || apptStatus === `canceled` ? `tel:${AF_SLEEP_CLINICIAN_PHONE_NUMBER}` : ``,
    stepToolTip: clinicalEduApptCopy.stepToolTip,
    completedStepHeading: clinicalEduApptCopy.stepHeaderComplete,
    completedStepDetails: apptStatus === `no show` || apptStatus === `canceled` ? AF_SLEEP_CLINICIAN_PHONE_NUMBER : ``,
    apptDatePlainText: getClinicalEduApptLinkText()
  }

  return (
    <div className="flex flex-wrap">
      <TrackerStepIcon
        imageSrc={listWithCheckmark}
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
        stepLinkUrl={clinicalEduApptInfo.stepLinkUrl || ``}
      />
      <StepContentRenderer
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
        stepCopy={clinicalEduApptInfo}
      />

      {isCurrentStep && apptStatus === `scheduled` || apptStatus === `complete` &&(
        <>
          <p className="text-sm pt-2 font-bold pl-[82px] mt-[-40px] flexgrow w-full text-stone-800">{`${getApptDate.date}, ${getApptDate.time}`}</p>
        </>
      )}

      {isCurrentStep && apptStatus === `scheduled` &&(
        <>
          <p className="text-sm pt-2 font-bold pl-24 mt-[-40px] flexgrow w-full text-stone-800">{getClinicalEduApptLinkText()}</p>
        </>
      )}
    </div>
  )
}

export default ClinicalEduApptStep