import React from 'react'
import PropTypes from 'prop-types'
import { useReactiveVar } from '@apollo/client'
import { ErrorBoundary, useErrorHandler } from 'react-error-boundary'

import ErrorDisplay from './ErrorDisplay'
import { networkErrorVar } from 'apollo'
import { reportToSentry } from 'utils/reportToSentry'
import { getMaintenancePageStatus } from 'modules/maintenanceListener/utils'

const HandleNetworkError = () => {

  const networkError = useReactiveVar( networkErrorVar )
  const handleError = useErrorHandler()

  React.useEffect( () => {
    if ( networkError ) {

      handleError( networkError )
      networkErrorVar( null )
    }
  }, [ networkError ] )

  return null
}

const ErrorHandler = ({ children }) => {
  const [ containsError, setContainsError ] = React.useState( false )

  const setError = ( bool ) => {
    setContainsError( bool )
  }

  const isMaintenancePage = getMaintenancePageStatus()

  return (
    <ErrorBoundary
      FallbackComponent={ErrorDisplay}
      onReset={() => {
        setError( false )
      }}
      onError={( error, errorInfo ) => {

        setError( true )

        return reportToSentry( error, {
          errorInfo,
          operationName: error?.operation,
          response: error?.response
        })
      }}
    >
      {!isMaintenancePage && <HandleNetworkError />}
      {/* children will continue to render with errors unless we display render safe null */}
      {containsError ? null : children}
    </ErrorBoundary>
  )
}
ErrorHandler.propTypes = {
  children: PropTypes.node
}

export default ErrorHandler
