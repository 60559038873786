import { gql } from '@apollo/client'

export const placeOrderMutation = gql`
  mutation placeOrderMutation($input : PlaceOrderInput!) {
    placeOrder( input: $input ) {
      order { 
        order_number
      }
    }
  }
`