import PropTypes from 'prop-types'
import { OpacityTransform } from 'spring'

const Submenu = ({title, url, submenuItems, className}) => {
  return (
    <OpacityTransform className={className} >
      { title && url && <p className="mb-4 cursor-pointer"><a href={url}>{title}</a></p> }
      { title && !url && <h4 className="mb-4">{title}</h4> } {/* This should mean that we have submenuItems*/}
      <ul>
        {submenuItems?.map( submenuItem => {

          let url = submenuItem.url ?? `/#`

          if ( !url.includes( `https://` ) ) {
            // Prepend shop host
            url = `${process.env.REACT_APP_MAGENTO_2_BASE_URL}${url}`
          }

          // Hardcoded blog for now
          if ( submenuItem.title === `Blog` ) {
            url = `${process.env.REACT_APP_MAGENTO_2_BASE_URL}/blog`
          }

          return (
            <li key={submenuItem.title}>
              <a href={url}><p className="p-1">{submenuItem.title}</p></a>
            </li>
          )
        })}
      </ul>
    </OpacityTransform>
  )
}
Submenu.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  submenuItems: PropTypes.arrayOf( PropTypes.object ).isRequired,
  className: PropTypes.string
}

export default Submenu
