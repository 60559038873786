import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { cmsPageQuery } from 'graphql/queries'
import CMSContent from './CMSContent'
import LoadingSpinner from 'components/LoadingSpinner'
import { NotFound } from 'modules/notFound'
import { reportToSentry } from 'utils/reportToSentry'

// @TODO we need to add a meta description tag and make sure the shop. equivalent page has a canonical url
// see what we do in PageSaber for an example

/** Takes a urlKey that translates into an M2 identifier used to render the corresponding cms page  */
const Page = ( props ) => {

  const { data, loading, error } = useQuery( cmsPageQuery, {
    skip: !props.urlKey,
    errorPolicy: `all`,
    variables: {
      identifier: props.urlKey
    },
    onError: ( error ) => {
      reportToSentry( `cmsPagesQuery Error -> Page.js`, {
        cause: error
      })
    }
  })

  if ( loading ) {
    return (
      <div className="my-8">
        <LoadingSpinner />
      </div>
    )
  }

  if ( error || !( data?.cmsPage?.content ) ) {
    return <NotFound />
  }

  return (
    <div className="sm:ml-2 md:container md:mx-auto">
      <h2
        className="font-light text-4xl text-deepSleepBlue mb-10"
      >
        {data.cmsPage?.content_heading ?? ``}
      </h2>
      <CMSContent content={data.cmsPage.content} />
    </div>
  )
}

Page.propTypes = {
  urlKey: PropTypes.string.isRequired
}

export default Page