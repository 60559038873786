import parse from 'react-html-parser'
import { SOCIAL_MEDIA_IMAGE_MAP } from './constants'

export type LinkMapItem = {
  display: string;
  href?: string;
  html?: string;
  image?: string;
}

export const parseContent = ( content: string ) => {
  const linkMapping = new Map()
  parse( content, {
    transform: ( node ) => {
      footerTransform( node, linkMapping )
    }
  })

  return linkMapping
}

export const footerTransform = ( node: any, linkMapping: Map<string, LinkMapItem[]> ) => {

  if ( node?.attribs?.[`data-content-type`] === `mobile-accordion-item` ) {
    const containerElement = node?.parent?.parent?.children
    const titleButtonElement = containerElement?.find( ( element: any ) => element?.attribs?.type === `button` )
    const title = titleButtonElement?.children?.[0]?.data?.trim()

    const linkText = node?.children?.[0]?.children?.[0]?.children?.[0]?.data?.trim()
    const linkHref = node?.children?.[0]?.attribs?.href

    if ( title && linkText && linkHref ) {
      if ( linkMapping.has( title ) ) {
        const currentLinks = linkMapping.get( title )

        const linkExists = currentLinks?.some( link => link.display === linkText )

        if ( currentLinks && !linkExists ) {
          linkMapping.set( title, [
            ...( currentLinks as LinkMapItem[] ),
            {
              display: linkText,
              href: linkHref
            }
          ] )
        }
      } else {
        linkMapping.set( title, [{
          display: linkText,
          href: linkHref
        }] )
      }
    }
  } else if ( node?.attribs?.class === `store-information-content` ) {
    const title = node?.parent?.children?.[0]?.children?.[0]?.children?.[0]?.data?.trim()
    const html = node?.children?.[0]?.data
    if ( title && html ) {
      if ( !linkMapping.has( title ) ) {
        linkMapping.set( title, [{
          display: title,
          html
        }] )
      }
    }
  } else if ( node?.attribs?.class?.includes( `social-icons-list` ) ) {
    if ( !linkMapping.has( `Social Media` ) ) {
      const buildPlatformArray:LinkMapItem[] = []

      node?.children?.forEach( ( child: any ) => {
        const platform = child?.children?.[0]?.children?.[1]?.children?.[0]?.data
        const href = child?.children?.[0]?.attribs?.href
        const image = ( SOCIAL_MEDIA_IMAGE_MAP as { [key: string ] : string })[platform?.toLowerCase()]
        if ( platform && href && image ) {
          buildPlatformArray.push({
            display: platform,
            href,
            image
          })
        }
      })

      linkMapping.set( `Social Media`, buildPlatformArray )
    }
  }
}