import MonthSelector from 'modules/clinicalScheduling/components/MonthSelector'
import { useDayPicker, useNavigation } from 'react-day-picker'
import NextMonthButton from './NextMonthButton'
import PrevMonthButton from './PrevMonthButton'

function CustomCaption() {
  const { goToMonth, nextMonth, previousMonth, currentMonth } = useNavigation()
  const { fromMonth, toMonth } = useDayPicker()

  return (
    <div className="flex items-center justify-between">
      <PrevMonthButton previousMonth={previousMonth} goToMonth={goToMonth} />
      <MonthSelector
        fromMonth={fromMonth ?? new Date()}
        toMonth={toMonth ?? new Date()}
        selectedMonth={currentMonth}
        handleMonthSelection={goToMonth}
      />
      <NextMonthButton nextMonth={nextMonth} goToMonth={goToMonth} />
    </div>
  )
}

export default CustomCaption
