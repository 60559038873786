import React, { useEffect, useState } from 'react'
import PageNavigator from 'components/pageNavigator'
import { AppointmentTypeSelection, SchedulingError, SchedulingThankYou } from './components'
import { fetchPatientTimezone, getCurrentTimeZone, getHash } from './utils'
import { SCHEDULE_PAGE_PATH, SCHEDULE_THANK_YOU_PATH } from './utils/constants'
import { reportToSentry } from 'utils/reportToSentry'
import InternalPatient from 'types/internalPatient'
import { useNavigate } from 'react-router-dom'
import LoadingSpinner from 'components/LoadingSpinner'
import { deprecatedPatientLogin } from 'components/auth/utils'
import { CLINICAL_SCHEDULING_AOB_PATH, SUMMARY_PAGE_PATH } from 'modules/aobPayments/constants'
import AobInvoice from 'types/aobInvoice'

function ClinicalScheduling({ aobIsCompleted, invoice, nextPage }: { aobIsCompleted?: boolean, invoice: AobInvoice, nextPage?: () => void }) : JSX.Element {

  const [ scheduledVirtually, setScheduledVirtually ] = useState<boolean>( true )
  const [ error, setError ] = useState<boolean>( false )
  const [ loading, setLoading ] = useState<boolean>( true )
  const [ timeZone, setTimeZone ] = useState<string | null>( null )
  const [ patientData, setPatientData ] = useState<InternalPatient | null>( null )

  const navigate = useNavigate()
  const isAob = aobIsCompleted !== undefined

  // useMedchatWidget( `EhAxocwJpUeVHCn_83Ssnw` )

  useEffect( () => {
    if ( aobIsCompleted === false ) navigate( SUMMARY_PAGE_PATH.concat( window.location.search ) )
  }, [] )

  useEffect( () => {
    const controller = new AbortController()
    if ( patientData?.patient_id ) {
      const patientId = patientData?.patient_id
      setLoading( true )
      fetchPatientTimezone( controller.signal )
        .then( ( response ) => {
          if ( !controller.signal.aborted ) {
            setLoading( false )
            if ( response?.meta?.status === `OK` ) {
              return setTimeZone( response?.data?.length > 0 ? response.data[0].timezone : getCurrentTimeZone().name )
            } else {
              return setTimeZone( getCurrentTimeZone().name )
            }
          }
        })
        .catch( ( error ) => {
          setTimeZone( getCurrentTimeZone().name )
          if ( !controller.signal.aborted ) setLoading( false )
          reportToSentry( `Clinical Scheduling Tool: There was an exception getting the default timezone`, {
            error,
            patientId
          })
        })
    }

    return () => {
      controller.abort()
    }
  }, [ patientData ] )

  useEffect( () => {
    const controller = new AbortController()
    const hash = getHash()
    if ( !hash ) {
      setLoading( false )
      setError( true )
    }

    if ( !patientData && hash ) {
      setLoading( true )
      deprecatedPatientLogin({
        patient_hash: hash
      })
        .then( data => {
          if ( !controller.signal.aborted ) {
            setLoading( false )
            if ( data?.meta?.status !== `OK` || !data?.data ) return isAob && nextPage ? nextPage() : setError( true )
            else setPatientData( data.data )
          }
        })
        .catch( () => {
          if ( !controller.signal.aborted ) {
            setLoading( false )

            return isAob && nextPage ? nextPage() : setError( true )
          }
        })
    }

    return () => {
      controller.abort()
    }
  }, [] )

  if ( loading ) return (
    <div className="my-24 md:my-36 bg-white">
      <LoadingSpinner
        message={`Please hold tight while we get your details...`}
        messageStyling={`text-deepSleepBlue text-center mb-10 font-light md:text-lg`}
      />
    </div> )

  if ( error ) return <SchedulingError header="unauthorized" message="You are not authorized to access the clinical scheduling tool..." />

  return (
    <PageNavigator pageNames={isAob ? [ CLINICAL_SCHEDULING_AOB_PATH, SCHEDULE_THANK_YOU_PATH ] : [ SCHEDULE_PAGE_PATH, SCHEDULE_THANK_YOU_PATH ]} pageClassName={isAob ? `mt-24` : `mt-10`}>
      <AppointmentTypeSelection
        setScheduledVirtually={setScheduledVirtually}
        timeZone={timeZone}
        setTimeZone={setTimeZone}
        patientData={patientData}
      />
      <SchedulingThankYou
        scheduledVirtually={scheduledVirtually}
        invoiceNumber={invoice?.invoice_number}
      />
    </PageNavigator>
  )
}

export default ClinicalScheduling