import { useNavigate } from "react-router-dom"
import { RESUPPLY_ADDRESS_PATH } from "modules/resupply2"
import { useAddressStore } from "components/addressBlock"

export default function ShippingPreview() : JSX.Element {
  const shippingAddress = useAddressStore( state => state.address )

  const navigate = useNavigate()

  return (
    <div className="rounded-2xl bg-lightGray max-w-md mx-auto my-4 p-3">
      <div className="mx-auto text-center">
        <p className="font-bold">{`Shipping Address:`}</p>
        <p>{`${shippingAddress?.firstName} ${shippingAddress?.lastName}`}</p>
        <div>
          <p>{`${shippingAddress?.street}`}{shippingAddress?.street2 ? `, ${shippingAddress.street2}` : ``}</p>
          <p>{`${shippingAddress?.city}, ${shippingAddress?.state} ${shippingAddress?.zipCode}`}</p>
        </div>
        <p className="a" onClick={() => { return navigate( RESUPPLY_ADDRESS_PATH ) }}>{`Edit Shipping Address`}</p>
      </div>
    </div>
  )
}