import React from 'react'
import PropTypes from 'prop-types'
import CMSContent from './CMSContent'
import { useQuery } from '@apollo/client'
import { cmsBlocksQuery } from 'graphql/queries'
import { reportToSentry } from 'utils/reportToSentry'

/** Takes an array os urlKeys that translates into M2 identifiers used to render the corresponding cms blocks  */
const Blocks = ( props ) => {

  const { data, loading, error } = useQuery( cmsBlocksQuery, {
    skip: !( props?.urlKeys?.length ),
    errorPolicy: `none`,
    onError: ( error ) => {
      reportToSentry( new Error( `Marketing Page 404: A patient attempted to navigate to a promotional page that doesn't exist`, {
        cause: error
      }), {
        urlKeys: JSON.stringify( props.urlKeys )
      })
    },
    variables: {
      identifiers: props.urlKeys
    }
  })

  if ( error ) return props.fallback ?? null

  if ( loading || !( data?.cmsBlocks?.items.length ) ) return null

  return data.cmsBlocks.items.map( ( item ) => {
    if ( !item ) return

    return (
      <CMSContent
        key={item.identifier}
        content={item.content}
        fallback={props.fallback}
      />
    )
  })
}

Blocks.propTypes = {
  urlKeys:  PropTypes.arrayOf( PropTypes.string ),
  fallback: PropTypes.node
}

export default Blocks