import React from 'react'
import CpapSuppliesTracker from 'components/accountTracker/components/cpapSuppliesTracker/CpapSuppliesTracker'
import CpapComplianceStatusTracker from 'components/accountTracker/components/cpapComplianceStatusTracker/CpapComplianceStatusTracker'
import ReplacementCpapTracker from 'components/accountTracker/components/replacementCpapTracker/ReplacementCpapTracker'
import NewCpapTracker from 'components/accountTracker/components/newCpapTracker/NewCpapTracker'
import { AccountTrackerDataType } from '../types/AccountTrackerTypes'
import { extractNestedObj } from '../utils/utils'

interface AccountTrackerDisplayProps {
  trackerDetails: AccountTrackerDataType | null;
}

function AccountTrackerDisplay({ trackerDetails } : AccountTrackerDisplayProps ) : JSX.Element {
  const cpapSuppliesTrackerData = extractNestedObj( trackerDetails?.sleep_new_leads, `Sleep New Leads` )
  const cpapComplianceTrackerData = extractNestedObj( trackerDetails?.compliance, `Compliance` )
  const replaceCpapSuppliesTrackerData = extractNestedObj( trackerDetails?.replacement_cpap, `Replacement` )
  const newCpapTrackerData = extractNestedObj( trackerDetails?.new_cpap, `New CPAP` )

  return (
    <>
      { Boolean( cpapSuppliesTrackerData ) && <CpapSuppliesTracker cpapTrackerDetails={cpapSuppliesTrackerData} /> }
      { Boolean( cpapComplianceTrackerData ) && <CpapComplianceStatusTracker complianceTrackerDetails={cpapComplianceTrackerData} /> }
      { Boolean( replaceCpapSuppliesTrackerData ) && <ReplacementCpapTracker replacementCpapTrackerDetails={replaceCpapSuppliesTrackerData} /> }
      { Boolean( newCpapTrackerData ) && <NewCpapTracker newCpapTrackerDetails={newCpapTrackerData} /> }
    </>
  )
}

export default AccountTrackerDisplay