import React from 'react'
import PropTypes from 'prop-types'
import LoadingSpinner from 'components/LoadingSpinner'

function PayButtonBlock({submitAob, setOpenModal, submittingAob, blockProceed, payLaterEligible }) {

  return (
    <div className={`flex flex-col gap-7`}>
      <div className="flex flex-col">
        <button className="btn-primary !my-0 max-w-xs" onClick={submitAob} disabled={submittingAob || blockProceed}>{submittingAob ? <LoadingSpinner height="2.5rem" width="2.5rem" rings={3} /> : `Proceed to Payment`}</button>
        <p className="text-sm text-center font-light text-[#676C73] py-6">{`Payment plans available on next screen`}</p>
      </div>
      {
        payLaterEligible &&
        <button className="btn-secondary max-w-xs" disabled={submittingAob || blockProceed} onClick={() => { setOpenModal( true ) }}>{`Pay Later`}</button>
      }
    </div>
  )
}

PayButtonBlock.propTypes = {
  submitAob: PropTypes.func,
  setOpenModal: PropTypes.func,
  submittingAob: PropTypes.bool,
  blockProceed: PropTypes.bool,
  payLaterEligible: PropTypes.bool
}

export default PayButtonBlock