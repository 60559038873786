import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import CMSWidgetTooltipWrapper from 'components/cmsWidgetTooltipWrapper'

export const FaqBlogBlockWidget = ( items ) => {

  const transformWidget = ( node, identifier ) => {

    if ( node.type === `tag` && node?.attribs[`data-element`] === `mobile_image` ) return null
    if ( node.type === `tag` ) {
      node.attribs.style = ``
    }
    if ( node?.name === `img` ) {
      // change media folder from sleep to breastpumps ( should default to breastpumps but not the case in prod )
      node.attribs.src = node.attribs.src.replace( process.env.REACT_APP_MAGENTO_2_BASE_URL, process.env.REACT_APP_CMS_IMAGE_URL )
      node.attribs.loading = `lazy`
      node.attribs.width = 966
      node.attribs.height = 483
    }
    if ( node?.name === `p` ) node.attribs.class = `my-6 h-28 md:w-96`
    if ( node?.name === `a` ) {
      if ( identifier === `blog` ) node.attribs.href = `${process.env.REACT_APP_MAGENTO_2_BASE_URL}/blog`
      else node.attribs.href = `${process.env.REACT_APP_MAGENTO_2_BASE_URL}/frequently-asked-questions`
    }
  }

  return (
    <div className="flex sm:flex-col md:flex-row justify-evenly w-full max-w-full">
      <CMSWidgetTooltipWrapper
        identifier={`react-blog-widget`}
        className="grid grid-cols-1 grid-rows-2 p-1 mx-8 mt-12 justify-items-center items-center"
      >
        <>
          {
            ReactHtmlParser( items.items[0].content, {
              transform: ( node ) => { return transformWidget( node, `blog` ) }
            })
          }
        </>
      </CMSWidgetTooltipWrapper>
      <CMSWidgetTooltipWrapper
        className="grid grid-cols-1 grid-rows-2 p-1 mx-8 mt-12 justify-items-center items-center"
        identifier={`react-faq-widget`}
      >
        <>
          {
            ReactHtmlParser( items.items[1].content, {
              transform: ( node ) => { return transformWidget( node, `faq` ) }
            })
          }
        </>
      </CMSWidgetTooltipWrapper>
    </div>
  )
}

export default FaqBlogBlockWidget