import styled from 'styled-components'
import tailwindColors from 'config/tailwindColors'

const StyledErrorWrapper = styled.span`
color: ${tailwindColors.error};
font-size: 14px;
line-height: 16px;
min-height: 16px;
`

export default StyledErrorWrapper