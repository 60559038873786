import { gql } from '@apollo/client'

export const cmsPageQuery = gql`
  query cmsPage($identifier: String) {
    cmsPage(identifier: $identifier) {
      identifier
      title
      content_heading
      content
    }
  }
`
