import React from 'react'
import { Tile } from 'components/tile'
import StepQueueRenderer from '../StepQueueRenderer'
import { ActionNeededStep, InsuranceInformationStep, InsurancePriorAuthStep, OrderShippedStep, PrescriptionNeededStep, SleepApneaProfileStep } from './steps'
import LoadingSpinner from 'components/LoadingSpinner'
import OrderCanceledStep from '../sharedSteps/OrderCanceledStep'

type SleepNewLeadsType = {
    sleep_apnea_profile_complete: boolean;
    information_and_insurance_verified: boolean;
    action_needed: boolean;
    documentation_on_file: boolean;
    pa_needed: boolean;
    pa_on_file: boolean;
    order_shipped: boolean;
    tracking_number?: string;
    tracking_link?: string;
    ship_date?: string;
};

type CpapSuppliesTrackerProps = {
  cpapTrackerDetails: SleepNewLeadsType;
}

function CpapSuppliesTracker({ cpapTrackerDetails }: CpapSuppliesTrackerProps ): JSX.Element {
  const sleepApneaProfileComplete = sessionStorage.getItem( `sleep_apnea_profile_complete` ) // check if SAP was completed in current session
  const hasShipped = Boolean( cpapTrackerDetails?.order_shipped || cpapTrackerDetails?.tracking_number || cpapTrackerDetails?.tracking_link )
  const isOrderCanceled = cpapTrackerDetails?.tracking_link === `Order Cancelled`

  return (
    <Tile headerContent="My CPAP Supplies Request" id="cpap-supplies-tracker" className="account-tracker">
      {
        !cpapTrackerDetails ? <LoadingSpinner /> :
          ( isOrderCanceled ? <OrderCanceledStep /> :
            <StepQueueRenderer dynamicStepStatus>
              <SleepApneaProfileStep stepStatus={cpapTrackerDetails?.sleep_apnea_profile_complete || Boolean( sleepApneaProfileComplete )} />
              <InsuranceInformationStep stepStatus={cpapTrackerDetails?.information_and_insurance_verified} />
              <ActionNeededStep stepStatus={!cpapTrackerDetails?.action_needed} /> {/* when the action_needed property is true, the step is not complete */}
              <PrescriptionNeededStep stepStatus={cpapTrackerDetails?.documentation_on_file} />
              {cpapTrackerDetails?.pa_needed && <InsurancePriorAuthStep stepStatus={cpapTrackerDetails?.pa_on_file} />} {/* if prior auth is not needed(false), do not to show this step*/ }
              <OrderShippedStep
                stepStatus={hasShipped}
                trackingNumber={cpapTrackerDetails?.tracking_number || ``}
                trackingLink={cpapTrackerDetails?.tracking_link || ``}
                shipDate={cpapTrackerDetails?.ship_date || ``}
              />
            </StepQueueRenderer>
          )
      }
    </Tile>
  )
}

export default CpapSuppliesTracker