/* eslint-disable @typescript-eslint/no-explicit-any */
import tailwindColors from "config/tailwindColors"
import { type StylesConfig } from 'react-select'

/*
* Note: This is a style override for the react-select library to adapt it to our design system
* You can pass these two constants into their Select component as props: `theme` and `styles` respectively
*/
export const getStylesConfig = ( isError: boolean ): StylesConfig<any> => ({
  control: ( styles : any ) => ({
    ...styles,
    backgroundColor: tailwindColors.white,
    borderRadius: `6px`,
    padding: `0.2rem`,
    borderColor: isError? tailwindColors.error : tailwindColors.inputGray,
    color: isError? tailwindColors.error : tailwindColors.graphite,
    cursor: `pointer`,
    textAlign: `left`,
    paddingLeft: `1rem`,
    margin: `2px`,
    fontWeight: 400,
    ":active": {
      borderColor: tailwindColors.sleepBlue
    }
  }),
  menu: ( styles : any ) => ({
    ...styles,
    backgroundColor: tailwindColors.white
  }),
  option: ( styles : any, { isFocused } : any ) => {
    return {
      ...styles,
      backgroundColor: isFocused ? tailwindColors.secondaryBlue.tint2 : /* isSelected ? tailwindColors.deepSleepBlue :*/ tailwindColors.white,
      color: isFocused ? tailwindColors.deepSleepBlue : /* isSelected ? tailwindColors.white:*/ tailwindColors.graphite,
      cursor: `pointer`,
      textAlign: `left`
    }
  },
  placeholder: ( styles : any ) => ({
    ...styles,
    color: isError ? tailwindColors.error : `#D1D5E1`
  })
})

export const themeConfig = ( theme : any ) => ({
  ...theme,
  colors: {
    primary: tailwindColors.deepSleepBlue,
    primary25: tailwindColors.secondaryBlue.tint3
  }
})

export const createChangeEvent = ( name: string, value: string ) => {
  return {
    target: {
      name,
      value
    },
    currentTarget: {
      name,
      value
    }
  } as React.ChangeEvent<HTMLSelectElement>
}