import { gql } from '@apollo/client'

import { customerFragment } from 'graphql/fragments'

export const createCustomerMutation = gql`
  ${customerFragment}
  mutation CreateCustomerMutation($customerInput: CustomerInput!) {
    createCustomer(input: $customerInput) {
      customer {
        ...Customer
      }
      fb_event_id
    }
  }
`
