import React from 'react'
import { Builder } from '@builder.io/react'
import { NewsLetterListTypes } from './types'
import { NewsLetterList } from './components/NewsLetterList'
import { NewsLetterSignup } from './components/NewsLetterSignup'

type NewsLetterLibraryProps = {
    newsLetters: NewsLetterListTypes[];
    newsLetterListHeader: string | null;
    newsLetterSignupHeader: string | null;
    newsLetterSignupText: string | null;
    borderTop?: boolean;
    borderBottom?: boolean;
}

export const NewsLetterLibrary = ( props: NewsLetterLibraryProps ) : JSX.Element => {
  const borderClasses = `bg-gray-100 border-gray-300 ${props?.borderTop ? `border-t ` : ``}${props?.borderBottom ? `border-b` : ``}`

  return (
    <div className={`bg-gray-100 border-gray-300 ${borderClasses}`}>
      <div className="font-family--inter sm:px-5 lg:px-7 sm:py-4 lg:py-7 lg:max-w-3xl mx-auto">
        <NewsLetterList newsLetters={props?.newsLetters} newsLetterHeader={props?.newsLetterListHeader} />
        <NewsLetterSignup newsLetterSignupHeader={props?.newsLetterSignupHeader} newsLetterSignupText={props?.newsLetterSignupText} />
      </div>
    </div>
  )
}

Builder.registerComponent( NewsLetterLibrary, {
  name: `NewsLetterLibrary`,
  inputs: [
    {
      name: `newsLetterListHeader`,
      type: `text`,
      defaultValue: `Newsletter Library`,
      required: true
    },
    {
      name: `newsLetterSignupHeader`,
      type: `text`,
      defaultValue: `Newsletter`,
      required: true
    },
    {
      name: `newsLetterSignupText`,
      type: `text`,
      defaultValue: `Sign up for our Newsletter lorem ipsum dolor sin`
    },
    {
      name: `newsLetters`,
      type: `list`,
      required: true,
      subFields: [
        {
          name: `newsLetterTitle`,
          type: `text`,
          required: true
        },
        {
          name: `newsLetterUrl`,
          type: `text`,
          required: true
        }
      ]
    },
    {
      name: `borderTop`,
      type: `boolean`
    },
    {
      name: `borderBottom`,
      type: `boolean`
    }
  ]
})

export default NewsLetterLibrary