import { useState } from "react"
import { Validators } from "components/forms/components"
import { regexErrors, AssociatedFields } from "components/forms/constants"

export default function useValidate( inputRefs : Record<string, React.RefObject<HTMLInputElement | HTMLSelectElement>>, options?: { skipInputs?: string[]}) {

  const [ formErrors, setFormErrors ] = useState<Record<string, string>>({})
  const [ shouldHideErrors, setShouldHideErrors ] = useState<boolean>( true )
  const [ touched, setTouched ] = useState<Record<string, boolean>>({})

  function focusOnFirstError( newFormErrors = formErrors ) { // We pass in here due to the fact that the formErrors state is not updated immediately
    // Focus on the first error if found and if shouldHideErrors is false
    const firstError = Object.keys( newFormErrors ).find( ( key ) => newFormErrors[key]?.length > 0 )
    if ( firstError && inputRefs[firstError] ) return inputRefs[firstError].current?.focus()
  }

  /**
   *
   * @param isSubmitEvent - If a submit event, we want to evaluate all fields, even if they are untouched, and we want to focus on the first error, and we want to show the errors
   * @returns hasAnError - Returns true if there are errors present
   */
  function evaluateErrors( isSubmitEvent = false ) : boolean {
    const newFormErrors = Object.keys( inputRefs ).reduce( ( acc, key ) => {
      if ( options?.skipInputs?.includes( key ) ) return acc // If the input is in the skipInputs array, don't validate it
      if ( !inputRefs[key].current ) console.error( `Ref not found for`, key, `\n Please check the refs object passed to useValidate` )
      if ( !inputRefs[key].current?.required ) return acc // If the input is disabled, don't validate it
      if ( !isSubmitEvent && !touched[key] ) return acc // If the input is untouched, don't validate it
      // If the input is dependent on another input, we want to pass in the value of the associated input as well
      const associatedFieldName = AssociatedFields[key] ?? undefined
      const associatedValue = associatedFieldName ? inputRefs[associatedFieldName].current?.value ?? `` : ``

      const valuesToValidate = associatedFieldName ? [ inputRefs[key].current?.value ?? ``, associatedValue ] : inputRefs[key].current?.value ?? ``
      const errorMessage = Validate( key, valuesToValidate )
      acc[key] = errorMessage
      return acc
    }
    , {} as Record<string, string> )
    setFormErrors( newFormErrors )

    if ( isSubmitEvent ) { // This would be used when we submit the form, which is why these booleans are bundled together
      focusOnFirstError( newFormErrors )
      setShouldHideErrors( false )
    }

    const hasAnError = Object.values( newFormErrors ).find( eM => eM?.length > 0 ) !== undefined
    return hasAnError
  }

  /** Allows for overwriting of normal validation errors, this could be used where an api returns an error for a specific field */
  const setFormError = ( name: string, errorMessage: string ) => {
    const newFormErrors = {
      ...formErrors,
      [name]: errorMessage
    }
    setFormErrors( newFormErrors )

    focusOnFirstError( newFormErrors )
  }

  /* Begin Custom Helper Functions */

  /** This function evaluates errors behind the scenes, as well as registers input touch */
  const evaluateErrorsOnChange = ( event : React.ChangeEvent<HTMLInputElement> ) => {
    // We want to now show the errors when the user is typing
    setShouldHideErrors( true )
    const { name } = event.currentTarget
    handleTouch( name )
    return evaluateErrors( false )
  }

  /** This function evaluates all errors, even if they are untouched, this will also focus on first error field if present */
  const evaluateErrorsOnSubmit = () => {
    return evaluateErrors( true )
  }

  const evaluateErrorsOnBlur = () => {
    evaluateErrors()
    setShouldHideErrors( false )
  }

  /* Begin Custom Input Handlers */

  const handleShowErrors = () => {
    setShouldHideErrors( false )
    evaluateErrors( true )
  }

  const disableDefaultAction = ( event : React.ClipboardEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement> ) => {
    event.preventDefault()
  }

  const handleTouch = ( name : string ) => {
    setTouched({
      ...touched,
      [name]: true
    })
  }

  return {
    formErrors,
    setFormError,
    shouldHideErrors,
    setShouldHideErrors,
    shouldShowErrors: !shouldHideErrors, // Going to allow for both shouldShowErrors and shouldHideErrors to be used
    setShouldShowErrors: ( shouldShowErrors: boolean ) => setShouldHideErrors( !shouldShowErrors ),
    showErrorsAndEvaluate: handleShowErrors,
    evaluateErrors,
    evaluateErrorsOnChange,
    evaluateErrorsOnSubmit,
    evaluateErrorsOnBlur,
    handleTouch,
    customInputHandlers: {
      disableDefaultAction
    },
    get errorsPresent(){ return Object.values( formErrors ).find( eM => eM?.length > 0 ) !== undefined }
  }
}

// Will take in a name and value and will return an error message if there is one
export function Validate( name: string, value: string | string[] ) {
  const validatorFn = Validators[name]
  if ( !validatorFn ) console.error( `Validator not found for`, name, `\n Please check the Validators object in src/components/forms/components/utils.ts` )
  const multipleValues = Array.isArray( value ) && value.length > 1
  // If there are multiple values we want to spread them out and pass them as arguments to the validator function (an example would be confirmPassword or confirmAccountNumber)
  const isValid = multipleValues ? validatorFn.call( null, ...value ) : validatorFn( value )
  return isValid ? `` : regexErrors[name]
}
