import { Formatters } from 'components/forms/components'
import DoctorResult from '../types/DoctorResult'

function DoctorOption({ doctorResult, selectDoctor } : { doctorResult: DoctorResult, selectDoctor: ( _newDoctor: DoctorResult ) => void }) : JSX.Element | null {

  if ( !doctorResult.first_name || !doctorResult.last_name || !doctorResult.addresses?.length ) return null

  return (
    <div className="flex text-left capitalize justify-between">
      <div className="flex flex-col gap-1 w-7/12">
        <p className="font-bold">{`${doctorResult.first_name?.toLowerCase()} ${doctorResult.last_name?.toLowerCase()}`}</p>
        <div>
          <p>{doctorResult.addresses[0].first_line?.toLowerCase()}</p>
          { doctorResult.addresses[0]?.second_line && <p>{doctorResult.addresses[0].second_line?.toLowerCase()}</p>}
          <p>{`${doctorResult.addresses[0].city.toLowerCase()}, ${doctorResult.addresses[0].state} ${doctorResult.addresses[0].postal_code?.slice( 0, 5 )?.toLowerCase()}`}</p>
          <p>{Formatters.phone( doctorResult.telephone_number )}</p>
        </div>
      </div>
      <div>
        <button
          className="btn-secondary w-fit"
          onClick={() => { selectDoctor( doctorResult ) }}
        >
          {`Select`}
        </button>
      </div>
    </div>
  )
}

export default DoctorOption