import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import React from 'react'

export const UnAuthorized = () : JSX.Element => {
  return (
    <div className="text-graphite" >
      <div className="md:p-40 sm:p-8">
        <div className="flex font-semibold flex-wrap md:m-0 sm:m-auto w-full sm:w-8/12 md:text-7xl sm:text-4xl md:justify-start sm:justify-center py-8 text-teal">
          <div className="mr-2">{`401.`}</div>
          <div>{`Not Authorized.`}</div>
        </div>
        <div className="md:text-3xl sm:text-lg md:text-left sm:text-center font-light leading-relaxed">
          <p>{`You are not authorized to access the payments portal.`}</p>
          <p>{`Please try again or reach out to customer service at ${AF_SUPPORT_PHONE_NUMBER} if this issue persists.`}</p>
        </div>
      </div>
    </div>
  )
}

export default UnAuthorized
