export type FieldData = {
  name: string
  values: string[]
}

export type Lead = {
  id: string
  created_time: Date
  field_data: FieldData[]
}

// expected names for field_data
export const EMAIL = `email`
export const FULL_NAME = `full_name`
export const FIRST_NAME = `first_name`
export const LAST_NAME = `last_name`
export const PHONE_NUMBER = `phone_number`
export const STREET_ADDRESS = `street_address`
export const CITY = `city`
export const STATE=`state`
export const COUNTRY= `country`
export const ZIP_CODE =`zip_code`
export const POST_CODE=`post_code`
export const DATE_OF_BIRTH=`date_of_birth`
export const GENDER =`gender`