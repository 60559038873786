import { useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { icoXGrey } from 'resources/images'
import MaskQuickView from './MaskQuickView'
import MachineQuickView from './MachineQuickView'

interface QuickViewModalProps {
  maskData?: MaskData;
  isMask?: boolean;
  machineData?: MachineData;
  isMachine?: boolean;
  isOpen: boolean;
  onCloseModal: () => void;
  initialClose?: boolean;
  defaultCloseButton?: boolean;
  closeOnFocusChange?: boolean;
  onMaskChange?: ( _selectedSize: string | null ) => void;
  onMachineChange?: ( _lastOrderCpapMachine: string | null ) => void;
}

interface MaskData {
  mask: string;
  maskSizes: {
    size: string;
    dme_id: number;
  }[];
  maskImages: {
    items: string[]
  };
  frameSystems: string[];
}

interface MachineData {
  machineImages: {
    items: string[]
  };
  cpapMachineModel: string;
}

const QuickViewModal = ( props: QuickViewModalProps ) => {
  const {
    maskData,
    isOpen,
    onCloseModal,
    initialClose = true,
    closeOnFocusChange = true,
    isMask,
    onMaskChange,
    isMachine,
    machineData,
    onMachineChange
  } = props

  const modalRef = useRef<HTMLDivElement>( null )
  const wasExecuted = useRef( false )

  const [ selectedSize, setSelectedSize ] = useState<string | null>( null )
  const [ lastOrderCpapMachine, setLastOrderCpapMachine ] = useState<string>( `` )
  const [ cpapDateUnknown, setCpapDateUnknown ] = useState<boolean>( false )
  const [ displayErrors, setDisplayErrors ] = useState( false )

  useEffect( () => {
    // remove existing click listener
    document.removeEventListener( `click`, triggerClose )

    if ( isOpen ) document.addEventListener( `click`, triggerClose )

    // cleanup
    return () => {
      document.removeEventListener( `click`, triggerClose )
      wasExecuted.current = false // Reset execution flag
    }
  }, [ isOpen, initialClose, closeOnFocusChange, onCloseModal ] )

  const triggerClose = ( e: MouseEvent ) => {
    if ( !wasExecuted.current && initialClose ) return wasExecuted.current = true
    if ( modalRef && !modalRef?.current?.contains( e.target as Node ) && closeOnFocusChange ) {
      onCloseModal()
    }
  }

  const handleUpdateLastOrderMachine = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    setCpapDateUnknown( false )
    setDisplayErrors( false )

    setLastOrderCpapMachine( e.target.value )
  }

  const handleCheck = () => {
    setCpapDateUnknown( !cpapDateUnknown )
    setLastOrderCpapMachine( `` )
  }

  const handleBlur = ( ) => {
    const date = new Date()
    const currentYear = date.getFullYear()
    const regex = /^(?:\d{4})?$/
    const isValidYear = regex.test( lastOrderCpapMachine )
    setDisplayErrors( !isValidYear || Number( lastOrderCpapMachine ) > currentYear )
  }

  const isMobile = useMediaQuery({
    query: `(max-width: 976px)`
  })

  const modalClasses = `w-full sm:max-w-lg lg:max-w-screen-xl lg:flex lg:flex-col lg:justify-center lg:h-full sm:max-h-none 
    lg:max-h-[672px] sm:p-5 lg:px-[60px] lg:pt-12 lg:pb-[80px] relative mx-auto my-auto rounded-xl shadow-lg bg-white min-h-[520px]`

  return (
    <>
      <div className="fixed inset-0 z-50 overflow-auto bg-graphite bg-opacity-70">
        <div className="min-w-screen h-screen animated fadeIn faster absolute flex justify-center items-center inset-0 z-50 outline-none focus:outline-none sm:p-6 lg:p-9">
          <div className={modalClasses} ref={modalRef}>
            <div className="flex justify-end cursor-pointer lg:h-18px lg:absolute lg:top-12 lg:right-[60px]">
              <div onClick={onCloseModal}>
                <img src={icoXGrey} alt="close" />
              </div>
            </div>
            {isMobile &&
             <div className="text-center w-full max-w-lg">
               <p className="font-semibold text-2xl pb-2 text-[#594859]">
                 {isMask ? maskData?.mask : machineData?.cpapMachineModel}
               </p>
             </div>
            }
            {isMask && maskData?.maskImages?.items && (
              <MaskQuickView
                maskData={maskData}
                selectedSize={selectedSize}
                setSelectedSize={setSelectedSize}
                onMaskChange={onMaskChange || ( () => null )}
              />
            )}
            {isMachine && machineData?.machineImages && (
              <MachineQuickView
                machineData={machineData}
                lastOrderCpapMachine={lastOrderCpapMachine}
                cpapDateUnknown={cpapDateUnknown}
                displayErrors={displayErrors}
                handleUpdateLastOrderMachine={handleUpdateLastOrderMachine}
                handleCheck={handleCheck}
                handleBlur={handleBlur}
                onMachineChange={onMachineChange || ( () => null )}
              />
            )}
          </div>
        </div>
      </div>

      <div>
        <div onClick={onCloseModal}>{`X`}</div>
        <h2>{maskData?.mask}</h2>
      </div>
    </>
  )
}

export default QuickViewModal



