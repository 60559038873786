import React from 'react'
import styled from 'styled-components'
import QualifyForm from 'modules/qualifyForm'
import { homePageCopy } from 'constants/copy'
import { animatedVideoPlaceholder } from 'resources/images'
import CMSBlock from 'components/cmsBlock'
import { useTokenStore } from 'stores/tokenStore'

const StyledGrid = styled.div`
   grid-template-columns: 1fr;
   grid-auto-rows: auto;
   @media(min-width: 976px){   
      grid-template-columns: 2fr 1fr;
   }
`

export const HeroBlock = () => {

  const {
    header,
    subheader
  } = homePageCopy

  const { customerToken } = useTokenStore()

  // if a user is logged the qualify form should not be shown
  if ( customerToken ) return <div className="my-12" />

  return (
    <div
      className="min-height-semi-lg bg-hero-image bg-cover bg-no-repeat bg-center lg:bg-fixed sm:bg-scroll flex justify-center sm:pb-24 md:pb-12 lg:pb-0 md:mb-24"
    >
      <StyledGrid className="grid">

        <div className="flex justify-evenly flex-col items-center px-4 min-height-md" >
          <CMSBlock
            customTag="h2"
            styling="sm:font-semibold md:font-normal md:text-4xl lg:text-6xl text-center py-4"
            type="text"
            identifier="react-hero-block-header"
            failSafe={header}
          />
          <CMSBlock
            type="video"
            containerStyling="max-w-lg w-full max-h-full"
            failSafe={{
              image: animatedVideoPlaceholder,
              videoID: `XoYDlzpdxkQ`
            }}
            identifier="react-hero-block-video"
            customProps={{
              title: `Get Your CPAP Supplies Through Insurance With Aeroflow Sleep`,
              startTime: `0`
            }}
          />
          <CMSBlock
            customTag="p"
            styling="text-center max-w-md sm:text-lg md:text-xl py-2"
            containerStyling="mb-4"
            type="text"
            identifier="react-hero-block-subheader"
            failSafe={subheader}
          />
        </div>

        <div className="bg-secondaryBlue-tint2 text-black text-center py-6 min-height-sm" >
          <QualifyForm />
        </div>
      </StyledGrid>
    </div>
  )
}

export default HeroBlock