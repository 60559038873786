import { gql } from '@apollo/client'

export const customerFragment = gql`
  fragment Customer on Customer {
    id
    patient_id
    customer_id
    firstname
    lastname
    email
    date_of_birth
    addresses { telephone }
  }
`
