import { Tile } from 'components/tile'
import { useReactiveVar } from '@apollo/client'
import React, { useEffect } from 'react'
import { myOrdersId } from '../constants'
import { accountNavOptions } from 'apollo'
import { useAccountStore } from '../state'
import Error from '../components/Error'
import OrderRows from '../components/Orders/OrderRows'

function Orders() : JSX.Element | null {

  const { orders, customer } = useAccountStore()
  const currentNavOptions = useReactiveVar( accountNavOptions )

  useEffect( () => {
    const myOrdersNavOption = currentNavOptions.find( ( option ) => { return option.id === myOrdersId })
    // add Orders to navigate dropdown
    if ( ( orders?.data?.currentOrders?.length || orders?.data?.pastOrders?.length ) && !myOrdersNavOption ) accountNavOptions( [{
      label: `My Orders`,
      id: myOrdersId
    }, ...currentNavOptions ] )
  }, [ orders ] )

  if ( orders?.error || !orders?.data ) return (
    <Error title="My Orders" id={myOrdersId} fillInTheBlank="order" />
  )

  // patient has no order history
  if ( !orders.data?.pastOrders?.length && !orders.data?.currentOrders?.length ) return (
    <Tile headerContent="My Orders" id={myOrdersId}>
      <p className="text-deepSleepBlue text-center my-8">{`You have no order history associated with this account.`}</p>
    </Tile>
  )

  return (
    <Tile headerContent="My Orders" id={myOrdersId}>
      { customer.error && <p className="text-error">{customer.error?.message}</p> }
      { customer.data &&
          <>
            {/* current orders */}
            <div className={`border-b -mx-4`}>
              <p className="text-sleepBlue p-lg pb-3 px-4 -mt-1">{`Current`}</p>
            </div>
            <div>
              {orders.data?.currentOrders?.length > 0 ?
                <OrderRows orders={orders.data.currentOrders} showPill /> : <div className={`border-b -mx-4 p-4`}>
                  <p>{`You have no current orders.`}</p></div>}
            </div>
            {/* past orders*/}
            <div className={`border-b -mx-4`}>
              <p className="text-sleepBlue p-lg p-3 px-4 -mt-1">{`Past Orders`}</p>
            </div>
            <div>
              {orders.data?.pastOrders?.length > 0 ?
                <div className={`-mb-4`} key={`past-orders-container`}>
                  <OrderRows orders={orders.data?.pastOrders} showPill />
                </div> :
                <div className={`px-4 pt-4`}>
                  <p>{`You have no past orders.`}</p>
                </div>}
            </div>
          </>
      }
    </Tile>
  )
}

export default Orders