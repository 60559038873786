import React from 'react'
import TrackerStepIcon from '../../TrackerStepIcon'
import { listWithCheckmark } from 'resources/images'
import { sleepApneaProfileCopy } from '../constants'
import StepContentRenderer from '../../StepContentRenderer'

type SleepApneaProfileStepProps = {
    stepStatus: boolean;
    isCurrentStep?: boolean;
 }

function SleepApneaProfileStep({ stepStatus, isCurrentStep } : SleepApneaProfileStepProps ) : JSX.Element {
  return (
    <div className="flex">
      <TrackerStepIcon
        imageSrc={listWithCheckmark}
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
        stepLinkUrl={sleepApneaProfileCopy.stepLinkUrl}
      />
      <StepContentRenderer
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
        stepCopy={sleepApneaProfileCopy}
      />
    </div>
  )
}

export default SleepApneaProfileStep
