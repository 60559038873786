import React from 'react'
import PropTypes from 'prop-types'
import { TrailDrop } from 'spring'


const TrailDropMobileClasses = ` bg-white border border-[#E4E4E4]z-50 rounded-lg w-min px-2`

const AccountMenuDropdownWrapper = ( props ) => {
  const tailDropClasses = props.isMobile ? TrailDropMobileClasses : `relative`

  return (
    <>
      <TrailDrop className={tailDropClasses} open={props.active}>
        <div className="whitespace-nowrap flex flex-col h-16 justify-evenly items-start">
          {props.children}
        </div>
      </TrailDrop>
    </>
  )
}
AccountMenuDropdownWrapper.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  isMobile: PropTypes.bool
}

export default AccountMenuDropdownWrapper
