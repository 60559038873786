import * as React from 'react'
import { useMutation } from '@apollo/client'
import { generateCustomerTokenMutation, resetPasswordMutation } from 'graphql/mutations'
import { FormPageProps } from '../types.d'
import { TextInput, PasswordInput } from 'components/forms/components'
import { Validate, ValidatePasswordMatch } from '../validation'
import LoadingSpinner from 'components/LoadingSpinner'
import { useNavigate } from 'react-router-dom'
import { reportToSentry } from 'utils/reportToSentry'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import { useQualifyFormStore } from '../state'
import { useTokenStore } from 'stores/tokenStore'
import { useQualifyFieldsStore, resetQualifyForm } from 'stores/qualifyFieldsStore'
import ThankYou from '../../sleepNewLeads/ThankYou/ThankYou'
import PageTracker from '../../sleepNewLeads/PageTracker/PageTracker'
import { clearSnlPatientHash } from 'stores/snlPatientHashStore'
import { useSNLStore } from 'modules/sleepNewLeads/State/snlStorage'
import { MY_ACCOUNT_PATH } from 'routes'
import { useHealthTapStore } from 'modules/sleepNewLeads/State/healthTapStore'
import { healthTapLogoImg } from 'resources/images'
import HealthTapModal from 'modules/sleepNewLeads/Components/healthtapModal/HealthTapModal'
import { getProductPricing } from 'utils/patientInfoApi'
import { updateSessionTracking } from 'modules/sleepNewLeads/fetch'

const Submit = ({ isEmbedded, ...pageProps }) => {
  const { snlEmail, clearSession } = useSNLStore()
  const { formFields, formErrors, setFormError, setFormField } = useQualifyFieldsStore( state => ({
    ...state,
    formFields: {
      ...state.formFields,
      username: state.formFields.username || snlEmail
    }
  }) )

  const { healthtapSelected } = useHealthTapStore()

  const inputRefs = {
    username: React.useRef(),
    password: React.useRef(),
    passwordConfirmation: React.useRef()
  }

  const navigate = useNavigate()

  const [ displayErrors, setDisplayErrors ] = React.useState( false )
  const [ submitError, setSubmitError ] = React.useState( `` )
  const [ submitLoading, setSubmitLoading ] = React.useState( false )
  const [ openHealthtapModal, setOpenHealthtapModal ] = React.useState( false )
  const [ patientId, setPatientId ] = React.useState( JSON.parse( sessionStorage.getItem( `product-pricing` ) ?? `` )?.data?.patient?.patient_id )

  const { resetPasswordToken } = useQualifyFormStore()
  const { setCustomerToken } = useTokenStore()
  const { fromEnabledAccount } = useSNLStore()

  const [ resetPassword ] = useMutation( resetPasswordMutation, {
    variables: {
      resetPasswordToken: resetPasswordToken,
      email: formFields.username,
      newPassword: formFields.passwordConfirmation
    },
    onError: ( ( error ) => {
      setSubmitLoading( false )
      setSubmitError( `There was an issue setting your password. Please contact customer service. Error id RP_0913` )
      reportToSentry( new Error ( `reset password mutation on -> PasswordResetForm`, {
        cause: error
      }) )
    })
  })

  React.useEffect( () => {
    window.scrollBy( 0, submitError ? -200 : -300 )
    const firstEnabledInput = submitError ? `username` : `password`
    inputRefs[firstEnabledInput]?.current?.focus()

    if ( fromEnabledAccount ) setOpenHealthtapModal( true )
  }, [] )

  const [ login ] = useMutation( generateCustomerTokenMutation, {
    fetchPolicy: `no-cache`, // Prevent caching user login creds
    variables: {
      email: formFields.username,
      password: formFields.passwordConfirmation
    },
    errorPolicy: `all`,
    onError: ( error ) => {
      reportToSentry( `Qualify Form - Login Mutation Error`, {
        cause: error
      })
    }
  })

  const handleLogin = async () => {
    const loginResult = await login()
    const token = loginResult?.data?.generateCustomerToken?.token
    if ( token ) {
      setCustomerToken( token )

      updateSessionTracking( `Bearer ${token}`, null, true )

      const productPricingResponse = await getProductPricing( token, true )

      if ( productPricingResponse?.data?.patient?.patient_id ) setPatientId( productPricingResponse.data.patient.patient_id )

      // if user selected healthtap option, open screener instead of clearing session and navigating
      if ( healthtapSelected ) return setOpenHealthtapModal( true )

      return handleSuccess()
    }
    setSubmitError( `Something went wrong logging you in after setting your password. Please try again or contact customer at ${AF_SUPPORT_PHONE_NUMBER} if the issue persists.` )
  }

  const handleSuccess = () => {
    clearSession()
    clearSnlPatientHash()
    resetQualifyForm()

    if ( isEmbedded ) return window?.parent?.postMessage( `Submit Success`, `*` )

    return navigate( MY_ACCOUNT_PATH )
  }

  const handleOfflineSubmit = () => {
    setSubmitLoading( false )
    return pageProps.onHandleOfflineSubmit()
  }

  const handleFormSubmit = async ( event ) => {
    event && event.preventDefault()

    // 1. Validate
    const fieldWithError = Object.keys( inputRefs ).find( name => {
      const errorMessage = ( name === `passwordConfirmation` ) ? (
        ValidatePasswordMatch( formFields.password, formFields.passwordConfirmation )
      ) : (
        Validate( name, formFields[name] )
      )
      setFormError( name, errorMessage )

      return Boolean( errorMessage )
    })

    if ( fieldWithError ) return inputRefs[fieldWithError]?.current.focus()

    // 2. reset password and then open healthtap screener modal if they selected that option
    resetUserPassword()
  }

  const resetUserPassword = () => {
    // Set up customer's new password
    setSubmitLoading( true )
    setSubmitError( `` )

    setOpenHealthtapModal( true )

    resetPassword().then( resetPasswordResult => {

      if ( resetPasswordResult?.errors ) {
        return setSubmitError( resetPasswordResult.errors.map( errorObject => { return errorObject.message }).join( ` ` ) )
      }

      if ( !resetPasswordResult?.data ) {
        return setSubmitError( `There was an issue setting your password. Please contact customer service. Error id RP_0913` )
      }

      if ( resetPasswordResult?.data && pageProps.isSiteOffline ) {
        return handleOfflineSubmit()
      }

      handleLogin().finally( () => setSubmitLoading( false ) )
    })
  }

  const handleBlur = () => { setDisplayErrors( true ) }

  const handleChange = ( event ) => {
    setDisplayErrors( false )
    const errorMessage = ( event.target.name === `passwordConfirmation` ) ? (
      ValidatePasswordMatch( event.target.value, formFields.password )
    ) : (
      Validate( event.target.name, event.target.value )
    )
    setFormError( event.target.name, errorMessage )
    setFormField( event.target.name, event.target.value )
  }

  const labelClassNames = `input-label text-left sm:font-medium md:font-normal sm:text-sm md:text-xl text-graphite !p-0 mb-[7px] text-left mr-2 block`
  const healthTapButtonContent =
    <div className="flex items-center justify-center flex-wrap">
      <p className="sm:text-base lg:text-xl">{`Save & Go To`}</p>
      <img src={healthTapLogoImg} className="ml-1" />
    </div>

  return (
    <div className="max-w-none mx-auto">
      <PageTracker
        title={`Thank you for completing your order!`}
        description={healthtapSelected ? <><p>{`Set your password to track your order and see other important account details.`}</p><br /><p>{`Upon setting your password for your Aeroflow Sleep Account, you will be directed to HealthTap's online system.`}</p></> : `Set your password to track your order and see other important account details.`}
      />


      {
        !fromEnabledAccount &&
          <div className="mt-8 mb-12 rounded-md bg-[#F7F7F7] px-6 py-[34px] max-w-xl mx-auto">
            <div className="my-4">
              <TextInput
                type="text"
                name="username"
                errorMessageClassName="text-error text-center text-sm"
                value={formFields.username}
                errorMessage={displayErrors && formErrors.username}
                reference={inputRefs.username}
                placeholder="Email"
                label="Your Account Name"
                labelClassName={labelClassNames}
                onChange={handleChange}
                autoComplete="username"
                onBlur={handleBlur}
                disabled={!submitError || resetPasswordToken}
                className="!bg-transparent !p-0 !h-auto text-base text-deepSleepBlue !text-opacity-100 !font-medium border-none"
              />
            </div>

            <div className="my-4">
              <PasswordInput
                name="password"
                value={formFields.password}
                errorMessage={displayErrors && formErrors.password}
                reference={inputRefs.password}
                label="Set your password"
                labelClassName={labelClassNames}
                autoComplete="new-password"
                required
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
            <div className="my-4">
              <PasswordInput
                name="passwordConfirmation"
                value={formFields.passwordConfirmation}
                errorMessage={displayErrors && formErrors.passwordConfirmation}
                reference={inputRefs.passwordConfirmation}
                label="Confirm password"
                labelClassName={labelClassNames}
                autoComplete="new-password"
                required
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
            <div className="mx-auto basis-full text-center">
              <button
                className={`btn-primary text-center sm:max-w-72 md:max-w-[420px] ${healthtapSelected ? `!px-0` : ``}`}
                onClick={handleFormSubmit}
                disabled={submitLoading}
              >{submitLoading ?
                  <LoadingSpinner height="2.5rem" width="2.5rem" rings={3} /> :
                  ( !healthtapSelected ? `Save` : healthTapButtonContent )
                }
              </button>
            </div>
            {submitError && (
              <div className="py-2">
                <p className="text-error">{submitError}</p>
              </div>
            )}
          </div>
      }
      {
        healthtapSelected ? (
          <HealthTapModal
            open={openHealthtapModal}
            setOpen={setOpenHealthtapModal}
            onSuccess={handleSuccess}
            patientId={patientId}
          />
        ) : <div className="mt-10"><ThankYou /></div>
      }
    </div>
  )
}

Submit.propTypes = FormPageProps

export default Submit