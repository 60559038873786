import { ReactNode, forwardRef } from "react"
/* Usage Example:
  <DropdownContainer>
    <Button onClick={() => setIsOpen(true)}>OpenDropDown</Button>
    <Dropdown shouldShow={isOpen}>
      <ul>
      <li>Item 1</li>
      </ul>
    </Dropdown>
  </DropdownContainer>
*/

interface DropdownContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode
}

const DropdownContainer = forwardRef<HTMLDivElement, DropdownContainerProps>(
  ({ children, ...htmlAttributes }, ref ) => (
    <div ref={ref} className="inline-block relative w-full" {...htmlAttributes}>
      {children}
    </div>
  )
)

DropdownContainer.displayName = `DropdownContainer`

export default DropdownContainer