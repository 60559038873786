import { Modal } from 'components/modal'
import { useState } from 'react'
import { useAccountStore } from '../../state'
import ContactInfoBlock from '../ContactInfoBlock'

export default function ContactBlock() : JSX.Element {
  const { customer } = useAccountStore()
  const [ editMode, setEditMode ] = useState( false )

  const customerPhone = customer?.data?.addresses?.length ? customer?.data?.addresses[0].telephone : ``

  return (
    <div className="my-5">
      <Modal
        isOpen={editMode}
        onClose={() => { setEditMode( !editMode ) }}
        initialClose
      >
        {customer?.data &&
        <ContactInfoBlock
          initialEdit
          containerClass="bg-white"
        />}
      </Modal>
      <p className="font-bold">{`Patient Contact`}</p>
      <>
        <p>{customerPhone}</p>
        <p className="mt-3 text-deepSleepBlue cursor-pointer hover:underline hover:decoration-deepSleepBlue active:text-black active:decoration-black" onClick={() => setEditMode( true )}>{`Edit Phone`}</p>
      </>
    </div>
  )
}