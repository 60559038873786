import PropTypes from 'prop-types'
import { PageProps } from 'components/formNavigator'

export const FormFieldsPropType = PropTypes.shape({
  username: PropTypes.string, // This is an email
  password: PropTypes.string,
  passwordConfirmation: PropTypes.string,
  dobMonth: PropTypes.string,
  dobDay: PropTypes.string,
  dobYear: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phoneNumber: PropTypes.string,
  state: PropTypes.string,
  zipCode: PropTypes.string,
  insuranceType: PropTypes.string,
  memberID: PropTypes.string,
  secondaryInsuranceType: PropTypes.string,
  secondaryMemberID: PropTypes.string,
  hasSleepApnea: PropTypes.oneOf( [ `yes`, `no`, `` ] ),
  howDidYouHearAboutUs: PropTypes.string,
  terms: PropTypes.bool
})

export const FormErrorsPropType = PropTypes.shape({
  ...FormFieldsPropType,
  terms: PropTypes.string
})

export const FormPageProps = {
  formFields: FormFieldsPropType,
  formErrors: FormErrorsPropType,
  onFormChange: PropTypes.func,
  onFormError: PropTypes.func,
  pageProps: PageProps
}