export const otpLogin = async ( phoneNumber: string, otpInput: string ) => {
  const response = await fetch( `${process.env.REACT_APP_MAGENTO_2_BASE_URL}/rest/V1/mobilelogin/otp/verify`, {
    method: `POST`,
    headers: {
      "Content-Type": `application/json`
    },
    body: JSON.stringify({
      mobilenumber: phoneNumber.replace( /[\\(\\)]/g, `` ).replace( / /g, `-` ),
      otptype: `login`,
      otpcode: otpInput,
      oldmobile: 0
    })
  })

  return response.json()
}

export const sendOTP = async ( phoneNumber: string ) => {
  const response = await fetch( `${process.env.REACT_APP_MAGENTO_2_BASE_URL}/rest/V1/mobilelogin/otp/send`, {
    method: `POST`,
    headers: {
      "Content-Type": `application/json`
    },
    body: JSON.stringify({
      mobilenumber: phoneNumber.replace( /[\\(\\)]/g, `` ).replace( / /g, `-` ),
      otptype: `login`,
      resendotp: false,
      oldmobile: false
    })
  })

  return response.json()
}

type LoginBody = {
  email?: string,
  phone?: string,
  dob?: string,
  patient_hash?: string
}
export const deprecatedPatientLogin = async ( loginBody: LoginBody ) => {
  const response = await fetch( `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/deprecated-login`, {
    method: `POST`,
    headers: {
      "Content-Type": `application/json`
    },
    body: JSON.stringify({
      profit_center_pk: 5,
      ...( loginBody.patient_hash && {
        patient_hash: loginBody.patient_hash
      }),
      ...( loginBody.dob && {
        dob: loginBody.dob
      }),
      ...( loginBody.email && {
        email: loginBody.email
      }),
      ...( loginBody.phone && {
        phone: loginBody.phone
      })
    })
  })

  return response.json()
}