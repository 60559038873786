import { gql } from "@apollo/client"

export const setShippingAddressesOnCartMutation = gql`
  mutation SetShippingAddressesOnCartMutation($input : SetShippingAddressesOnCartInput) {
    setShippingAddressesOnCart(input : $input) {
      cart {
      email
      id
      shipping_addresses {
        firstname
        lastname
        company
        street
        city
        region {
          code
          label
        }
        postcode
        telephone
        country {
          code
          label
        }
        available_shipping_methods{
          carrier_code
          carrier_title
          method_code
          method_title
        }
      }
    }
    }
  }
`