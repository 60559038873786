import React from 'react'

function ActiveStepIndicator() : JSX.Element {
  return (
    <div className="absolute top-[-11px] left-12">
      <div className=" flex items-center justify-center w-9 h-9 rounded-full border-4 border-solid border-white bg-red-600">
        <p className="font-serif text-2xl text-white">{`!`}</p>
      </div>
    </div>
  )
}

export default ActiveStepIndicator