import React, { useState, useCallback } from 'react'
import Slider from 'react-slick'
import { PrevArrow, NextArrow } from './SliderArrow'
import './product-carousel.css'

interface imagesProps {
  productImages: {
    items: string[]
    mask?: string
    machine?: string
  }
}

const ProductCarousel = ({ productImages }: imagesProps ) => {
  const [ activeSlide, setActiveSlide ] = useState( 0 )
  const sliderRef = React.useRef<Slider>( null )

  const handleBeforeChange = useCallback( ( oldIndex: number, newIndex: number ) => {
    setActiveSlide( newIndex )
  }, [] )

  const handleThumbnailClick = useCallback( ( index: number ) => {
    if ( sliderRef.current ) {
      sliderRef.current.slickGoTo( index )
    }
  }, [] )

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    beforeChange: handleBeforeChange
  }

  return (
    <div className="sm:w-full lg:w-1/2">
      <div className="sm:max-w-none lg:max-w-[562px] sm:mx-0 lg:mx-auto">
        <div className="product-carousel">
          <Slider ref={sliderRef} {...settings}>
            {productImages?.items.map( ( image ) => (
              <div key={image} className="sm:inline-block lg:!inline-flex w-full justify-center items-center focus:outline-none">
                <img className="h-full w-full max-w-[400px]" src={image} alt={`${productImages?.mask || productImages?.machine}`} />
              </div>
            ) )}
          </Slider>
          <div className="flex justify-center mt-[20px]">
            {productImages?.items.map( ( image, index ) => (
              <img
                key={image}
                src={image}
                alt={`Thumbnail ${index + 1}`}
                className={`w-[50px] h-[50px] mr-[5px] cursor-pointer border border-solid ${index === activeSlide ? `border-sleepBlue` : `border-[#E8E8E8]`}`}
                onClick={() => handleThumbnailClick( index )}
              />
            ) )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductCarousel