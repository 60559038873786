import React from 'react'
import { qualifyStandaloneCopy as copy } from 'constants/copy'
import { simpleStepsGif } from 'resources/images'

export const QualifyPlugBlockBackup = () => {
  return (
    <div className="sm:mx-0 sm:my-6 md:m-2 sm:p-0 md:p-4 max-w-full">
      <h5 className="text-center">{copy.stepsSubheader}</h5>
      <h3 className="text-center mt-3 text-4xl">{copy.stepsHeader}</h3>
      <img
        src={simpleStepsGif}
        alt="Aeroflow Sleep Simple Steps"
        className="object-contain mx-auto"
      />
      <h3 className="text-center mt-8 text-4xl">{copy.postStepHeader}</h3>
      <p className="text-center mt-4">{copy.postStepSubheader}</p>
    </div>
  )
}

export default QualifyPlugBlockBackup