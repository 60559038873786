
import { PhoneLink } from 'components/ALinks'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import { useAddressStore } from 'components/addressBlock'

const Header = ({ isMedicare = false } : { isMedicare?: boolean }) : JSX.Element => {
  const { address } = useAddressStore()

  return (
    <div className="flex flex-col items-center px-5">
      <p className="text-center text-3xl capitalize font-sans tracking-wide">{`Confirm ${address?.firstName && address?.lastName ? `${address.firstName.toLowerCase()} ${address.lastName.toLowerCase()}'s` : `Your`} Supplies`}</p>
      <div className="text-center max-w-xl my-8">
        {
          isMedicare ?
            <p className="font-light text-lg">{`To comply with insurance guidelines we are required to verify that replacement of your medical supplies is reasonable and necessary. Please answer the following:`}</p>
            :
            <p className="font-light text-lg">
              {`Below are the supply items that you are eligible to receive through insurance at this time. Select all products you would like to receive. If any changes to your product type are needed, please call us at `}
              <PhoneLink displayNumber={AF_SUPPORT_PHONE_NUMBER} textClassName="text-deepSleepBlue decoration-deepSleepBlue whitespace-nowrap" />
            </p>
        }

      </div>
    </div>
  )
}

export default Header