import { makeVar } from '@apollo/client'
import { buildCustomState } from 'apollo'
import {
  ResupplyOption,
  ResupplySetting,
  ContactInfo,
  InsuranceInfo
} from '../types.d'

const claimedItemsVar = makeVar<ResupplyOption[]>( [] )
const resupplySettingVar = makeVar<ResupplySetting>({
  patientId: null,
  updateDoctorRequired: null,
  updatePrimaryPayerStatus: null,
  updateSecondaryPayerStatus: null,
  medicareChecklistRequired: null,
  eligible: false,
  proceedToPayment: false,
  resupplyAOBSettings: null,
  doctorFirstName: ``,
  doctorLastName: ``,
  doctorPhone: ``,
  doctorNpi: ``
})
const doctorSelection = makeVar<boolean | null>( null )
const contactInfoVar = makeVar<ContactInfo>({
  email: ``,
  phone: ``
})

const insuranceInfoVar = makeVar<InsuranceInfo>({
  primary: null,
  secondary: null
})

/**
 * useState syntax for managing user claimed resupplyOptions
 * @returns {[ResupplyOption[], (_s : ResupplyOption[]) => void]}
 */
export function useClaimedState(): [ResupplyOption[], ( _s: ResupplyOption[] ) => void] {
  return buildCustomState<ResupplyOption[]>( claimedItemsVar )
}

export function useResupplySetting() : [ResupplySetting, ( _rs : ResupplySetting ) => void] {
  return buildCustomState<ResupplySetting>( resupplySettingVar )
}

export function useContactInfoState() : [ContactInfo, ( _ci: ContactInfo ) => void] {
  return buildCustomState<ContactInfo>( contactInfoVar )
}

export function useInsuranceInfoState() : [InsuranceInfo, ( _ii: InsuranceInfo ) => void] {
  return buildCustomState<InsuranceInfo>( insuranceInfoVar )
}

export function useDoctorSelectionState() : [boolean | null, ( _b: boolean | null ) => void] {
  return buildCustomState<boolean | null>( doctorSelection )
}