import { PhoneLink } from 'components/ALinks'
import { PHONE } from 'components/footerBarV2/constants'

type SubmissionBlockProps = {
  handleSubmit(): void,
  disableSubmission: boolean,
  onlineOption: boolean,
  submitError: boolean
}

function SubmissionBlock({ handleSubmit, disableSubmission, onlineOption, submitError }: SubmissionBlockProps ) : JSX.Element {

  return (
    <>
      <div className="w-64 m-auto">
        <button
          className="btn-secondary"
          disabled={disableSubmission}
          onClick={handleSubmit}
        >
          {onlineOption ? `Schedule Appointment` : `Submit`}
        </button>
      </div>
      {
        submitError &&
          <p className="text-error md:text-lg mt-3 md:mt-6 text-center px-5 max-w-2xl">
            {`Oops... We are having trouble scheduling your appointment right now. Please try again or if the issue persists reach out to customer service at `}
            <PhoneLink displayNumber={PHONE} />{`.`}
          </p>
      }
    </>

  )
}

export default SubmissionBlock