export const home = {
  title: `Aeroflow Sleep - CPAP and Sleep Apnea Supplies Through Insurance`,
  meta: {
    name: `description`,
    content: `Aeroflow Sleep is dedicated to helping patients receive CPAP and other sleep apnea supplies through insurance`
  }
}

export const qualify = {
  title: `CPAP Through Insurance - Check Your Eligibility with Aeroflow Sleep`,
  meta: {
    name: `description`,
    content: `Need a CPAP through insurance? Aeroflow Sleep handles everything from contacting your insurance, connecting with your doctor, and matching you with the perfect CPAP Machine.`
  }
}

// refer a friend disabled 03-14-22, may be re-enabled
export const refer = {
  title: `Aeroflow Sleep - Refer a Friend`,
  meta: {
    name: `description`,
    content: `Aeroflow Sleep is dedicated to helping patients receive CPAP and other sleep apnea supplies through insurance.`
  }
}