import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { onlineFormImg, shipmentImg, contactDoctorImg, verifyInsuranceImg } from 'resources/images'
import { sleepProcessAnimation } from 'resources/animations'
import { useTokenStore } from 'stores/tokenStore'

const HowItWorksHeading = ({children}) => {
  return (
    <h2 className="sm:text-xl sm:mb-4 lg:text-4xl">{children}</h2>
  )
}

HowItWorksHeading.propTypes = {
  children: PropTypes.node
}

const Section = ({children}) => {
  return (
    <div className="sm:mb-12 lg:mb-20 sm:grid justify-items-center lg:grid-cols-2 lg:auto-rows-min mx-auto my-0">{children}</div>
  )
}

Section.propTypes = {
  children: PropTypes.node
}

const HowAeroflowSleepWorks = () => {

  const { customerToken } = useTokenStore()

  return (
    <>
      <div className="p-7 lg:flex lg:flex-col text-center">
        <div className="sm:mb-12 lg:mb-20 lg:max-w-5xl mx-auto">
          <h1 className="sm:text-2xl sm:mb-4 lg:text-5xl">{`The Aeroflow Sleep Program`}</h1>
          <p className="sm:text-sm sm:mb-4 lg:text-lg">{`We understand that navigating insurance can be complicated and frustrating. That’s why we’ve taken the headache out of healthcare!`}</p>
          <p className="sm:text-sm lg:text-lg">{`To get started and see what your estimated cost might be, here’s what we need from you in order to see if your insurance provides any coverage for your CPAP supplies:`}</p>
        </div>
        <Section>
          <div className="lg:max-w-2xl">
            <HowItWorksHeading>{`1) Complete Our Online Form`}</HowItWorksHeading>
            <img
              className="sm:mb-4 sm:max-w-full md:max-w-md mx-auto my-0 lg:hidden object-contain"
              src={onlineFormImg}
              width={300}
              alt="Online Form"
            />
            <p className="sm:text-sm sm:mb-4 lg:text-lg lg:px-12">
              {`Fill out a simple form to determine if your insurance provider is in network with Aeroflow Sleep. `}
            </p>
            <p className="sm:text-sm sm:mb-4 lg:text-lg lg:px-12">
              {`This will include telling us which insurance you have and which CPAP supplies you currently use.`}
              <span className="font-bold">{` This will allow us to display your estimated cost for those supplies based on your insurance plan.`}</span>
              {` It’s as simple as that!`}
            </p>
            {
              !customerToken &&
                <div className="flex justify-center w-fit mx-auto">
                  <Link className="w-max btn-secondary pt-2" to="/qualify-through-insurance">{`Qualify Now`}</Link>
                </div>
            }
          </div>
          <img
            className="sm:hidden sm:mb-4 lg:block lg:col-start-2 lg:row-start-1 object-contain lg:max-w-md"
            src={onlineFormImg}
            width={300}
            alt="Online Form"
          />
          <div className="mt-5">
            <HowItWorksHeading>{`Aeroflow Sleep Will Then...`}</HowItWorksHeading>
          </div>
        </Section>
        <Section>
          <div className="lg:col-start-2 lg:max-w-2xl">
            <HowItWorksHeading>{`1. Verify Your Insurance`}</HowItWorksHeading>
            <img
              className="sm:mb-4 sm:max-w-full md:max-w-md lg:hidden mx-auto my-0 object-contain"
              src={verifyInsuranceImg}
              alt="Verify your insurance"
              width={500}
              height={500}
            />
            <p className="sm:text-sm mb-4 lg:text-lg lg:px-12">
              {`After you’ve told us what CPAP supplies you need, Aeroflow Sleep will work with your insurance provider to determine what your policy covers, both partially and in full.`}
            </p>
          </div>
          <img
            className="sm:mb-4 sm:hidden lg:block lg:col-start-1 lg:row-start-1 object-contain max-w-md"
            src={verifyInsuranceImg}
            alt="Verify your insurance"
            width={500}
            height={500}
          />
        </Section>
        <Section>
          <div className="lg:max-w-2xl">
            <HowItWorksHeading>{`2. Obtain Your Prescription`}</HowItWorksHeading>
            <img
              className="sm:mb-4 sm:max-w-full md:max-w-md lg:hidden mx-auto my-0 object-contain"
              src={contactDoctorImg}
              width={500}
              height={500}
              alt="Contact your doctor"
            />
            <p className="sm:text-sm lg:text-lg lg:px-12">
              {`Next, Aeroflow Sleep will work with your doctor to submit all required paperwork to your insurance provider. Proof of sleep study and a prescription for CPAP supplies are required per insurance guidelines.`}
            </p>
          </div>
          <img
            className="sm:mb-4 sm:hidden lg:block object-contain max-w-md"
            src={contactDoctorImg}
            alt="Contact your doctor"
            width={500}
            height={500}
          />
        </Section>
        <Section>
          <div className="lg:col-start-2 lg:max-w-2xl">
            <HowItWorksHeading>{`3. Ship New CPAP Supplies Right To Your Door`}</HowItWorksHeading>
            <img
              className="sm:mb-4 sm:max-w-full md:max-w-md lg:hidden object-contain mx-auto my-0"
              src={shipmentImg}
              width={300}
              alt="Sleep Shipment"
            />
            <p className="sm:text-sm mb-4 lg:text-lg lg:px-12">
              {`The hard work is done, and your first order of CPAP supplies from Aeroflow Sleep will be shipped right to your door.`}
            </p>
            <p className="sm:text-sm mb-6 lg:text-lg lg:px-12">
              {`We will inform you when it’s time to replace existing supplies and when new equipment is released.`}
            </p>
            <p className="sm:text-xs md:text-base lg:px-12 italic">
              {`*Important Note: CPAP supplies covered through insurance is paid for according to your plan's rules for cost-sharing. This would include whether or not you have a deductible to pay on your plan. Any amount that you do owe out-of-pocket toward PAP equipment with your insurance plan will go toward paying down your deductible and out-of-pocket max. For more information on insurance, visit our `}
              <span className="a">
                <a href={`${process.env.REACT_APP_MAGENTO_2_BASE_URL}/insurance-rx-resources`}>
                  {`how insurance works`}
                </a>
              </span>
              {` webpage.`}
            </p>
          </div>
          <img
            className="sm:mb-4 sm:hidden lg:block lg:col-start-1 lg:row-start-1 object-contain max-w-md"
            src={shipmentImg}
            width={300}
            alt="Sleep Shipment"
          />
        </Section>
        <img
          src={sleepProcessAnimation}
          width={700}
          height={700}
          className="mx-auto mb-16 px-5"
        />
        <div className="sm:mb-12 sm:grid justify-items-center lg:max-w-2xl mx-auto">
          <HowItWorksHeading>{`Questions?`}</HowItWorksHeading>
          <p className="sm:text-sm mb-4 lg:text-lg lg:px-12">
            {`Aeroflow Sleep believes in connection. If you have questions along the way, our team of experts can explain how your coverage may include CPAP machines, masks, headgear, tubing, filters, and more or just be there to make sense of confusing terms like deductible and coinsurance. `}
          </p>
          <p className="sm:text-sm lg:text-lg lg:px-12">
            {` Contact our Sleep Specialists by phone or email. Our office hours are 9am - 5pm EST, Monday through Friday. In the meantime, feel free to `}
            <Link className="underline text-deepSleepBlue decoration-deepSleepBlue cursor-pointer hover:opacity-80" to={`${process.env.REACT_APP_MAGENTO_2_BASE_URL}/frequently-asked-questions`}>{`browse our FAQs`}</Link>
            {` sent in by patients just like you.`}
          </p>
        </div>
      </div>
    </>
  )
}

export default HowAeroflowSleepWorks
