import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { reportToSentry } from 'utils/reportToSentry'

/**
 * Renders children elements as Pages that can be clicked through with next and prev
 * This is an alternative to having the component controller handled by the main parent.
 * This differs from the pageNavigator in that it does not use react router or pages to keep track of the component, but rather just an index
 * */
const FormNavigator = ( props ) => {

  const [ currentPageIndex, setCurrentPageIndex ] = useState( 0 )
  const firstMount = React.useRef( true )

  useEffect( () => {
    if ( firstMount.current && props.sessionStorageKey?.length ) {
      const initIndex = Number( sessionStorage.getItem( props.sessionStorageKey ) )
      setCurrentPageIndex( isNaN( initIndex ) ? 0 : initIndex )
    }
    firstMount.current = false
  }, [] )

  useEffect( () => {
    props.onChange && props.onChange( currentPageIndex )
    props.sessionStorageKey && sessionStorage.setItem( props.sessionStorageKey, currentPageIndex )

    const nextIndex = currentPageIndex + 1
    const isLast = ( React.Children.count( props.children ) === nextIndex )
    if ( isLast ) {
      props.onLastPage && props.onLastPage()
      return
    }
  }, [ currentPageIndex ] )

  function _onNext() {
    const nextIndex = currentPageIndex + 1
    const isLast = ( React.Children.count( props.children ) === nextIndex )
    if ( isLast ) return
    setCurrentPageIndex( nextIndex )
  }

  function _onPrev() {
    if ( currentPageIndex === 0 ) return
    const prevIndex = currentPageIndex - 1
    setCurrentPageIndex( prevIndex )
  }

  function _onSet( pageIndex ) {
    const length = React.Children.count( props.children )
    let set = pageIndex
    if ( set < 0 ) set = 0
    if ( set >= length ) set = length - 1
    setCurrentPageIndex( set )
  }

  const child = React.Children.toArray( props.children )[currentPageIndex]

  if ( !child ) {
    console.error( `FormNavigator.js: child is undefined, this usually occurs when page index is mis-set or when the child is not a valid react element` )
    reportToSentry( `An error occurred in FormNavigator.js: child is undefined, this usually occurs when page index is mis-set` )
    return <></>
  }

  return (
    <div className={props.pageClassName ?? ``}>
      {React.cloneElement( child, { // Copying over the child element with out custom props
        nextPage: _onNext, // Allows for child to go to nextPage
        prevPage: _onPrev, // Allows for child to go to prevPage
        setPage: _onSet, // Allows for a redirect to a specific pageName
        ...props.childSharedProps,
        ...child.props
      })}
    </div>
  )
}

FormNavigator.propTypes = {
  children: PropTypes.node,
  pageClassName: PropTypes.string,
  childSharedProps: PropTypes.object, // global props to be passed down to all children
  onChange: PropTypes.func, // callback that will fire when the route changes
  sessionStorageKey: PropTypes.string, // key to use for sessionStorage
  onLastPage: PropTypes.func // callback for when the last page is reached
}

export default FormNavigator