import * as React from 'react'
import { ALink } from 'components/ALinks'
import { YouTubeFacade } from 'components/video'
import { animatedVideoPlaceholder } from 'resources/images'

export default function Ineligible() {
  const {heading, stepHeading, steps, stressful, hereToHelp, resources} = IneligibleCopy

  return (
    <div className="max-w-5xl mx-auto">
      <h3 className="text-center text-deepSleepBlue mb-8">{heading}</h3>
      <h4 className="text-deepSleepBlue my-4 sm:text-center md:text-left">{stepHeading}</h4>
      <ol className="list-decimal m-8">
        { steps.map( ( step ) => {
          return <li className="my-2" key={step.title}><p><strong>{`${step.title}: `}</strong>{step.body}</p></li>
        })}
      </ol>
      <p className="sm:text-center md:text-left"><strong>{stressful}</strong></p>
      <br></br>
      <p className="sm:text-center md:text-left"><strong>{hereToHelp}</strong></p>
      <br></br>
      <div className="my-8 md:w-1/2 lg:max-w-lg relative cursor-pointer">
        <YouTubeFacade title="Get Your CPAP Supplies Through Insurance With Aeroflow Sleep" videoID="XoYDlzpdxkQ" imgSrc={animatedVideoPlaceholder} />
      </div>
      <h4 className="text-deepSleepBlue sm:text-center md:text-left">{`Resources`}</h4>
      <ul className="list-disc m-8">
        {resources.map( r => {
          return <ALink href={r.link} key={r.title}><li className="" >{r.title}</li></ALink>
        })}
      </ul>
    </div>
  )
}

const IneligibleCopy = {
  heading: `Thank you for your information. Unfortunately, you must be diagnosed with sleep apnea in order to receive CPAP supplies through insurance.`,
  stepHeading: `What steps should you take next?`,
  steps: [
    {
      title: `Contact your doctor`,
      body: `You should contact your doctor if you notice any changes in your sleep. Being diagnosed requires a sleep study, which will record your oxygen levels, heart rate, and breathing. This data determines how often you have apneas (how often your breathing stops) during the night.`
    },
    {
      title: `Receive a sleep study`,
      body: `You can have a sleep study done using a home sleep test (not all doctors recommend this) OR you can have your sleep study done in an actual sleep lab where you spend the night. This sleep study determines your diagnosis.`
    },
    {
      title: `Follow up with your doctor`,
      body: `After the sleep study is performed, you will have a follow-up appointment with your doctor where you will find out if you have sleep apnea and be prescribed CPAP therapy if you do.`
    },
    {
      title: `Contact Aeroflow Sleep`,
      body: `Upon diagnosis, come back to us to see if you are eligible to get your setup needs through insurance. We are in-network with Medicare, Medicaid, and most commercial insurance providers.`
    }
  ],
  stressful: `Being diagnosed with sleep apnea is already stressful enough; we alleviate that stress by presenting processes and services that make it easy to navigate supplies through insurance every step of the way.`,
  hereToHelp: `If diagnosed, please come back to us and we'll be happy to help.`,
  resources: [
    {
      title: `FAQ (including most common sleep apnea symptoms)`,
      link: `${process.env.REACT_APP_MAGENTO_2_BASE_URL}/frequently-asked-questions`
    },
    {
      title: `Sleep apnea and heart disease`,
      link: `https://shop.aeroflowsleep.com/blog/sleep-apnea-and-heart-health`
    },
    {
      title: `How to stop fearing a sleep apnea diagnosis`,
      link: `https://shop.aeroflowsleep.com/blog/how-to-overcome-compliance`
    },
    {
      title: `How does a CPAP work?`,
      link: `https://shop.aeroflowsleep.com/blog/how-cpap-works`
    },
    {
      title: `Does Insurance Cover CPAP?`,
      link: `https://shop.aeroflowsleep.com/blog/does-insurance-cover-cpap`
    }
  ]
}