import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { useQualifyFormStore } from 'modules/qualifyForm/state'

export interface QualifyFormFields {
  username: string;
  password: string;
  passwordConfirmation: string;
  dobMonth: string;
  dobDay: string;
  dobYear: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  state: string;
  zipCode: string;
  insuranceType: string;
  memberID: string;
  secondaryInsuranceType: string;
  secondaryMemberID: string;
  hasSleepApnea: string;
  hasReceivedCpapThroughInsurance: string;
  howDidYouHearAboutUs: string;
  terms: boolean;
  smsOptIn: boolean;
}

export interface QualifyFormErrors extends Omit<QualifyFormFields, `terms` | `smsOptIn`> {
  terms: string;
}

interface QualifyFieldsStore {
  formFields: QualifyFormFields;
  setFormFields: ( _formFields: QualifyFormFields ) => void;
  formErrors: QualifyFormErrors;
  setFormErrors: ( _formErrors: QualifyFormErrors ) => void;
  setFormField: ( _name: keyof QualifyFormFields, _value: QualifyFormFields[keyof QualifyFormFields] ) => void;
  setFormError: ( _name: keyof QualifyFormErrors, _value: QualifyFormErrors[keyof QualifyFormErrors] ) => void;
}

export const useQualifyFieldsStore = create<QualifyFieldsStore>()(
  persist(
    ( set ) => ({
      formFields: {
        username: ``,
        password: ``,
        passwordConfirmation: ``,
        dobMonth: ``,
        dobDay: ``,
        dobYear: ``,
        firstName: ``,
        lastName: ``,
        phoneNumber: ``,
        state: ``,
        zipCode: ``,
        insuranceType: ``,
        memberID: ``,
        secondaryInsuranceType: ``,
        secondaryMemberID: ``,
        hasSleepApnea: ``,
        hasReceivedCpapThroughInsurance: ``,
        howDidYouHearAboutUs: ``,
        terms: false,
        smsOptIn: false
      },
      setFormFields: ( formFields: QualifyFormFields ) => set({
        formFields
      }),
      formErrors: {
        username: ``,
        password: ``,
        passwordConfirmation: ``,
        dobMonth: ``,
        dobDay: ``,
        dobYear: ``,
        firstName: ``,
        lastName: ``,
        phoneNumber: ``,
        state: ``,
        zipCode: ``,
        insuranceType: ``,
        memberID: ``,
        secondaryInsuranceType: ``,
        secondaryMemberID: ``,
        hasSleepApnea: ``,
        hasReceivedCpapThroughInsurance: ``,
        howDidYouHearAboutUs: ``,
        terms: ``
      },
      setFormErrors: ( formErrors: QualifyFormErrors ) => set({
        formErrors
      }),
      setFormField: ( name, value ) => set( ( state ) => ({
        formFields: {
          ...state.formFields,
          [name]: value
        }
      }) ),
      setFormError: ( name, value ) => set( ( state ) => ({
        formErrors: {
          ...state.formErrors,
          [name]: value
        }
      }) )
    }),
    {
      name: `qualify-fields-store`,
      storage: createJSONStorage( () => sessionStorage )
    }
  )
)

export const resetQualifyForm = () => {
  useQualifyFormStore.getState().setEmailNotAvailable( false ) // we don't want to show the EmailUnavailableDialogue on QualifyForm refresh
  sessionStorage.removeItem( `qualify-fields-store` )
  sessionStorage.removeItem( `qualify-form-navigator-index` )
}