import React from 'react'
import PhoneNumber from './PhoneNumber'
import Logo from './Logo'
import { AccountMenu } from 'components/accountMenuV2'
import { MegaMiniMenu } from 'components/megaMenuV2/mobile'
import { Container } from '../Container'

const HeaderBar = () => {

  return (
    <div className="sm:px-4 md:px-6">
      <Container>
        <div className="bg-white sm:pt-6 sm:pb-4 lg:pt-6 lg:pb-0 relative z-40">
          <div className="flex justify-between items-center relative max-h-[54px]">
            <>
              <MegaMiniMenu />
              <PhoneNumber />
            </>
            <Logo withoutLink={false} logoClasses="flex justify-center items-center px-3" />
            <AccountMenu />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default HeaderBar