import React, { useEffect, useState } from 'react'
import { thankYouInPerson, thankYouVirtual } from '../utils/constants'
import { icoCheckAob } from 'resources/images'
import { CSTStorage } from 'apollo/storage'
import { getHash } from '../utils'
import { reportToSentry } from 'utils/reportToSentry'
import { finalizeInvoice } from 'modules/aobPayments/fetchPromises'
import LoadingSpinner from 'components/LoadingSpinner'

function SchedulingThankYou({ scheduledVirtually, invoiceNumber, prevPage }: { scheduledVirtually: boolean | null, invoiceNumber?: string, prevPage?(): void }) : JSX.Element {

  const [ loading, setLoading ] = useState<boolean>( false )

  useEffect( () => {
    if ( !CSTStorage.getScheduledStatus( getHash() ) && scheduledVirtually === null && prevPage ) prevPage()

    let mounted = true

    const urlParams = new URLSearchParams( window.location.search )
    const hash = urlParams.get( `sgh` ) ?? urlParams.get( `txt` )

    if ( hash && invoiceNumber ) {
      setLoading( true )

      finalizeInvoice( hash )
        .then( () => { if ( mounted ) setLoading( false ) })
        .catch( ( error: Error ) => {
          if ( mounted ) setLoading( false )
          reportToSentry( new Error( `AOB 2.0 CST: Error finalizing patient invoice`, {
            cause: error
          }), {
            hash
          })
        })
    }

    return () => { mounted = false }
  }, [] )

  if ( loading ) return (
    <div className="my-16"><LoadingSpinner /></div>
  )

  return (
    <div className="flex items-center flex-col gap-7 text-center my-10 px-3">
      {scheduledVirtually}
      <h1 className="font-bold text-4xl md:text-5xl">{`Thank You`}</h1>
      <p className="text-xl max-w-xl font-light md:text-2xl">{scheduledVirtually ? thankYouVirtual : thankYouInPerson}</p>
      <img
        src={icoCheckAob}
        alt="scheduling-thank-you-check"
        height={250}
        width={250}
        className="mb-24"
      />
    </div>
  )
}

export default SchedulingThankYou