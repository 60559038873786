import { useEffect, useState } from 'react'
import { fetchPatientRouting } from '../fetch'
import AwsResponse from 'types/awsResponse'
import RoutingResponse from 'types/routingResponse'
import { repeatSAPCopy } from '../constants'
import { useQualifyFormStore } from 'modules/qualifyForm/state'

type CheckSAPRepeat = {
  repeatMessage: string | null;
  loading: boolean;
}

// this custom hook checks if the user has already filled out their SAP
// if so it displays a message to the user letting them know
function useCheckSAPRepeat() : CheckSAPRepeat {
  const [ repeatMessage, setRepeatMessage ] = useState<string | null>( null )
  const [ loading, setLoading ] = useState<boolean>( true )
  const { processId } = useQualifyFormStore()

  useEffect( () => {
    const controller = new AbortController()
    fetchPatientRouting( controller.signal )
      .then( ( response: AwsResponse<RoutingResponse> ) => {
        // block the user from entering SAP if we get an area path back that doesn't indicate the proper path
        const blockSAP = ( ( response?.data?.area_path && response.data.area_path !== `/new-patient-intake/sleep` ) && !processId )
        setRepeatMessage( blockSAP ? repeatSAPCopy : `` )
        setLoading( false )
      })
      .catch( () => {
        setRepeatMessage( `` )
        setLoading( false )
      })

    return () => { controller.abort() }
  }, [] )

  return {
    loading,
    repeatMessage: loading ? null : repeatMessage
  }
}

export default useCheckSAPRepeat