import React from 'react'
import { Builder } from '@builder.io/react'

type TeamMemberType = {
   name: string;
   role: string;
   desc: string;
   img: string;
   focus_areas?: string;
   certifications?: string;
   affiliations?: string;
   recognitions?: string;
}

type ContentProps = {
   team?: TeamMemberType[];
}

const AboutAeroflowSleepTeam = ( props: ContentProps ) => {

  return (
    <div className="px-4 mt-4 mb-4 w-full">
      {
        props?.team?.length && props.team.map( ( teamMember, index ) => {
          return (
            <div
              className="flex sm:flex-col lg:flex-row sm:items-center lg:items-start gap-3 sm:mb-12 lg:mb-16"
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              <div className="flex sm:justify-center lg:justify-start">

                <img
                  className="max-w-none max-h-none object-contain"
                  src={teamMember.img}
                  alt={teamMember.name}
                  height="215"
                  width="215"
                />

              </div>

              <div>

                <p className="sm:text-center lg:text-left"><b className="text-lg">{teamMember.name}</b></p>

                <p className="mb-2 sm:text-center lg:text-left font-light text-xl">{teamMember.role}</p>

                <p
                  className="mb-2" dangerouslySetInnerHTML={{
                    __html: teamMember.desc
                  }}
                >
                </p>

                {teamMember.focus_areas &&
                  <p className="mb-2">
                    <b>{`Focus Areas: `}</b>{teamMember.focus_areas}
                  </p>
                }
                {teamMember.certifications &&
                  <p className="mb-2">
                    <b>{`Certifications: `}</b>{teamMember.certifications}
                  </p>
                }
                {teamMember.affiliations &&
                  <p className="mb-2">
                    <b>{`Affiliations: `}</b>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: teamMember.affiliations
                      }}
                    ></span>
                  </p>
                }
                {teamMember.recognitions &&
                  <p className="mb-2">
                    <b>{`Recognitions: `}</b>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: teamMember.recognitions
                      }}
                    ></span>
                  </p>
                }
                {/*

                  teamMember.links?.length &&
                  <MemberWebLinks wwwLinks={leadershipTeamMember.links} />
        */}
              </div>



            </div>
          )
        })
      }
    </div>
  )
}

Builder.registerComponent( AboutAeroflowSleepTeam, {
  name: `AboutAeroflowSleepTeam`,
  inputs: [
    {
      name: `team`,
      type: `list`,
      subFields: [{
        name: `name`,
        type: `string`
      },
      {
        name: `role`,
        type: `string`
      },
      {
        name: `desc`,
        type: `richText`
      },
      {
        name: `img`,
        type: `string`
      },
      {
        name: `focus_areas`,
        type: `string`,
        required: false
      },
      {
        name: `certifications`,
        type: `string`,
        required: false
      },
      {
        name: `affiliations`,
        type: `richText`,
        required: false
      },
      {
        name: `recognitions`,
        type: `string`,
        required: false
      }]
    }
  ]
})

export default AboutAeroflowSleepTeam