import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import CustomSkeletonTheme from 'components/reactSkeleton/SkeletonTheme'

// 4 x 1 dsktp grid, 2 x 2 mobile grid

type TwoColGridSkeletonProps = {
  isMobile?: boolean;
}

const TwoColGridSkeleton = ({isMobile} : TwoColGridSkeletonProps ) => {

  return (
    <CustomSkeletonTheme>
      <div className="sm:px-4 md:px-6">
        <div className={`grid sm:py-5 md:py-12 ${isMobile ? `grid-cols-2` : `grid-cols-4`} gap-4`}>
          {[ ...Array( 4 ) ].map( ( _, index ) => (
            <Skeleton key={index} height={`auto`} className="aspect-square" />
          ) )}
        </div>
      </div>
    </CustomSkeletonTheme>
  )
}

export default TwoColGridSkeleton