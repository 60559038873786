import React from 'react'
import TrackerStepIcon from '../../TrackerStepIcon'
import { faceToFaceRequirementsCopy } from '../constants'
import { listWithCheckmark } from 'resources/images'
import StepContentRenderer from '../../StepContentRenderer'

type FaceToFaceRequirementsStepProps = {
    stepStatus: boolean;
    isCurrentStep?: boolean;
 }

function FaceToFaceRequirementsStep({ stepStatus, isCurrentStep } : FaceToFaceRequirementsStepProps ) : JSX.Element {
  return (
    <div className="flex">
      <TrackerStepIcon
        imageSrc={listWithCheckmark}
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
      />
      <StepContentRenderer
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
        stepCopy={faceToFaceRequirementsCopy}
      />
    </div>
  )
}

export default FaceToFaceRequirementsStep
