import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import CustomSkeletonTheme from 'components/reactSkeleton/SkeletonTheme'
import HeroMainCtaSkeleton from './HeroMainCtaSkeleton'

type HomePageHeroSkeleton = {
    isMobile?: boolean;
}

const HomePageHeroSkeleton = ({ isMobile } : HomePageHeroSkeleton ) => {
  return (
    <CustomSkeletonTheme>
      <div className="sm:min-h-[360px] lg:min-h-0 mb-0 sm:px-4 md:px-6 pt-9" >
        {
          isMobile ? (
            <Skeleton
              count={4}
              height={64}
              className="mb-4"
            />
          ) : (
            <div className="flex gap-[26px] pt-9 justify-center">
              {[ ...Array( 4 ) ].map( ( _, index ) => (
                <div key={index} className="w-[200px]">
                  <Skeleton height={120} className="mb-2" />
                  <Skeleton width={150} height={20} className="mb-2" />
                  <Skeleton count={3} height={16} className="mb-1" />
                </div>
              ) )}
            </div>
          )
        }
        <HeroMainCtaSkeleton />
      </div>
    </CustomSkeletonTheme>
  )
}

export default HomePageHeroSkeleton