/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { FieldData, Lead } from '../../graphql/models/Lead'
import { Validate } from '../qualifyForm/validation'

export const promoContentTransform = (
  node: any,
  heroSrc: string,
  setHeroSrc: React.Dispatch<React.SetStateAction<string>>,
  handleSetHeroContent: ( _key: string, _content: string ) => void
) : void | null => {
  if ( node.type === `tag` && node?.attribs[`data-element`] === `mobile_image` ) return null
  if ( node?.attribs?.class ) {
    if ( node.attribs.class.includes( `lg-` ) ) node.attribs.class = node.attribs.class.replaceAll( `lg-`, `lg:` )
    if ( node.attribs.class.includes( `md-` ) ) node.attribs.class = node.attribs.class.replaceAll( `md-`, `md:` )
    if ( node.attribs.class.includes( `sm-` ) ) node.attribs.class = node.attribs.class.replaceAll( `sm-`, `sm:` )
    if ( node.attribs.class.includes( `ratio` ) ) node.attribs.class = node.attribs.class.replaceAll( `ratio`, `/` )
    if ( node.attribs.class.includes( `hero-background-img` ) && node?.children?.length ) {
      if ( node.children[0]?.attribs?.src && heroSrc !== node.children[0].attribs.src ) setHeroSrc( node.children[0].attribs.src )

      return null
    } else if ( node.attribs.class.includes( `hero-heading` ) && node?.children?.length && node.children[0]?.data ) {
      handleSetHeroContent( `heroHeading`, node.children[0]?.data )

      return null
    } else if ( node.attribs.class.includes( `qualify-heading` ) && node?.children?.length && node.children[0]?.data ) {
      handleSetHeroContent( `qualifyHeading`, node.children[0]?.data )

      return null
    }
  }

  if ( node.type === `tag` && node?.name === `img` ) {
    // change media folder from sleep to breastpumps ( should default to breastpumps but not the case in prod )
    node.attribs.src = node.attribs.src.replaceAll( process.env.REACT_APP_MAGENTO_2_BASE_URL, process.env.REACT_APP_CMS_IMAGE_URL )
    node.attribs.loading = `lazy`
  }
}

export const mapStateNameToAbbreviation = ( stateName: string | undefined ): string | undefined => {
  const stateMap = new Map<string, string>( [
    [ `Alabama`, `AL` ],
    [ `Alaska`, `AK` ],
    [ `Arizona`, `AZ` ],
    [ `Arkansas`, `AR` ],
    [ `California`, `CA` ],
    [ `Colorado`, `CO` ],
    [ `Connecticut`, `CT` ],
    [ `Delaware`, `DE` ],
    [ `Florida`, `FL` ],
    [ `Georgia`, `GA` ],
    [ `Hawaii`, `HI` ],
    [ `Idaho`, `ID` ],
    [ `Illinois`, `IL` ],
    [ `Indiana`, `IN` ],
    [ `Iowa`, `IA` ],
    [ `Kansas`, `KS` ],
    [ `Kentucky`, `KY` ],
    [ `Louisiana`, `LA` ],
    [ `Maine`, `ME` ],
    [ `Maryland`, `MD` ],
    [ `Massachusetts`, `MA` ],
    [ `Michigan`, `MI` ],
    [ `Minnesota`, `MN` ],
    [ `Mississippi`, `MS` ],
    [ `Missouri`, `MO` ],
    [ `Montana`, `MT` ],
    [ `Nebraska`, `NE` ],
    [ `Nevada`, `NV` ],
    [ `New Hampshire`, `NH` ],
    [ `New Jersey`, `NJ` ],
    [ `New Mexico`, `NM` ],
    [ `New York`, `NY` ],
    [ `North Carolina`, `NC` ],
    [ `North Dakota`, `ND` ],
    [ `Ohio`, `OH` ],
    [ `Oklahoma`, `OK` ],
    [ `Oregon`, `OR` ],
    [ `Pennsylvania`, `PA` ],
    [ `Rhode Island`, `RI` ],
    [ `South Carolina`, `SC` ],
    [ `South Dakota`, `SD` ],
    [ `Tennessee`, `TN` ],
    [ `Texas`, `TX` ],
    [ `Utah`, `UT` ],
    [ `Vermont`, `VT` ],
    [ `Virginia`, `VA` ],
    [ `Washington`, `WA` ],
    [ `West Virginia`, `WV` ],
    [ `Wisconsin`, `WI` ],
    [ `Wyoming`, `WY` ]
  ] )

  return stateMap.get( stateName || `` )
}

export const getFormDataValue = ( data: {lead: Lead}, key: string ): string | undefined => {
  return data?.lead?.field_data?.find( ( field: FieldData ) => field.name === key )?.values[0]
}

export const validateAndCheckForNull = ( fieldName: string, value: string | undefined ) => {
  return Validate( fieldName, value ) === `` ? value ?? `` : ``
}