import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

interface SnlPatientHashStore {
  snlPatientHash: string | null
  setSnlPatientHash: ( _snlPatientHash: string ) => void
  clearSnlPatientHash: () => void
}

export const getSnlPatientHash = () => {
  const snlPatientHashStore = JSON.parse( sessionStorage.getItem( `snl-patient-hash` ) || `{"state":{"snlPatientHash":null},"version":0}` )
  return snlPatientHashStore.state.snlPatientHash || ``
}

export const useSnlPatientHashStore = create<SnlPatientHashStore>()(
  persist(
    ( set ) => ({
      snlPatientHash: null,
      setSnlPatientHash: ( snlPatientHash ) => set({
        snlPatientHash
      }),
      clearSnlPatientHash: () => set({
        snlPatientHash: null
      })
    }),
    {
      storage: createJSONStorage( () => sessionStorage ),
      name: `snl-patient-hash`
    }
  )
)

export const clearSnlPatientHash = () => {
  sessionStorage.removeItem( `snl-patient-hash` )
}
