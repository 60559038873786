import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { resetQualifyForm } from './qualifyFieldsStore'
import { trackUserLoginEvent } from 'utils/analytic'

interface TokenStore {
  customerToken: string;
  setCustomerToken: ( _customerToken: string ) => void;
  clearCustomerToken: () => void;
}

// these are for util functions
export const getToken = () => {
  const tokenStore = JSON.parse( sessionStorage.getItem( `token-store` ) || `{"state":{"customerToken":""},"version":0}` )
  return tokenStore?.state?.customerToken || ``
}

export const clearToken = () => {
  sessionStorage.removeItem( `token-store` )
  sessionStorage.removeItem( `clinical-scheduling-submitted` )
  sessionStorage.removeItem( `resupply-order-placed` )
  resetQualifyForm()
  sessionStorage.setItem( `invoice-control`, JSON.stringify({
    fetchEnabled: true,
    data: null
  }) )
}

export const useTokenStore = create<TokenStore>()(
  persist(
    ( set ) => ({
      customerToken: ``,
      setCustomerToken: ( customerToken: string ) => {
        set({
          customerToken
        })
        trackUserLoginEvent() // fire dataLayer event upon login
      },
      clearCustomerToken: () => {
        sessionStorage.removeItem( `clinical-scheduling-submitted` )
        sessionStorage.removeItem( `resupply-order-placed` )
        sessionStorage.setItem( `invoice-control`, JSON.stringify({
          fetchEnabled: true,
          data: null
        }) )
        set({
          customerToken: ``
        })
      }
    }),
    {
      name: `token-store`,
      storage: createJSONStorage( () => sessionStorage )
    }
  )
)