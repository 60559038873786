import { create } from 'zustand'

type AobSegmentState = {
  aobSegmentType: string | null;
  setAobSegmentType: ( _type: string ) => void;
}

export const useAobSegmentStore = create<AobSegmentState>( ( set ) => ({
  aobSegmentType: null,
  setAobSegmentType: ( _type: string ) => set({
    aobSegmentType: _type
  })
}) )