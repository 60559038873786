import { reportToSentry } from "utils/reportToSentry"

type LeadsQualifyBody = {
  form_code: string
  email: string,
  first_name:string,
  last_name: string,
  phone: string,
  dob?: string,
  state?: string,
  zipcode?: string,
  insurance_primary_provider?: string,
  insurance_primary_policy_number?: string,
  has_sleep_apnea?: boolean,
  how_did_you_hear_about_us?: string,
  referral_url?: string,
  utm_campaign?: string,
  fbclid?: string,
  gclid?: string,
  msclkid?: string,
  insurance_secondary_provider?: string,
  insurance_secondary_policy_number?: string
}

type LeadsQualifyResponse = {
  data?: {
    qualify_event_tracking_id: string | number;
  },
  meta?: {
    status: string;
  },
  errors?: {
    message: string;
    context: {
      key: string;
    }
  }[];
}

export default async function postLeadsQualify( body : LeadsQualifyBody ) : Promise<LeadsQualifyResponse> {
  const requestInit = {
    method: `POST`,
    headers: {
      'Content-Type': `application/json`
    },
    body: JSON.stringify( body )
  }

  const response = await fetch( `${process.env.REACT_APP_LAMBDA_LEADS_URL}/qualify?sync=true`, requestInit ).catch( reason => {
    reportToSentry( `Leads/Qualify request failed`, {
      rejectReason: JSON.stringify( reason )
    })
  })

  const responseBody = await response?.json()

  return responseBody
}