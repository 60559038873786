import DetailsBlock from "./DetailsBlock"
import { ThankYouDetailsFromSNL } from "./ThankYouDetailsCopy"

export default function ThankYou() {

  return (
    <div className="max-w-xl md:mx-auto sm:mx-4 mb-20">
      <h3 className="sm:text-3xl md:text-[42px] font-semibold font-inter text-[#0057A8] sm:m-5 lg:mb-14">{`What's next?`}</h3>
      <div className="max-w-lg>">
        {
          ThankYouDetailsFromSNL.map( detail => {
            return (
              <DetailsBlock
                key={detail.title}
                {...detail}
              />
            )
          })
        }

        <div className="flex sm:max-w-md md:max-w-lg gap-2">
          <div className="w-1/4"></div>
          <p className="sm:ml-4 md:ml-0 mt-0 w-3/4 text-deepSleepBlue">{`Be on the lookout for communication from us within 5 business days.`}</p>
        </div>

      </div>
    </div>
  )
}