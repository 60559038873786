import { gql } from '@apollo/client'

import { customerAddressFragment } from 'graphql/fragments'

export const updateCustomerAddressMutation = gql`
  ${customerAddressFragment}
  mutation UpdateCustomerAddressMutation($id: Int!, $customerAddressInput: CustomerAddressInput!) {
    updateCustomerAddress(id: $id, input: $customerAddressInput) {
      ...CustomerAddress
    }
  }
`
