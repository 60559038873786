import { useQuery } from '@apollo/client'
import { useLocation, Navigate } from 'react-router-dom'

import { heartbeatQuery } from 'graphql/queries'
import { reportToSentry } from 'utils/reportToSentry'
import LoadingSpinner from 'components/LoadingSpinner'
import { INSTANT_LOGIN_PATH, LOGIN_PATH, SHOULD_DISABLE_INSTANT_LOGIN } from 'routes'
import { useTokenStore } from 'stores/tokenStore'

const AuthRoute = ({...routeProps}) : JSX.Element => {
  const location = useLocation()
  const { customerToken } = useTokenStore()

  const urlParams = new URLSearchParams( location.search )
  const hash = urlParams.get( `sgh` ) ?? urlParams.get( `txt` ) ?? ``

  const { loading, error } = useQuery( heartbeatQuery, {
    skip: !customerToken,
    onError: ( error ) => {
      reportToSentry( `heartbeatQuery Error -> AuthRoute.js`, {
        error: error?.message,
        jsonError: JSON.stringify( error )
      })
      window.location.href = `/login`
    },
    fetchPolicy: `network-only`
  })


  if ( !customerToken || error ) return (
    <Navigate
      to={`${( hash?.length && !SHOULD_DISABLE_INSTANT_LOGIN ) ? INSTANT_LOGIN_PATH : LOGIN_PATH}${location.search}`}
      state={{
        from: location
      }}
      replace
    /> )

  if ( loading ) return ( <div className="m-2 min-h-240 flex justify-center items-center">
    <LoadingSpinner />
  </div> )

  return routeProps.element
}


export default AuthRoute
