import styled from 'styled-components'
import { DayPicker } from 'react-day-picker'
import tailwindColors from 'config/tailwindColors'

export const StyledDayPicker = styled( DayPicker )`
.available {
  transition: all 0.25s ease-in-out; 
  background: #1d57a8;
  color: white;
  border-radius: 50%;
}

.available:hover {
  transform: scale( 1.20 );
  background: white;
  color: #1d57a8;
  cursor: pointer;
}

.disabled {
  pointer-events: none;
}

.rdp-cell {
  padding: 14px;
  cursor: default;
  color: rgba(0, 0, 0, 0.18);
  border: none;
  border-radius: 0;
  width: 45px;
  height: 45px;
  background: none;
}

.rdp-day {
  padding: 3px;
}

.rdp-day:not(.available) {
  color: rgba(0, 0, 0, 0.18);
  pointer-events: none;
}

.selected {
  border-radius: 50%;
  box-shadow: 0px 0px 0px 12px #cfdaef;
}

.rdp-day_today {
  border: 1px solid rgba(0, 0, 0, 0.18);
}

.rdp-head_cell {
  color: rgb(139, 152, 152);
}

@media screen and (max-width: 600px) {
  .rdp-cell {
    height: 20px;
    width: 20px;
    padding: 0;
  }
  .rdp-day {
    padding: 0;
    transform: scale( 0.7 );
  }
  .rdp-day:hover {
    transform: scale( 0.9 );
  }
  .selected {
    border-radius: 50%;
    box-shadow: 0px 0px 0px 5px #cfdaef;
  }

  .rdp-months {
    width: 100%;
  }
}
`

export const AppointmentButton = styled.button.attrs({
  className: `p-2 px-4 mx-1 my-2 border-none`
})`
  background-color: ${props => {
    return props.name === `active`? tailwindColors.deepSleepBlue: tailwindColors.white
  }};
  color: ${props => {
    return props.name === `active` ? tailwindColors.white : tailwindColors.deepSleepBlue
  }};
  width: fit-content;
  border-radius: 25px;
  border: 1px solid ${tailwindColors.deepSleepBlue};
  box-sizing: border-box;

  &:active {
    transform: scale(0.98);
  }
  &:hover {
    cursor: pointer;
    background-color: ${tailwindColors.deepSleepBlue};
    color: white;
  }
`
