import { useState } from 'react'
import styled from 'styled-components'
import {
  healthlineImg,
  webmdImg,
  marthaStewartImg,
  webmdBlueImg,
  marthaStewartBlueImg,
  sleepopolisImg,
  sleepopolisBlueImg,
  insiderBlueImg,
  insiderImg,
  healthlineBlueImg
} from 'resources/images'

export type ImageBlockProps = {
  imgSrc: string; title: string; width: string; height: string; hoverImgSrc: string, href: string
}

const imgs = [
  {
    imgSrc: webmdImg,
    hoverImgSrc: webmdBlueImg,
    title: `WebMD`,
    width: `110`,
    href: `https://www.webmd.com/connect-to-care/sleep-apnea/the-most-severe-sleep-apnea-symptoms`
  },
  {
    imgSrc: healthlineImg,
    hoverImgSrc: healthlineBlueImg,
    title: `Health`,
    width: `150`,
    href: `https://www.healthline.com/health-news/mouth-taping-what-to-know-about-this-dangerous-tiktok-trend`
  },
  {
    imgSrc: sleepopolisImg,
    hoverImgSrc: sleepopolisBlueImg,
    title: `Sleepopolis`,
    width: `160`,
    href: `https://sleepopolis.com/education/the-future-of-sleep-apnea-treatment`
  },
  {
    imgSrc: marthaStewartImg,
    hoverImgSrc: marthaStewartBlueImg,
    title: `Martha Stewart`,
    width: `120`,
    href: `https://www.marthastewart.com/8164277/wine-before-bed-sleep-disruptor`
  },
  {
    imgSrc: insiderImg,
    hoverImgSrc: insiderBlueImg,
    title: `Insider`,
    width: `140`,
    href: `https://www.insider.com/guides/health/diet-nutrition/is-it-bad-to-eat-late-at-night`
  }
] as Array<ImageBlockProps>

const HoverEffectImg = styled.img`
  transition: .3s ease-in-out;
  :focus, :hover {
    transform: scale(1.2);
    cursor: pointer;
  }
`

export function ImageBlock({imgSrc, hoverImgSrc, href, title, width, height} : ImageBlockProps ) : JSX.Element {
  const [ hover, setHover ] = useState( false )

  // RRV6: UPDATE TO REACT_ROUTER V6 -> this is a placehoder comment. Once React Router v6 gets merged I will search for this
  return (
    <div className="mx-8 my-2">
      <a href={href} target="_blank" rel="noreferrer">
        <HoverEffectImg
          src={hover ? hoverImgSrc : imgSrc}
          alt={`As seen on ${title}`}
          width={width}
          height={height}
          loading="lazy"
          onMouseEnter={() => { return setHover( true ) }}
          onMouseLeave={() => { return setHover( false ) }}
        />
      </a>
    </div>
  )
}

export default function AsSeenInBlock() : JSX.Element {
  return (
    <>
      <h2 className="max-w-sm mx-auto text-center">{`As Seen In`}</h2>
      <div className="flex flex-wrap items-center justify-center">
        {imgs.map( ( img ) => {
          return (
            <ImageBlock
              key={img.title}
              {...img}
            />
          )
        })}
      </div>
    </>
  )
}
