import { addMagentoEnvAuthHeaders } from "utils/magentoEnvAuthHeaders"
import { reportToSentry } from "utils/reportToSentry"

export type DemographicsInput = {
  patientId?: number | string,
  firstName?: string,
  lastName?: string,
  dob?: string,
  phone?: string,
  emailAddress?: string,
  magentoCustomerID?: number
}

export default async function postDemographics( authorizationHeader : string, input : DemographicsInput ):Promise<boolean> {

  const reqInit = {
    headers: {
      ...addMagentoEnvAuthHeaders(),
      'Authorization': authorizationHeader,
      'Content-Type': `response/json`
    },
    body: JSON.stringify( input ),
    method: `POST`
  }

  const response = await fetch( `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/update-demographics`, reqInit ).catch( error => {

    reportToSentry( `Error updating patient demographics`, {
      errorReturned: JSON.stringify( error )
    })

    throw new Error( `Error updating patient demographics` )
  })

  return await response.json()

}