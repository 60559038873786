import React, { useEffect } from 'react'
import AccountTrackerDisplay from './components/AccountTrackerDisplay'
import { useAccountStore } from '../../modules/accountV2/state'
import { accountTrackerDataFetch } from './utils/utils'
import { reportToSentry } from 'utils/reportToSentry'
import { refreshTrackerDataStore } from '../../stores'

function AccountTracker(): JSX.Element {
  const { accountTracker, setAccountTracker } = useAccountStore()

  // trackerRefreshIndex updates every time fetchTrackerData is called to trigger a refresh of the account tracker data after the 1st mount
  const { trackerRefreshIndex } = refreshTrackerDataStore()

  const errorMessage = `useAccountData: Error refreshing account tracker data`

  const fetchTrackerData = async () => {
    accountTrackerDataFetch()
      .then( ( response ) => {
        if ( response.meta?.status !== `OK` ) throw new Error( errorMessage )
        setAccountTracker({
          data: response?.data,
          error: response ? undefined : response,
          loading: false
        })
      })
      .catch( ( error ) => {
        setAccountTracker({
          data: undefined,
          error,
          loading: false
        })
        reportToSentry( new Error( errorMessage, {
          cause: error
        }) )
      })
  }

  // trigger a refresh of the account tracker data
  useEffect( () => {
    if ( !trackerRefreshIndex ) return
    fetchTrackerData()
  }, [ trackerRefreshIndex ] )

  return (
    <>
      {accountTracker.data && <AccountTrackerDisplay trackerDetails={accountTracker.data} />}
    </>
  )
}

export default AccountTracker