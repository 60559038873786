import React from 'react'
import { Builder } from '@builder.io/react'
import { TerritoryType } from './types'

type ContentProps = {
   territories?: TerritoryType[];
}

export const TerritoryGrid = ( props: ContentProps ) => {

  return (
    <div className="flex flex-wrap">
      { props?.territories?.length && props?.territories?.map( ( territoryObj, index ) => {

        return (
          // eslint-disable-next-line react/no-array-index-key
          <div className="w-1/3 border grow-0 shrink-0 px-4 py-4" key={index}>
            <p className="text-center">
              <a
                className="a"
                href={`#territory-${territoryObj.id}`}
              >{territoryObj.territory}</a></p>
          </div>
        )
      })}
    </div>
  )
}

Builder.registerComponent( TerritoryGrid, {
  name: `TerritoryGrid`,
  inputs: [{
    name: `territories`,
    type: `list`,
    subFields: [{
      name: `territory`,
      type: `string`
    },
    {
      name: `id`,
      type: `string`
    }],
    required: false
  }]
})

export default TerritoryGrid