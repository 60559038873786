
interface SelectInputContainerProps {
  children: React.ReactNode;
  label?: string;
  errorMessage?: string | undefined;
  errorMessageClassName?: string;
  labelClassName?: string;
  asteriskClassName?: string;
}

export default function SelectInputContainer({children,
  label = ``,
  errorMessage = `select-error`,
  errorMessageClassName = `text-error px-3`,
  labelClassName = `input-label text-left`,
  asteriskClassName = `font-semibold text-error` }: SelectInputContainerProps ) {
  return (
    <div className="flex flex-col">
      <label className={labelClassName}>{label} <span className={asteriskClassName}>{`*`}</span> </label>
      {children}
      {errorMessage && <div className={errorMessageClassName}>{errorMessage}</div>}
    </div>
  )
}