import { getAuthHeader } from "utils/auth/helpers"
import { addMagentoEnvAuthHeaders } from "utils/magentoEnvAuthHeaders"
import { reportToSentry } from "utils/reportToSentry"
export type UpdateInsuranceBody = {
    insurance_type: string,
    parent_payer_pk?: number,
    member_id?: string
}

export default async function updateInsurance( use_new: boolean, patient_id: string, payload_body : UpdateInsuranceBody ) {
  const requestInit = {
    method: use_new ? `PUT` : `POST`,
    headers: {
      ...addMagentoEnvAuthHeaders(),
      'Authorization': getAuthHeader(),
      "Content-Type" : `application/json`
    },
    body: JSON.stringify( use_new ? {
      store_name: `aeroflowsleep_english`,
      profit_center_pk: 5,
      patient_id: parseInt( patient_id ),
      payers: [ payload_body ]
    } :
      {
        patient_id: parseInt( patient_id ),
        [`${payload_body.insurance_type.toLowerCase()}_payer_pk`]: payload_body.parent_payer_pk,
        [`${payload_body.insurance_type.toLowerCase()}_member_id`]: payload_body.member_id
      }
    )
  }

  // we toggle the endpoint here as follows - patients is used for primary/secondary as a catch all for those 2, resupply is used for resupply insurance update
  const ENDPOINT = use_new ? `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/insurance-update-v2` : `${process.env.REACT_APP_LAMBDA_RESUPPLY_URL}/insurance-update`

  const response = await fetch( ENDPOINT, requestInit ).catch( error => {
    reportToSentry( new Error( `Error updating insurance lambda` ), {
      responseError: error,
      requestBody: JSON.stringify( payload_body )
    })
  }) as Response

  return await response.json()
}