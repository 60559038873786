import React, { useEffect, useRef, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { Link } from 'react-router-dom'

import { activeMobileMenuTab } from 'apollo'

import MenuItems from './components/MenuItems'
import LeftNav from 'components/account/LeftNav'
import SearchBar from 'components/headerBarV2/search/SearchBar'
import { burgerBarImg, icoTelephone } from 'resources/images'
import { MY_ACCOUNT_PATH } from 'routes'
import { useTokenStore } from 'stores/tokenStore'
import { useSnowdogMenusStore } from '../state'

const MegaMiniMenu = () => {
  const [ isActive, setIsActive ] = useState( false )
  const { customerToken } = useTokenStore()
  const activeMenu = useReactiveVar( activeMobileMenuTab )
  const menuRef = useRef( null )
  const {menus} = useSnowdogMenusStore()
  useEffect( () => {
    const path = window.location.pathname
    // Will set the menu to pull up account menu if the path is within /account
    if ( path.includes( MY_ACCOUNT_PATH ) ) {
      activeMobileMenuTab( `Account` )
    }

    const handleClickOutside = e => {
      if ( !menuRef?.current?.contains( e.target ) ) {
        setIsActive( false )
      }
    }

    document.addEventListener( `click`, handleClickOutside )

    return () => {
      document.removeEventListener( `click`, handleClickOutside )
    }
  }, [] )

  const toggleMenuIsActive = () => {
    setIsActive( !isActive )
  }

  function _isActive( menu ) {
    return ( menu === activeMenu )
  }

  function showMenu() {
    activeMobileMenuTab( `Menu` )
  }

  function showAccountMenu() {
    activeMobileMenuTab( `Account` )
  }

  const menuClassName = `pb-1 pt-2 w-full text-center text-aeroflowNavy`
  const inActiveMenuClassName = `${menuClassName} bg-[#D5D9DF]`

  if ( menus.loading || menus.error ) return null

  return (
    <div className="lg-wide:hidden shrink-0 flex" ref={menuRef}>
      <button
        type="button"
        onClick={toggleMenuIsActive}
        className="focus:outline-none"
      >
        <img
          src={burgerBarImg}
          alt="Open Menu"
          width={28}
          height={28}
        />
      </button>

      <div className={`fixed top-0 left-0 bottom-0 bg-white overflow-y-scroll z-50 w-4/5 font-normal box-shadow-heavy ${!isActive ? `hidden` : ``}`}>
        <div className="flex flex-row">
          <button
            type="button"
            className={`${_isActive( `Menu` ) ? menuClassName : inActiveMenuClassName} cursor-pointer focus:outline-none font-normal`}
            onClick={showMenu}
          >
            {`Menu`}
          </button>

          <button
            type="button"
            className={`${_isActive( `Account` ) ? menuClassName : inActiveMenuClassName} cursor-pointer focus:outline-none font-normal`}
            onClick={showAccountMenu}
          >
            {`Account`}
          </button>
        </div>

        <div className="px-4 pt-8">
          {_isActive( `Menu` ) && (
            <>
              <a
                className="block mb-4 text-base white text-aeroflowNavy font-normal"
                href="tel:+18004805491" type="tel"
              >
                <img
                  src={icoTelephone}
                  height="16"
                  width="16"
                  className="mr-2 inline"
                  alt="Phone Image"
                />
                {`1 (800) 480-5491`}
              </a>

              <MenuItems />
              <SearchBar />
            </>
          )}

          {_isActive( `Account` ) && (
            <>
              <Link to={customerToken ? `/logout` : `/login`} className="block mb-4 font-normal text-left w-full focus:outline-none text-aeroflowNavy" >
                {customerToken ? `Log out` : `Log in`}
              </Link>

              {customerToken ? <LeftNav /> : <MenuItems />}
            </>
          ) }
        </div>
      </div>
    </div>
  )
}

export default MegaMiniMenu
