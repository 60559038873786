import * as Sentry from "@sentry/react"

import { apolloClient } from 'apollo/apolloClient'
import { filterCache } from 'utils/filterCache'

export const reportToSentry = ( error : Error | string, extraErrorDataObject = {}) => {
  Sentry.withScope( ( scope : Sentry.Scope ) => {
    scope.setContext( `Apollo Cache`, {
      apolloCacheData: JSON.stringify( filterCache( apolloClient?.cache?.data?.data ) )
    })
    scope.setContext( `Extra Details`, extraErrorDataObject )
    Sentry.captureException( ( typeof error === `string` ) ? new Error( error ) : error )
  })
}

export const getStringFromUnknownError = ( error : unknown ) : string => {
  if ( error instanceof Error ) {
    return error.message
  } else if ( typeof error === `string` ) {
    return error
  } else {
    return `Unknown error type - ${JSON.stringify( error )}`
  }
}