export const EligibleForNewMachineCopy = {
  completedStepHeading: `Eligible for New Machine`,
  completedStepDetails: `You're eligible for a new machine through insurance!`
}

export const insuranceInformationCopy = {
  notCompletedStepHeading: `Insurance Information Not Verified`,
  currentStepDetails: `We're having an issue verifying your insurance information. Make sure your name and member ID matches your insurance card. If recently updated in our system, please allow up to 24 hours for this update to reflect.`,
  stepLinkText: `View/change your insurance information`,
  stepToolTip: `We must verify that your insurance information is correct and that we are in network with your provider before we can move forward.`,
  completedStepHeading: `Insurance Information Verified`
}

export const prescriptionNeededCopy = {
  notCompletedStepHeading: `Obtain Documentation from MD`,
  currentStepDetails: `Our representatives are working to obtain all medical documentation that is required by your insurance provider.`,
  stepLinkText: `View/change your MD information`,
  stepLinkUrl: `#my-account-physician`,
  stepToolTip: `Your insurance provider requires that we obtain a prescription, sleep study, and recent office visit notes stating that you are using your current machine and benefiting from therapy.`,
  completedStepHeading: `Documentation from MD Received`
}

export const insurancePriorAuthCopy = {
  notCompletedStepHeading: `Prior Authorization Needed`,
  currentStepDetails: `Your insurance provider requires prior authorization before we can ship your order. Our representatives are working to obtain this. Please reference your email for important updates.`,
  stepToolTip: `Your insurance provider requires prior authorization before we can ship out your order.`,
  completedStepHeading: `Prior Authorization Obtained`
}