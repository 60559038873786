import { RadioInput } from "components/forms/components"
import LoadingSpinner from "components/LoadingSpinner"
import { useState } from 'react'
import AuthBorderWrap from "./AuthBorderWrap"

type EmailSelectionFormProps = {
  emailSelection: string[];
  onEmailSubmit: ( _e: React.FormEvent, _selectedEmail : string ) => void;
  shouldLoad: boolean;
}
export default function EmailSelectionForm({emailSelection, onEmailSubmit, shouldLoad} : EmailSelectionFormProps ) {

  const [ selectedEmail, setSelectedEmail ] = useState<string>( ( emailSelection && emailSelection[0] ) ?? `` )


  return (
    <AuthBorderWrap title="Multiple Emails Found">
      <p>{`Multiple email addresses are associated with the information you have entered. Please select which email you would like to log in from below:`}</p>
      <form onSubmit={( e ) => { onEmailSubmit( e, selectedEmail ) }} className="flex flex-col items-center">
        <ul className="my-4">
          { emailSelection.map( ( email : string ) => {
            return (
              <li key={email} onClick={() => { return setSelectedEmail( email ) }} className="my-4">
                <RadioInput
                  label={email} value={email} checked={email === selectedEmail}
                  name={`emailSelect`}
                  onChange={() => { return setSelectedEmail( email ) }}
                />
              </li>
            )
          })}
        </ul>
        <button type="submit" className="btn btn-primary" disabled={!selectedEmail.length || shouldLoad}>{shouldLoad ? <LoadingSpinner width={`32px`} height={`32px`} /> : `Log In to your Aeroflow Sleep Account`}</button>
      </form>
    </AuthBorderWrap>
  )
}