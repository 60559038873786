import React from 'react'
import { PhoneLink, EmailLink } from 'components/ALinks'
import { vidPlaceholderNoPlayButton } from 'resources/images'
import styled from 'styled-components'
import CMSBlock from 'components/cmsBlock'

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 150%;
  bottom: -22%;
  left: 128px;
`

export const VideoBlock = () => {
  return (
    <div className="flex overflow-hidden sm:flex-col-reverse md:flex-row md:items-center md:justify-center px-12 sm:bg-lightGray md:bg-opacity-0">
      <div className="md:w-1/2 px-4">
        <CMSBlock
          type="text"
          identifier="react-video-block-header"
          customTag="h3"
          styling="text-center my-4"
          failSafe="Sleep Apnea is Manageable"
        />
        <div className="sm:mb-8 md:w-2/3 my-0 mx-auto text-center">
          <CMSBlock
            identifier="react-video-block-text"
            customTag="span"
            type="text"
            containerStyling="inline"
            failSafe="Aeroflow Sleep’s experienced Sleep Specialists are here to guide you through every step of your sleep apnea journey. "
          />
          <p className="inline">
            {` If you have any questions, email us at `}
            <EmailLink email={`info@aeroflowsleep.com`} />
            {` or call at `}
            <PhoneLink displayNumber={`(800) 480-5491`} />
            {`. We’re here to help!`}
          </p>
        </div>
      </div>
      <div className="sm:mt-8 md:my-8 md:w-1/2 lg:max-w-lg relative">
        <Background className="bg-lightGray sm:hidden md:block" />
        <CMSBlock
          type="video"
          identifier="react-video-block-video"
          failSafe={{
            image: vidPlaceholderNoPlayButton,
            videoID: `vfyiuYz46E0`
          }}
          customProps={{
            title: `Telehealth Solutions for Sleep Apnea`
          }}
        />
      </div>
    </div>
  )
}

export default VideoBlock
