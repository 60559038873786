import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'


import { ColWrapper, ColHeader } from './tailwindWrappers'

const LinksCol = ({ panelHeader, links, row = false, hideHeader = false }) => {
  return (
    <ColWrapper>
      { !hideHeader && <ColHeader>{panelHeader}</ColHeader>}

      <ul className={`p-0 m-0 ${row && `flex gap-2 items-center justify-between`}`}>
        {links.map( ( link, i ) => {

          const { display, href, CMSPage, html, image } = link

          if ( image ) {
            return (
              <Link key={display} to={href}>
                <img src={image} alt={display} />
              </Link>
            )
          }

          if ( html ) {
            return (
              <div key={display} className="footer-info">
                {
                  ReactHtmlParser( html )
                }
              </div>
            )
          }

          return (
          // eslint-disable-next-line react/no-array-index-key
            <li key={i} className="my-3">
              {CMSPage ? <Link to={href}><span className="text-white text-sm xl:text-base">{display}</span></Link> : <a href={href} rel="noreferrer"><span className="text-white text-sm lg:text-base">{display}</span></a>}
            </li>
          )
        })}
      </ul>
    </ColWrapper>
  )
}
LinksCol.propTypes = {
  panelHeader: PropTypes.string,
  links: PropTypes.array.isRequired,
  hideHeader: PropTypes.bool,
  row: PropTypes.bool
}

export default LinksCol
