import { useContactInfoState } from 'modules/resupply2'


export default function ContactCard({ onEdit } : {onEdit: () => void}) : JSX.Element {
  const [ contactInfo ] = useContactInfoState()

  return (
    <div className="flex flex-col items-center">
      <p>{contactInfo.email}</p>
      <p>{contactInfo.phone}</p>
      <br></br>
      <p className="cursor-pointer text-deepSleepBlue underline" onClick={onEdit}>{`Edit Contact Info`}</p>
    </div>
  )
}