
import { useEffect } from "react"
import { getHash } from "modules/clinicalScheduling/utils"
import { useNavigate } from "react-router-dom"
import { ResupplyStorage } from 'apollo/storage'
import { useClaimedState, useResupplySetting } from 'modules/resupply2'
import AobPaymentsPortal from "./AobPaymentsPortal"
import { LAST_STEP_COMPLETED_SESSION_KEY, LastStepCompleted } from 'modules/aobPayments/constants'

const AobPayments = () => {
  // check if coming from resupply
  const urlParams = new URLSearchParams( window.location.search )
  const isResupply = urlParams.get( `isResupply` )
  const patientIdFromUrl = urlParams.get( `patientId` )

  const claimedItems = useClaimedState()[0]
  const { patientId } = useResupplySetting()[0]

  const navigate = useNavigate()

  useEffect( () => {
    if ( !isResupply && !getHash() && ( !claimedItems?.length || !ResupplyStorage.getOrderPlaced() || !patientId ) ) return navigate( `/404` )

    if ( isResupply && patientIdFromUrl ) {
      ResupplyStorage.setOrderPlaced( true )
      // if below is true we need to set a flag in state, flag needs to be inspected on the payments summary and if present do not show the shipping address input block
      sessionStorage.setItem( `patientShippingAddressCollectedPriorToPaymentPortalMount`, `true` )
      // this is to track where the patient came from when accessing aob payments page
      sessionStorage.setItem( LAST_STEP_COMPLETED_SESSION_KEY, LastStepCompleted.Resupply )
    }
  }, [] )

  return <AobPaymentsPortal type={getHash() ? `hash` : `token`} />
}

export default AobPayments