import React, { useState } from 'react'

type ToggleContentProps = {
  children: React.ReactNode
}

const ToggleContent = ({ children }: ToggleContentProps ) => {
  const [ showMore, setShowMore ] = useState( false )

  return (
    <div>
      <button onClick={() => setShowMore( !showMore )}>
        <p className="underline py-1">{showMore ? `See Less` : `See More`}</p>
      </button>
      {showMore && <div>{children}</div>}
    </div>
  )
}

export default ToggleContent
