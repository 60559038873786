export const reasonForVisitOptions = [
  {
    value: `sleep_apnea_q_1_a1`,
    label: `Refill my Sleep Apnea device or supplies`
  },
  {
    value: `sleep_apnea_q_1_a2`,
    label: `Obtain a new prescription for a sleep apnea device, I’ve never been prescribed one before`
  }
]

export const apneaDeviceOptions = [
  {
    value: `sleep_apnea_q_2_a1`,
    label: `ResMed`
  },
  {
    value: `sleep_apnea_q_2_a2`,
    label: `iBreeze`
  },
  {
    value: `sleep_apnea_q_2_a3`,
    label: `3B`
  },
  {
    value: `sleep_apnea_q_2_a4`,
    label: `Other`
  },
  {
    value: `sleep_apnea_q_2_a5`,
    label: `I don't know`
  }
]

export const apneaOptions = [
  {
    value: `sleep_apnea_q_3_a1`,
    label: `Obstructive Sleep Apnea`
  },
  {
    value: `sleep_apnea_q_3_a2`,
    label: `Central Sleep Apnea`
  },
  {
    value: `sleep_apnea_q_3_a3`,
    label: `Combination of Obstructive and Central`
  },
  {
    value: `sleep_apnea_q_3_a4`,
    label: `I don't know`
  }
]
export const apneaFreqOptions = [
  {
    value: `sleep_apnea_q_4_a1`,
    label: `Daily`
  },
  {
    value: `sleep_apnea_q_4_a2`,
    label: `Occasionally`
  },
  {
    value: `sleep_apnea_q_4_a3`,
    label: `Rarely, almost never`
  }
]
export const apneaMedConditions = [
  {
    value: `sleep_apnea_q_5_a1`,
    label: `Large Neck Diameter`
  },
  {
    value: `sleep_apnea_q_5_a2`,
    label: `Obesity`
  },
  {
    value: `sleep_apnea_q_5_a3`,
    label: `Diabetes`
  },
  {
    value: `sleep_apnea_q_5_a4`,
    label: `High blood pressure`
  },
  {
    value: `sleep_apnea_q_5_a5`,
    label: `None of the above`
  }
]
export const healthTapBloodPressureOptions = [
  {
    value: `sleep_apnea_q_6_a1`,
    label: `Yes`
  },
  {
    value: `sleep_apnea_q_6_a2`,
    label: `No`
  }
]
export const apneaLastDiscussedOptions = [
  {
    value: `sleep_apnea_q_7_a1`,
    label: `Less than 1 month ago`
  },
  {
    value: `sleep_apnea_q_7_a2`,
    label: `1-6 months ago`
  },
  {
    value: `sleep_apnea_q_7_a3`,
    label: `More than 6 months ago`
  }
]