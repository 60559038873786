import { gql } from '@apollo/client'

export const regionsQuery = gql`
  query regions {
    countries {
      id
      available_regions {
        id
        code
        name
      }
    }
  }
`
