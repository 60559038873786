import React from 'react'
import TrackerStepIcon from '../../TrackerStepIcon'
import { checkmarkWhite } from 'resources/images'
import StepContentRenderer from '../../StepContentRenderer'
import { convertIsoDateToDateString } from 'components/accountTracker/utils/utils'

type SleepTherapyBeginsStepProps = {
  stepStatus: boolean;
  isCurrentStep?: boolean;
  isoTherapyDate: string;
}

function SleepTherapyBeginsStep({ stepStatus, isCurrentStep, isoTherapyDate } : SleepTherapyBeginsStepProps ) : JSX.Element {
  const getTherapyDate = convertIsoDateToDateString( isoTherapyDate )
  const complianceYoutubeLink = `https://www.youtube.com/watch?v=eOut3TmKDnE&t=118s`
  const complianceYoutubeLinkText = `What is Compliance?`

  const sleepTherapyBeginsCopy = {
    notCompletedStepHeading: `Sleep Therapy Begins`,
    currentStepDetails: `Date your Sleep Therapy Begins ${getTherapyDate.date}`,
    stepToolTip: `Your insurance requires that you meet certain compliance requirements. This includes two items: 1) usage and 2) 
    follow-up notes from your doctor once your usage requirement is met.`,
    completedStepHeading: `Sleep Therapy Started`,
    completedStepDetails: getTherapyDate.date,
    stepLinkText: complianceYoutubeLinkText,
    stepLinkUrl: complianceYoutubeLink,
    openLinkInNewTab: true,
    completedStepLinkText: complianceYoutubeLinkText,
    completedStepLinkUrl: complianceYoutubeLink
  }

  return (
    <div className="flex flex-wrap">
      <TrackerStepIcon
        imageSrc={checkmarkWhite}
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
        stepLinkUrl={sleepTherapyBeginsCopy.stepLinkUrl || ``}
        openLinkInNewTab={sleepTherapyBeginsCopy.openLinkInNewTab}
      />
      <StepContentRenderer
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
        stepCopy={sleepTherapyBeginsCopy}
      />
    </div>
  )
}

export default SleepTherapyBeginsStep