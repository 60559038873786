import React from 'react'
import { Builder } from '@builder.io/react'
import { ImageCarouselTypes } from './types'
import { SlickSlider } from './utils/SlickSlider'

type ImageCarouselProps = {
    slides: ImageCarouselTypes[];
    infiniteCarouselScroll: boolean;
    showDots: boolean;
    header: string;
}

export const ImageCarousel = ( props: ImageCarouselProps ) : JSX.Element => {
  return (
    <div className="sm:px-5 lg:px-7 py-7 lg:max-w-3xl mx-auto w-full rc-img-carousel">
      <p className="text-center font-family--inter font-normal text-xl text-gray-500 pb-4">{props.header}</p>
      <SlickSlider infiniteCarouselScroll={props.infiniteCarouselScroll} showDots={props.showDots}>
        {props.slides?.map( ( slide ) => (
          <div key={slide.altText} className="mb-7">
            <a className="flex justify-center focus-visible:no-underline" href={slide.url} target={slide.openInNewTab ? `_blank` : `_self`} rel="noopener noreferrer">
              <img src={slide.slideImage} alt={slide.altText} />
            </a>
          </div>
        ) )}
      </SlickSlider>
    </div>
  )
}

Builder.registerComponent( ImageCarousel, {
  name: `ImageCarousel`,
  inputs: [
    {
      name: `header`,
      type: `text`
    },
    {
      name: `slides`,
      type: `list`,
      required: true,
      subFields: [
        {
          name: `slideImage`,
          type: `file`,
          allowedFileTypes: [ `jpeg`, `jpg`, `png`, `svg`, `webp` ],
          required: true,
          helperText: `Upload .jpeg, .jpg, .png, .svg, or .webp`
        },
        {
          name: `url`,
          type: `text`,
          required: true
        },
        {
          name: `altText`,
          type: `text`,
          required: true
        },
        {
          name: `openInNewTab`,
          type: `boolean`,
          defaultValue: true
        }
      ]
    },
    {
      name: `infiniteCarouselScroll`,
      type: `boolean`,
      defaultValue: true
    },
    {
      name: `showDots`,
      type: `boolean`,
      defaultValue: true
    }
  ]
})


export default ImageCarousel