import React from 'react'
import ContentLoader from 'react-content-loader'

function ValuePropositionLoader() {

  return (
    <div className="text-center mt-12 md:mb-24 lg:w-10/12 lg:my-0 lg:mx-auto lg:mb-24 xl:w-8/12">
      <ContentLoader viewBox="0 0 100 10">
        <rect
          x="2" y="1" rx="2"
          ry="2" width="97" height="2"
        />
        <rect
          x="2" y="5" rx="2"
          ry="2" width="80" height="2"
        />
      </ContentLoader>
      <div className="grid justify-center grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7 pb-12">
        {
          [ ...Array( 4 ) ].map( ( _entry, i ) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <ContentLoader key={i} viewBox="0 0 100 60">
                <rect
                  x="4" width="90" height="50"
                  rx="3" ry="3"
                />
                <rect
                  x="4" y="52" rx="2"
                  ry="2" width="86" height="2"
                />
                <rect
                  x="4" y="56" rx="2"
                  ry="2" width="80" height="2"
                />
              </ContentLoader>
            )
          })
        }
      </div>
    </div>
  )
}

export default ValuePropositionLoader