import { useState } from 'react'
import useGetInvoiceByHash from './useGetInvoiceByHash'
import useResupplyMimicInvoice from './useResupplyMimicInvoice'

const useGetInvoice = ( type, setError ) => {

  const [ invoice, setInvoice ] = useState( null )
  const [ invoiceIneligible, setInvoiceIneligible ] = useState( false )

  useResupplyMimicInvoice( type, setInvoice, setError )

  useGetInvoiceByHash( type, setInvoice, setError, setInvoiceIneligible )

  return {
    invoice,
    setInvoice,
    invoiceIneligible
  }
}

export default useGetInvoice