export const transformWidget = ( node ) => {

  if ( node.type === `tag` && node?.attribs[`data-element`] === `mobile_image` ) return null
  if ( node.type === `tag` ) {
    node.attribs.style = ``
  }
  if ( node?.name === `h3` ) {
    node.attribs.class = `text-4xl mt-3`
  } else if ( node?.name === `img` ) {
    // change media folder from sleep to breastpumps ( should default to breastpumps but not the case in prod )
    node.attribs.src = node.attribs.src.replace( process.env.REACT_APP_MAGENTO_2_BASE_URL, process.env.REACT_APP_CMS_IMAGE_URL )
    node.attribs.class = `object-contain mx-auto`
  }
  if ( node?.attribs?.class?.includes( `qualify-plug-heading` ) ) {
    node.attribs.class = `mx-auto`
  }
}