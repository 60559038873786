import { gql } from '@apollo/client'

export const leadQuery = gql`
  query lead($leadId: String!) {
    lead(lead_id: $leadId) {
      id
      created_time
      field_data {
        name
        values
       }
    }
  }
`
