import React, { useState, useMemo } from 'react'
import Calendar from 'components/calendar'
import Appointment from 'types/appointment'
import { addZeroIfNeeded, getMDY } from '../utils'
import TimezoneSelector from 'components/timezoneSelector'
import { DAYS, MONTHS, canContactCopy } from '../utils/constants'
import OptionSelection from 'components/optionSelection'
import LoadingSpinner from 'components/LoadingSpinner'
import {Tooltip} from 'react-tooltip'
import TermsAndConditionsTooltip from 'components/termsAndConditionsTooltip/TermsAndConditionsTooltip'
import AnimatedHeightDiv from 'components/animatedHeightDiv/AnimatedHeightDiv'
import AppointmentButtons from './AppointmentButtons'
import { reportToSentry } from 'utils/reportToSentry'
import { CLINICAL_SCHEDULING_AOB_PATH } from 'modules/aobPayments/constants'

type OnlineAppointmentDisplayProps = {
  appointments: {
    [key: string]: Array<Appointment>;
  } | null;
  onlineOption: boolean;
  canContact: boolean;
  setCanContact: React.Dispatch<React.SetStateAction<boolean>>;
  timeZone: string | null;
  setTimeZone( _value: string | null ): void;
  appointment: Appointment | null;
  setAppointment: React.Dispatch<React.SetStateAction<Appointment | null>>;
  error: string | null;
}

function OnlineAppointmentDisplay({
  appointments,
  onlineOption,
  appointment,
  setAppointment,
  canContact,
  setCanContact,
  timeZone,
  setTimeZone,
  error
}: OnlineAppointmentDisplayProps ) : JSX.Element {

  const [ dayFromCal, setDayFromCal ] = useState<Date | null>( null )
  const [ selectedMonth, setSelectedMonth ] = useState<Date>( new Date() )

  const dateFromKey = ( key: string ):Date => {
    try {
      return new Date( parseInt( key.substring( 0, 4 ) ), parseInt( key.substring( 5, 7 ) ) - 1, parseInt( key.substring( key.length - 2, key.length ) ) )
    } catch ( error ) {
      reportToSentry( `CST: Non-typical date string key coming from appointments endpoint`, {
        key,
        error
      })

      return new Date()
    }
  }

  const getFirstApptDate = ( m: Date ) => {
    if ( appointments ) {
      const monthCompare = addZeroIfNeeded( m.getMonth() + 1 ).toString()

      const firstApptKey = sortedAppointmentKeys.find( apptKey => {
        return apptKey.substring( 5, 7 ) === monthCompare && appointments[apptKey]?.length
      })

      return firstApptKey ? new Date( appointments[firstApptKey][0].start_time.substring( 0, 16 ) ) : m
    }

    return m
  }

  const handleMonthChange = ( m: Date ) => {
    setAppointment( null )
    setDayFromCal( getFirstApptDate( m ) )
    setSelectedMonth( m )
  }

  const handleDayChange = ( d: Date | null ) => {
    setAppointment( null )
    setDayFromCal( d )
  }

  const [ appointmentDates, sortedAppointmentKeys ] = useMemo( () => {
    const apptKeys = Object.keys( appointments ?? {})

    apptKeys.sort( ( a, b ) => {
      return a < b ? -1 : 1
    })

    return [
      apptKeys.map( d => {
        const currentDate = d.split( `-` )

        return new Date(
          parseInt( currentDate[0] ),
          parseInt( currentDate[1] ) - 1,
          parseInt( currentDate[2] )
        )
      }),
      apptKeys
    ]
  }, [ appointments ] )

  const [ selectedDayLabel, times ] = useMemo( () => {

    if ( !dayFromCal ) return [ null, [] ]
    const { year, month, date, weekday } = getMDY( dayFromCal )
    if ( !year || typeof month !== `number` || typeof date !== `number` || typeof weekday !== `number` ) return [ null, [] ]

    const dateKey =`${year}-${addZeroIfNeeded(
      month + 1
    )}-${addZeroIfNeeded( date )}`

    const timesMap: { [key: string]: Appointment } = {}
    const times = appointments && appointments[dateKey] ? appointments[dateKey] : []
    times.forEach( item => {
      // filter out appointments with no spots open
      if ( item.spots_open > 0 ) timesMap[ item.start_time ] = item
    })

    return [
      `${DAYS[weekday]}, ${MONTHS[month]} ${addZeroIfNeeded( date )}`,
      Object.values( timesMap )
        .sort(
          ( a, b ) => {
            const dateA:Date = new Date( a?.start_time?.substring( 0, 16 ) )
            const dateB:Date = new Date( b?.start_time?.substring( 0, 16 ) )

            return dateA < dateB ? 1 : 0
          }
        )
    ]
  }, [ dayFromCal, appointments, appointmentDates ] )

  const [ fromMonth, toMonth ] = useMemo( () => {
    if ( !appointments || !sortedAppointmentKeys?.length ) {
      const constantFromMonth = new Date()
      const constantToMonth = new Date()
      constantToMonth.setMonth( constantToMonth.getMonth() + 3 )

      return [
        constantFromMonth,
        constantToMonth
      ]
    }

    const firstApptDate = dateFromKey( sortedAppointmentKeys[0] )

    setDayFromCal( firstApptDate )
    setSelectedMonth( firstApptDate )

    return [
      firstApptDate,
      dateFromKey( sortedAppointmentKeys[ sortedAppointmentKeys.length - 1 ] )
    ]
  }, [ appointments, sortedAppointmentKeys ] )

  const handleToggleCanContact = () => {
    return setCanContact( !canContact )
  }

  return (
    <AnimatedHeightDiv
      display={onlineOption}
      heightDependencies={[ dayFromCal, appointments, error ]}
    >
      <div className="flex flex-col py-8 overflow-hidden">
        <TimezoneSelector setTimezone={setTimeZone} timezone={timeZone} />
        {
          appointments !== null || error ?
            <div className="flex justify-center min-h-400 relative">
              <div className="md:w-max rounded-lg mx-3 md:px-12 lg:px-24 min-w-fit shadow-xl mb-10 items-center justify-center">
                <Calendar
                  initialMonth={appointmentDates.length > 0 ? new Date( appointmentDates[0] ) : new Date()}
                  dayFromCal={dayFromCal}
                  setDayFromCal={handleDayChange}
                  selectedDays={appointmentDates}
                  selectedMonth={selectedMonth}
                  handleMonthChange={handleMonthChange}
                  fromMonth={fromMonth}
                  toMonth={toMonth}
                />
              </div>
            </div>
            :
            <div className="my-10 md:my-20">
              <LoadingSpinner
                message={`Please hold tight while we get the available appointments...`}
                messageStyling={`text-deepSleepBlue text-center mb-10 font-light md:text-lg`}
              />
            </div>
        }
        <div className="px-3 md:px-10">
          {selectedDayLabel && times.length > 0 && (
            <>
              <div className="text-center text-2xl mb-2 text-deepSleepBlue">
                {selectedDayLabel}
              </div>
              <p className="text-center mb-5 text-deepSleepBlue font-bold">{timeZone}</p>
            </>
          )}
          <div className="flex flex-col items-center">
            <AppointmentButtons
              times={times}
              dayFromCal={dayFromCal}
              appointment={appointment}
              setAppointment={setAppointment}
              appointments={appointments}
            />
            {
              window.location.pathname !== CLINICAL_SCHEDULING_AOB_PATH &&
              <OptionSelection
                checked={canContact}
                handleToggle={handleToggleCanContact}
                containerStyling="md:my-5 bg-white md:bg-lightGray text-sm md:text-base max-w-2xl"
              >
                <div>
                  <span className="font-light text-sm md:text-base cursor-pointer" onClick={handleToggleCanContact}>{canContactCopy}</span>
                  <Tooltip
                    anchorSelect="sched-terms-tooltip"
                    className="ins-tooltip"
                  >
                    <TermsAndConditionsTooltip />
                  </Tooltip>
                  <span className="text-deepSleepBlue underline cursor-pointer" id="sched-terms-tooltip">{`Aeroflow Terms and Conditions.`}</span>
                </div>
              </OptionSelection>
            }
          </div>
          {error && (
            <div className="text-error text-center text-xs">{error}</div>
          )}
        </div>
      </div>
    </AnimatedHeightDiv>
  )
}

export default OnlineAppointmentDisplay