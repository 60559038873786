export const TIME_ZONES = [
  {
    name: `US/Pacific`,
    label: `PDT`,
    area: `America/Los_Angeles`
  },
  {
    name: `US/Mountain`,
    label: `MDT`,
    area: `America/Denver`
  },
  {
    name: `US/Central`,
    label: `CDT`,
    area: `America/Chicago`
  },
  {
    name: `US/Eastern`,
    label: `EST`,
    area: `America/New_York`
  }
]