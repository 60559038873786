import React from 'react'
import { useQuery } from '@apollo/client'
import { cmsBlocksQuery } from 'graphql/queries/cmsBlocks'
import FaqBlogBlockBackup from './FaqBlogBlockBackup'
import FaqBlogBlockWidget from './FaqBlogBlockWidget'
import FaqBlogContentLoader from './FaqBlogContentLoader'

export const FaqBlogBlock = () => {

  const { data, loading, error } = useQuery( cmsBlocksQuery, {
    errorPolicy: `ignore`,
    variables: {
      identifiers: [ `react-blog-widget`, `react-faq-widget` ]
    }
  })

  if ( loading ) return <FaqBlogContentLoader />

  if ( error || data?.cmsBlocks?.items?.length < 2 || !data?.cmsBlocks?.items[0]?.content || !data?.cmsBlocks?.items[1]?.content ) return <FaqBlogBlockBackup />

  return <FaqBlogBlockWidget items={data.cmsBlocks.items} />
}

export default FaqBlogBlock
