import { gql } from "@apollo/client"

export const setBillingAddressOnCartMutation = gql`
  mutation SetBilingAddressOnCartMutation($input : SetBillingAddressOnCartInput) {
    setBillingAddressOnCart(input : $input) {
      cart {
        billing_address {
          firstname
          lastname
          company
          street
          city
          region{
            code
            label
          }
          postcode
          telephone
          country{
            code
            label
          }
        }
    }
    }
  }
`