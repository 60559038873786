import React from 'react'
import PropTypes from 'prop-types'
import { fontsGoogleCaretDown } from 'resources/images'

const MenuDropdownButton = ({title, activeSubmenu, setActiveSubmenu, hasSubmenu, url}) => {
  const isActive = activeSubmenu === title

  const handleClick = () => {

    setActiveSubmenu( isActive ? null : title )
  }

  return (
    <li>
      <button
        type="button"
        className={`flex flex-row cursor-pointer items-center focus:outline-none mx-auto ${isActive ? `text-deepSleepBlue` : `font-light`} md:text-xs lg:text-sm`}
        onClick={hasSubmenu ? handleClick : null}
      >
        {hasSubmenu ? <span>{title}</span> : <a href={url} >{title}</a>}
        {hasSubmenu && <img
          className={`transform pl-1${isActive ? ` rotate-180` : ` rotate-0`}`}
          alt={`Toggle submenu dropdown`}
          src={fontsGoogleCaretDown}
          width={18}
          height={18}
        />}
      </button>
    </li>
  )
}
MenuDropdownButton.propTypes = {
  title: PropTypes.string.isRequired,
  activeSubmenu: PropTypes.string,
  setActiveSubmenu: PropTypes.func.isRequired,
  hasSubmenu: PropTypes.bool,
  url: PropTypes.string
}

export default MenuDropdownButton
