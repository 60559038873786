import { MAINTENANCE_PAGE_PATH, MAINTENANCE_PAGE_THANK_YOU_PATH } from "routes"

export const checkMaintenanceFlag = async () => {
  const response = await fetch( `${process.env.REACT_APP_SITE_MAINTENANCE_URL}/get-sleep-status` )

  return await response.json()
}

export const getMaintenancePageStatus = () => {
  const { location: { pathname }} = window

  return pathname === MAINTENANCE_PAGE_PATH || pathname === MAINTENANCE_PAGE_THANK_YOU_PATH
}