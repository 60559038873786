import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import AccountMenuDropdownWrapper from './AccountMenuDropdownWrapper'
import { useTokenStore } from 'stores/tokenStore'

const AccountMenuDropdown = ({ menuIsActive, onlyShowLogout, isMobile }) => {

  const { customerToken } = useTokenStore()

  if ( !menuIsActive ){
    return null
  }

  // for resupply pt's onlyShowLogout will be true
  if ( customerToken ) {
    return (
      <AccountMenuDropdownWrapper active={menuIsActive} isMobile={isMobile}>
        {!onlyShowLogout && <Link to="/account" className="text-deepSleepBlue sm:text-xs md:text-base">{`My Account`}</Link>}
        <Link to="/logout" className="text-deepSleepBlue sm:text-xs md:text-base">{`Log out`}</Link>
      </AccountMenuDropdownWrapper>
    )
  }
}
AccountMenuDropdown.propTypes = {
  menuIsActive: PropTypes.bool.isRequired,
  onlyShowLogout: PropTypes.bool,
  isMobile: PropTypes.bool
}

export default AccountMenuDropdown
