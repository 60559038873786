import { AuthBorderWrap, MigrationLoginForm } from "components/auth"
import PageNavigator from '../../components/pageNavigator'
import { ResupplyGateway } from '../resupplyGateway'
import React from 'react'

export default function LegacyLoginScreen() : JSX.Element {
  const [ patientData, setPatientData ] = React.useState<any>({})
  const resupplyGatewayPage= `/login-legacy/resupply-gateway`
  const handleSetPatientData = ( data: any ) => {
    setPatientData( data )
  }

  return (
    <AuthBorderWrap title={`Sign In`} backButton>
      <PageNavigator
        pageNames={[ `/login-legacy`, resupplyGatewayPage ]}
        childSharedProps={{
          patientData,
          handleSetPatientData
        }}
      >
        <MigrationLoginForm />
        <ResupplyGateway />
      </PageNavigator>
    </AuthBorderWrap>
  )
}