import { Link } from 'react-router-dom'

import { sleepLogoHorizontalBlack } from 'resources/images'
const Logo = ( props : {withoutLink : boolean, logoClasses? : string }) : JSX.Element => {
  const LogoImage = () => {
    return (
      <img
        className="object-contain"
        src={sleepLogoHorizontalBlack}
        alt={`Aeroflow Sleep Logo`}
        width="430"
        height="37"
      /> )
  }

  if ( props.withoutLink ) {
    return <LogoImage />
  }

  return (
    <Link to={`/`} className={props.logoClasses}>
      <LogoImage />
    </Link>
  )
}

export default Logo
