import React from 'react'
import { useSNLStore } from '../State/snlStorage'
import icoBlueCheckLg from 'resources/images/intermediate-svg/large-blue-checkmark.svg'
import { useNavigate } from 'react-router-dom'

interface EditSelectionProps {
  title: string
  optionLabel: string
  step: string
  path: string
  transparentBackground?: boolean
}

interface EditSelectionComponentProps {
  transparentBackground?: boolean;
  showSummary?: boolean;
  activeStep: number;
}

export const EditSelectionItem = ({ title, optionLabel, path, transparentBackground}: EditSelectionProps ) => {
  const navigate = useNavigate()

  return (
    <div className="sm:mb-2 md:mb-[18px]">
      <div
        className={`flex items-stretch rounded-2xl overflow-hidden ${transparentBackground ? `` : `bg-[#fff]`}`}
        style={{
          boxShadow: `0px 4px 4px 0px rgba(0, 0, 0, 0.25)`
        }}
      >
        <div className="bg-[#BBEBFC] basis-[57px] shrink-0 flex items-center justify-center">
          <img src={icoBlueCheckLg} alt="blue check" />
        </div>
        <div className="flex flex-col p-3">
          <p className="font-inter">{title}</p>
          <div>
            <div className="flex text-deepSleepBlue">
              <p>{`${optionLabel}`}</p>
              <p className="px-1">{`-`}</p>
              <p className="underline cursor-pointer" onClick={() => navigate( path )}>{`Edit`}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const EditSelection = ({ transparentBackground, activeStep }: EditSelectionComponentProps ) => {
  const { snlEditSelection } = useSNLStore()
  const selection = snlEditSelection()

  const maskSteps = [ `cpapMaskType`, `cpapMaskBrand`, `cpapMaskModel` ]
  const machineSteps = [ `cpapMachineBrand`, `cpapMachineModel` ]

  const getMaskSelections = selection.filter( item => maskSteps.includes( item.step ) )
  const getMachineSelections = selection.filter( item => machineSteps.includes( item.step ) )

  /*
    Return a dynamic array of completed SAP steps
    - on mask steps, show all mask slections and show the last completed machine step( if any was completed)
    - on machine steps, show all machine selections, and the last completed mask step( if any was completed)
    - on the summary page, show the Mask Model(if there is one) and the Machine Model
  */

  const getSelections = () => {
    if ( activeStep === undefined || selection.length === 0 ) return [] // no selections will be available on the 1st load
    const showSummary = activeStep === 6

    if ( activeStep < 4 ) { // mask steps
      return [
        ...( getMachineSelections.length > 0 ? [ getMachineSelections.pop() ] : [] ),
        ...getMaskSelections
      ]
    } else if ( activeStep > 3 && !showSummary ) { // machine steps
      return [
        ...( getMaskSelections.length > 0 ? [ getMaskSelections.pop() ] : [] ),
        ...getMachineSelections
      ]
    } else { // summary page
      return selection.filter( item => !showSummary || [ `cpapMaskModel`, `cpapMachineModel` ].includes( item.step ) )
    }
  }

  const filteredSelections = getSelections()

  return (
    <div>
      {filteredSelections.length > 0 && filteredSelections.map( ( item ) => (
        <EditSelectionItem
          key={item?.label || ``}
          title={item?.label || ``}
          optionLabel={item?.value || ``}
          step={item?.step || ``}
          path={item?.path || ``}
          transparentBackground={transparentBackground}
        />
      ) )}
    </div>
  )
}