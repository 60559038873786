export const newsLetterSubmit = async ( email: string ) => {
  const payload = {
    "contacts": [
      {
        "email": email
      }
    ],
    "form_code": `newsletter_list`
  }
  const response = await fetch( `${process.env.REACT_APP_LUXSCI_NEWSLETTER_URL}/contacts`, {
    method: `PUT`,
    headers: {
      "Content-Type": `application/json`,
      "x-api-key": `${process.env.REACT_APP_LUXSCI_NEWSLETTER_API_KEY}`
    },
    body: JSON.stringify( payload )
  })
  return await response.json()
}