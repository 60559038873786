import { useAddressStore } from "../state"
import { Address } from "../types"
import Highlight from "./Highlight"

export default function HighlightP({ verifiedAddress, className } : { verifiedAddress: Address, className?: string }) : JSX.Element {
  const { address } = useAddressStore()

  // remove street 2 from street 1 for highlight
  const street1 = verifiedAddress.street2 ? address.street.replace( ` ${verifiedAddress.street2}`, `` ) : address.street

  return (
    <p className={className ?? `text-center self-start`}>
      <Highlight highlightClassName="font-bold uppercase" shouldNotHighlight={street1?.toLowerCase() === verifiedAddress.street?.toLowerCase()}>
        {verifiedAddress.street}
      </Highlight>{`, `}
      <Highlight highlightClassName="font-bold uppercase" shouldNotHighlight={address.city?.toLowerCase() === verifiedAddress.city?.toLowerCase()}>{verifiedAddress.city}</Highlight>{`, `}
      <Highlight highlightClassName="font-bold uppercase" shouldNotHighlight={address.state?.toLowerCase() === verifiedAddress.state?.toLowerCase()}>{verifiedAddress.state}</Highlight>{` `}
      <Highlight highlightClassName="font-bold uppercase" shouldNotHighlight={address.zipCode === verifiedAddress.zipCode}>{verifiedAddress.zipCode}</Highlight>
    </p>
  )
}