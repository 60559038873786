import { useNavigate } from "react-router-dom"
export default function ChangePasswordPrompt({email} : {email: string}) {

  const navigate = useNavigate()
  return (
    <p
      className="a mt-5"
      onClick={() => {
        navigate( `/request-password-reset`, {
          state: {
            email
          }
        })
      }}
    >
      {`Change Password`}
    </p>
  )
}