import React, { useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { fetchTermsAndConditions } from 'modules/aobPayments/fetchPromises'
import PropTypes from 'prop-types'
import { reportToSentry } from 'utils/reportToSentry'
import LoadingSpinner from 'components/LoadingSpinner'


function TermsAndConditionsTooltip() {

  const [ aobContent, setAobContent ] = useState( null )

  useEffect( () => {
    const controller = new AbortController()

    fetchTermsAndConditions( controller.signal )
      .then( data => {
        const aob_content = data?.data?.aob_content ?? data?.[0]?.aob_content
        if ( aob_content ) setAobContent( aob_content )
        else setAobContent( null )
      })
      .catch( error => {
        if ( error.name !== `AbortError` ) {
          reportToSentry( error )
          setAobContent( null )
        }
      })

    return () => {
      controller.abort()
    }
  }, [] )


  const transform = ( node ) => {
    if ( node?.name === `style` ) return null
    if ( node?.name === `p` ) {
      node.attribs.class = `my-5`
    }
    if ( node?.name === `span` ) {
      if ( node?.attribs?.class?.includes( `s_6555B096` ) ) node.attribs.class = `font-bold text-lg`
      if ( node?.attribs?.class?.includes( `s_4A6E6CA9` ) ) node.attribs.class = `font-bold text-xl uppercase`
    }
  }

  return (
    <div className="max-h-96 overflow-y-scroll w-11/12">
      <div className={`px-2 text-left`}>
        {
          aobContent ?
            <>
              {
                ReactHtmlParser( aobContent, {
                  transform
                })
              }
            </>
            :
            <div className="my-10">
              <LoadingSpinner />
            </div>
        }
      </div>
    </div>
  )
}

TermsAndConditionsTooltip.propTypes = {
  aobContent: PropTypes.string
}

export default TermsAndConditionsTooltip