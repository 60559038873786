import { Validators } from "components/forms/components"
import { regexErrors } from "components/forms/constants"

// map of field names to errorMessages
export const errorMessages = {
  username: regexErrors.email,
  dobMonth: regexErrors.expmm,
  dobDay: regexErrors.dd,
  dobYear: regexErrors.dob_yyyy,
  firstName: regexErrors.first_name,
  lastName: regexErrors.last_name,
  phoneNumber: regexErrors.phone,
  zipCode: regexErrors.zip,
  terms: regexErrors.terms
}

// Map of field names to Validator functions
export const validators = {
  username: Validators.email,
  dobDay: Validators.dd,
  firstName: Validators.name,
  lastName: Validators.name,
  phoneNumber: Validators.phone,
  terms: ( _isChecked ) => { return _isChecked }
}

export const Validate = ( name, value ) => {
  const validate = getNamedValidator( name )
  const isValid = Boolean( value ) && validate( value )
  if ( isValid ) return ``

  return getNamedErrorMessage( name )
}

export const getNamedErrorMessage = ( name ) => {
  return errorMessages[name] ?? regexErrors[name] ?? `Please complete this field`
}

export const getNamedValidator = ( name ) => {
  const isNotNullish = ( value ) => { return Boolean( value ) } // False-ish, so 0 is included here

  return validators[name] || Validators[name] || isNotNullish
}

export const ValidatePasswordMatch = ( password, passwordConfirmation ) => {
  return ( password === passwordConfirmation ) ? `` : regexErrors.passwordConfirmation
}