import FormNavigator from 'components/formNavigator'
import React from 'react'
import { Lead, Qualify, Submit } from './pages'
import PropTypes from 'prop-types'
import Container from '../../components/Container.tsx'
import QualifyFormCallToAction
  from '../../components/builderComponents/qualifyThroughInsurance/QualifyFormCallToAction.tsx'
import { useQualifyFormStore } from './state'
import OfflineLeadSubmitThankYou from '../../components/maintenance/OfflineLeadSubmitThankYou'

export default function QualifyForm({ isSiteOffline = false, isEmbedded = false, classNames }) {
  const { setSubmitStepActive, skipSubmitted, setSkipSubmitted } = useQualifyFormStore()

  const handleOfflineRequest = () => {
    setSkipSubmitted( true )
  }
  const handleLastPage = () => {
    setSubmitStepActive( true )
  }

  return (
    <Container classNames={!classNames ? `sm:px-6 md:px-10 pb-28` : classNames}>
      {/* render QualifyFormCallToAction with default copy if isEmbedded or in maint. mode */}
      {( isEmbedded || isSiteOffline ) &&
        <QualifyFormCallToAction
          useDefaultCopy={isEmbedded || isSiteOffline}
          isSiteOffline={isSiteOffline}
          isEmbedded={isEmbedded}
        />
      }
      <form>
        <FormNavigator
          sessionStorageKey="qualify-form-navigator-index"
          childSharedProps={{
            isSiteOffline,
            isEmbedded
          }}
          onLastPage={handleLastPage}
        >
          <Qualify />
          <Lead onHandleOfflineSubmit={handleOfflineRequest} />
          {!skipSubmitted && <Submit />}
          {skipSubmitted && <OfflineLeadSubmitThankYou />}
        </FormNavigator>
      </form>
    </Container>
  )
}

QualifyForm.propTypes = {
  isSiteOffline: PropTypes.bool,
  isEmbedded: PropTypes.bool,
  classNames: PropTypes.string,
  onHandleOfflineSubmit: PropTypes.func
}
